import { WorkflowDataDto } from '../interfaces';
import { LayerData } from './timelines.helpers';

export const SEEK_OFFSET = 1000;

export function getPreviousSectionTransitionOffset(sectionId: string, workflow: WorkflowDataDto) {
  const mainTimeline = workflow.timelines.find((t) => t.type === 'main');
  const sectionIndex = mainTimeline.layers.findIndex((l) => l.type === 'section' && l.sectionId === sectionId);

  if (sectionIndex === 0) {
    return 0;
  }

  const previousSectionLayer = mainTimeline.layers[sectionIndex - 1];

  let transitionOffset = 0;
  if (previousSectionLayer.transitions?.crossLayer) {
    transitionOffset = previousSectionLayer.transitions.crossLayer.midpoint;
  } else if (previousSectionLayer.transitions?.cross) {
    transitionOffset = previousSectionLayer.transitions.cross.duration / 2;
  } else if (previousSectionLayer.transitions?.exit) {
    transitionOffset = previousSectionLayer.transitions.exit.duration / 2;
  }

  return transitionOffset;
}

export function getSectionSeekTimestampFromId(sectionId: string, workflow: WorkflowDataDto) {
  let durationOfSectionsBeforeCurrentSection = 0;
  const sectionLayers = workflow.timelines.find((timeline) => timeline.type === 'main').layers;
  for (const layer of sectionLayers) {
    if (layer.type === 'section') {
      if (layer.sectionId === sectionId) {
        break;
      }
      if (layer.enabled) {
        durationOfSectionsBeforeCurrentSection += workflow.sections[layer.sectionId].sectionDuration;
      }
    }
  }

  const sectionDuration = workflow.sections[sectionId].sectionDuration;
  const offset = Math.min(SEEK_OFFSET, sectionDuration / 2);

  return {
    sectionTimestampStart: durationOfSectionsBeforeCurrentSection,
    sectionTimestampEnd: durationOfSectionsBeforeCurrentSection + sectionDuration,
    sectionDuration,
    offset,
  };
}

export function getLayerSeekTimestampFromId(layerData: LayerData, workflow: WorkflowDataDto) {
  const sectionTimestampStart = getSectionSeekTimestampFromId(layerData.sectionId, workflow).sectionTimestampStart;

  const layerDuration = (layerData.layer.visibility?.endAt ?? 0) - (layerData.layer.visibility?.startAt ?? 0);
  const offset = Math.min(SEEK_OFFSET, layerDuration / 2);

  const layerTimestampStart = sectionTimestampStart + (layerData.layer.visibility?.startAt ?? 0);
  const layerTimestampEnd = layerTimestampStart + layerDuration;

  return {
    sectionTimestampStart,
    layerTimestampStart,
    layerTimestampEnd,
    offset,
  };
}
