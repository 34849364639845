import { TemplateBuilderAsset, TemplateBuilderTextOverlayTransition } from '../interfaces/template-builder.interfaces';
import { createLottieAsset } from '../template-builder/createLottieAsset';
import { WorkflowTemplatesBaseBuilder } from './workflow-templates-base.builder';

export interface BuilderTextOverlayChangeCommandEvent {
  textOverlay: TemplateBuilderAsset;
  textOverlayTransition: TemplateBuilderTextOverlayTransition;
}

export class BuilderTextOverlayChangeCommand extends WorkflowTemplatesBaseBuilder<BuilderTextOverlayChangeCommandEvent> {
  run(event: BuilderTextOverlayChangeCommandEvent) {
    this.updateTextOverlayAsset(event);
    this.updateExistingMainScenes();

    return this.ok();
  }

  private updateTextOverlayAsset(event: BuilderTextOverlayChangeCommandEvent) {
    const hasAsset = !!event.textOverlay.assetFileId;

    if (hasAsset) {
      const existingAssetId = this.source.globalSettings.textOverlays?.assetId;
      if (existingAssetId) {
        this.source.assets = this.source.assets.filter((a) => a.id !== existingAssetId);
      }

      const { asset } = createLottieAsset(event.textOverlay, this.source.globalSettings);
      if (existingAssetId) {
        asset.id = existingAssetId;
      }
      this.source.assets.push(asset);
      this.source.globalSettings.textOverlays = { assetId: asset.id };

      if (event.textOverlayTransition?.type === 'fade-out') {
        this.source.globalSettings.textOverlays.transitions = {
          exit: {
            type: event.textOverlayTransition.type,
            duration: event.textOverlayTransition.duration,
            color: '#00000000',
          },
        };
      }
    } else {
      delete this.source.globalSettings.textOverlays;
    }
  }

  private updateExistingMainScenes() {
    const hasTextOverlay = !!this.source.globalSettings.textOverlays?.assetId;

    for (const [sectionId, section] of Object.entries(this.source.sections)) {
      if (section.sectionType !== 'main') {
        continue;
      }

      const overlayTimelines = section.timelines.filter((t) => t.type === 'overlays');
      for (const overlayTimeline of overlayTimelines) {
        overlayTimeline.layers = [];

        if (hasTextOverlay) {
          this.addTextOverlayToSection(sectionId);
        }
      }
    }
  }
}
