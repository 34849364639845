import { getLayerFromId } from '../helpers/timelines.helpers';
import { TEXT_BOX_PRESET_FIELD_NAME } from '../constants';
import { Bounds, LayerIdEvent } from '../interfaces';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export interface UpdateTextBoxFontSizeEvent extends LayerIdEvent {
  fontSize: number;
  bounds?: Bounds;
}

export class UpdateTextBoxFontSizeCommand extends WorkflowBaseBuilder<UpdateTextBoxFontSizeEvent> {
  run({ layerId, fontSize, bounds }: UpdateTextBoxFontSizeEvent) {
    const layerData = getLayerFromId(layerId, this.source);

    if (layerData?.layer.type !== 'lottie') {
      return this.error('Layer type is not lottie.');
    }
    if (layerData?.timeline.type !== 'text-boxes') {
      return this.error('Timeline type is not text-boxes.');
    }

    const fieldData = layerData.layer.data[TEXT_BOX_PRESET_FIELD_NAME];

    this.applyLayerChanges(layerData.layer, {
      values: {
        [TEXT_BOX_PRESET_FIELD_NAME]: fieldData,
      },
      assetChanges: {},
      styleChanges: {
        [TEXT_BOX_PRESET_FIELD_NAME]: {
          fontSize,
        },
      },
    });

    this.updateTextLayerBounds(layerData.layer, bounds);

    return this.ok();
  }
}
