import { getAssetFinalDuration, getTimelineById } from '../helpers';
import { LayerOptions } from '../interfaces';
import { AddClipsEvent } from '../interfaces/timelines.interfaces';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export class AddVideoOverlaysCommand extends WorkflowBaseBuilder<AddClipsEvent> {
  run({ clip, timelineId }: AddClipsEvent) {
    const { timeline, sectionId } = getTimelineById(timelineId, this.source.sections);

    const options: Partial<LayerOptions> = {
      bounds: timeline.bounds ?? { x: 0, y: 0, width: 100, height: 100 },
      styles: timeline.styles,
    };

    timeline.layers = [];

    const layer = this.addAssetLayer(timeline, clip, options);
    const newAsset = this.source.assets.find((a) => a.id === layer.assetId);

    const mainDuration = this.source.sections[sectionId].sectionDuration;
    const clampedDuration = Math.min(getAssetFinalDuration(newAsset), mainDuration);

    layer.visibility = {
      startAt: 0,
      endAt: clampedDuration,
    };

    return this.ok();
  }
}
