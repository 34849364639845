import { VideoMetadataType } from './constants/video-metadata.contants';
import { CaptionEditorTimebox, CaptionSpeaker } from './interfaces/caption.interface';
import { ASSStrategy, ASSStyleWithFont } from './subtitles-builder/strategies/ass-strategy/ass-strategy';
import { SRTStrategy } from './subtitles-builder/strategies/srt-strategy/srt-strategy';
import { SubtitleBuilderStrategy } from './subtitles-builder/strategies/subtitle-builder-strategy';
import { VTTStrategy } from './subtitles-builder/strategies/vtt-strategy/vtt-strategy';

export class SubtitlesBuilder {
  private builderStrategy: SubtitleBuilderStrategy = null;

  constructor(
    timeboxes: CaptionEditorTimebox[],
    speakers: CaptionSpeaker[],
    offset: number,
    type: VideoMetadataType.SRT | VideoMetadataType.VTT | VideoMetadataType.ASS,
    resolution: { width: number; height: number },
    style?: ASSStyleWithFont
  ) {
    switch (type) {
      case VideoMetadataType.VTT:
        this.builderStrategy = new VTTStrategy(timeboxes, speakers, offset);
        break;
      case VideoMetadataType.SRT:
        this.builderStrategy = new SRTStrategy(timeboxes, speakers, offset);
        break;
      case VideoMetadataType.ASS:
        this.builderStrategy = new ASSStrategy(timeboxes, speakers, offset, resolution, style);
        break;
    }
  }

  static buildFromParagraphs(
    timeboxes: CaptionEditorTimebox[],
    speakers: CaptionSpeaker[],
    offset: number,
    type: VideoMetadataType.SRT | VideoMetadataType.VTT | VideoMetadataType.ASS,
    resolution: { width: number; height: number },
    style?: ASSStyleWithFont
  ): string {
    const builder = new SubtitlesBuilder(timeboxes, speakers, offset, type, resolution, style);
    builder.buildCues();

    return builder.getSubtitles();
  }

  buildCues() {
    this.builderStrategy.buildCues();
  }

  getSubtitles() {
    return this.builderStrategy.getHeader() + this.builderStrategy.getContent();
  }
}
