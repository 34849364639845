import { cloneDeep } from 'lodash';
import { TemplateSchemaTransformation, TemplateSchemaTransformationOptions } from '../../../interfaces';
import { TemplateWorkflowDataDto_1_5 } from '../template_transformation_1_5/schema_after';
import { TemplateWorkflowDataDto_1_6 } from './schema_after';

export const TemplateSchemaTransformation_1_6: TemplateSchemaTransformation = {
  version: '1.6',

  run(
    oldSchema: TemplateWorkflowDataDto_1_5.WorkflowDataDto,
    { aspectRatios }: TemplateSchemaTransformationOptions
  ): TemplateWorkflowDataDto_1_6.WorkflowDataDto {
    const newSchema = cloneDeep(oldSchema) as unknown as TemplateWorkflowDataDto_1_6.WorkflowDataDto;

    newSchema.templateSections[TemplateWorkflowDataDto_1_6.SectionId.Intro].sections = aspectRatios.map(({ id }) => {
      return {
        ...newSchema.templateSections[TemplateWorkflowDataDto_1_6.SectionId.Intro].sections[0],
        aspectRatioId: id,
      };
    });

    newSchema.templateSections[TemplateWorkflowDataDto_1_6.SectionId.Outro].sections = aspectRatios.map(({ id }) => {
      return {
        ...newSchema.templateSections[TemplateWorkflowDataDto_1_6.SectionId.Outro].sections[0],
        aspectRatioId: id,
      };
    });

    return newSchema;
  },
};
