<div class="topbar">
	<h2 class="top-title" style="float:left;">{{title}}</h2>
	  <div class="admin-nme">
		  	<div class="welcome-txt">Welcome</div>
			<div class="admin-name">{{username}}</div>
  	  </div>
		<div class="admin-thumb">
	  		<ngx-avatar [name]="username" bgColor="#3546ff" size="35"></ngx-avatar>			
	  </div>

</div>