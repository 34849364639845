import { FontDto } from '@openreel/common';
import { getLayerFromId } from '../helpers';
import { DEFAULT_FONT_WEIGHT, LayerIdEvent, Style } from '../interfaces';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export interface UpdateTextBoxStyleEvent extends LayerIdEvent {
  style: Style;
  font?: FontDto;
}

export class UpdateTextBoxStyleCommand extends WorkflowBaseBuilder<UpdateTextBoxStyleEvent> {
  run({ style, layerId, font }: UpdateTextBoxStyleEvent) {
    const layerData = getLayerFromId(layerId, this.source);
    if (!layerData) {
      return this.error(`Cant find layer with ID: ${layerId}`);
    }

    if (layerData.layer.type !== 'lottie' || !layerData.layer.isTextBox) {
      return this.error('Layer is not a lottie text box layer.');
    }

    const styleIndex = this.source.styles.findIndex((s) => s.id === style.id);
    if (styleIndex === -1) {
      return this.error('Style not found.');
    }

    const newStyle: Style = {
      ...this.source.styles[styleIndex],
      ...style,
    };

    if (font) {
      if (
        newStyle.fontWeight !== DEFAULT_FONT_WEIGHT &&
        !font?.variants.find((variant) => variant.slug === String(newStyle.fontWeight))
      ) {
        newStyle.fontWeight = DEFAULT_FONT_WEIGHT;
      }

      if (newStyle.fontStyle !== 'normal' && !font?.variants.find((variant) => variant.slug === newStyle.fontStyle)) {
        newStyle.fontStyle = 'normal';
      }
    }

    this.source.styles[styleIndex] = newStyle;

    this.updateTextLayerBounds(layerData.layer);

    return this.ok();
  }
}
