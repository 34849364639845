import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
declare const gapi;
declare const google;
@Injectable({ providedIn: 'root' })
export class YoutubeStreamingService {
  initYoutube() {
    if (environment.googleApiKey) {
      this.loadClient();
    }
  }
  authenticate() {
    return new Promise((resolve, reject) => {
      google.accounts.oauth2
        .initTokenClient({
          client_id: environment.googleClientId,
          scope: 'https://www.googleapis.com/auth/youtube',
          callback: (response) => {
            if (response.error) {
              reject(response.error);
            } else {
              resolve(response);
            }
          },
        })
        .requestAccessToken();
    });
  }
  loadClient() {
    gapi.load('client', () => {
      gapi.client.init({}).then(() => {
        gapi.client.setApiKey(environment.googleApiKey);
        gapi.client.load('https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest', '3');
      });
    });
  }
  async getYoutubeRtmpStream(title: string) {
    const liveBroadcast = await this.createLiveBroadcast(title);
    const liveStream = await this.createLiveStream(title);
    await this.attachStreamToBroadcast(liveBroadcast.result.id, liveStream.result.id);
    return liveStream;
  }
  createLiveBroadcast(title: string) {
    return gapi.client.youtube.liveBroadcasts.insert({
      part: ['snippet', 'status', 'contentDetails'],
      resource: {
        snippet: {
          scheduledStartTime: new Date().toISOString(),
          title: title,
        },
        contentDetails: {
          enableAutoStart: true,
          enableAutoStop: true,
        },
        status: {
          privacyStatus: 'public',
        },
      },
    });
  }
  createLiveStream(title: string) {
    return gapi.client.youtube.liveStreams.insert({
      part: ['snippet', 'cdn'],
      resource: {
        snippet: {
          title: title,
        },
        cdn: {
          format: '',
          ingestionType: 'rtmp',
          resolution: 'variable',
          frameRate: 'variable',
        },
      },
    });
  }
  setLiveStatus(broadcastId: string) {
    return gapi.client.youtube.liveBroadcasts.transition({
      broadcastStatus: 'live',
      id: broadcastId,
      part: ['id'],
    });
  }
  attachStreamToBroadcast(broadcastId: string, streamId: string) {
    return gapi.client.youtube.liveBroadcasts.bind({
      id: broadcastId,
      part: ['id'],
      streamId: streamId,
    });
  }
}
