import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { commonenv } from '../../environments/environment';
import {
  AppUser,
  DirectorUserDetails,
  IAccountSelectRespDto,
  ILoginRespDto,
  InvitationsValidateDto,
  InvitationsValidateResponseDto,
  LoginViaTokenResp,
  NewAccessTokenResponse,
  RegistrationRespDto,
  UserRoleType,
} from '../../interfaces/interfaces';
import { CaptureSessionUser, IUserAssociatedAccount, IUserDetails } from '@openreel/common';

import { ToastrService } from 'ngx-toastr';
import { ROUTE_DIRECTOR_LOGIN } from '../../route-utils';
import { FeatureFlaggingService } from '../feature-flagging/feature-flagging.service';
import { CLOSE_EMBED_UPGRADE_BAR } from '../../hosting/constants';
import { parseJwt } from '../../jwt.util';

const TOKEN_EXPIRED_MESSAGE = 'Your Token has expired. Please Login again';

interface ForgotPasswordResponse {
  status: number;
  message: string;
}

interface AccessDetails {
  hostingAllowed?: boolean;
  webinarAllowed?: boolean;
  creatorAllowed?: boolean;
  captureAllowed?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  /**
   * Cookie Data
   */
  private cookieName = 'openreel-user';
  private ugcCookieName = 'openreel-ugc-user';
  private expireAfter = 30;

  /**
   * urls
   */
  private urls = {
    login: commonenv.nextGenApiUrl + 'login',
    logout: commonenv.nextGenApiUrl + 'logout',
    collaborator_login: commonenv.nextGenApiUrl + 'invitations/validate',
    forgotPasswordEmail: commonenv.nextGenApiUrl + 'forgot-password-send-link',
    updateUserPassword: commonenv.nextGenApiUrl + 'user/password-reset',
    forgotPasswordVerify: (email, token) => `${commonenv.nextGenApiUrl}user/verify-user-email/${email}/${token}`,
    accountLogin: commonenv.nextGenApiUrl + 'user-account-login/',
    existingUserRegistration: commonenv.nextGenApiUrl + 'registration/existing-user',
    user: commonenv.nextGenApiUrl + 'user',
    inviteInternalUserTokenVerify: (token: string) => `${commonenv.nextGenApiUrl}company/accept-invitation/${token}`,
    leaveTeam: (id: number) => `${commonenv.nextGenApiUrl}seats/leave/${id}`,
    newToken: `${commonenv.nextGenApiUrl}token`,
    otp2FaVerify: (jwtToken: string) => commonenv.nextGenApiUrl + `tfa/verify-code/${jwtToken}`,
    reSendTfaOtp: (jwtToken: string) => commonenv.nextGenApiUrl + `tfa/re-send-code/${jwtToken}`,
    socialUserLogin: commonenv.nextGenApiUrl + 'login/social-user',
  };

  private logoutSource = new Subject();
  logout$ = this.logoutSource.asObservable();
  public isAuthenticated$ = new BehaviorSubject(false);
  public token$ = new BehaviorSubject(null);
  public role$ = new BehaviorSubject(null);
  public data$ = new BehaviorSubject<IUserDetails>(null);
  public role: UserRoleType;
  public selectedAccountId$ = new BehaviorSubject<number>(null);
  public userId$ = new BehaviorSubject<number>(null);
  public loginData$ = new BehaviorSubject<AppUser<Partial<IUserDetails>>>(null);

  associated_accounts?: IUserAssociatedAccount[] = [];
  available_accounts?: IUserAssociatedAccount[] = [];

  public jwt$ = new BehaviorSubject<string>(sessionStorage.getItem('jwt-token'));
  public liteUser$ = this.jwt$.pipe(map((token) => (token ? parseJwt<CaptureSessionUser>(token) : null)));

  private _logoutConfirmed = false;

  userDetails$: Observable<DirectorUserDetails> = this.selectedAccountId$.pipe(
    distinctUntilChanged(),
    switchMap((accountId) =>
      combineLatest([this.userId$, this.role$]).pipe(
        distinctUntilChanged((x, y) => x[0] === y[0] && x[1] === y[1]),
        switchMap(([id, role]) =>
          accountId && id && role === UserRoleType.Internal ? this.fetchUserDetails() : of(null)
        ),
        tap((details) => {
          if (details) {
            this.featureFlaggingService.setAccountFeatureFlags(details.featureFlags);
          }
        })
      )
    ),
    shareReplay(1)
  );

  accessDetail$: Observable<AccessDetails> = this.userDetails$.pipe(map((details) => details?.site_users));

  private fetchUserDetails() {
    return this.httpClient.get<DirectorUserDetails>(this.urls.user, {});
  }

  get isLogoutConfirmed(): boolean {
    return this._logoutConfirmed;
  }

  constructor(
    private readonly cookieService: CookieService,
    private readonly httpClient: HttpClient,
    private readonly router: Router,
    private readonly toastr: ToastrService,
    private readonly featureFlaggingService: FeatureFlaggingService
  ) {
    this.restore();
  }

  // used to show warning on logout
  private busyProcesses: string[] = [];

  setBusy(processId: string): void {
    this.busyProcesses.push(processId);
  }

  setIdle(processId: string): void {
    this.busyProcesses = this.busyProcesses.filter((p) => p !== processId);
  }

  /**
   * Restore token from cookies
   */
  restore() {
    const user = this.getUserDetails();
    if (user) {
      this.isAuthenticated$.next(true);
      this.token$.next(user.auth_token);
      this.selectedAccountId$.next(user.data.account_id);
      this.role = user.role;
      this.role$.next(this.role);
      this.data$.next(user.data);
      this.userId$.next(user.data.user_id);
    } else {
      this.isAuthenticated$.next(false);
      this.token$.next(null);
      this.role$.next(null);
      this.role = null;
      this.data$.next(null);
      this.selectedAccountId$.next(null);
      this.userId$.next(null);
    }
  }

  /**
   *
   */
  isLoggedIn() {
    return this.isAuthenticated$.getValue();
  }

  isInternalUser() {
    if (this.isAuthenticated$.getValue() && this.role === UserRoleType.Internal && this.selectedAccountId$.getValue()) {
      return true;
    } else {
      return false;
    }
  }

  isInternalUser$ = this.isAuthenticated$.pipe(
    map((authenticated) => authenticated && this.role === UserRoleType.Internal)
  );

  isRootAdmin() {
    const cookieData = this.getUserDetails();
    if (cookieData) {
      return cookieData.data.is_root_admin;
    }
    return false;
  }

  getCurrentToken(): string {
    return this.token$.value;
  }

  getCurrentJwt(): string {
    return this.jwt$.value;
  }

  injectAuthData(user: AppUser<IUserDetails>): void {
    this.setCookie(JSON.stringify(user));
    this.restore();
  }

  collaborator_login(email: string, token: string, password: string) {
    const data: InvitationsValidateDto = {
      email,
      token,
      password,
    };
    return this.httpClient.post(this.urls.collaborator_login, data).pipe(
      tap((res: InvitationsValidateResponseDto) => {
        const data: IUserDetails = {
          account_id: res.account_id,
          session_id: res.session_id,
          email: res.user_email,
          name: res.user_mapping.name,
          loginId: res.user_mapping.id,
          company_name: null,
          site_user_slug: null,
          webinarAllowed: false,
          hostingAllowed: false,
          creatorAllowed: false,
          captureAllowed: false,
          loggedin_fullname: null,
          is_root_admin: false,
          user_id: null,
          image: null,
          defaultCaptureAccessLevel: null,
          defaultCreatorAccessLevel: null,
          defaultHostingAccessLevel: null,
          defaultWebinarAccessLevel: null,
        };

        const user: AppUser<typeof data> = {
          auth_token: res.email_token,
          invite_email_token: token,
          role: res.user_mapping?.role,
          data,
        };
        this.isAuthenticated$.next(true);
        this.token$.next(user.auth_token);
        this.role = user.role;
        this.role$.next(this.role);
        this.data$.next(data);
        this.setCookie(JSON.stringify(user));
      })
    );
  }

  /**
   * Submit the login request
   *
   * @username
   * @password
   * @remember
   */
  login(username, password, remember) {
    const data = {
      email: username,
      password,
      remember,
      ovra_access: true,
      source: 2,
      product: 3,
    };
    return this.httpClient.post(this.urls.login, data, { withCredentials: true }).pipe(
      tap((res: ILoginRespDto) => {
        if (!res.data.tfaEnabled) {
          this.onLoginSuccess(res);
        }
      })
    );
  }

  socialUserLogin(userToken: string) {
    return this.httpClient.post(this.urls.socialUserLogin, { token: userToken }).pipe(
      tap((res: ILoginRespDto) => {
        this.onLoginSuccess(res);
      })
    )
  }

  onLoginSuccess(res: ILoginRespDto) {
    res.data.token = undefined;
    const data: Partial<IUserDetails> = {
      loggedin_fullname: res.data.loggedin_fullname,
      account_id: res.data.account_id,
      email: res.data.email,
      analytics_id: res.data.analytics_id,
      user_id: res.data.user_id,
      image: res.data.image,
      is_root_admin: res.data.is_root_admin,
    };

    this.associated_accounts = res.data.associated_accounts || [];
    this.available_accounts = res.data.available_accounts || [];

    const user: AppUser<typeof data> = {
      auth_token: res.data.token || res.data.auth_token,
      role: UserRoleType.Internal,
      data,
    };
    this.isAuthenticated$.next(true);
    this.token$.next(user.auth_token);
    this.role = user.role;
    this.role$.next(this.role);
    this.setCookie(JSON.stringify(user));
    this.selectedAccountId$.next(data.account_id);
    this.loginData$.next(user);
  }

  cookieDeleted() {
    return !JSON.parse(this.getCookie());
  }

  getCookie$ = this.selectedAccountId$.pipe(
    map((isInternalUser) => ({
      isInternalUser,
      cookie: this.cookieService.get(this.cookieName),
    })),
    tap(({ cookie, isInternalUser }) => {
      if (isInternalUser && !cookie) {
        if (this.toastr.toasts.length < 1 && !this.router.url.endsWith(ROUTE_DIRECTOR_LOGIN)) {
          this.toastr.error(TOKEN_EXPIRED_MESSAGE, 'Error!');
        }
        this.router.navigate(['/login']);
      }
    }),
    map(({ cookie }) => JSON.parse(cookie?.length ? cookie : null))
  );

  cookieDeleted$ = this.getCookie$.pipe(map((cookie) => !cookie));

  getCookie() {
    const cookie = this.cookieService.get(this.cookieName);
    if (this.isInternalUser() && !cookie) {
      if (this.toastr.toasts.length < 1 && !this.router.url.endsWith(ROUTE_DIRECTOR_LOGIN)) {
        this.toastr.error(TOKEN_EXPIRED_MESSAGE, 'Error!');
      }
      this.router.navigate(['/login']);
    }
    return cookie?.length ? cookie : null;
  }

  unsetCookie() {
    const host = this.getCookieHost();

    /**
     * The cookie service *delete* doesn't work for some reason!
     * So let's expire it manually.
     */
    const expiresDate = new Date('Thu, 01 Jan 1970 00:00:01 GMT');

    this.cookieService.set(this.cookieName, '', {
      expires: expiresDate,
      secure: commonenv.production ? true : false,
      domain: host,
    });
    const secure = commonenv.production ? true : false;
    this.cookieService.delete(this.cookieName, '/', host, secure);
  }

  getCookieHost() {
    /**
     * The Cookie should be available for *.host.com.
     * To support both capture and workflows
     */
    let host = window.location.host;
    const hostParts = host.split('.');
    if (hostParts.length > 2) {
      /**
       * Not localhost and not host.com, at least subdomain.host.com
       *  */
      hostParts.shift();
    }
    host = hostParts.join('.');

    /**
     * Remove port
     *  */
    host = host.split(':')[0];
    return host;
  }
  setCookie(token) {
    if (!this.isExtension()) {
      //For extension not use this domain cookie
      const host = this.getCookieHost();
      /**
       * Set Cookie
       */
      this.cookieService.set(this.cookieName, token, {
        expires: this.expireAfter,
        secure: commonenv.production ? true : false,
        domain: host,
        path: '/',
      });
    }
  }

  setUgcUser(user: string) {
    const host = this.getCookieHost();
    /**
     * Set Cookie
     */
    this.cookieService.set(this.ugcCookieName, user, {
      expires: this.expireAfter,
      secure: commonenv.production,
      domain: host,
      path: '/',
    });
  }

  getUgcUser(): AppUser<IUserDetails> {
    const cookie = this.cookieService.get(this.ugcCookieName);
    return cookie?.length ? JSON.parse(cookie) : null;
  }

  deleteUgcUser() {
    const host = this.getCookieHost();
    const expiresDate = new Date('Thu, 01 Jan 1970 00:00:01 GMT');

    this.cookieService.set(this.ugcCookieName, '', {
      expires: expiresDate,
      secure: commonenv.production,
      domain: host,
    });
    const secure = commonenv.production;
    this.cookieService.delete(this.ugcCookieName, '/', host, secure);
  }

  private isExtension() {
    return window.location.href.startsWith('chrome://extension');
  }

  softLogoutRequest(): boolean {
    if (this.busyProcesses.length) {
      const confirmation = window.confirm('You have work in progress that may be lost if you log out. Continue?');

      if (confirmation) {
        this.confirmLogout();
      }

      return confirmation;
    }

    return true;
  }

  /**
   * Force logout: resets busy processes, invalidates token without user's confirmation
   */
  logout(): void {
    this.httpClient.get(this.urls.logout, { withCredentials: true }).subscribe();
    this.resetActiveSession();
  }

  liteLogout() {
    this.jwt$.next(null);
    sessionStorage.removeItem('jwt-token');
  }

  resetActiveSession(): void {
    this.busyProcesses = [];
    this.logoutSource.next(null);
    this.invalidateToken();
    localStorage.removeItem('teleprompterPreviewPosition');
    this.resetLogoutConfirmation();
    this.associated_accounts = [];
    this.available_accounts = [];
  }

  removeSubjectToken() {
    const currentSession = localStorage.getItem('current-session');
    localStorage.removeItem(currentSession);
    localStorage.removeItem('current-session');
    this.invalidateToken();
  }

  /**
   * Invalidate token, usually when you get 403
   */
  invalidateToken() {
    console.log('invalidate token');
    localStorage.removeItem(CLOSE_EMBED_UPGRADE_BAR);
    this.token$.next(null);
    this.isAuthenticated$.next(false);
    this.role = null;
    this.data$.next(null);
    this.selectedAccountId$.next(null);
    this.userId$.next(null);
    this.clearLiteSession();
    this.unsetCookie();
  }

  clearLiteSession() {
    this.jwt$.next(null);
    sessionStorage.removeItem('jwt-token');
  }

  sendForgotPasswordEmail(email: string) {
    return this.httpClient.post<ForgotPasswordResponse>(this.urls.forgotPasswordEmail, {
      email: email,
    });
  }

  send2FaOtpVerification(jwtToken: string, otp: string) {
    const headers = new HttpHeaders().set('OR-MFA-TOKEN', otp);
    return this.httpClient.get(this.urls.otp2FaVerify(jwtToken), { headers: headers }).pipe(
      tap((res: ILoginRespDto) => {
        this.onLoginSuccess(res);
      })
    );
  }

  reSendTfaOtp(jwtToken: string) {
    return this.httpClient.get<{ message: string }>(this.urls.reSendTfaOtp(jwtToken));
  }

  verifyForgotPasswordToken(email: string, token: string) {
    return this.httpClient.get<ForgotPasswordResponse>(this.urls.forgotPasswordVerify(email, token));
  }

  verifyInternalUserInviteToken(token: string) {
    return this.httpClient.patch<ForgotPasswordResponse>(this.urls.inviteInternalUserTokenVerify(token), {});
  }

  updateUserPassword(email: string, token: string, password: string) {
    return this.httpClient.post<ForgotPasswordResponse>(this.urls.updateUserPassword, {
      email: email,
      token: token,
      pass: password,
    });
  }

  getUserDetails(): AppUser<IUserDetails> {
    return JSON.parse(this.getCookie());
  }

  updateAccountDetails(accountId: number, data?: AppUser<IUserDetails>) {
    const url = this.urls.accountLogin + accountId;
    return this.httpClient.patch(url, { account_id: accountId }).pipe(
      tap((res: IAccountSelectRespDto) => {
        if (res.data?.nextgenAllowed) {
          const userDetails = data || this.getUserDetails();

          userDetails.data.is_root_admin = res.data.is_root_admin;
          userDetails.data.account_id = res.data.account_id;
          userDetails.data.teamJoiningRule = res.data.joiningRules;
          userDetails.data.company_name = res.data.name;
          userDetails.data.site_user_slug = res.data.site_user_slug;
          userDetails.data.hostingAllowed = res.data.hostingAllowed;
          userDetails.data.captureAllowed = res.data.captureAllowed;
          userDetails.data.webinarAllowed = res.data.webinarAllowed;
          userDetails.data.creatorAllowed = res.data.creatorAllowed;
          userDetails.data.defaultCaptureAccessLevel = res.data.defaultCaptureAccessLevel;
          userDetails.data.defaultCreatorAccessLevel = res.data.defaultCreatorAccessLevel;
          userDetails.data.defaultHostingAccessLevel = res.data.defaultHostingAccessLevel;
          userDetails.data.defaultWebinarAccessLevel = res.data.defaultWebinarAccessLevel;
          this.data$.next(userDetails.data);
          this.userId$.next(userDetails.data.user_id);
          this.selectedAccountId$.next(res.data.account_id);
          this.setCookie(JSON.stringify(userDetails));
          this.loginData$.next(userDetails);
        } else {
          window.location.href = 'https://remote.openreel.com';
          return;
        }
      })
    );
  }

  loginViaTokenSuccess(result: LoginViaTokenResp) {
    const data: IUserDetails = {
      loggedin_fullname: result.name,
      analytics_id: result.analytics_id,
      account_id: result.account_id,
      email: result.email,
      user_id: result.user_id,
      company_name: result.company_name,
      site_user_slug: result.site_user_slug,
      image: result.image,
      is_root_admin: result.is_root_admin,
      webinarAllowed: result.webinarAllowed,
      hostingAllowed: result.hostingAllowed,
      creatorAllowed: result.creatorAllowed,
      captureAllowed: result.captureAllowed,
      associated_accounts: [],
      available_accounts: [],
      teamJoiningRule: result.joiningRules,
      defaultCaptureAccessLevel: result.defaultCaptureAccessLevel,
      defaultCreatorAccessLevel: result.defaultCreatorAccessLevel,
      defaultHostingAccessLevel: result.defaultHostingAccessLevel,
      defaultWebinarAccessLevel: result.defaultWebinarAccessLevel,
    };
    const user: AppUser<typeof data> = {
      auth_token: result.token,
      role: UserRoleType.Internal,
      data,
    };
    this.role = user.role;
    this.role$.next(this.role);
    this.isAuthenticated$.next(true);
    this.token$.next(user.auth_token);
    this.userId$.next(data.user_id);
    this.selectedAccountId$.next(data.account_id);
    this.setCookie(JSON.stringify(user));
  }

  existingUserRegistration(accountId: number, companyName: string): Observable<RegistrationRespDto> {
    return this.httpClient.post<RegistrationRespDto>(this.urls.existingUserRegistration, {
      company_name: companyName,
      account_id: accountId,
    });
  }
  leaveTeam(accountId: number) {
    return this.httpClient.delete(this.urls.leaveTeam(accountId));
  }

  async switchTeam(accountId: number) {
    const userLoginDetails = await this.generateNewToken().toPromise();
    const data: ILoginRespDto = {
      data: {
        loggedin_fullname: userLoginDetails.fullname,
        ...userLoginDetails,
      },
    } as unknown as ILoginRespDto;

    this.onLoginSuccess(data);
    return this.updateAccountDetails(accountId).toPromise();
  }

  generateNewToken(): Observable<NewAccessTokenResponse> {
    return this.httpClient.post<NewAccessTokenResponse>(this.urls.newToken, {});
  }

  isCreatorAllowed(): boolean {
    const userDetails = this.getUserDetails();
    return userDetails.data.creatorAllowed;
  }

  isCaptureAllowed(): boolean {
    const userDetails = this.getUserDetails();
    return userDetails.data.captureAllowed;
  }

  getCookieKeyValues() {
    return {
      name: this.cookieName,
      expireAfter: this.expireAfter,
    };
  }

  private resetLogoutConfirmation(): void {
    this._logoutConfirmed = false;
  }

  private confirmLogout(): void {
    this._logoutConfirmed = true;
  }
}
