import { WorkflowTemplatesBaseBuilder } from './workflow-templates-base.builder';
import { SectionId } from '../interfaces';
import { createSectionLayer } from '../template-builder';

export interface BuilderIntroOutroWorkflowCommandEvent {
  aspectRatio: {
    id: number;
    width: number;
    height: number;
  };
}

export class BuilderIntroOutroWorkflowCommand extends WorkflowTemplatesBaseBuilder<BuilderIntroOutroWorkflowCommandEvent> {
  run(event: BuilderIntroOutroWorkflowCommandEvent) {
    // Remove soundtrack
    const soundtrackAsset = this.source.assets.find((a) => a.id === this.source.globalSettings.soundtrack.assetId);
    delete soundtrackAsset.file;

    // Prepare layers
    const mainTimeline = this.source.timelines.find((t) => t.type === 'main');

    const sectionLayerIntro = createSectionLayer('intro', 'intro');
    const sectionLayerOutro = createSectionLayer('outro', 'outro');
    mainTimeline.layers = [sectionLayerIntro, sectionLayerOutro];

    sectionLayerIntro.enabled = this.source.templateSections[SectionId.Intro].isEnabled;
    sectionLayerOutro.enabled = this.source.templateSections[SectionId.Outro].isEnabled;

    const introSections = this.source.templateSections[SectionId.Intro].sections;
    const introSection = introSections.find((s) => s.aspectRatioId === event.aspectRatio.id);

    const outroSections = this.source.templateSections[SectionId.Outro].sections;
    const outroSection = outroSections.find((s) => s.aspectRatioId === event.aspectRatio.id);

    // Add intro/outro sections
    this.source.sections = {
      [SectionId.Intro]: introSection.section,
      [SectionId.Outro]: outroSection.section,
    };

    this.addAndReplaceAssets([...introSection.assets, ...outroSection.assets]);
    this.addAndReplaceStyles([...introSection.styles, ...outroSection.styles]);

    // Add global font to all styles
    for (const style of this.source.styles) {
      style.font = this.source.globalSettings.font;
    }

    // Set resolution
    this.source.globalSettings.resolution = {
      width: event.aspectRatio.width,
      height: event.aspectRatio.height,
    };

    return this.ok();
  }
}
