import { createAction, props } from '@ngrx/store';
import { StartVideo, SetVideoUploadActive, VideoSource } from '@openreel/frontend/common';

interface PreRecordingPayload {
  videoId: number;
  videoName: string;
  videoSource: VideoSource;
  countdown: number;
  startRecordingTime: Date;
  uploadDuringRecording: string[];
  resolution: string;
  fps: string;
  uploadAuthToken: string;
}

const context = '[Recording]';
export const startRecordingRequest = createAction(`${context} Start Recording Request`, props<{ event: StartVideo }>());
export const startPreRecording = createAction(`${context} Start Prerecording`, props<PreRecordingPayload>());
export const startRecording = createAction(
  `${context} Start Recording`,
  props<{ videoId: number; videoSource: VideoSource }>()
);
export const startRecordingAck = createAction(
  `${context} Start Recording Ack`,
  props<{ videoId: number; videoSource: VideoSource; stat: '1' | '0'; errorcode?: '1' }>()
);

export const stopRecordingRequest = createAction(
  `${context} Stop Recording Request`,
  props<{ excludePresentations?: boolean }>()
);

export const stopRecording = createAction(`${context} Stop Recording`, props<{ videoId: number }>());

export const stopRecordingSuccess = createAction(
  `${context} Stop Recording Success`,
  props<{ videoId: number; recordingStartTime?: Date }>()
);

export const stopRecordingFailed = createAction(`${context} Stop Recording Failed`);

export const startUploading = createAction(`${context} Start Uploading`, props<{ videoId: number }>());

export const startUploadingSuccess = createAction(`${context} Start Uploading Success`, props<{ videoId: number }>());

export const cancelUploadingRequest = createAction(`${context} Stop Uploading Request`);

export const setUploadVideoActive = createAction(`${context} Set Upload Video Active`, props<SetVideoUploadActive>());

export const deleteRecordingRequest = createAction(
  `${context} Delete Recording Request`,
  props<{ videoid: number; identityArr: string[] }>()
);
export const deleteRecordingSuccess = createAction(`${context} Delete Recording Success`, props<{ videoId: number }>());
