/* eslint-disable @typescript-eslint/no-explicit-any */
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_2_1 } from '../transformation_2_1/schema_after';
import { WorkflowDataDto_2_2 } from './schema_after';

/*
  NOTE: Purpose of this transformation:
    - check and transform workflow layers to have unique layerIds
*/
export const SchemaTransformation_2_2: SchemaTransformation = {
  version: '2.2',
  supportsPolyfill: false,

  run(oldSchema: WorkflowDataDto_2_1.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as unknown as WorkflowDataDto_2_2.WorkflowDataDto;
    const layerIds = new Set<string>();

    for (const [id, section] of Object.entries(newSchema.sections)) {
      if (id === WorkflowDataDto_2_2.SectionId.Intro || id === WorkflowDataDto_2_2.SectionId.Outro) {
        continue;
      }

      for (const timeline of section.timelines) {
        for (const layer of getLayersFromTimeline(timeline)) {
          if (layerIds.has(layer.layerId)) {
            layer.layerId = uuidv4();
          }
          layerIds.add(layer.layerId);
        }
      }
    }

    return newSchema;
  },
};

function* getLayersFromTimeline(timeline: WorkflowDataDto_2_2.Timeline): Generator<WorkflowDataDto_2_2.LayerOptions> {
  for (const layer of timeline.layers) {
    if (layer.type === 'timelines') {
      yield layer;
      for (const childTimeline of layer.children) {
        yield* getLayersFromTimeline(childTimeline);
      }
    } else {
      yield layer;
    }
  }
}
