<ng-container *ngIf="showAsIcon; else showInRow">
  <div
    fxLayout="column"
    class="camera-icon"
    (click)="toggleWebcam(webcamDisabled ? false : true)"
    fxLayoutAlign="center center"
  >
    <mat-icon [class.off]="webcamDisabled || webcamError">{{ webcamDisabled ? 'videocam_off' : 'videocam' }}</mat-icon>
    <div fxLayout="row" fxLayoutAlign="center center">{{ webcamDisabled ? 'Start Video' : 'Stop Video' }}</div>
  </div>
  <ng-template [ngTemplateOutlet]="optionsTemplate" *ngIf="!webcamDisabled"></ng-template>
</ng-container>

<ng-template #showInRow>
  <div fxLayout="row" fxFlex="100" fxLayoutGap="10px" fxLayoutAlign="center center">
    <mat-icon
      fxFlex="10"
      class="camera-icon"
      [ngClass]="webcamDisabled || webcamError ? 'off' : 'on'"
      (click)="toggleWebcam(webcamDisabled ? false : true)"
      >{{ webcamDisabled ? 'videocam_off' : 'videocam' }}</mat-icon
    >
    <ng-container *ngIf="webcamSelectStatus$ | async as webcamSelectStatus">
      <div fxFlex="80" *ngIf="webcamSelectStatus && !selectedWebcamDevice">
        {{ webcamSelectStatus }}
      </div>
    </ng-container>
    <div fxFlex="80" *ngIf="selectedWebcamDevice">
      <span ngClass.lt-md="hide">Camera is {{ webcamDisabled ? 'off ' : 'ready ' }}</span>
      <span class="selected-option">{{ selectedWebcamDevice.name }}</span>
    </div>
    <div fxFlex="10" fxLayoutAlign="center center">
      <ng-template [ngTemplateOutlet]="optionsTemplate"></ng-template>
    </div>
  </div>
</ng-template>

<ng-template #optionsTemplate>
  <div class="camera-options" *ngIf="availableWebcamDevices?.length || selectedWebcamDevice">
    <span [matMenuTriggerFor]="cameraOptions">
      <mat-icon>expand_more</mat-icon>
    </span>
    <mat-menu #cameraOptions="matMenu" xPosition="before">
      <button
        mat-menu-item
        *ngFor="let availableWebcamDevice of availableWebcamDevices"
        (click)="changeWebcamDevice(availableWebcamDevice)"
        [ngClass]="{ 'selected-option': availableWebcamDevice.id === selectedWebcamDevice?.id }"
      >
        {{ availableWebcamDevice.name }}
      </button>
    </mat-menu>
  </div>
</ng-template>
