import { cloneDeep } from 'lodash';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_4_9 } from '../transformation_4_9/schema_after';
import { WorkflowDataDto_5_0 } from './schema_after';
import { v4 } from 'uuid';

/*
  NOTE: Purpose of this transformation:
    - 1) remove templateSections
    - 2) split usages of placeholders into separate assets
*/
export const SchemaTransformation_5_0: SchemaTransformation = {
  version: '5.0',
  supportsPolyfill: false,

  run(oldSchema: WorkflowDataDto_4_9.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as unknown as WorkflowDataDto_5_0.WorkflowDataDto;

    // 1)
    delete newSchema.templateSections;

    // 2)
    for (const [, section] of Object.entries(newSchema.sections)) {
      for (const timeline of section.timelines) {
        if (timeline.type !== 'main') {
          continue;
        }

        if (timeline.layers.length === 0) {
          continue;
        }

        const layer = timeline.layers[0] as WorkflowDataDto_5_0.AssetLayer;
        if (!layer.assetId) {
          continue;
        }

        const asset = newSchema.assets.find((a) => a.id === layer.assetId);
        if (!asset || !asset.isPlaceholder) {
          continue;
        }

        const newAsset: WorkflowDataDto_5_0.Asset = { ...asset, id: v4(), isGlobal: false };
        newSchema.assets.push(newAsset);
        layer.assetId = newAsset.id;
      }
    }

    return newSchema;
  },
};
