import { createAction, props } from '@ngrx/store';
import {
  AudioDevice,
  AudioStream,
  IStreamingLib,
  LobbyDeviceSelection,
  StreamTokenResponse,
  ParticipantAudioProperties,
  AudioSettingsRequest,
  VideoConstraints,
  VideoDevice,
  VideoSource,
  VideoStream,
  LiveBroadcast,
  PresentSource,
} from '@openreel/frontend/common';
import { RoomMapper } from '../participants/interfaces/participant-props.interface';
import { LocalAudioVideoSource } from './interfaces/local-audio-video-source';
import { PartipantStatus } from './interfaces/participant-status';
const context = '[Stream]';

export const initEffect = createAction(`${context} init effect`);

export const confirmedDevice = createAction(
  `${context} Confirm Device`,
  props<{ lobbyDeviceSelection: LobbyDeviceSelection }>()
);

export const loadedAudioVideoSourceFromStorage = createAction(
  `${context} Loaded audio video source from storage`,
  props<LocalAudioVideoSource>()
);

export const getStreamTokenFailure = createAction(`${context} Get Stream token failure`, props<{ error: Error }>());

export const getStreamTokenSuccess = createAction(
  `${context} Get Stream token success`,
  props<{ token: StreamTokenResponse; isSubject: boolean }>()
);

export const connectStreamSuccess = createAction(`${context} Connect Stream Success`, props<IStreamingLib>());

export const myParticipantUpdate = createAction(
  `${context} My participant update`,
  props<{ myParticipant: PartipantStatus }>()
);

export const updateRemoteParticipantList = createAction(
  `${context} Update participants list`,
  props<{ participants: PartipantStatus[] }>()
);

/**
 * Setting local device
 */

export const setVideoSource = createAction(`${context} Set Video Source`, props<{ source: VideoSource }>());

export const openAudioInputDevice = createAction(
  `${context} Open Audio Input Device`,
  props<{
    device: AudioDevice;
    audioSettings?: AudioSettingsRequest | ParticipantAudioProperties;
  }>()
);

export const openWebcamInputDevice = createAction(
  `${context} Open Webcam Input Device`,
  props<{ device: VideoDevice }>()
);

export const openScreencastSource = createAction(`${context} Open Screencast Source`);

export const openAudioStreamSuccess = createAction(
  `${context} Open audio stream success`,
  props<{ stream: AudioStream }>()
);

export const openVideoStreamSuccess = createAction(
  `${context} Open video stream success`,
  props<{ stream: VideoStream }>()
);

export const openStreamingAudioStream = createAction(
  `${context} Open Streaming audio stream`,
  props<{ stream: AudioStream }>()
);

export const changeSpeakerDevice = createAction(
  `${context} Change Speaker Device`,
  props<{
    device: AudioDevice;
  }>()
);

/**
 * Device Lists Update
 */

export const requestDevicesList = createAction(`${context} Request Devices List`, props<{ keepStream: boolean }>());

export const setWebcamDevices = createAction(`${context} Set Webcam Devices`, props<{ devices: VideoDevice[] }>());

export const setAudioInputDevices = createAction(
  `${context} Set Audio Input Devices`,
  props<{ devices: AudioDevice[] }>()
);

export const setAudioOutputDevices = createAction(
  `${context} Set Audio Output Devices`,
  props<{ devices: AudioDevice[] }>()
);

export const setScreencastSources = createAction(
  `${context} Set Screencast Sources`,
  props<{ sources: VideoDevice[] }>()
);

/**
 * Not Implemented
 */
export const setAvailableSpeakers = createAction(`${context} Set Available Speakers`);
//===================================================

/**
 * Publishing Streams
 */
export const publishVideoSuccess = createAction(`${context} Publish video success`);

export const publishAudioSuccess = createAction(`${context} Publish audio success`);

/**
 * Stream controls
 */

export const setFrameRate = createAction(`${context} Set Frame Rate`, props<{ fps: number }>());
export const setFrameRateAck = createAction(`${context} Set Frame Rate Ack`, props<{ fps: number }>());
export const trackFrameRateChange = createAction(
  `${context} Track Frame Rate Change`,
  props<{ success: boolean; requestedValue: number; resultValue: number }>()
);
export const setStreamConstraints = createAction(
  `${context} Set Stream Constraints`,
  props<{ constraints: VideoConstraints }>()
);

export const setStreamConstraintsSuccess = createAction(
  `${context} Set Stream Constraints Success`,
  props<{ constraints: VideoConstraints }>()
);

export const closeOpenStreams = createAction(`${context} Close Open Streams`);

export const setResolution = createAction(`${context} Set Resolution`, props<{ width: number; height: number }>());

export const setResolutionAck = createAction(
  `${context} Set Resolution Ack`,
  props<{ height: number; width: number }>()
);

export const trackResolutionChange = createAction(
  `${context} Track Resolution Change`,
  props<{ success: boolean; requestedValue: number; resultValue: number }>()
);

export const roomInitialized = createAction(`${context} ROOM INITIALIZED`, props<RoomMapper>());

export const roomDisconnect = createAction(`${context} ROOM DISCONNECT`);

export const changeNetworkQuality = createAction(`${context} CHANGE NETWORK QUALITY`, props<{ level: number }>());

export const sessionTriggered = createAction(`${context} SESSION TRIGGERED`);

export const disableVideoStream = createAction(`${context} Disable Video Stream`, props<{ source: VideoSource }>());

export const changeVideoSource = createAction(`${context} Change Video Source`, props<{ source: VideoSource }>());
export const closeAllVideoStreams = createAction(`${context} Close All Video Streams`);

export const getLiveStream = createAction(`${context} Get Live Stream`);
export const updateLiveStream = createAction(`${context} update Live Stream`, props<{ data: LiveBroadcast }>());
export const updateLiveStreamIdentities = createAction(
  `${context} update Live Stream Identities`,
  props<{ data: string[] }>()
);
export const updatePresentOptions = createAction(`${context} update Present Options`, props<{ data: PresentSource[] }>());