import { cloneDeep } from 'lodash';
import { getLayerFromId } from '../helpers';
import { Transitions } from '../interfaces';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export interface UpdateLayerTransitionsEvent {
  layerId: string;
  transitions: Transitions;
}

export class UpdateLayerTransitionsCommand extends WorkflowBaseBuilder<UpdateLayerTransitionsEvent> {
  run(event: UpdateLayerTransitionsEvent) {
    const layerData = getLayerFromId(event.layerId, this.source);
    const layer = layerData.layer;

    const hasTransition = event.transitions && Object.keys(event.transitions).length > 0;
    if (!hasTransition) {
      delete layer.transitions;
    } else {
      layer.transitions = { ...cloneDeep(event.transitions) };
    }

    return this.ok();
  }
}
