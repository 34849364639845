import { cloneDeep } from 'lodash';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_1_0 } from '../transformation_1_0/schema_after';
import { WorkflowDataDto_1_1 } from './schema_after';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function updateBackgroundColor(styles: any) {
  if (styles?.backgroundColor?.type) {
    return;
  }

  if (typeof styles?.backgroundColor === 'string') {
    styles.backgroundColor = {
      type: 'solid',
      color: styles.backgroundColor,
    };
  } else if (typeof styles.backgroundColor === 'object') {
    styles.backgroundColor.type = 'gradient';
  }
}

export const SchemaTransformation_1_1: SchemaTransformation = {
  version: '1.1',
  supportsPolyfill: false,

  run(oldSchema: WorkflowDataDto_1_0.WorkflowDataDto) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newSchema = cloneDeep(oldSchema) as WorkflowDataDto_1_1.WorkflowDataDto;
    if (newSchema.features.layouts?.styles?.backgroundColor) {
      updateBackgroundColor(newSchema.features.layouts.styles);
    }

    for (const [, section] of Object.entries(newSchema.sections)) {
      for (const timeline of section.timelines) {
        if (timeline.styles?.backgroundColor) {
          updateBackgroundColor(timeline.styles);
        }

        for (const layer of timeline.layers) {
          if (layer.styles?.backgroundColor) {
            updateBackgroundColor(layer.styles);
          }
        }
      }
    }
    return newSchema;
  },
};
