import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { AudioDevice, Cleanupable, filterDevices, stopStream } from '@openreel/frontend/common';
import { debounceTime, fromEvent, tap } from 'rxjs';

@Component({
  selector: 'openreel-speaker-select',
  templateUrl: './openreel-speaker-select.component.html',
  styleUrls: ['./openreel-speaker-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FlexLayoutModule, MatIconModule, MatLegacyButtonModule, MatLegacyMenuModule],
})
export class OpenreelSpeakerSelectComponent extends Cleanupable implements OnInit, OnDestroy {
  availableSpeakerDevices: AudioDevice[] = [];
  speakerSelectStatus: string;

  @Input() showAsIcon: boolean;
  @Input() speakerMuted: boolean;
  @Input() selectedSpeakerDevice: AudioDevice;
  @Output() speakerDeviceChange = new EventEmitter<AudioDevice>();
  @Output() speakerMuteStatusChange = new EventEmitter<boolean>();

  async ngOnInit(): Promise<void> {
    if (!this.selectedSpeakerDevice) {
      this.getPermission();
    } else {
      await this.getDevices();
    }
    this.mortalize(
      fromEvent(navigator.mediaDevices, 'devicechange').pipe(
        debounceTime(500),
        tap(() => this.getDevices())
      )
    ).subscribe();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private async getPermission() {
    try {
      this.speakerSelectStatus = 'waiting for permission';
      const audioStream = await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
      stopStream(audioStream);
      await this.getDevices();
    } catch (error) {
      this.speakerSelectStatus = error.message ?? 'permission denied';
      this.availableSpeakerDevices = [];
    }
  }

  private async getDevices() {
    this.speakerSelectStatus = 'fetching available speaker options';
    const devices = await navigator.mediaDevices.enumerateDevices();
    const { speakerDevices } = filterDevices(devices);
    this.availableSpeakerDevices = speakerDevices;
    this.speakerSelectStatus = null;
    this.setDefaultDevice();
  }

  setDefaultDevice() {
    const currentSpeaker = JSON.parse(sessionStorage.getItem('openreel-selected-speaker'));

    // set default audio device
    const selectedSpeakerDevice = this.availableSpeakerDevices?.find(
      (device) => JSON.stringify(device) === JSON.stringify(currentSpeaker)
    );
    const defaultSelectedDevice = selectedSpeakerDevice || this.availableSpeakerDevices[0];
    this.changeSpeakerDevice(defaultSelectedDevice);

    if (JSON.parse(sessionStorage.getItem('openreel-speaker-muted'))) {
      this.toggleSpeakerMuted(true);
    } else {
      this.toggleSpeakerMuted(false);
    }
  }

  changeSpeakerDevice(device: AudioDevice) {
    if (JSON.stringify(this.selectedSpeakerDevice) !== JSON.stringify(device)) {
      sessionStorage.setItem('openreel-selected-speaker', JSON.stringify(device));
      this.selectedSpeakerDevice = device;
      this.speakerDeviceChange.next(device);
    }
  }

  toggleSpeakerMuted(status: boolean) {
    sessionStorage.setItem('openreel-speaker-muted', `${status}`);
    this.speakerMuteStatusChange.next(status);
  }
}
