import { cloneDeep } from 'lodash';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_1_6 } from '../transformation_1_6/schema_after';
import { WorkflowDataDto_1_7 } from './schema_after';

/*
  NOTE: Purpose of this transformation:
    - remove watermarkLayer and replace it with globalSettings.watermark.settings
*/
export const SchemaTransformation_1_7: SchemaTransformation = {
  version: '1.7',
  supportsPolyfill: false,

  run(oldSchema: WorkflowDataDto_1_6.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as WorkflowDataDto_1_7.WorkflowDataDto;

    const watermark = oldSchema.globalSettings.watermarkLayer;
    if (watermark) {
      newSchema.globalSettings.watermark.settings = {
        assetId: watermark.assetId,
        enabled: watermark.enabled,
        visible: true,
        targetTags: [],
      };
    } else {
      newSchema.globalSettings.watermark.settings = {
        assetId: null,
        enabled: false,
        visible: false,
        targetTags: [],
      };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    delete (newSchema.globalSettings as any).watermarkLayer;
    return newSchema;
  },
};
