import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BaseOauthService {
  openOauthWindow(url: string, name: string) {
    const w = 500;
    const h = 650;
    const left = (window.screen.width/2)-(w/2);
    const top = (window.screen.height/2)-(h/2);

    window.open(
      url,
      name,
      `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no,width=${w},height=${h},top=${top},left=${left}`
    );
  }
}
