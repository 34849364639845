import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  Cleanupable,
  SingleInvitation,
  UserRoleType,
  UserRoleTypeHumanReadable,
  SESSION_COLLAB_WATCHER_LIMIT,
} from '@openreel/frontend/common';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { InvitationFormStatus } from './invitation-list-creator.interfaces';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'openreel-invitation-list-creator',
  templateUrl: './invitation-list-creator.component.html',
  styleUrls: ['./invitation-list-creator.component.scss'],
})
export class InvitationListCreatorComponent extends Cleanupable implements OnInit, OnChanges, OnDestroy {
  @Input()
  addAnother$: Observable<UserRoleType[]>;

  @Input()
  currentList: SingleInvitation[];

  @Input()
  visibleRoleTypes: UserRoleType[];

  @Input()
  roleSelectionEnabled = true;

  @Input()
  submitted = new Subject<boolean>();

  @Output()
  formStatus = new EventEmitter<InvitationFormStatus>();

  @Input()
  updateInviteeDetail: SingleInvitation;

  @Input()
  sessionInviteLimit: number;

  @Output()
  inviteListChanged = new EventEmitter<void>();

  oldCurrentList: SingleInvitation[];

  UserRoleTypeHumanReadable = UserRoleTypeHumanReadable;
  roleTypes = [UserRoleType.Collaborator, UserRoleType.Watcher, UserRoleType.Subject];

  userRoleType = UserRoleType;

  inviteExceedMsgShown = false;

  form: UntypedFormGroup;

  constructor(private toastr: ToastrService) {
    super();
    const onlySpaceRegex = /^(\s+\S+\s*)*(?!\s).*$/; // check for only space
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(undefined, [
        Validators.required,
        Validators.pattern(onlySpaceRegex),
        Validators.maxLength(100),
      ]),
      email: new UntypedFormControl(undefined, [Validators.required, Validators.email]),
      type: new UntypedFormControl('subject', Validators.required),
    });
  }

  loadDefaults() {
    const newValue: SingleInvitation = {
      email: '',
      name: '',
      type:
        this.visibleRoleTypes && this.visibleRoleTypes.indexOf(UserRoleType.Internal) !== -1
          ? UserRoleType.Internal
          : UserRoleType.Subject,
    };
    this.form.reset();
    this.form.setValue(newValue);
    this.formStatus.emit({ isValid: this.form.valid, isEmpty: true });
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.form.valueChanges.subscribe((newValue: SingleInvitation) => {
        this.currentList[this.currentList.length - 1] = newValue;
        this.formStatus.emit({
          isValid: this.form.valid,
          isEmpty: !newValue.email && !newValue.name,
        });
      }),
      this.form.controls.name.valueChanges.pipe(debounceTime(10), distinctUntilChanged()).subscribe((val) => {
        this.form.controls.name.patchValue(val.replace('%', ''));
      })
    );
    this.subscriptions.push(
      this.submitted.subscribe({
        next: (submitted) => {
          if (submitted) {
            this.loadDefaults();
          }
        },
      })
    );
    this.limitRoleTypeToVisibleRoles();

    this.currentList.push({} as SingleInvitation);
    this.loadDefaults();
    this.subscriptions.push(
      this.addAnother$.subscribe((roles) => {
        if (roles) {
          this.roleTypes = roles;
        }
        this.addAnother();
      })
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  limitRoleTypeToVisibleRoles() {
    if (this.visibleRoleTypes?.length > 0) {
      this.roleTypes = this.roleTypes.filter((item) => this.visibleRoleTypes.indexOf(item) !== -1);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['updateInviteeDetail'] && changes['updateInviteeDetail'].currentValue) {
      this.form.setValue({
        name: changes['updateInviteeDetail'].currentValue.name,
        email: changes['updateInviteeDetail'].currentValue.email,
        type: changes['updateInviteeDetail'].currentValue.type,
      });
    } else if (
      changes['updateInviteeDetail'] &&
      !changes['updateInviteeDetail'].currentValue &&
      changes['updateInviteeDetail'].previousValue
    ) {
      this.form.setValue({
        name: '',
        email: '',
        type: 'subject',
      });
    }
  }

  addAnother() {
    if (this.form.valid) {
      this.currentList.push({} as SingleInvitation);
      this.loadDefaults();
    }
  }

  deleteInvitation(invitation: SingleInvitation) {
    this.currentList.splice(this.currentList.indexOf(invitation), 1);
    this.inviteListChanged.emit();
  }
  submit() {
    this.form.reset();
  }

  onAddedInviteTypeChange(changedItemIndex: number) {
    const previousInviteType = this.oldCurrentList[changedItemIndex].type;
    const changedInvite = this.currentList[changedItemIndex];
    const changedType = changedInvite.type;
    const totalInviteUser =
      this.currentList.filter((o) => o.type === UserRoleType.Collaborator || o.type === UserRoleType.Watcher)?.length ||
      0;
    if (
      changedType === UserRoleType.Collaborator ||
      (changedType === UserRoleType.Watcher && previousInviteType === UserRoleType.Subject)
    ) {
      if (
        this.validateInvitationExceeds(totalInviteUser) &&
        this.oldCurrentList[changedItemIndex].type === UserRoleType.Subject
      ) {
        if (!this.inviteExceedMsgShown) {
          this.toastr.error(SESSION_COLLAB_WATCHER_LIMIT);
        }
        this.currentList[changedItemIndex] = this.oldCurrentList[changedItemIndex];
        this.inviteExceedMsgShown = true;
        return;
      } else if (this.validateInvitationEqualOrExceeds(totalInviteUser)) {
        this.roleTypes = [UserRoleType.Subject];
      }
    } else if (
      changedType === UserRoleType.Subject &&
      (previousInviteType === UserRoleType.Collaborator || previousInviteType === UserRoleType.Watcher)
    ) {
      if (!this.validateInvitationExceeds(totalInviteUser)) {
        this.roleTypes = [UserRoleType.Internal, UserRoleType.Collaborator, UserRoleType.Watcher, UserRoleType.Subject];
        this.limitRoleTypeToVisibleRoles();
      }
    }
    this.inviteExceedMsgShown = false;
    // Deep Clone Copy
    this.oldCurrentList = JSON.parse(JSON.stringify(this.currentList));
  }

  onAddedInviteTypeDropDownOpen(isOpen: boolean) {
    if (isOpen) {
      // Deep Clone Copy
      this.oldCurrentList = JSON.parse(JSON.stringify(this.currentList));
    }
  }

  onInviteTypeChange() {
    const value = this.form.controls['type'].value;
    if (value === UserRoleType.Collaborator || value === UserRoleType.Watcher) {
      const totalInviteUser =
        this.currentList.filter((o) => o.type === UserRoleType.Collaborator || o.type === UserRoleType.Watcher)
          ?.length || 0;
      if (this.validateInvitationExceeds(totalInviteUser)) {
        this.toastr.error(SESSION_COLLAB_WATCHER_LIMIT);
        this.form.controls['type'].patchValue(UserRoleType.Subject);
      }
    }
  }

  handlePropagation(event: Event) {
    event.stopPropagation();
  }

  private validateInvitationExceeds(currentInviteMember: number) {
    return this.sessionInviteLimit && this.sessionInviteLimit < currentInviteMember;
  }

  private validateInvitationEqualOrExceeds(currentInviteMember: number) {
    return this.sessionInviteLimit && this.sessionInviteLimit <= currentInviteMember;
  }
}
