import { cloneDeep } from 'lodash';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_4_8 } from '../transformation_4_8/schema_after';
import { WorkflowDataDto_4_9 } from './schema_after';

/*
  NOTE: Purpose of this transformation:
    - 1) cleanup placeholder objects
*/
export const SchemaTransformation_4_9: SchemaTransformation = {
  version: '4.9',
  supportsPolyfill: false,

  run(oldSchema: WorkflowDataDto_4_8.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as unknown as WorkflowDataDto_4_9.WorkflowDataDto;

    newSchema.globalSettings.placeholders = {
      interviewAssetIds: {},
      presentationAssetIds: {},
    };

    const oldPlaceholders = oldSchema.features.layouts?.placeholders?.mainClips;
    const newPlaceholders = newSchema.globalSettings.placeholders;

    if (oldPlaceholders.simpleAssetId) {
      newPlaceholders.simpleAssetId = oldPlaceholders.simpleAssetId;
    }

    if (oldPlaceholders?.interviewAssetIds && oldPlaceholders.interviewAssetIds.length > 0) {
      newPlaceholders.interviewAssetIds.left = oldPlaceholders.interviewAssetIds[0];
      if (oldPlaceholders.interviewAssetIds.length > 1) {
        newPlaceholders.interviewAssetIds.right = oldPlaceholders.interviewAssetIds[1];
      }
    }

    if (oldPlaceholders?.presentationAssetIds && oldPlaceholders.presentationAssetIds.length > 0) {
      newPlaceholders.presentationAssetIds.left = oldPlaceholders.presentationAssetIds[0];
      if (oldPlaceholders.presentationAssetIds.length > 1) {
        newPlaceholders.presentationAssetIds.right = oldPlaceholders.presentationAssetIds[1];
      }
    }

    return newSchema;
  },
};
