import { Injectable, OnDestroy } from '@angular/core';
import { commonenv } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ROUTE_OAUTH_CONNECTIONS } from '@openreel/frontend/common';
import { BaseOauthService } from './base-oauth.service';
import { ConnectionAuthEntity } from '@openreel/common/automations';

export const HUBSPOT_CALLBACK_FN = 'hubspotCallback';

@Injectable({
  providedIn: 'root',
})
export class HubspotService implements OnDestroy {
  public urls = {
    redirectUrl: commonenv.automationsApiUrl + 'connections/hubspot/auth',
    callbackUrl: commonenv.automationsApiUrl + 'connections/hubspot/auth/callback',
  };

  constructor(
    private readonly baseOauthService: BaseOauthService,
    private readonly httpClient: HttpClient,
  ) { }

  getRedirectUrl() {
    return this.httpClient.get<{ redirectUrl: string }>(this.urls.redirectUrl, {});
  }

  authCallback(code: string, state: string) {
    return this.httpClient.post<ConnectionAuthEntity>(this.urls.callbackUrl, { code, state });
  }

  login() {
    this.baseOauthService.openOauthWindow(`/${ROUTE_OAUTH_CONNECTIONS}/hubspot`, 'Hubspot Login');

    return new Promise<ConnectionAuthEntity>((resolve) => {
      window[HUBSPOT_CALLBACK_FN] = (connection: ConnectionAuthEntity) => {
        resolve(connection);
        delete window[HUBSPOT_CALLBACK_FN];
      };
    });
  }

  ngOnDestroy() {
    delete window[HUBSPOT_CALLBACK_FN];
  }
}
