import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { ProjectSessionData } from '@openreel/frontend/common';
import moment from 'moment';

@Pipe({ name: 'scheduledSessionDateTime', pure: false })
export class ScheduledSessionDateTimePipe implements PipeTransform {
  transform(session: ProjectSessionData): string {
    if (moment().isSameOrBefore(session.start_time)) {
      return 'Scheduled for ' + formatDate(session.start_time, 'MMM dd, yyyy @ h:mm a', 'en-US');
    } else if (session.end_time && moment().isSameOrAfter(session.end_time)) {
      return 'Expired on ' + formatDate(session.end_time, 'MMM dd, yyyy @ h:mm a', 'en-US');
    } else if (session.end_time && moment().isBetween(session.start_time, session.end_time)) {
      return 'Expires on ' + formatDate(session.end_time, 'MMM dd, yyyy @ h:mm a', 'en-US');
    } else {
      return null;
    }
  }
}
