import { v4 as uuidv4 } from 'uuid';
import { Asset } from './../interfaces/workflow.interfaces';

export function createVideoAsset(assetFileId: number, duration: number) {
  const asset: Asset = {
    id: uuidv4(),
    type: 'clip',
    data: { duration: duration },
    ...(assetFileId ? { file: { path: assetFileId, provider: 'or-assets' } } : {}),
  };

  return asset;
}
