import { WorkflowBaseBuilder } from './workflow-base.builder';

export interface UpdateAssetsPrimaryColorEvent {
  color: string;
}

export class UpdateAssetsPrimaryColorCommand extends WorkflowBaseBuilder<UpdateAssetsPrimaryColorEvent> {
  run({ color }: UpdateAssetsPrimaryColorEvent) {
    this.applyGlobalColor(color, 'primary');
    this.updatePrimaryColor(color);

    return this.ok();
  }
}
