import { cloneDeep } from 'lodash';
import { getAssetFinalDuration } from '../helpers';
import { getTimelineById } from '../helpers/timelines.helpers';
import { Layer, LayerOptions, Section, VideoLayer } from '../interfaces';
import { AddClipsEvent } from '../interfaces/timelines.interfaces';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export class AddClipsCommand extends WorkflowBaseBuilder<AddClipsEvent, VideoLayer & LayerOptions> {
  run(event: AddClipsEvent) {
    const { timeline, sectionId } = getTimelineById(event.timelineId, this.source.sections);

    const section = this.source.sections[sectionId];

    let existingLayer: Layer;
    if (timeline.layers.length > 0) {
      existingLayer = cloneDeep(timeline.layers[0]);
      this.removeAssets((timeline.layers[0] as VideoLayer).assetId);
    }
    timeline.layers = [];

    const layer = this.addAssetLayer(timeline, event.clip, {
      ...(existingLayer
        ? { bounds: existingLayer.bounds, styles: existingLayer.styles, transitions: existingLayer.transitions }
        : {}),
    });
    this.updateSectionDuration(section);

    this.stretchLowerThird(section);
    this.stretchVideoOverlay(section);

    return this.ok(layer);
  }

  private stretchLowerThird(section: Section) {
    const textOverlayAssetId = this.source.globalSettings.textOverlays?.assetId;
    if (!textOverlayAssetId) {
      return;
    }

    const textOverlayAsset = this.source.assets.find((a) => a.id === textOverlayAssetId);
    const textOverlayDuration = textOverlayAsset.data.duration;

    const overlayTimelines = section.timelines.filter((t) => t.type === 'overlays');
    for (const overlayTimeline of overlayTimelines) {
      if (overlayTimeline.layers.length === 0) {
        continue;
      }

      const layer = overlayTimeline.layers[0];
      const layerDuration = (layer.visibility?.endAt ?? textOverlayDuration) - (layer.visibility?.startAt ?? 0);

      const newLayerDuration =
        textOverlayDuration > layerDuration ? Math.min(section.sectionDuration, textOverlayDuration) : layerDuration;

      layer.visibility = {
        startAt: layer.visibility?.startAt ?? 0,
        endAt: (layer.visibility?.startAt ?? 0) + newLayerDuration,
      };
    }
  }

  private stretchVideoOverlay(section: Section) {
    const videoOverlayTimeline = section.timelines.find((t) => t.type === 'b-roll');
    if (!videoOverlayTimeline || videoOverlayTimeline.layers.length === 0) {
      return;
    }

    const layer = videoOverlayTimeline.layers[0] as VideoLayer & LayerOptions;
    const asset = this.source.assets.find((a) => a.id === layer.assetId);

    const assetDuration = getAssetFinalDuration(asset);
    const sectionDuration = section.sectionDuration;

    const layerVisibilityDuration = (layer.visibility?.endAt ?? assetDuration) - (layer.visibility?.startAt ?? 0);
    if (layerVisibilityDuration > 0) {
      return;
    }

    const clampedDuration = Math.min(sectionDuration, assetDuration);

    layer.visibility = {
      startAt: 0,
      endAt: clampedDuration,
    };
  }
}
