import { Injectable } from '@angular/core';
import { commonenv } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AutomationConnectionType } from '@openreel/common/automations';
import { ConnectionEntity } from './connections.interface';


@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  public urls = {
    list: commonenv.automationsApiUrl + 'connections',
  };

  constructor(public httpClient: HttpClient) {}

  getConnections(type: AutomationConnectionType) {
    return this.httpClient.get<ConnectionEntity[]>(this.urls.list + '?type=' + type);
  }
}
