<ng-container *ngIf="showAsIcon; else showInRow">
  <div
    class="mic-icon"
    (click)="toggleAudioMuted(audioMuted ? false : true)"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <mat-icon [class.off]="audioMuted || audioError">{{ audioMuted ? 'mic_off' : 'mic' }}</mat-icon>
    <div fxLayout="row" fxLayoutAlign="center center">{{ audioMuted ? 'Unmute' : 'Mute' }}</div>
  </div>
  <ng-template [ngTemplateOutlet]="optionsTemplate" *ngIf="!audioMuted || showMuteAll"></ng-template>
</ng-container>

<ng-template #showInRow>
  <div fxLayout="row" fxFlex="100" fxLayoutGap="10px" fxLayoutAlign="center center">
    <mat-icon
      fxFlex="10"
      class="mic-icon"
      [ngClass]="audioMuted || audioError ? 'off' : 'on'"
      (click)="toggleAudioMuted(audioMuted ? false : true)"
      >{{ audioMuted ? 'mic_off' : 'mic' }}</mat-icon
    >
    <ng-container *ngIf="audioSelectStatus$ | async as audioSelectStatus">
      <div fxFlex="80" *ngIf="audioSelectStatus && !selectedAudioDevice">
        {{ audioSelectStatus }}
      </div>
    </ng-container>
    <div fxFlex="80" *ngIf="selectedAudioDevice">
      <span ngClass.lt-md="hide">Mic is {{ audioMuted ? 'off ' : 'ready ' }}</span>
      <span class="selected-option">{{ selectedAudioDevice.name }}</span>
    </div>
    <div fxFlex="10" fxLayoutAlign="center center">
      <ng-template [ngTemplateOutlet]="optionsTemplate"></ng-template>
    </div>
  </div>
</ng-template>

<ng-template #optionsTemplate>
  <div class="mic-options" *ngIf="selectedAudioDevice">
    <span [matMenuTriggerFor]="showMuteAll ? audioOptions : micOptions">
      <mat-icon>expand_more</mat-icon>
    </span>
    <mat-menu #audioOptions="matMenu" xPosition="before">
      <button mat-menu-item (click)="muteAll.emit()">Mute All</button>
      <button mat-menu-item [matMenuTriggerFor]="micOptions" *ngIf="!audioMuted">Choose Mic</button>
    </mat-menu>
    <mat-menu #micOptions="matMenu" [xPosition]="showMuteAll ? 'after' : 'before'">
      <button
        mat-menu-item
        *ngFor="let availableAudioDevice of availableAudioDevices"
        (click)="changeAudioDevice(availableAudioDevice, false)"
        [ngClass]="{ 'selected-option': availableAudioDevice.id === selectedAudioDevice.id }"
      >
        {{ availableAudioDevice.name }}
      </button>
    </mat-menu>
  </div>
</ng-template>
