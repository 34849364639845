import { VideoSource } from './video-source.interface';

/**
 * Unique identifier for audio device. For example, we should be able to pass
 * this information to any other system (for example FFMPEG), and (with some
 * modifications) it should be able to get the exact same device that we refer
 * to.
 */
export class VideoDevice {
  /**
   * The video source type.
   */
  source: VideoSource;
  /**
   * User-friendly device name
   */
  name?: string;
  /**
   * Some kind of id that other systems should be able to understand
   */
  id?: string;
  /**
   * Stream max height
   */
  height?: number;
  /**
   * Stream max width
   */
  width?: number;
}

export class PresentSource {
  /**
   * The video source type.
   */
  source: VideoSource;
  /**
   * User-friendly present name
   */
  name?: string;

  /**
   * Some kind of id that other systems should be able to understand
   */
   id?: string;
}

/**
 * Options used to get a new stream
 */
export interface VideoDeviceOptions {
  /**
   * Some kind of id that other systems should be able to understand
   */
  id?: string;
  /**
   * The video source type.
   */
  source: VideoSource;
}
