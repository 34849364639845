import { TemplateBuilderStyles } from '../interfaces';
import { getLayerStyles } from '../template-builder/getLayerStyles';
import { WorkflowTemplatesBaseBuilder } from './workflow-templates-base.builder';

export interface BuilderStylesVideoChangeCommandEvent {
  styles: TemplateBuilderStyles;
}

export class BuilderStylesVideoChangeCommand extends WorkflowTemplatesBaseBuilder<BuilderStylesVideoChangeCommandEvent> {
  run(event: BuilderStylesVideoChangeCommandEvent) {
    const layerStyles = getLayerStyles(event.styles);

    // Update settings
    this.source.features.layouts = { ...this.source.features.layouts, styles: layerStyles };

    // Update existing main timelines/layers
    for (const [, section] of Object.entries(this.source.sections)) {
      if (section.sectionType !== 'main') {
        continue;
      }

      const mainTimelines = section.timelines.filter((t) => t.type === 'main');
      for (const timeline of mainTimelines) {
        timeline.styles = layerStyles;

        for (const layer of timeline.layers) {
          layer.styles = layerStyles;
        }
      }
    }

    return this.ok();
  }
}
