import * as TimelineInterfaces from '../interfaces/timelines.interfaces';
import { round } from 'lodash';
import { getLayerFromId } from '../helpers';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export class MoveItemsCommand extends WorkflowBaseBuilder<TimelineInterfaces.ItemsMovedEvent> {
  run(event: TimelineInterfaces.ItemsMovedEvent) {
    event.items.forEach(({ layerId, newStartAt }) => {
      const { sectionId, layer } = getLayerFromId(layerId, this.source);
      if (!layer) {
        console.error('Cannot find layer to update.');
        return;
      }

      const layerDuration = layer.visibility.endAt - layer.visibility.startAt;
      const sectionDuration = this.source.sections[sectionId].sectionDuration;

      newStartAt = round(newStartAt);
      let newEndAt = newStartAt + layerDuration;
      if (newEndAt > sectionDuration) {
        newEndAt = sectionDuration;
        newStartAt = newEndAt - layerDuration;

        if (newStartAt < 0) {
          newStartAt = 0;
        }
      }

      layer.visibility = {
        ...layer.visibility,
        startAt: newStartAt,
        endAt: newEndAt,
      };
    });

    return this.ok();
  }
}
