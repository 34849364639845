import { createSelector, MemoizedSelector, select } from '@ngrx/store';
import { DirectorSession } from '@openreel/frontend/common';
import { pipe } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { RootState } from '../app-state/root-state';
import { SessionState } from '../app-state/session/interfaces/session-store.interface';
import * as sessionSelectors from '../app-state/session/session.selectors';
import * as participantSelectors from '../app-state/session/participants/participant.selectors';

export * from '../app-state/session/session.selectors';

export const selectDirectorSessionDetails = pipe(
  select(sessionSelectors.selectSession as MemoizedSelector<RootState, SessionState<DirectorSession>>),
  filter((session) => session.sessionDetails != null),
  map(
    (session) =>
      <DirectorSession>{
        ...session.sessionDetails,
        identity: session.stream?.streamToken?.identity,
      }
  )
);

export const selectSessionSettings = createSelector(sessionSelectors.selectSession, (state) => state.sessionSettings);

export const selectSessionFetching = createSelector(
  sessionSelectors.selectSession,
  (state) => state.sessionSettingsFetching
);

export const selectSessionState = createSelector(sessionSelectors.selectSession, (state) => state.sessionState);

export const selectStream = createSelector(sessionSelectors.selectSession, (state) => state.stream);

export const selectRecordingIdentities = createSelector(sessionSelectors.selectSession, (state) =>
  Object.keys(state.sessionState.states)
);

export const selectDominantIdentity = createSelector(
  participantSelectors.selectParticipants,
  (state) => state.dominantSubjectIdentity
);

export const selectDominantSubject = createSelector(
  participantSelectors.selectOnlineSubjects,
  selectDominantIdentity,
  (subjects, dominant) => subjects.find((subject) => subject.identity === dominant) || subjects[0]
);

export const selectOnlineSubjectsWithoutDominant = createSelector(
  participantSelectors.selectOnlineSubjects,
  selectDominantSubject,
  (subjects, dominant) => (dominant ? subjects.filter((subject) => subject.identity !== dominant.identity) : subjects)
);

export const selectDominantParticipant = createSelector(
  participantSelectors.selectOnlineParticipantsIncludingMe,
  selectDominantIdentity,
  (participants, dominant) => participants.find((participant) => participant?.identity === dominant)
);

export const selectParticipantsWithGuide = createSelector(
  participantSelectors.selectParticipants,
  (state) => state.participantIdsWithGuides
);

export const selectParticipantVideoGuideByIdentity = (identity: string) =>
  createSelector(selectParticipantsWithGuide, (participants) => participants?.includes(identity));
