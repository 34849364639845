import { cloneDeep } from 'lodash';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_3_2 } from '../transformation_3_2/schema_after';
import { WorkflowDataDto_3_3 } from './schema_after';
/*
  NOTE: Purpose of this transformation:
    - 1) set groups for all sections
*/
export const SchemaTransformation_3_3: SchemaTransformation = {
  version: '3.3',
  supportsPolyfill: false,

  run(oldSchema: WorkflowDataDto_3_2.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as unknown as WorkflowDataDto_3_3.WorkflowDataDto;

    for (const [, section] of Object.entries(newSchema.sections)) {
      section.groups = [];
    }

    return newSchema;
  },
};
