import { cloneDeep } from 'lodash';
import { Asset, Section, TemplateSchemaTransformation } from '../../../interfaces';
import { TemplateWorkflowDataDto_1_7 } from '../template_transformation_1_7/schema_after';
import { TemplateWorkflowDataDto_1_8 } from './schema_after';

export const TemplateSchemaTransformation_1_8: TemplateSchemaTransformation = {
  version: '1.8',

  run(oldSchema: TemplateWorkflowDataDto_1_7.WorkflowDataDto): TemplateWorkflowDataDto_1_8.WorkflowDataDto {
    const newSchema = cloneDeep(oldSchema) as unknown as TemplateWorkflowDataDto_1_8.WorkflowDataDto;

    const logoAssetId = newSchema.globalSettings.logo.settings.assetId;

    // Cleanup assets
    for (const asset of newSchema.assets) {
      cleanupAsset(asset, logoAssetId);
    }

    // Cleanup section
    for (const [, section] of Object.entries(newSchema.sections)) {
      cleanupSection(section, logoAssetId);
    }

    // Cleanup template sections
    for (const [, templateSectionType] of Object.entries(newSchema.templateSections)) {
      for (const templateSection of templateSectionType.sections) {
        for (const asset of templateSection.assets) {
          cleanupAsset(asset, logoAssetId);
        }

        cleanupSection(templateSection.section, logoAssetId);
      }
    }

    return newSchema;
  },
};

function cleanupAsset(asset: Asset, logoAssetId: string) {
  if (asset.type !== 'json' || !asset.preset) {
    return;
  }

  for (const [, field] of Object.entries(asset.preset)) {
    if (field.type !== 'logo') {
      return;
    }

    field.assetId = logoAssetId;
  }
}

function cleanupSection(section: Section, logoAssetId: string) {
  for (const timeline of section.timelines) {
    for (const layer of timeline.layers) {
      if (layer.type !== 'lottie' || !layer.data) {
        continue;
      }

      for (const [, field] of Object.entries(layer.data)) {
        if (field.type !== 'logo') {
          continue;
        }

        field.assetId = logoAssetId;
      }
    }
  }
}
