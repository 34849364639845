import { SectionTimeline } from './../interfaces/workflow.interfaces';
import { v4 as uuidv4 } from 'uuid';
import { getLayerBounds } from './getLayerBounds';
import { getLayerStyles } from './getLayerStyles';
import { TemplateBuilderParsedTimeline } from './../interfaces/template-builder.interfaces';

export function createTimeline(timelineDef: TemplateBuilderParsedTimeline, id?: string) {
  const newTimeline: SectionTimeline = {
    id: id || uuidv4(),
    type: timelineDef.type,
    layers: [],
    zIndex: timelineDef.zIndex,
    title: timelineDef.title ?? '',
    hasAudio: typeof timelineDef.hasAudio === 'boolean' ? timelineDef.hasAudio : false,
    ...(timelineDef.bounds ? { bounds: getLayerBounds(timelineDef.bounds) } : {}),
    ...(timelineDef.styles ? { styles: getLayerStyles(timelineDef.styles) } : {}),
    ...(timelineDef.controls
      ? {
          controls: {
            switch: timelineDef.controls.switch ?? false,
          },
        }
      : {}),
    ...(timelineDef.pairId ? { pairId: timelineDef.pairId } : {}),
    ...(timelineDef.contentType ? { contentType: timelineDef.contentType } : {}),
  };

  return newTimeline;
}
