import { Injectable, OnDestroy } from '@angular/core';
import { commonenv } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ROUTE_OAUTH_CONNECTIONS } from '@openreel/frontend/common';
import { MailchimpListEntity, MailchimpTokenEntity } from './connections.interface';
import { BaseOauthService } from './base-oauth.service';
import { ConnectionAuthEntity } from '@openreel/common/automations';

@Injectable({
  providedIn: 'root',
})
export class MailchimpService implements OnDestroy {
  public urls = {
    redirectUrl: commonenv.automationsApiUrl + 'connections/mailchimp/auth',
    callbackUrl: commonenv.automationsApiUrl + 'connections/mailchimp/auth/callback',
    lists: (connectionId: number) =>
      `${commonenv.automationsApiUrl}connections/mailchimp/lists?connection_id=${connectionId}`,
    tokens: (connectionId: number, listId: string) =>
      `${commonenv.automationsApiUrl}connections/mailchimp/tokens?connection_id=${connectionId}&list_id=${listId}`,
  };

  constructor(
    private readonly baseOauthService: BaseOauthService,
    private readonly httpClient: HttpClient,
  ) { }

  getRedirectUrl() {
    return this.httpClient.get<{ redirectUrl: string }>(this.urls.redirectUrl, {});
  }

  authCallback(code: string) {
    return this.httpClient.post<ConnectionAuthEntity>(this.urls.callbackUrl, { code });
  }

  getLists(connectionId: number) {
    return this.httpClient.get<MailchimpListEntity[]>(this.urls.lists(connectionId));
  }

  getTokens(connectionId: number, listId: string) {
    return this.httpClient.get<MailchimpTokenEntity[]>(this.urls.tokens(connectionId, listId));
  }

  login() {
    this.baseOauthService.openOauthWindow(`/${ROUTE_OAUTH_CONNECTIONS}/mailchimp`, 'Mailchimp Login');

    return new Promise<ConnectionAuthEntity>((resolve) => {
      window['mailchimpCallback'] = (connection: ConnectionAuthEntity) => {
        resolve(connection);
        delete window['mailchimpCallback'];
      };
    });
  }

  ngOnDestroy() {
    delete window['mailchimpCallback'];
  }
}
