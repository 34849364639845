import { AssetType, LayerType } from './../interfaces/workflow.interfaces';

export const layerTypeToAssetType = (layerType: LayerType): AssetType => {
  switch (layerType) {
    case 'video':
      return 'clip';
    case 'image':
      return 'image';
    case 'lottie':
      return 'json';
    default:
      throw new Error(`Can't find upload type for layer type: '${layerType}'`);
  }
};
