import { Component, ChangeDetectionStrategy } from '@angular/core';
import {
  AuthService,
} from '@openreel/frontend/common';
import { ToastrService } from 'ngx-toastr';
import { RecordingItemComponent } from '../../recordings-list/recording-item/recording-item.component';
@Component({
  selector: 'openreel-recording-item-clip',
  templateUrl: './recording-item.component.html',
  styleUrls: ['./recording-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecordingItemClipComponent extends RecordingItemComponent{
  
  constructor(authService: AuthService, toast: ToastrService) {
    super(authService, toast)
  }
}
