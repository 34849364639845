import { Asset, ImageLayer, LayerOptions } from './../interfaces/workflow.interfaces';
import { v4 } from 'uuid';
import { createImageAsset } from './createImageAsset';
import { TemplateBuilderParsedLayer } from './../interfaces/template-builder.interfaces';

export function createImageLayer(layerDef: TemplateBuilderParsedLayer) {
  let asset: Asset;
  if (!layerDef.existingAssetId) {
    asset = createImageAsset(layerDef.asset.assetFileId);
  }

  const layer: LayerOptions & ImageLayer = {
    layerId: v4(),
    enabled: true,
    loop: false,
    type: 'image',
    assetId: asset?.id ?? layerDef.existingAssetId,
  };

  return { layer, asset };
}
