import { cloneDeep } from 'lodash';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_3_8 } from '../transformation_3_8/schema_after';
import { v4 } from 'uuid';

/*
  NOTE: Purpose of this transformation:
    - 1) add missing background layers
*/
export const SchemaTransformation_4_3: SchemaTransformation = {
  version: '4.3',
  supportsPolyfill: true,

  run(oldSchema: WorkflowDataDto_3_8.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as unknown as WorkflowDataDto_3_8.WorkflowDataDto;

    for (const [, section] of Object.entries(newSchema.sections)) {
      const backgroundTimeline = section.timelines.find((t) => t.type === 'background');
      if (!backgroundTimeline) {
        continue;
      }

      if (backgroundTimeline.layers.length > 0) {
        continue;
      }

      const backgroundColor = (section.backgroundColor as WorkflowDataDto_3_8.SolidColor).color;

      const newStyle: WorkflowDataDto_3_8.Style = { id: v4(), color: backgroundColor };
      newSchema.styles.push(newStyle);

      backgroundTimeline.layers.push({
        loop: true,
        enabled: true,
        type: 'color',
        bounds: { x: 0, y: 0, width: 100, height: 100 },
        styleId: newStyle.id,
        layerId: v4(),
        visibility: {
          startAt: 0,
        },
      });
    }

    return newSchema;
  },
};
