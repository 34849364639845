export type FontVariant = 'regular' | 'italic' | '700' | '700italic';

export const FontVariantsCaptions = new Map<FontVariant, string>([
  ['regular', 'Regular'],
  ['italic', 'Italic'],
  ['700', 'Bold'],
  ['700italic', 'Bold & Italic'],
]);

export type FontSource = 'google' | 'custom';

export interface FontDto {
  id: number;
  family: string;
  source: FontSource;
  variants: { slug: FontVariant; url: string }[];
}

export interface LoadedFont {
  id: number;
  source: FontSource;
  fontFamily: string;
  fontVariant: FontVariant;
  fontPath?: string;
  fontFile?: ArrayBuffer;
}
