import { extractSubjectName, getTextLayerFromMainLayer } from '../helpers';
import { Layer, LayerOptions, LottieLayer, PresetFieldTokenType, Source } from '../interfaces';
import { LayersToggleCommand } from './layers-toggle.command';
import { UpdateLayersDataCommand } from './update-layers-data.command';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export interface AddSubjectNameOverlayEvent {
  mainClipsLayerIds: string[];
  defaultOtherLower3rdField?: string;
  clips: {
    personName: string;
    source: Source;
  }[];
}

export class AddSubjectNameOverlayCommand extends WorkflowBaseBuilder<AddSubjectNameOverlayEvent, Layer[]> {
  run({ mainClipsLayerIds, clips, defaultOtherLower3rdField }: AddSubjectNameOverlayEvent) {
    const layers: (LayerOptions & LottieLayer)[] = [];

    mainClipsLayerIds.forEach((mainLayerId, index) => {
      const textLayer = getTextLayerFromMainLayer(this.source, mainLayerId);
      if (!textLayer) {
        return;
      }

      layers.push(textLayer);

      const subjectName = extractSubjectName(clips[index]?.personName);
      if (subjectName && clips[index].source === 'webcam') {
        const {
          updatedWorkflow: updatedWorkflow2,
          errorMessage,
          success,
        } = new LayersToggleCommand(this.source).run({
          layerIds: [textLayer.layerId],
          enabled: true,
        });
        if (!success) {
          throw new Error('Subject name layer toggle enable failed: ' + errorMessage);
        }
        this.source = updatedWorkflow2;

        const asset = this.source.assets.find((asset) => asset.id === textLayer.assetId);
        for (const presetKey in asset.preset) {
          let newValue: string = null;

          if (asset.preset[presetKey].tokenType === PresetFieldTokenType.FULL_NAME) {
            newValue = `${subjectName.subjectFirstName} ${subjectName.subjectLastName}`;
          } else if (asset.preset[presetKey].tokenType === PresetFieldTokenType.FIRST_NAME) {
            newValue = subjectName.subjectFirstName;
          } else if (asset.preset[presetKey].tokenType === PresetFieldTokenType.LAST_NAME) {
            newValue = subjectName.subjectLastName;
          } else if (asset.preset[presetKey].type === 'text' && defaultOtherLower3rdField !== undefined) {
            newValue = defaultOtherLower3rdField;
          }

          if (newValue !== null) {
            const { updatedWorkflow, errorMessage, success } = new UpdateLayersDataCommand(this.source).run({
              data: {
                [textLayer.layerId]: {
                  values: {
                    [presetKey]: {
                      type: asset.preset[presetKey].type,
                      styleId: textLayer.data[presetKey].styleId,
                      value: newValue,
                    },
                  },
                  assetChanges: {},
                  styleChanges: {},
                },
              },
            });

            if (!success) {
              throw new Error('Subject name layer update failed: ' + errorMessage);
            }

            this.source = updatedWorkflow;
          }
        }
      } else {
        const {
          updatedWorkflow: updatedWorkflow2,
          success,
          errorMessage,
        } = new LayersToggleCommand(this.source).run({
          layerIds: [textLayer.layerId],
          enabled: false,
        });
        if (!success) {
          throw new Error('Subject name layer toggle disable failed: ' + errorMessage);
        }
        this.source = updatedWorkflow2;
      }
    });

    return this.ok(layers);
  }
}
