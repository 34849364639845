import { cloneDeep } from 'lodash';
import {
  SchemaTransformation,
  SchemaTransformationOptions,
} from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_3_6 } from '../transformation_3_6/schema_after';
import { WorkflowDataDto_3_7 } from './schema_after';

/*
  NOTE: Purpose of this transformation:
    - 1) add resolution
*/
export const SchemaTransformation_3_7: SchemaTransformation = {
  version: '3.7',
  supportsPolyfill: true,

  run(oldSchema: WorkflowDataDto_3_6.WorkflowDataDto, { template }: SchemaTransformationOptions) {
    const newSchema = cloneDeep(oldSchema) as unknown as WorkflowDataDto_3_7.WorkflowDataDto;

    newSchema.globalSettings.resolution = {
      width: 1920,
      height: 1080,
    };

    return newSchema;
  },
};
