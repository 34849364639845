<div class="video-item-wrapper" [attr.data-cy]="'director-clipWrapper-' + recording.video_type" fxLayout="row"
  fxLayout.lt-md="row" fxFill>
  <div fxLayoutAlign="start center">
    <mat-icon *ngIf="selectionEnabled" svgIcon="check-round" (click)="onSelectClicked()" class="selection-icon"
      [ngClass]="{ selected: recording.selected }"></mat-icon>
    <div *ngIf="
        recording.is_opentok_archive ||
        (recording.preview_status && recording.preview_status === videoTranscodingStatus.Ready)
      " class="preview-image">
      <img class="recording-item-thumbnail" (click)="onPlayClicked(VideoType.SD)" [src]="recording.thumb"
        openreelFallback="assets/common/images/placeholder.jpg" alt="thumb-nail" />
      <mat-icon svgIcon="play"></mat-icon>
    </div>
    <img src="assets/common/icons/no-preview.svg" *ngIf="recording.preview_status === videoTranscodingStatus.Failed"
      alt="No Preview" title="Preview is unavailable" class="recording-item-thumbnail" />
  </div>
  <div fxFlex fxLayout="column" class="details-container">
    <div class="video-title-info">
      <span class="video-name" [matTooltip]="recording.video_name" matTooltipPosition="above">
        {{ recording.video_name }}
      </span>

      <div class="video-info">
        <div *ngIf="showInfo" class="toggle-info">
          <span class="video-info-detail-header">{{
            resolutionUnits[recording.resolution] || recording.resolution + 'P'
            }}</span>
          <span class="video-info-detail-header">{{ recording.video_length | clipDuration }}</span>
          <span class="video-info-detail-header">{{ recording.file_size | clipSizeBytes }}</span>
          <span class="video-info-detail-header">{{ recording.fps }}fps</span>
          <div *ngIf="recording.destination_indications?.length > 0" class="session-selector">
            <mat-form-field appearance="outline" class="no-margin-padding">
              <mat-label>{{ recording.destination_indications?.length }} Destination</mat-label>
              <mat-select id="sessions-list-select">
                <mat-option *ngFor="let dest of recording.destination_indications">
                  <mat-icon class="dest-dropbox-icon" svgIcon="dest_dropbox_dark_mode_default"></mat-icon>
                  {{ dest.destinationName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- <mat-menu #actionMenu="matMenuDestination">
            <ng-container *ngFor="let dest of recording.destination_indications">
              <button mat-menu-item>
                {{ dest.destinationName }}
              </button>
            </ng-container>
          </mat-menu> -->
        </div>
        <button (click)="setShowInfo()" class="recording-more-options" mat-icon-button>
          <mat-icon *ngIf="!showInfo" svgIcon="info-alt" class="settings-icon"></mat-icon>
          <mat-icon *ngIf="showInfo" class="close-icon">close</mat-icon>
        </button>
      </div>
    </div>
    <div *ngIf="recording.destination_indications?.length > 0" fxLayout.lt-md="row wrap" fxLayout.lt-md="row"
      fxLayoutAlign="center center">
      <ng-template cdkConnectedOverlay [cdkConnectedOverlayHasBackdrop]="true" (backdropClick)="isOpen = !isOpen"
        [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen" class="record-details-container">
        <div class="destinations-overlay">
          <table class="dest-table"
            *ngIf="recording.destination_indications && recording.destination_indications?.length > 0"
            style="color: black !important">
            <tr *ngFor="let dest of recording.destination_indications">
              <td class="dest-name">
                <mat-icon class="dest-dropbox-icon" svgIcon="dest_dropbox_dark_mode_default"></mat-icon>
                <span>{{ dest.destinationName }}</span>
              </td>
              <td class="dest-original">
                Original
                <span>
                  <span class="material-icons dot-icon" [ngStyle]="{ color: dest.original ? 'green' : 'darkred' }">
                    fiber_manual_record
                  </span>
                </span>
              </td>
              <td class="dest-hq">
                MP4
                <span>
                  <span class="material-icons dot-icon" [ngStyle]="{ color: dest.hq ? 'green' : 'darkred' }">
                    fiber_manual_record
                  </span>
                </span>
              </td>
            </tr>
          </table>
          <div style="color: black" class="no-destinations-defined"
            *ngIf="!recording.destination_indications || recording.destination_indications?.length === 0">
            No destinations.
          </div>
        </div>
      </ng-template>
    </div>
    <div class="record-description">
      <div *ngIf="recording.transcript_status == transcriptStatus.Success" class="record-summary" fxFlex>
        {{ recording.transcript.fullText }}
      </div>
      <div *ngIf="recording.transcript_status == transcriptStatus.Success" class="overlay"></div>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <!-- <ng-container *ngIf="recording.status == VideoStatus.RECORDING">
      <div class="video-status status-recording" fxFlex="30">Recording</div>
      <mat-progress-bar
        *ngIf="recording.uploadDuringRecording && !recording.uploadPaused"
        class="uploading-progress-bar"
        mode="indeterminate"
      ></mat-progress-bar>
    </ng-container>
    <ng-container *ngIf="recording.status == VideoStatus.RECORDED">
      <div class="video-status status-uploading please-upload" fxFlex="30" fxFlex.xs="100">Please Upload</div>
    </ng-container>
    <ng-container *ngIf="recording.status == VideoStatus.UPLOADING">
      <div class="video-status status-uploading" fxFlex="30">Uploading</div>
      <mat-progress-bar class="uploading-progress-bar" mode="determinate" [value]="recording.upload_percentage">
      </mat-progress-bar>
      <p class="percentage" *ngIf="recording.upload_percentage && !recording.uploadDuringRecording">
        {{ recording.upload_percentage | number: '1.0-0' }}%
      </p>
    </ng-container> -->
      <!-- <ng-container *ngIf="recording.status == VideoStatus.UPLOADED">
      <div
        class="video-status"
        [ngClass]="['status-transcoding-' + recording.video_status]"
        fxFlex="30"
        *ngIf="!recording.archived_at"
      >
        {{ recording.video_showable_status }}
      </div>
      <div class="video-status status-transcoding-archived" fxFlex="30" *ngIf="recording.archived_at">Archived</div>
      <mat-progress-bar
        *ngIf="recording.sd_transcoding_percentage"
        class="uploading-progress-bar mat-accent"
        [ngClass]="['status-transcoding-' + recording.video_status]"
        mode="determinate"
        [value]="recording.sd_transcoding_percentage"
      ></mat-progress-bar>
      <mat-progress-bar
        *ngIf="recording.hq_transcoding_percentage"
        class="uploading-progress-bar"
        [ngClass]="['status-transcoding-' + recording.video_status]"
        mode="determinate"
        [value]="recording.hq_transcoding_percentage"
      ></mat-progress-bar>
    </ng-container> -->
      <div>
        <span *ngIf="recording.video_status === 'ready'" class="video-info-detail">{{
          recording.video_length | clipDuration
          }}</span>
      </div>
      <div class="video-info" fxFlex="70" fxLayout="row" fxLayoutAlign="end center">
        <!-- <span class="video-info-detail">{{ recording.fps }}fps</span>
      <span class="video-info-detail">{{ resolutionUnits[recording.resolution] || recording.resolution + 'P' }}</span>
      <span class="video-info-detail">{{ recording.file_size | clipSizeBytes }}</span> -->
        <div (click)="onRecordingDownloadClicked()" class="download" fxLayout="row" fxLayoutAlign="center"
          *ngIf="recording.video_status === 'ready'">
          <mat-icon svgIcon="download-cloud"></mat-icon>
          Download
        </div>
        <span class="video-info-detail video-favorite" *ngIf="canManipulate && !canRestore"><button
            (click)="onFavouriteClicked()" mat-icon-button>
            <mat-icon *ngIf="!recording.favourite">favorite_border</mat-icon>
            <mat-icon color="primary" *ngIf="recording.favourite">favorite</mat-icon>
          </button>
        </span>

        <button class="recording-more-options" mat-icon-button [matMenuTriggerFor]="menu" *ngIf="
            (recording.status != VideoStatus.RECORDING || recording.uploadDuringRecording) &&
            !((recording.status == VideoStatus.UPLOADING || recording.status == VideoStatus.RECORDED) && !canManipulate)
          ">
          <mat-icon style="font-size: 20px">more_horiz</mat-icon>
        </button>

        <mat-menu #menu="matMenu" xPosition="before">
          <ng-template matMenuContent *ngIf="!canRestore">
            <button mat-menu-item *ngIf="
                !recording.uploadPaused && recording.status == VideoStatus.RECORDING && recording.uploadDuringRecording
              " (click)="pauseUpload()">
              Pause Upload
            </button>
            <button mat-menu-item *ngIf="
                recording.uploadPaused && recording.status == VideoStatus.RECORDING && recording.uploadDuringRecording
              " (click)="resumeUpload()">
              Resume Upload
            </button>
            <button mat-menu-item *ngIf="recording.status == VideoStatus.RECORDED && canManipulate"
              (click)="onRecordingUploadClicked()">
              Upload
            </button>
            <button mat-menu-item *ngIf="recording.status == VideoStatus.UPLOADING && canManipulate && isIos()"
              (click)="onRecordingUploadCancelClicked()">
              Cancel Upload
            </button>
            <div *ngIf="recording.status == VideoStatus.UPLOADED">
              <button mat-menu-item (click)="onRecordingDownloadClicked()">Download Original</button>
              <button mat-menu-item (click)="onRecordingCopyClicked()">Share Original</button>

              <button *ngIf="recording.hq_status == videoTranscodingStatus.Ready" mat-menu-item
                (click)="onRecordingDownloadClicked(VideoType.HD)">
                Download MP4
              </button>
              <button *ngIf="recording.hq_status == videoTranscodingStatus.Ready" mat-menu-item
                (click)="onRecordingCopyClicked(VideoType.HD)">
                Share MP4
              </button>
              <button *ngIf="recording.hq_status == videoTranscodingStatus.Ready" mat-menu-item
                (click)="onPlayClicked(VideoType.HD)">
                Play MP4 (HQ)
              </button>
              <button *ngIf="
                  canManipulate &&
                  !recording.hq_status &&
                  !recording.is_opentok_archive &&
                  !recording.isPresentationVideo
                " mat-menu-item (click)="onRecordingTranscodeClicked(VideoType.HD)">
                Request MP4
              </button>
              <div *ngIf="recording.hq_status == videoTranscodingStatus.Failed && !recording.is_opentok_archive">
                <span mat-menu-item style="display: inline"> MP4 Unavailable</span>
                <img src="assets/common/icons/info.svg" alt="MP4 Unavailable"
                  style="margin-right: 3px; vertical-align: middle" />
              </div>
              <ng-container *ngIf="captureVideoExtractAudio | featureflag">
                <button
                  *ngIf="canManipulate && !recording.audio && !recording.is_opentok_archive && !recording.isPresentationVideo"
                  mat-menu-item (click)="onRecordingAudioExtractClicked(recording.ovra_session_videos_id)">
                  Extract Audio
                </button>
                <button
                  *ngIf="canManipulate && recording.audio?.state == 'ready' && !recording.is_opentok_archive && !recording.isPresentationVideo"
                  mat-menu-item (click)="onRecordingAudioDownloadClicked(recording.ovra_session_videos_id)">
                  Download Audio
                </button>
                <div *ngIf="canManipulate && recording.audio?.state == 'processing' && !recording.is_opentok_archive && !recording.isPresentationVideo">
                  <span mat-menu-item style="display: inline; padding-right: 6px;">Audio Processing</span> 
                  <mat-spinner [diameter]="20" style="display: inline; margin-right: 25px"></mat-spinner>
                </div>
                <div *ngIf="canManipulate && recording.audio?.state == 'failed' && !recording.is_opentok_archive && !recording.isPresentationVideo">
                  <span mat-menu-item style="display: inline; padding-right: 6px;"> Extract Audio Failed </span>
                  <img src="assets/common/icons/info.svg" alt="Transcript Unavailable"
                    style="margin-right: 3px; vertical-align: middle;" title="Extract Audio Failed" />
                </div>
              </ng-container>
              <ng-container *ngIf="transcription | featureflag">
                <button *ngIf="
                    canManipulate &&
                    !canArchive &&
                    !recording.transcript_status &&
                    recording.status == VideoStatus.UPLOADED &&
                    !recording.isPresentationVideo
                  " mat-menu-item (click)="onRecordingTranscriptClicked(recording.ovra_session_videos_id)">
                  Request Transcript
                </button>
                <button *ngIf="recording.transcript_status == transcriptStatus.Success" mat-menu-item
                  (click)="onTranscriptDownloadClicked(recording)">
                  Download Transcript
                </button>
                <div *ngIf="recording.transcript_status == transcriptStatus.Failed">
                  <span mat-menu-item style="display: inline"> Transcript Unavailable</span>
                  <img src="assets/common/icons/info.svg" alt="Transcript Unavailable"
                    style="margin-right: 3px; vertical-align: middle" title="Transcribing Failed" />
                </div>
                <div *ngIf="recording.transcript_status == transcriptStatus.Processing">
                  <span mat-menu-item style="display: inline; padding-right: 6px"> Transcript Processing</span>
                  <mat-spinner [diameter]="20" style="display: inline; margin-right: 25px"></mat-spinner>
                </div>
              </ng-container>
            </div>
            <button *ngIf="canArchive && captureArchiveClips | featureflag" class="archive-recording-button"
              color="warn" mat-menu-item (click)="onRecordingArchiveClicked()">
              Archive clip
            </button>
            <button *ngIf="canDelete" class="delete-recording-button" color="warn" mat-menu-item
              (click)="onRecordingDeleteClicked()">
              Delete clip
            </button>
            <button *ngIf="recording.comment" class="recording-comment-button" mat-menu-item
              (click)="onCommentShowClicked(recording.comment)">
              Show Comments
            </button>
          </ng-template>
          <ng-template matMenuContent *ngIf="canRestore">
            <button *ngIf="canRestore" class="restore-recording-button" color="warn" mat-menu-item
              (click)="onRecordingRestoreClicked()">
              Restore clip
            </button>
            <button *ngIf="canDelete" class="delete-recording-button" color="warn" mat-menu-item
              (click)="onRecordingDeleteClicked()">
              Delete clip
            </button>
          </ng-template>
        </mat-menu>
      </div>
    </div>
  </div>
</div>