import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { HostingPermissions } from '@openreel/common';
import { forkJoin } from 'rxjs';
import { HostingHub } from '../../hosting-interfaces';
import { HostingService } from '../../services/hosting.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HostablePermissionTypes } from '@openreel/frontend/common/hosting';
import { catchError, finalize, take } from 'rxjs/operators';
import { Cleanupable } from '@openreel/frontend/common';
import { ToastrService } from 'ngx-toastr';

class HostingHubCheck {
  hub: HostingHub;
  checked: boolean;
}

interface NewHubForm {
  name: FormControl<string>;
  slug: FormControl<string>;
}

@Component({
  selector: 'openreel-add-to-hub',
  templateUrl: './add-to-hub.component.html',
  styleUrls: ['./add-to-hub.component.scss']
})
export class AddToHubComponent extends Cleanupable implements OnInit {

  readonly HostingPermissions = HostingPermissions;
  readonly newHubForm: FormGroup<NewHubForm>;

  hubCreationActive = false;
  creatingNewHub = false;
  createdHubs: HostingHub[] = [];

  saveDisabled = true;
  isLightThemed = true;
  isPopup = true;

  hubPageNumber = 0;
  hubPageSize = 1000;
  totalHubs = 0;

  hubList: Array<HostingHubCheck> = [];
  preselectedList: Array<number> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {videoId: number},
    public dialogRef: MatDialogRef<AddToHubComponent>,
    private hostingService: HostingService,
    private fb: FormBuilder,
    private toastr: ToastrService,
  ) {
    super();
    this.newHubForm = this.buildNewHubForm();
  }

  ngOnInit() {
    this.getAllHubs();
    this.subscribeToNewHubNameChanges();
  }

  //
  // Get all hubs for account
  //
  getAllHubs() {
    forkJoin([
      this.hostingService.getHubs(this.hubPageNumber, this.hubPageSize, null),
      this.hostingService.getHubs4video(this.data.videoId),
    ]).subscribe(
      ([hubResp, preselected]) => {
        this.preselectedList = preselected.map(h => h.id);
        this.totalHubs = hubResp.count;

        hubResp.rows.forEach(hub => {
          const hostingHub: HostingHubCheck = new HostingHubCheck();
          hostingHub.hub = hub;
          hostingHub.checked = false;
          this.hubList.push(hostingHub);
        });
        this.markPreselected();
      }
    );
  }

  //
  // Get all pre selected
  //
  markPreselected() {
    if (!this.preselectedList.length) return;

    // walk throuth and set checked flag
    this.hubList.forEach(hub => {
      hub.checked = this.preselectedList.includes(hub.hub.id);
    });
  }

  onChange(event: MatCheckboxChange, hub: HostingHubCheck) {
    this.saveDisabled = false;
    hub.checked = event.checked;
  }

  add2Hub() {
    const checked: Array<HostingHub> = [];
    const unchecked: Array<HostingHub> = [];

    // collect checked and unchecked
    this.hubList.forEach(hub => {
      if (hub.checked) { // checked
        // test if it was checked before
        if (!this.preselectedList.includes(hub.hub.id))
          checked.push(hub.hub);
      } else { // unchecked
        // test if it was checked before and got unchecked
        if (this.preselectedList.includes(hub.hub.id))
          unchecked.push(hub.hub);
      }
    });

    this.dialogRef.close({checked, unchecked, created: this.createdHubs});
  }

  setNewHubCreationFormVisibility(creationActive: boolean): void {
    this.hubCreationActive = creationActive;
  }

  exitHubCreationMode(): void {
    this.setNewHubCreationFormVisibility(false);
    this.newHubForm.get('name').setValue('');
  }

  createNewHub(): void {
    if (this.newHubForm.invalid) {
      return;
    }

    this.creatingNewHub = true;

    this.hostingService.createHub(
      this.newHubForm.value.name,
      HostablePermissionTypes.Private,
      this.newHubForm.value.slug,
    )
      .pipe(
        take(1),
        catchError(error => {
          this.toastr.error(error.message, 'Error!');

          throw error;
        }),
        finalize(() => {
          this.creatingNewHub = false;
        }),
      )
      .subscribe(hub => {
        this.createdHubs.push(hub);
        this.hubList = [{ hub, checked: false }, ...this.hubList];

        this.exitHubCreationMode();
      });
  }

  buildNewHubForm(): FormGroup<NewHubForm> {
    return this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(50)]],
      slug: ['', [Validators.required, Validators.maxLength(100)]],
    });
  }

  subscribeToNewHubNameChanges(): void {
    this.mortalize(this.newHubForm.get('name').valueChanges).subscribe((name: string) => {
      this.newHubForm.get('slug').setValue(name.trim());
    });
  }

  cancel(): void {
    this.dialogRef.close({checked: [], unchecked: [], created: this.createdHubs });
  }
}
