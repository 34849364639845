import { PipeTransform, Pipe } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ExternalMember, UserRoleType } from '@openreel/frontend/common';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { selectOnlineParticipantsIncludingMe } from '../../app-state/session/participants/participant.selectors';

interface OnlineUser {
  email?: string;
  login_id?: number;
  device_name?: string;
  country?: string;
  name?: string;
}

@Pipe({ name: 'isMemberOnline' })
export class IsMemberOnlinePipe implements PipeTransform {
  participants$: Observable<OnlineUser[]>;
  constructor(private store: Store) {
    this.participants$ = this.store.pipe(
      select(selectOnlineParticipantsIncludingMe),
      map((participants) =>
        participants.map(
          (participant) =>
            ({
              login_id: participant.loginId,
              name: participant.name,
              country: participant.location?.country,
              device_name: participant.deviceName,
              email: participant.email,
            } as OnlineUser)
        )
      ),
      shareReplay()
    );
  }

  transform(member: ExternalMember) {
    return this.isMemberOnline(member);
  }

  private isNonSubjectOnline(member: ExternalMember): Observable<boolean> {
    return this.participants$.pipe(map((participants) => participants.some((p) => p.name === member.name)));
  }

  private isSubjectOnline(member: ExternalMember): Observable<boolean> {
    return this.participants$.pipe(
      map((participants) => participants.some((p) => member.device_ids.indexOf(p.login_id) > -1))
    );
  }

  private isMemberOnline(member: ExternalMember): Observable<boolean> {
    if (member.role !== UserRoleType.Subject) {
      return this.isNonSubjectOnline(member);
    } else {
      return this.isSubjectOnline(member);
    }
  }
}
