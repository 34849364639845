<div
  class="video-stream-container"
  [class.is-recording]="isRecording"
  [class.fullscreen]="isFullscreen"
  (fullscreenchange)="toggleFullscreen()"
  #videoWrapperElement
  [class.disable-container]="!enableVideo"
>
  <video
    class="video-player fit-cover"
    [class.is-mirrored]="mirrored"
    [class.disable-video]="!enableVideo"
    #videoPlayer
    autoplay
    playsinline
    [muted]="true"
  ></video>
  <div class="video-stream-status" *ngIf="streamStatus && streamStatus !== 'loading'" fxLayoutAlign="center center">
    <span class="label">{{ streamStatus }}</span>
  </div>
  <div class="memory-info" *ngIf="storage" ngClass.lt-md="hide" [class.right]="!enableVideo">
    {{ storage | number: '1.1-1' }} GB
  </div>
  <button mat-icon-button class="full-screen" ngClass.lt-md="hide" (click)="changeFullScreen()" *ngIf="enableVideo && fullScreenControl">
    <mat-icon>{{ isFullscreen ? 'fullscreen_exit' : 'fullscreen' }}</mat-icon>
  </button>
  <div *ngIf="!enableVideo" class="no-video">
    <div *ngIf="participantName; else novideo" class="avatar">
      {{ getInitials(participantName) }}
    </div>
    <ng-template #novideo>
      <div class="no-video-text">
        <mat-icon color="accent" svgIcon="videocam-off"></mat-icon>
        <span> No Video</span>
      </div></ng-template
    >
  </div>
  <ng-container>
    <div class="chips">
      <ng-content select="[chip]"></ng-content>
      <div *ngIf="isInternalUser" class="internal-logo" ngClass.lt-md="hidden" [class.adjust-position]="isRecording">
        <img [src]="logoUrl || 'assets/common/images/logo.light.png'" alt="logo" />
      </div>
    </div>
    <div *ngIf="participantName" class="participant-name chip" ngClass.lt-md="hidden">
      {{ participantName }}
    </div>
    <ng-content></ng-content>
  </ng-container>
</div>
