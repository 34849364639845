import { Component, Inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { FeatureFlaggingService, liveDestinationData, LiveDestinations } from '@openreel/frontend/common';
import { LIVE_STREAM_DESTINATION_FACEBOOK, LIVE_STREAM_DESTINATION_YOUTUBE } from '@openreel/common';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { SessionService } from '../../../app-state/session/session.service';
import { FacebookStreamingService } from '../../../shared/services/live-streaming-destinations/facebook-streaming.service';
import { YoutubeStreamingService } from '../../../shared/services/live-streaming-destinations/youtube-streaming.service';

@Component({
  selector: 'openreel-capture-live-destinations',
  templateUrl: 'live-destinations.component.html',
  styleUrls: ['live-destinations.component.scss'],
})
export class LiveDestinationsComponent {
  destinations = liveDestinationData;
  currentDestination = new UntypedFormControl();
  streamUrl = new UntypedFormControl();
  streamKey = new UntypedFormControl();
  fetchingUrl = false;
  sessionName: string;

  constructor(
    private dialogRef: MatDialogRef<LiveDestinationsComponent>,
    private toastr: ToastrService,
    private facebookStreamingService: FacebookStreamingService,
    private youtubeStreamingService: YoutubeStreamingService,
    private sessionService: SessionService,
    private featureFlaggingService: FeatureFlaggingService,
    @Inject(MAT_DIALOG_DATA) public data: { addedDestinations: LiveDestinations[] }
  ) {
    this.currentDestination.setValue(this.destinations[this.destinations.length - 1]);
    this.sessionService.sessionDetails$.pipe(take(1)).subscribe((sessionDetails) => {
      this.sessionName = sessionDetails.session_name;
    });
    if (!this.featureFlaggingService.getFlag(LIVE_STREAM_DESTINATION_FACEBOOK)) {
      this.destinations = this.destinations.filter((destination) => destination.value !== 'facebook');
    } else {
      this.facebookStreamingService.initFacebook();
    }
    if (!this.featureFlaggingService.getFlag(LIVE_STREAM_DESTINATION_YOUTUBE)) {
      this.destinations = this.destinations.filter((destination) => destination.value !== 'youtube');
    } else {
      this.youtubeStreamingService.initYoutube();
    }
  }
  onDialogCloseClicked() {
    this.dialogRef.close();
  }
  changeDestination() {
    this.streamUrl.setValue(null);
    this.streamKey.setValue(null);
    this.fetchingUrl = false;
  }
  async getFacebookStreamUrl() {
    try {
      this.fetchingUrl = true;
      const token = await this.facebookStreamingService.initLogin();
      const fbUrlDetails = await this.facebookStreamingService.getLiveVideoDetails(token.authResponse.accessToken);
      const splitBy = 'rtmp/';
      const urlSplit = fbUrlDetails.secure_stream_url.split(splitBy);
      this.streamUrl.setValue(urlSplit[0] + splitBy);
      this.streamKey.setValue(urlSplit[1]);
    } catch (error) {
      console.log(error);
      this.toastr.error('Unable to get URL from Facebook');
      this.fetchingUrl = false;
    }
  }
  async getYoutubeStreamUrl() {
    try {
      this.fetchingUrl = true;
      await this.youtubeStreamingService.authenticate();
      const youtubeUrlDetails = await this.youtubeStreamingService.getYoutubeRtmpStream(this.sessionName);
      this.streamUrl.setValue(youtubeUrlDetails.result.cdn.ingestionInfo.ingestionAddress);
      this.streamKey.setValue(youtubeUrlDetails.result.cdn.ingestionInfo.streamName);
    } catch (error) {
      console.log(error);
      this.toastr.error('Unable to get URL from YouTube');
      this.fetchingUrl = false;
    }
  }
  saveDestination() {
    if (
      !this.streamUrl.value ||
      !this.streamKey.value ||
      (!this.streamUrl.value.startsWith('rtmp://') && !this.streamUrl.value.startsWith('rtmps://'))
    ) {
      this.toastr.error('Please enter a valid rtmp stream url & key');
    } else if (this.duplicateStreamUrlKey()) {
      this.toastr.error('Stream url & key already added');
    } else {
      this.dialogRef.close({
        name: this.currentDestination.value.name,
        value: this.currentDestination.value.value,
        url: this.streamUrl.value,
        key: this.streamKey.value,
      });
    }
  }
  duplicateStreamUrlKey() {
    return (
      this.data.addedDestinations.filter(
        (data) => data.url === this.streamUrl.value && data.key === this.streamKey.value
      ).length > 0
    );
  }
}
