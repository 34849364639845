import { clamp, cloneDeep } from 'lodash';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_3_8 } from '../transformation_3_8/schema_after';
import { getLayers } from '../../../helpers';
import { TEXT_BOX_EFFECTS_LIMITS } from '../../../constants/text-box-effects-limits.constants';

/*
  NOTE: Purpose of this transformation:
    - 1) clamp all text effects values to be in line with the new limits
*/
export const SchemaTransformation_4_2: SchemaTransformation = {
  version: '4.2',
  supportsPolyfill: true,

  run(oldSchema: WorkflowDataDto_3_8.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as unknown as WorkflowDataDto_3_8.WorkflowDataDto;

    for (const { layer } of getLayers(newSchema as never)) {
      if (layer.type === 'lottie' && layer.isTextBox) {
        switch (layer.textEffect?.type) {
          case 'shadow':
            layer.textEffect.blur = clamp(
              Math.round(layer.textEffect.blur),
              TEXT_BOX_EFFECTS_LIMITS.shadow.blur.min,
              TEXT_BOX_EFFECTS_LIMITS.shadow.blur.max
            );
            layer.textEffect.offset = clamp(
              Math.round(layer.textEffect.offset),
              TEXT_BOX_EFFECTS_LIMITS.shadow.offset.min,
              TEXT_BOX_EFFECTS_LIMITS.shadow.offset.max
            );
            layer.textEffect.angle = clamp(
              Math.round(layer.textEffect.angle),
              TEXT_BOX_EFFECTS_LIMITS.shadow.angle.min,
              TEXT_BOX_EFFECTS_LIMITS.shadow.angle.max
            );
            layer.textEffect.opacity = clamp(
              Math.round(layer.textEffect.opacity),
              TEXT_BOX_EFFECTS_LIMITS.shadow.opacity.min,
              TEXT_BOX_EFFECTS_LIMITS.shadow.opacity.max
            );
            break;
          case 'glow':
            layer.textEffect.blur = clamp(
              Math.round(layer.textEffect.blur),
              TEXT_BOX_EFFECTS_LIMITS.glow.blur.min,
              TEXT_BOX_EFFECTS_LIMITS.glow.blur.max
            );
            break;
          case 'echo':
            layer.textEffect.offset = clamp(
              Math.round(layer.textEffect.offset),
              TEXT_BOX_EFFECTS_LIMITS.echo.offset.min,
              TEXT_BOX_EFFECTS_LIMITS.echo.offset.max
            );
            layer.textEffect.angle = clamp(
              Math.round(layer.textEffect.angle),
              TEXT_BOX_EFFECTS_LIMITS.echo.angle.min,
              TEXT_BOX_EFFECTS_LIMITS.echo.angle.max
            );
            layer.textEffect.opacity = clamp(
              Math.round(layer.textEffect.opacity),
              TEXT_BOX_EFFECTS_LIMITS.echo.opacity.min,
              TEXT_BOX_EFFECTS_LIMITS.echo.opacity.max
            );
            break;
          case 'outline':
            layer.textEffect.thickness = clamp(
              Math.round(layer.textEffect.thickness),
              TEXT_BOX_EFFECTS_LIMITS.outline.thickness.min,
              TEXT_BOX_EFFECTS_LIMITS.outline.thickness.max
            );
            break;
          case 'glitch':
            layer.textEffect.offset = clamp(
              Math.round(layer.textEffect.offset),
              TEXT_BOX_EFFECTS_LIMITS.glitch.offset.min,
              TEXT_BOX_EFFECTS_LIMITS.glitch.offset.max
            );
            layer.textEffect.angle = clamp(
              Math.round(layer.textEffect.angle),
              TEXT_BOX_EFFECTS_LIMITS.glitch.angle.min,
              TEXT_BOX_EFFECTS_LIMITS.glitch.angle.max
            );
            break;
          case 'splice':
            layer.textEffect.thickness = clamp(
              Math.round(layer.textEffect.thickness),
              TEXT_BOX_EFFECTS_LIMITS.splice.thickness.min,
              TEXT_BOX_EFFECTS_LIMITS.splice.thickness.max
            );
            layer.textEffect.offset = clamp(
              Math.round(layer.textEffect.offset),
              TEXT_BOX_EFFECTS_LIMITS.splice.offset.min,
              TEXT_BOX_EFFECTS_LIMITS.splice.offset.max
            );
            layer.textEffect.angle = clamp(
              Math.round(layer.textEffect.angle),
              TEXT_BOX_EFFECTS_LIMITS.splice.angle.min,
              TEXT_BOX_EFFECTS_LIMITS.splice.angle.max
            );
            break;
          case 'hollow':
            layer.textEffect.thickness = clamp(
              Math.round(layer.textEffect.thickness),
              TEXT_BOX_EFFECTS_LIMITS.hollow.thickness.min,
              TEXT_BOX_EFFECTS_LIMITS.hollow.thickness.max
            );
            break;
        }
      }
    }

    return newSchema;
  },
};
