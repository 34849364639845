import { File, SectionId } from '../interfaces';
import { isEqual } from 'lodash';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export interface UpdateLogoEvent {
  assetFile: File;
  sectionId?: SectionId.Intro | SectionId.Outro;
}

export class UpdateLogoCommand extends WorkflowBaseBuilder<UpdateLogoEvent> {
  run({ assetFile, sectionId }: UpdateLogoEvent) {
    const isRemove = !assetFile;
    const isGlobalUpdate = sectionId === null || sectionId === undefined;
    const globalAssetId = this.source.globalSettings.logo.settings.assetId;
    const globalAsset = this.getAsset(globalAssetId);

    if (isGlobalUpdate) {
      if (isRemove) {
        this.removeGlobalLogo();
        this.updateLogo(null, null);
      } else {
        globalAsset.file = assetFile;
        this.setGlobalLogo(assetFile);
        this.updateLogo(null, globalAssetId);
      }
    } else {
      if (isRemove) {
        this.updateLogo(sectionId, null);
      } else {
        if (isEqual(globalAsset.file, assetFile)) {
          this.updateLogo(sectionId, globalAssetId);
        } else {
          const newAsset = this.addAsset(assetFile.path, 'image');
          this.updateLogo(sectionId, newAsset.id);
        }
      }
    }
    return this.ok();
  }
}
