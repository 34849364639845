export const debounce = (fn: (...args: never[]) => void, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;

  const tearDown = (): void => {
    clearTimeout(timeoutId);
  }

  const debouncedFunction = function (this: never, ...args: never[]): void {
    tearDown();
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  }

  return {
    debouncedFunction,
    tearDown,
  };
};
