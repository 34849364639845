import { ProjectFont } from '../interfaces';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export class UpdateFontCommand extends WorkflowBaseBuilder<{ font: ProjectFont }> {
  run({ font }) {
    this.updateGlobalFont(font, !!WorkflowBaseBuilder.textBoxMeasurer);

    return this.ok();
  }
}
