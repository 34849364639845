import { SectionVolumeEvent } from '../interfaces';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export class UpdateSceneVolumeCommand extends WorkflowBaseBuilder<SectionVolumeEvent> {
  run({ sectionId, volume }: SectionVolumeEvent) {
    const section = this.source.sections[sectionId];
    if (!section) {
      throw new Error(`Cannot find section with ID: ${sectionId}`);
    }

    section.audio.muted = volume === 0;
    section.audio.volume = volume;

    return this.ok();
  }
}
