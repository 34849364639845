import { NumberRange } from '@openreel/common';
import { AssetTranscriptWord } from '@openreel/creator/common';

/**
 * Find ranges of words belonging to a speaker
 * @param words Transcription words.
 * @param speakerId Speaker id to which found words belong to. Pass `null` to find words with no speaker.
 * @returns Words belonging to specified `spekaerId`.
 */
export function getSpeakerWordsRanges(words: AssetTranscriptWord[], speakerId: string): NumberRange[] {
  const ranges: NumberRange[] = [];

  let currentRange: NumberRange = null;
  let currentSpeakerId: string = null;
  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    const speakerMatches = word.speakerId === speakerId;

    if (!speakerMatches) {
      currentRange = null;
      currentSpeakerId = null;
      continue;
    }

    if (currentRange && currentSpeakerId === word.speakerId) {
      currentRange.to = i;
    } else {
      currentRange = { from: i, to: i };
      currentSpeakerId = word.speakerId;
      ranges.push(currentRange);
    }
  }

  return ranges;
}

export function getWordsRangeGroupedBySpeaker<T extends NumberRange>(words: AssetTranscriptWord[], range: T) {
  const groups: (T & { speakerId: string })[] = [];
  let group: T & { speakerId: string } = null;

  for (let i = range.from; i <= range.to; i++) {
    const word = words[i];

    if (group && group.speakerId === word.speakerId) {
      group.to = i;
    } else {
      group = {
        ...range,
        speakerId: word.speakerId,
        from: i,
        to: i,
      };
      groups.push(group);
    }
  }

  return groups;
}

export function extendWordsRangeWithTrailingNewLines<T extends NumberRange>(words: AssetTranscriptWord[], range: T): T {
  let to = range.to;

  for (let i = range.to + 1; i < words.length; i++) {
    if (words[i].type !== 'newline') {
      break;
    }

    to = i;
  }

  return {
    ...range,
    from: range.from,
    to: to,
  };
}
