import { TemplateBuilderTextOverlayTransition } from '../interfaces';
import { WorkflowTemplatesBaseBuilder } from './workflow-templates-base.builder';

export interface BuilderTextOverlayChangeTransitionCommandEvent {
  textOverlayTransition: TemplateBuilderTextOverlayTransition;
}

export class BuilderTextOverlayChangeTransitionCommand extends WorkflowTemplatesBaseBuilder<BuilderTextOverlayChangeTransitionCommandEvent> {
  run(event: BuilderTextOverlayChangeTransitionCommandEvent) {
    const hasTransition = event.textOverlayTransition?.type === 'fade-out';

    if (hasTransition) {
      this.source.globalSettings.textOverlays.transitions = {
        exit: { type: 'fade-out', duration: event.textOverlayTransition.duration, color: '#00000000' },
      };
    } else {
      delete this.source.globalSettings.textOverlays?.transitions;
    }

    return this.ok();
  }
}
