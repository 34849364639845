import { GlobalSettings, LayerOptions, LottieLayer } from '@openreel/creator/common';
import { v4 } from 'uuid';
import { createLottieAsset } from './createLottieAsset';
import { TemplateBuilderParsedLayer } from './../interfaces/template-builder.interfaces';

export function createLottieLayer(layerDef: TemplateBuilderParsedLayer, globalSettings: GlobalSettings) {
  const { asset, layerData, layerStyles: styles } = createLottieAsset(layerDef.asset, globalSettings);

  const layer: LayerOptions & LottieLayer = {
    layerId: v4(),
    enabled: true,
    type: 'lottie',
    assetId: asset.id,
    data: layerData,
    colorTags: asset.colorTags,
    renderer: 'svg',
    loop: layerDef.loop ?? false,
  };

  return { layer, asset, styles };
}
