/* eslint-disable @typescript-eslint/no-explicit-any */
import { cloneDeep } from 'lodash';
import {
  SchemaTransformation,
  SchemaTransformationOptions,
} from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_2_3 } from '../transformation_2_3/schema_after';
import { WorkflowDataDto_2_4 } from './schema_after';

const DEFAULT_FONT = 'Roboto';

/*
  NOTE: Purpose of this transformation:
    - check and transform workflow transition layers to have unique layerIds
*/
export const SchemaTransformation_2_4: SchemaTransformation = {
  version: '2.4',
  supportsPolyfill: false,

  run(oldSchema: WorkflowDataDto_2_3.WorkflowDataDto, options: SchemaTransformationOptions) {
    const newSchema = cloneDeep(oldSchema) as unknown as WorkflowDataDto_2_4.WorkflowDataDto;

    const globalFamily = oldSchema.fonts[oldSchema.globalSettings.fontIndex]?.family ?? DEFAULT_FONT;

    let newFont = options.fonts.find((f) => f.family === globalFamily);
    if (!newFont) {
      newFont = options.fonts.find((f) => f.family === DEFAULT_FONT);
    }

    newSchema.globalSettings.font = {
      source: newFont.source,
      id: newFont.id,
      family: newFont.family,
    };

    for (const style of newSchema.styles) {
      style.font = {
        source: newFont.source,
        id: newFont.id,
        family: newFont.family,
      };
    }

    delete (newSchema as any).fonts;
    delete (newSchema.globalSettings as any).fontIndex;

    for (const style in newSchema.styles) {
      delete (style as any).fontIndex;
    }

    return newSchema;
  },
};
