import { select, Store } from '@ngrx/store';
import { RootState } from '../root-state';
import { setSessionId, watcherOrCollabLogout } from './session.actions';
import * as sessionSelectors from './session.selectors';
import * as participantSelectors from './participants/participant.selectors';
import * as streamSelectors from './stream/stream.selectors';
import * as authSelectors from '../auth/auth.selector';
import { ISessionService } from 'libs/frontend/common/src/interfaces/session-state.interface';
import { Injectable } from '@angular/core';
import {
  changeVideoSource,
  closeAllVideoStreams,
  closeOpenStreams,
  openAudioInputDevice,
  openScreencastSource,
  openWebcamInputDevice,
  requestDevicesList,
  roomDisconnect,
  sessionTriggered,
} from './stream/stream.actions';
import { toggleMuteStatus, toggleVideoEnabledStatus } from './participants/participants.actions';
import { AudioDevice, GenericAlertService, isFirefox, VideoDevice, VideoSource } from '@openreel/frontend/common';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable()
export class SessionService implements ISessionService {
  public sessionDetails$ = this.store.pipe(select(sessionSelectors.selectSessionDetails));
  public sessionId$ = this.store.pipe(select(sessionSelectors.selectSessionId));
  public myParticipant$ = this.store.pipe(select(participantSelectors.selectMyParticipant));
  public allParticipants$ = this.store.pipe(select(participantSelectors.selectAllParticipants));
  public onlineParticipants$ = this.store.pipe(select(participantSelectors.selectOnlineParticipants));
  public onlineParticipantsIncludingMe$ = this.store.pipe(
    select(participantSelectors.selectOnlineParticipantsIncludingMe)
  );
  public onlineLiveParticipants$ = this.store.pipe(select(participantSelectors.selectOnlineLiveParticipants));
  public onelineNonSubjects$ = this.store.pipe(select(participantSelectors.selectOnlineNonSubjects));
  public onlineSubjects$ = this.store.pipe(select(participantSelectors.selectOnlineSubjects));
  public sessionInitialized$ = this.store.pipe(select(participantSelectors.sessionInitialized));

  public selectAdjustmentsPanelSelectedId$ = this.store.pipe(select(sessionSelectors.selectAdjustmentsPanelSelectedId));
  // public sessionToken$ = this.store.pipe(select(streamSelectors.selectToken));
  public twilioToken$ = this.store.pipe(select(streamSelectors.selectStreamToken));

  public twilioNetworkQuality$ = this.store.pipe(select(streamSelectors.selectStreamNetworkQuality));

  public twilioTokenError$ = this.store.pipe(select(streamSelectors.selectStreamTokenError));
  public isSubject$ = this.store.pipe(select(participantSelectors.selectIsSubject));

  // Stream
  public videoDevice$ = this.store.pipe(select(streamSelectors.selectVideoDevice));
  public audioDevice$ = this.store.pipe(select(streamSelectors.selectAudioDevice));
  public speakerDevice$ = this.store.pipe(select(streamSelectors.selectAudioOutputDevice));


  public currentConstraints$ = this.store.pipe(select(streamSelectors.selectCurrentConstraints));

  public videoStream$ = this.store.pipe(select(streamSelectors.selectVideoStream));

  public audioStream$ = this.store.pipe(select(streamSelectors.selectAudioStream));

  public isLive$ = this.store.pipe(select(streamSelectors.selectIsLiveStatus));

  public liveBroadcastUrl$ = this.store.pipe(select(streamSelectors.selectLiveBroadcastUrl));

  public liveBroadcastRtmps$ = this.store.pipe(select(streamSelectors.selectLiveBroadcastRtmps));

  public liveParticipantIdentitites$ = this.store.pipe(select(streamSelectors.selectLiveParticipantsIdentities));

  // Auth
  public admissionStatus$ = this.store.pipe(select(authSelectors.selectAdmissionStatus));

  constructor(protected store: Store<RootState>, public genericAlertService: GenericAlertService) {}

  // Action Dispatchers
  setSessionId(sessionId: number, isSubject: boolean) {
    this.store.dispatch(setSessionId({ sessionId, isSubject }));
  }

  triggerSession() {
    this.store.dispatch(sessionTriggered());
  }

  selfAudioToggle(propValue: boolean) {
    this.store.dispatch(toggleMuteStatus({ propValue }));
  }

  selfVideoToggle(propValue: boolean) {
    this.store.dispatch(toggleVideoEnabledStatus({ propValue }));
  }

  changeWebcamSource(newDevice: VideoDevice) {
    if (newDevice.source === VideoSource.WEBCAM) {
      this.store.dispatch(openWebcamInputDevice({ device: newDevice }));
    } else {
      this.store.dispatch(openScreencastSource());
    }
  }
  changeAudioDevice(newDevice: AudioDevice) {
    this.store.dispatch(openAudioInputDevice({ device: newDevice }));
  }
  changeVideoSource(source: VideoSource) {
    this.store.dispatch(changeVideoSource({ source }));
  }
  closeAllVideoStreams() {
    this.store.dispatch(closeAllVideoStreams());
  }
  requestDevicesList() {
    this.store.dispatch(requestDevicesList({ keepStream: false }));
  }

  disconnectStreamRoom() {
    this.store.dispatch(roomDisconnect());
  }
  closeStream() {
    this.store.dispatch(closeOpenStreams());
  }
  getVideoAudioDevice() {
    return combineLatest([this.videoDevice$, this.audioDevice$]).pipe(take(1));
  }
  getVideoAudioStream() {
    return combineLatest([this.videoStream$, this.audioStream$]).pipe(take(1));
  }

  watcherOrCollabLogout() {
    this.store.dispatch(watcherOrCollabLogout());
  }

  checkScreenShare(video: VideoDevice) {
    if (video.source === VideoSource.DESKTOP && isFirefox()) {
      //For firefox need some user interaction for open screen share popup
      this.genericAlertService
        .openAlertModal({
          title: 'Screen Share',
          content: 'Do you want to continue screen share?',
          confirmButtonLabel: 'Continue',
          cancelButtonLabel: 'Cancel',
          isLightThemed: false,
        })
        .subscribe((result) => {
          if (result.value) {
            this.changeWebcamSource(video);
          }
        });
    }
  }
}
