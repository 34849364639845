<div>
  <div *ngFor="let invitation of currentList; let i = index">
    <div flexLayout="row" fxLayoutGap="10px" *ngIf="invitation !== currentList[currentList.length - 1]">
      <div fxFlex="1 1" class="input-wrapper" fxLayoutGap="10px">
        <mat-form-field appearance="outline" style="font-size: 12px" fxFlex="33">
          <mat-label>Name</mat-label>
          <input matNativeControl [(ngModel)]="invitation.name" [disabled]="invitation.type == userRoleType.Internal" />
        </mat-form-field>
        <mat-form-field appearance="outline" style="font-size: 12px" fxFlex="33">
          <mat-label>Email</mat-label>
          <input
            matNativeControl
            [(ngModel)]="invitation.email"
            [disabled]="invitation.type == userRoleType.Internal"
          />
        </mat-form-field>

        <mat-form-field appearance="outline" style="font-size: 12px">
          <div *ngIf="invitation.type == userRoleType.Internal">
            <mat-select [(ngModel)]="invitation.type" [disabled]="true">
              <mat-option [value]="userRoleType.Internal">
                {{ UserRoleTypeHumanReadable[userRoleType.Internal] }}
              </mat-option>
            </mat-select>
          </div>
          <div *ngIf="invitation.type != userRoleType.Internal">
            <mat-select
              [(ngModel)]="invitation.type"
              (selectionChange)="onAddedInviteTypeChange(i)"
              (openedChange)="onAddedInviteTypeDropDownOpen($event)"
            >
              <mat-option *ngFor="let invType of visibleRoleTypes" [value]="invType">
                {{ UserRoleTypeHumanReadable[invType] }}
              </mat-option>
            </mat-select>
          </div>
        </mat-form-field>
      </div>
      <div fxFlex="40px">
        <button
          [disabled]="invitation.isRequired"
          (click)="deleteInvitation(invitation)"
          mat-icon-button
          color="warn"
          class="action-button"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <form [formGroup]="form" (submit)="addAnother()" flexLayout="row" fxLayoutGap="10px" fxFill>
    <div class="form-field" fxFlex="1 1">
      <mat-label>Full Name</mat-label>
      <mat-form-field appearance="outline" style="font-size: 12px">
        <input
          matInput
          placeholder="Enter Name"
          formControlName="name"
          (keyup)="handlePropagation($event)"
          (keydown)="handlePropagation($event)"
          required
        />
        <mat-error>
          <span *ngIf="form.get('name').hasError('maxlength')">Name must be less than 100 characters long. </span>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="form-field" fxFlex="1 1">
      <mat-label>Email Address</mat-label>
      <mat-form-field appearance="outline" style="font-size: 12px">
        <input
          matInput
          placeholder="Enter Email"
          formControlName="email"
          (keyup)="handlePropagation($event)"
          (keydown)="handlePropagation($event)"
          type="email"
          required
        />
      </mat-form-field>
    </div>
    <div class="form-field" fxFlex="1 1">
      <mat-label>User type</mat-label>
      <mat-form-field appearance="outline" style="font-size: 12px">
        <mat-select [disabled]="!roleSelectionEnabled" formControlName="type" (selectionChange)="onInviteTypeChange()">
          <mat-option *ngFor="let invType of roleTypes" [value]="invType">
            {{ UserRoleTypeHumanReadable[invType] }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</div>
