import { Asset, AssetId } from '../interfaces';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export interface AddBackgroundAssetEvent {
  assetFileId: AssetId;
  fileName: string;
  assetType: 'image' | 'clip';
}

export class GlobalSettingsBackgroundAddCommand extends WorkflowBaseBuilder<AddBackgroundAssetEvent> {
  run({ assetFileId, fileName, assetType }) {
    const newAsset: Asset = {
      id: this.getUniqueId(),
      file: {
        path: assetFileId,
        provider: 'or-assets',
      },
      fileName,
      type: assetType,
    };
    this.source.globalSettings.backgroundAsset.uploaded.push(newAsset);
    return this.ok();
  }
}
