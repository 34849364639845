import { ColorTag, LayerOptions, LottieLayer, Style } from '../interfaces/workflow.interfaces';
import { getLayerFromId } from '../helpers/timelines.helpers';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export interface UpdateColorTagsEvent {
  layerIds: string[];
  colorTags: ColorTag[];
}

export class UpdateColorTagsCommand extends WorkflowBaseBuilder<UpdateColorTagsEvent> {
  run({ layerIds, colorTags }: UpdateColorTagsEvent) {
    for (const layerId of layerIds) {
      const layerInfo = getLayerFromId(layerId, this.source);
      if (layerInfo === null) {
        console.error(`No layer with id ${layerId}`);
        return { success: false };
      }

      const layerToUpdate = layerInfo.layer as LayerOptions & LottieLayer;
      if (layerToUpdate.type !== 'lottie') {
        console.error(`${layerId} has incorrect layer type: ${layerToUpdate.type}`);
        return { success: false };
      }

      // Update color tags
      layerToUpdate.colorTags = layerToUpdate.colorTags.map((layerColorTag) => {
        const modifiedColorTag = colorTags.find((ct) => ct.tag === layerColorTag.tag);
        return modifiedColorTag ?? layerColorTag;
      });

      // Get fields from preset to update
      const asset = this.getAsset(layerToUpdate.assetId);
      const tagsPresetFieldsToUpdate = colorTags.map((colorTag) =>
        Object.keys(asset.preset).filter((key) => asset.preset[key].colorTag === colorTag.tag)
      );

      // Update layer field styles with new tag colors
      tagsPresetFieldsToUpdate.forEach((tagPresetFieldsToUpdate, i) => {
        this.updateLayerColors(layerToUpdate, tagPresetFieldsToUpdate, colorTags[i].color);
      });
    }

    return this.ok();
  }

  private updateLayerColors(layer: LottieLayer, presetFields: string[], tagColor: string) {
    presetFields.forEach((field) => {
      const styleId = layer.data[field].styleId;
      const style = this.source.styles.find((s) => s.id === styleId);
      if (style) {
        style.color = tagColor;
      } else {
        const newStyle: Style = {
          id: styleId,
          color: tagColor,
        };
        this.source.styles.push(newStyle);
      }
    });
  }
}
