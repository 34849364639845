import { NumberRange } from '@openreel/common';
import { getAssetFinalDuration, getLayerFromId, getSectionDuration } from '../helpers';
import { TextCutRange } from '../interfaces';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export interface UpdateClipTrimData {
  layerId: string;
  textCuts: TextCutRange[];
  trim: NumberRange;
}

export interface UpdateClipTrimsEvent {
  data: UpdateClipTrimData[];
}

export class UpdateClipTrimsCommand extends WorkflowBaseBuilder<UpdateClipTrimsEvent> {
  run(event: UpdateClipTrimsEvent) {
    const { data } = event;

    for (const { layerId, textCuts, trim } of data) {
      const layerFromId = getLayerFromId(layerId, this.source);
      if (!layerFromId) {
        return this.error(`Layer with id ${layerId} was not found!`);
      }

      const { layer, timeline, sectionId } = layerFromId;
      if (layer.type !== 'video') {
        return this.error('Trim can be updated only on video layers');
      }

      const asset = this.getAsset(layer.assetId);
      asset.textCuts = textCuts;
      // NOTE: trim is converted to asset time in component since we need asset time for syncing text trims and timeline trim
      asset.trim = trim;

      const assetDuration = getAssetFinalDuration(asset);

      if (timeline.type === 'main') {
        layer.visibility = {
          startAt: 0,
          endAt: assetDuration,
        };
      } else {
        const section = this.source.sections[sectionId];
        const sectionDuration = getSectionDuration(section, this.source.assets);

        layer.visibility.endAt = Math.min(layer.visibility.startAt + assetDuration, sectionDuration);
      }
    }

    return this.ok();
  }
}
