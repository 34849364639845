import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Recording, VideoStatus, VideoType, BinaryStringFields, AuthService } from '@openreel/frontend/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface RecordingGroup {
  name: string;
  created_at: string;
  batch: string;
  recordings: Recording[];
}

@Component({
  selector: 'openreel-recordings-list',
  templateUrl: './recordings-list.component.html',
  styleUrls: ['./recordings-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecordingsListComponent implements OnInit {
  recordingGroups: Observable<RecordingGroup[]>;
  paginatedGroups: Observable<RecordingGroup[]>;
  @Input()
  enablePagination = false;
  public slicer = 10;
  pageEvent: PageEvent = {
    length: 100,
    pageSize: 10,
    pageIndex: 0,
  };

  @Input() elevationLevel = 8;
  @Input() selectionEnabled = false;
  @Input() listViewEnabled = false;
  @Input() canManipulate = false;
  @Input() recordings: Observable<Recording[]>;

  @Output() onArchiveClick = new EventEmitter<Recording>();
  @Output() onRestoreClick = new EventEmitter<Recording>();
  @Output() onDeleteClick = new EventEmitter<Recording>();
  @Output() onRecordingSelect = new EventEmitter<void>();
  @Output() onGroupSelectChange = new EventEmitter<{
    recordings: Recording[];
    selected: boolean;
  }>();

  @Output() onTranscodeClick = new EventEmitter<{
    recording: Recording;
    type: string;
  }>();

  @Output() onTranscriptClick = new EventEmitter<{
    videoId: number;
  }>();

  @Output() onAudioExtractClick = new EventEmitter<{
    videoId: number;
  }>();

  @Output() onAudioDownloadClick = new EventEmitter<{
    videoId: number;
  }>();

  @Output() onTranscriptShowClick = new EventEmitter<{
    transcript: string;
  }>();

  @Output() onCommentShowClick = new EventEmitter<{
    comment: string;
  }>();

  @Output() onDownloadClick = new EventEmitter<{
    recording: Recording;
    type: string;
  }>();

  @Output() onCopyClick = new EventEmitter<{
    recording: Recording;
    type: string;
  }>();
  @Output() onPlayClick = new EventEmitter<{
    recording: Recording;
    type: string;
  }>();
  @Output() onFavouriteClick = new EventEmitter<Recording>();
  @Output() onUploadClick = new EventEmitter<Recording>();
  @Output() onUploadCancelClick = new EventEmitter<Recording>();
  @Output() onTogglePauseUploadClick = new EventEmitter<{
    recording: Recording;
    active: number;
  }>();

  VideoStatus = VideoStatus;
  binaryStringFields = BinaryStringFields;

  constructor(private authService: AuthService) { }
  isGroupSelected(group: RecordingGroup) {
    return group.recordings.every((recording) => recording.selected);
  }

  toggleGroupSelection(group, event: Event) {

    const allSelected = this.isGroupSelected(group);
    this.onGroupSelectChange.emit({
      recordings: group.recordings,
      selected: !allSelected,
    });
    event.stopPropagation();
  }

  trackGroupsByFn(group: RecordingGroup) {
    return group.created_at;
  }
  trackRecordingsByFn(recording: Recording) {
    return recording.ovra_session_videos_id;
  }

  async onRecordingDeleteClicked(recording: Recording) {
    if (this.authService.cookieDeleted()) {
      return;
    }
    this.onDeleteClick.next(recording);
  }

  async onRecordingArchiveClicked(recording: Recording) {
    if (this.authService.cookieDeleted()) {
      return;
    }
    this.onArchiveClick.next(recording);
  }

  async onRecordingRestoreClicked(recording: Recording) {
    if (this.authService.cookieDeleted()) {
      return;
    }
    this.onRestoreClick.next(recording);
  }

  async onRecordingTranscodeClicked(recording: Recording, type: string) {
    this.onTranscodeClick.next({ recording, type });
  }

  async onRecordingTranscriptClicked(videoId: number) {
    this.onTranscriptClick.next({ videoId });
  }

  async onRecordingAudioExtractClicked(videoId: number) {
    this.onAudioExtractClick.next({ videoId });
  }

  async onRecordingAudioDownloadClicked(videoId: number) {
    this.onAudioDownloadClick.next({ videoId })
  }

  async onTranscriptShowClicked(transcript: string) {
  this.onTranscriptShowClick.next({ transcript });
}

  async onCommentShowClicked(comment: string) {
  this.onCommentShowClick.next({ comment });
}

  async onRecordingDownloadClicked(recording: Recording, type = VideoType.ORIGINAL) {
  this.onDownloadClick.next({ recording, type });
}
  protected async onRecordingCopyClicked(recording: Recording, type = VideoType.ORIGINAL) {
  this.onCopyClick.next({ recording, type });
}

  async onRecordingPlayClicked(recording: Recording, type = VideoType.ORIGINAL) {
  this.onPlayClick.next({ recording, type });
}

  async onRecordingFavouriteClicked(recording: Recording) {
  this.onFavouriteClick.next(recording);
}
  async onRecordingUploadClicked(recording: Recording) {
  this.onUploadClick.next(recording);
}
  async onRecordingUploadCancelClicked(recording: Recording) {
  this.onUploadCancelClick.next(recording);
}

onTogglePauseUpload(recording: Recording, active: number) {
  this.onTogglePauseUploadClick.next({ recording, active });
}

onPaginateChange(data) {
  this.slicer = data.pageSize;
  this.pageEvent = data;
  this.paginatedGroups = this.recordingGroups.pipe(
    map((recordings) =>
      recordings.slice(
        this.pageEvent.pageIndex * this.pageEvent.pageSize,
        this.pageEvent.pageSize + this.pageEvent.pageIndex * this.pageEvent.pageSize
      )
    )
  );
}
ngOnInit() {
  if (!this.enablePagination) {
    this.pageEvent = {
      length: 10000,
      pageSize: 10000,
      pageIndex: 0,
    };
  }

  this.recordingGroups = this.recordings?.pipe(
    map((recordings) =>
      recordings.reduce((acc: RecordingGroup[], curr) => {
        let group = acc.find((g) => g.batch === curr.batch);
        if (!group) {
          const name = curr.session?.session_name;
          group = {
            name,
            created_at: curr.created_at,
            batch: curr.batch,
            recordings: [],
          };
          acc = [...acc, group];
        }
        group.recordings = [...group.recordings, curr];
        return acc;
      }, [])
    )
  );

  if (this.enablePagination) {
    this.paginatedGroups = this.recordingGroups.pipe(map((recordings) => recordings.slice(0, this.slicer)));
  } else {
    this.paginatedGroups = this.recordingGroups;
  }
}
}
