import { cloneDeep } from 'lodash';
import { v4 } from 'uuid';
import { WorkflowDataDto, Layer, SectionTimeline, OVERLAY_TIMELINE_TYPES } from '../interfaces';
import { getLayers } from './timelines.helpers';
import { getSectionTypeTimelinesByType } from './workflow.helpers';
import { getAssetFinalDuration } from './workflow-duration.helpers';

export function removeTransitionFromLastScene(workflow: WorkflowDataDto) {
  const mainTimeline = workflow.timelines.find((t) => t.type === 'main');

  let lastEnabledLayer: Layer;
  for (const layer of mainTimeline.layers) {
    if (layer.enabled) {
      lastEnabledLayer = layer;
    }
  }

  if (lastEnabledLayer?.transitions) {
    lastEnabledLayer.transitions = null;
  }
}

export function removeCoveredBackgrounds(workflow: WorkflowDataDto) {
  for (const [, section] of Object.entries(workflow.sections)) {
    if (section.sectionType !== 'main') {
      continue;
    }

    const mainTimelines = section.timelines.filter((t) => t.type === 'main');
    if (mainTimelines.length !== 1) {
      continue;
    }

    if (mainTimelines[0].layers.length !== 1) {
      continue;
    }

    const layer = mainTimelines[0].layers[0];
    const hasBorder = (layer.styles?.border?.x ?? 0) !== 0 || (layer.styles?.border?.y ?? 0) !== 0;
    const isEntireViewport = (layer.bounds?.width ?? 100) === 100 && layer.bounds?.height === 100;

    const isBackgroundHidden = isEntireViewport && !hasBorder;
    if (!isBackgroundHidden) {
      continue;
    }

    section.timelines = section.timelines.filter((t) => t.type !== 'background');
  }
}

export function removeLayersWithZeroVisibility(workflow: WorkflowDataDto) {
  for (const [, section] of Object.entries(workflow.sections)) {
    for (const timeline of section.timelines) {
      if (timeline.layers.length === 0) {
        continue;
      }

      if (timeline.layers[0].visibility) {
        const { startAt, endAt } = timeline.layers[0].visibility;
        if (startAt === endAt) {
          timeline.layers = [];
        }
      }
    }
  }
}

export function removeDisabledLayers(workflow: WorkflowDataDto) {
  const mainTimeline = workflow.timelines.find((t) => t.type === 'main');
  mainTimeline.layers = mainTimeline.layers.filter((l) => (typeof l.enabled === 'undefined' ? true : l.enabled));

  mainTimeline.layers.forEach((l) => {
    if (!l.transitions?.crossLayer) {
      return;
    }

    if (!l.transitions.crossLayer.layer.enabled) {
      delete l.transitions.crossLayer;
    }
  });

  for (const [, section] of Object.entries(workflow.sections)) {
    section.timelines = section.timelines.map((t) => ({
      ...t,
      layers: t.layers.filter((l) => (typeof l.enabled === 'undefined' ? true : l.enabled)),
    }));
  }
}

export function removeDuplicateImageVideoAssets(workflow: WorkflowDataDto) {
  const usedAssetIds = new Set<string>();
  for (const { layer } of getLayers(workflow)) {
    if (!['video', 'image', 'lottie'].includes(layer.type)) {
      continue;
    }

    if (layer.type === 'lottie') {
      for (const [, field] of Object.entries(layer.data)) {
        if (field.type !== 'logo' || !field.assetId) {
          continue;
        }

        if (!usedAssetIds.has(field.assetId)) {
          usedAssetIds.add(field.assetId);
          continue;
        }

        const oldAsset = workflow.assets.find((a) => a.id === field.assetId);
        const newAsset = cloneDeep(oldAsset);
        newAsset.id = v4();

        workflow.assets.push({ ...newAsset });
        field.assetId = newAsset.id;
      }
    } else if (layer.type === 'image' || layer.type === 'video') {
      if (!layer.assetId) {
        continue;
      }

      if (!usedAssetIds.has(layer.assetId)) {
        usedAssetIds.add(layer.assetId);
        continue;
      }

      const oldAsset = workflow.assets.find((a) => a.id === layer.assetId);
      const newAsset = cloneDeep(oldAsset);
      newAsset.id = v4();

      workflow.assets.push({ ...newAsset });
      layer.assetId = newAsset.id;
    }
  }
}

export function removeTrailingVideoAssetTrims(workflow: WorkflowDataDto) {
  for (const [sectionId, section] of Object.entries(workflow.sections).filter(
    ([, section]) => section.sectionType === 'main'
  )) {
    const sectionDuration = section.sectionDuration;
    const overlayTimelines: SectionTimeline[] = [];
    for (const timelineType of OVERLAY_TIMELINE_TYPES) {
      overlayTimelines.push(
        ...getSectionTypeTimelinesByType(workflow.sections, section.sectionType, timelineType, sectionId)
      );
    }

    for (const overlayTimeline of overlayTimelines.filter((t) => t.layers.length > 0)) {
      for (const layer of overlayTimeline.layers) {
        if (layer.type !== 'video') {
          continue;
        }

        const asset = workflow.assets.find((a) => a.id === layer.assetId);
        const assetDuration = getAssetFinalDuration(asset);

        let layerDuration = null;
        if (layer.visibility.startAt < sectionDuration) {
          // Layer is partially still visible
          layerDuration = sectionDuration - layer.visibility.startAt;
        } else {
          layerDuration = assetDuration ? Math.min(assetDuration, sectionDuration) : sectionDuration;
        }

        if (assetDuration > layerDuration) {
          asset.trim = {
            from: asset.trim?.from ?? 0,
            to: (asset.trim?.from ?? 0) + layerDuration,
          };
        }
      }
    }
  }
}
