import { DrawableShapeType } from "../constants/drawable.constants";

export interface RectangleAttributes {
    color: string;
    x: number;
    y: number;
    width: number;
    height: number;
    strokeWidth: number;
    cornerRadius: number;
}

export interface ArrowAttributes {
    start: [number, number];
    end: [number, number];
    color: string;
    strokeWidth: number;
}

export type DrawableShapeAttributes = RectangleAttributes | ArrowAttributes;


export interface DrawableShapeData { 
    type: DrawableShapeType; 
    attributes: DrawableShapeAttributes 
};

export interface DrawableAreaData {
    shapes: Array<DrawableShapeData>
}