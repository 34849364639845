import { AssetType } from './../interfaces/workflow.interfaces';

export const assetTypeToUploadType = (assetType: AssetType): 'video' | 'image' | 'document' => {
  switch (assetType) {
    case 'clip':
      return 'video';
    case 'image':
      return 'image';
    case 'json':
      return 'document';
    default:
      throw new Error(`Can't find upload type for asset type: '${assetType}'`);
  }
};
