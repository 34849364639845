import { cloneDeep } from 'lodash';
import { SectionId } from '../interfaces';
import { WorkflowTemplatesBaseBuilder } from './workflow-templates-base.builder';

export interface BuilderSectionChangeCommandEvent {
  sectionId: SectionId.Intro | SectionId.Outro;
  isEnabled: boolean;
  useSameSectionForAllAspectRatios: boolean;
}

export class BuilderSectionChangeCommand extends WorkflowTemplatesBaseBuilder<BuilderSectionChangeCommandEvent> {
  run(event: BuilderSectionChangeCommandEvent) {
    const sourceTemplateSection = this.source.templateSections[event.sectionId];
    sourceTemplateSection.isEnabled = event.isEnabled;
    sourceTemplateSection.useSameSectionForAllAspectRatios = event.useSameSectionForAllAspectRatios;

    if (event.useSameSectionForAllAspectRatios) {
      const sourceSectionToCopyFrom = sourceTemplateSection.sections[0];
      for (const section of sourceTemplateSection.sections) {
        section.assets = cloneDeep(sourceSectionToCopyFrom.assets);
        section.styles = cloneDeep(sourceSectionToCopyFrom.styles);
        section.section = cloneDeep(sourceSectionToCopyFrom.section);
        section.transitions = cloneDeep(sourceSectionToCopyFrom.transitions);
      }
    }

    if (sourceTemplateSection.isEnabled) {
      const sectionToCopy = sourceTemplateSection.sections[0];
      this.copyTemplateSection(event.sectionId, sectionToCopy, true);
    } else {
      this.removeTemplateSection(event.sectionId);
    }

    return this.ok();
  }
}
