import { Asset, GlobalSettings, Section, Style } from './../interfaces/workflow.interfaces';
import { TemplateBuilderParsedTimeline } from './../interfaces/template-builder.interfaces';
import { createTimeline } from './createTimeline';
import { createLayer } from './createLayer';
import { getSectionDuration } from '../helpers';

export function copyBuilderTimelinesToSection(
  targetSection: Section,
  timelines: TemplateBuilderParsedTimeline[],
  templateGlobalSettings: GlobalSettings
) {
  const assets: Asset[] = [];
  const styles: Style[] = [];

  for (const timelineDef of timelines) {
    const timeline = createTimeline(timelineDef);
    targetSection.timelines.push(timeline);

    for (const layerDef of timelineDef.layers ?? []) {
      const { layer, layerAsset, layerStyles } = createLayer(layerDef, timelineDef, templateGlobalSettings);
      timeline.layers.push(layer);

      if (layerAsset) {
        assets.push(layerAsset);
      }
      styles.push(...layerStyles);
    }
  }
  targetSection.sectionDuration = getSectionDuration(targetSection, assets);

  return { assets, styles };
}
