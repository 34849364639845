/* eslint-disable @typescript-eslint/no-explicit-any */
import { cloneDeep } from 'lodash';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_2_5 } from '../transformation_2_5/schema_after';
import { WorkflowDataDto_2_6 } from './schema_after';

/*
  NOTE: Purpose of this transformation:
    - 1) flatten main timelines contained inside timelines layer
*/
export const SchemaTransformation_2_6: SchemaTransformation = {
  version: '2.6',
  supportsPolyfill: false,

  run(oldSchema: WorkflowDataDto_2_5.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as unknown as WorkflowDataDto_2_6.WorkflowDataDto;

    for (const [, section] of Object.entries(newSchema.sections)) {
      const mainTimeline = section.timelines.find((t) => t.type === 'main');
      const hasNested = mainTimeline.layers.length === 1 && (mainTimeline.layers[0].type as any) === 'timelines';
      if (!hasNested) {
        continue;
      }

      section.timelines = section.timelines.filter((t) => t.type !== 'main');
      section.timelines.push(...(mainTimeline.layers[0] as any).children);
    }

    return newSchema;
  },
};
