import { Component } from '@angular/core';
import { ROUTE_PROJECTS, ROUTE_SETTINGS, ROUTE_DASHBOARD } from '@openreel/frontend/common';

@Component({
  selector: 'openreel-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent {
  public routes = {
    dashboard: '/' + ROUTE_DASHBOARD,
    projects: '/' + ROUTE_PROJECTS,
    settings: '/' + ROUTE_SETTINGS,
  };
}
