import { cloneDeep } from 'lodash';
import { TemplateSchemaTransformation } from '../../../interfaces';
import { TemplateWorkflowDataDto_1_2 } from '../template_transformation_1_2/schema_after';
import { TemplateWorkflowDataDto_1_3 } from './schema_after';

export const TemplateSchemaTransformation_1_3: TemplateSchemaTransformation = {
  version: '1.3',

  run(
    oldSchema: TemplateWorkflowDataDto_1_2.TemplateWorkflowDataDto
  ): TemplateWorkflowDataDto_1_3.TemplateWorkflowDataDto {
    const newSchema = cloneDeep(oldSchema) as unknown as TemplateWorkflowDataDto_1_3.TemplateWorkflowDataDto;

    newSchema.globalSettings.backgroundAsset = oldSchema.globalSettings.backgroundVideo;
    delete (newSchema.globalSettings as any).backgroundVideo;

    return newSchema;
  },
};
