import { AssetTranscriptSpeaker, AssetsFileProviderType } from '@openreel/creator/common';
import { findLastIndex } from 'lodash';

export const SPEAKER_COLORS = [
  '#c41d7f',
  '#d46b08',
  '#389e0d',
  '#1d39c4',
  '#cf1322',
  '#d48806',
  '#08979c',
  '#531dab',
  '#d4380d',
  '#7cb305',
  '#096dd9',
];

export function getNextSpeakerColor(speakers: { color: string }[], colors = SPEAKER_COLORS): string {
  const colorMap = new Map(colors.map((color) => [color, findLastIndex(speakers, (s) => s.color === color)]));

  return [...colorMap.entries()].sort((a, b) => a[1] - b[1])[0][0];
}

export function getNextSpeakerName(speakersCount: number): string {
  return `Speaker ${speakersCount + 1}`;
}

export function getOrCreateSpeaker(
  assetId: number,
  assetProvider: AssetsFileProviderType,
  responseSpeakerId: string,
  speakers: Map<string, AssetTranscriptSpeaker>,
  colors = SPEAKER_COLORS
) {
  const uid = `${assetProvider}-${assetId}-${responseSpeakerId}`;

  if (speakers.has(uid)) {
    return speakers.get(uid);
  }

  const newSpeaker = {
    uid,
    name: getNextSpeakerName(speakers.size),
    color: getNextSpeakerColor([...speakers.values()], colors),
  };

  speakers.set(uid, newSpeaker);

  return newSpeaker;
}
