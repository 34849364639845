import { getLayerFromId } from '../helpers';
import { SectionTimeline } from '../interfaces';
import { WorkflowBaseBuilder } from './workflow-base.builder';

type UpdateZIndexDirection = 'sendToBack' | 'backward' | 'forward' | 'bringToFront';

export interface UpdateTimelineZIndexEvent {
  layerId: string;
  direction: 'sendToBack' | 'backward' | 'forward' | 'bringToFront';
}

export class UpdateTimelineZIndexCommand extends WorkflowBaseBuilder<UpdateTimelineZIndexEvent> {
  run({ layerId, direction }: UpdateTimelineZIndexEvent) {
    const layer = getLayerFromId(layerId, this.source);

    if (!this.source.sections[layer.sectionId]) {
      return this.error('Can not find section with sectionId : ' + layer.sectionId);
    }

    const timeline = layer.timeline;
    const oldZIndex = timeline.zIndex;

    const section = this.source.sections[layer.sectionId];
    section.timelines.sort(
      (timeline1: SectionTimeline, timeline2: SectionTimeline) => timeline1.zIndex - timeline2.zIndex
    );

    const newZIndex = this.calculateNewZIndex(direction, oldZIndex, section.timelines);

    const directionCommand = newZIndex > oldZIndex ? 'back' : 'front';

    for (const timeline of section.timelines) {
      if (timeline.zIndex === oldZIndex) {
        timeline.zIndex = newZIndex;
      } else if (directionCommand === 'front' && timeline.zIndex < oldZIndex && timeline.zIndex >= newZIndex) {
        timeline.zIndex = timeline.zIndex + 1;
      } else if (directionCommand === 'back' && timeline.zIndex > oldZIndex && timeline.zIndex <= newZIndex) {
        timeline.zIndex = timeline.zIndex - 1;
      }
    }

    return this.ok();
  }

  private calculateNewZIndex(
    direction: UpdateZIndexDirection,
    currentZIndex: number,
    sectionTimelines: SectionTimeline[]
  ) {
    const nonBackgroundTimelines = sectionTimelines.filter(
      (timeline) => timeline.type !== 'background' && timeline.layers.length === 1 && timeline.layers[0].enabled
    );
    if (direction === 'sendToBack') {
      return Math.min(...nonBackgroundTimelines.map((timeline) => timeline.zIndex));
    } else if (direction === 'backward') {
      const filteredTimelines = nonBackgroundTimelines.filter((timeline) => timeline.zIndex < currentZIndex);
      return Math.max(...filteredTimelines.map((timeline) => timeline.zIndex));
    } else if (direction === 'forward') {
      const filteredTimelines = nonBackgroundTimelines.filter((timeline) => timeline.zIndex > currentZIndex);
      return Math.min(...filteredTimelines.map((timeline) => timeline.zIndex));
    } else if (direction === 'bringToFront') {
      return Math.max(...nonBackgroundTimelines.map((timeline) => timeline.zIndex));
    }
  }
}
