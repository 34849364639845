import { Asset, LayerOptions, VideoLayer } from './../interfaces/workflow.interfaces';
import { v4 } from 'uuid';
import { createVideoAsset } from './createVideoAsset';
import { TemplateBuilderParsedLayer } from './../interfaces/template-builder.interfaces';

export function createVideoLayer(layerDef: TemplateBuilderParsedLayer) {
  let asset: Asset;
  if (!layerDef.existingAssetId) {
    asset = createVideoAsset(layerDef.asset.assetFileId, layerDef.asset.duration);
  }

  const layer: LayerOptions & VideoLayer = {
    layerId: v4(),
    enabled: true,
    type: 'video',
    assetId: asset?.id ?? layerDef.existingAssetId,
    loop: layerDef.loop ?? false,
  };

  return { layer, asset };
}
