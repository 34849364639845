/* eslint-disable @typescript-eslint/no-explicit-any */
import { cloneDeep } from 'lodash';
import { v4 } from 'uuid';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_1_9 } from '../transformation_1_9/schema_after';
import { WorkflowDataDto_2_0 } from './schema_after';

/*
  NOTE: Purpose of this transformation:
    - remove file from asset if file.path is null
    - remove layer is layer.assetId is missing
    - fill in mandatory fields in all layers (layerId, enabled, loop)
*/
export const SchemaTransformation_2_0: SchemaTransformation = {
  version: '2.0',
  supportsPolyfill: false,

  run(oldSchema: WorkflowDataDto_1_9.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as unknown as WorkflowDataDto_2_0.WorkflowDataDto;

    // remove file from asset if file.path is null
    for (const asset of newSchema.assets) {
      if (asset.file?.path === null) {
        delete asset.file;
      }
    }

    // remove layer if layer.assetId is missing
    for (const [, section] of Object.entries(newSchema.sections)) {
      for (const timeline of section.timelines) {
        timeline.layers = timeline.layers.filter((layer) => {
          if (layer.type === 'video' || layer.type === 'image' || layer.type === 'lottie') {
            return layer.assetId !== null && layer.assetId !== undefined;
          }

          return true;
        });
      }
    }

    // fill in mandatory fields in all layers
    for (const [, section] of Object.entries(newSchema.sections)) {
      for (const timeline of section.timelines) {
        for (const layer of timeline.layers) {
          cleanupLayer(layer);

          if (layer.type === 'timelines') {
            for (const childTimeline of layer.children) {
              for (const childLayer of childTimeline.layers) {
                cleanupLayer(childLayer);
              }
            }
          }
        }
      }
    }

    for (const timeline of newSchema.timelines) {
      for (const layer of timeline.layers) {
        cleanupLayer(layer);

        if (layer.transitions?.crossLayer) {
          cleanupLayer(layer.transitions.crossLayer.layer);
        }
      }
    }

    return newSchema;
  },
};

function cleanupLayer(layer: WorkflowDataDto_2_0.Layer) {
  if (typeof layer.enabled === 'undefined') {
    layer.enabled = true;
  }

  if (typeof layer.loop === 'undefined') {
    layer.loop = false;
  }

  if (!layer.layerId) {
    layer.layerId = v4();
  }
}
