/* eslint-disable @typescript-eslint/no-explicit-any */
import { cloneDeep } from 'lodash';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_2_7 } from '../transformation_2_7/schema_after';
import { WorkflowDataDto_2_8 } from './schema_after';

/*
  NOTE: Purpose of this transformation:
    - 1) add section audio properties
*/
export const SchemaTransformation_2_8: SchemaTransformation = {
  version: '2.8',
  supportsPolyfill: false,

  run(oldSchema: WorkflowDataDto_2_7.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as unknown as WorkflowDataDto_2_8.WorkflowDataDto;

    for (const [, section] of Object.entries(newSchema.sections)) {
      section.audio = {
        muted: false,
        volume: 1,
      };
    }

    return newSchema;
  },
};
