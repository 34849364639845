<div class="sidebar">
  <img src="assets/common/logos/blue-gradient.svg" width="50" style="padding:10px">
  <mat-list role="list" style="margin-top: 40px;">
	  <mat-list-item role="listitem" class="menu-item">
	  	<span class="material-icons menu-icon"  title="Dashboard" [routerLink]="routes.dashboard">dashboard</span>
	  </mat-list-item>
	  <mat-list-item role="listitem" class="menu-item">
	  	<span class="material-icons menu-icon" title="Projects" [routerLink]="routes.projects">notes</span>
	  </mat-list-item>
	  <mat-list-item role="listitem" class="menu-item">
	  	<span class="material-icons menu-icon" title="Settings" [routerLink]="routes.settings">settings</span>
	  </mat-list-item>
	</mat-list>
</div>