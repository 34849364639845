import { AssetId } from '../interfaces';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export class GlobalSettingsBackgroundDeleteCommand extends WorkflowBaseBuilder<{ assetFileId: AssetId }> {
  run({ assetFileId }) {
    const index = this.source.globalSettings.backgroundAsset.uploaded.findIndex(
      (asset) => asset.file.path === assetFileId
    );

    if (index === -1) {
      return this.error('Background not found');
    }

    this.source.globalSettings.backgroundAsset.uploaded.splice(index, 1);

    return this.ok();
  }
}
