<ng-container *ngIf="showAsIcon && selectedSpeakerDevice">
  <div
    class="speaker-icon"
    (click)="toggleSpeakerMuted(speakerMuted ? false : true)"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <mat-icon [class.off]="speakerMuted">{{ speakerMuted ? 'volume_off' : 'volume_up' }}</mat-icon>
    <div fxLayout="row" fxLayoutAlign="center center">{{ speakerMuted ? 'Speaker On' : 'Speaker Off' }}</div>
  </div>
  <ng-template [ngTemplateOutlet]="optionsTemplate" *ngIf="!audioMuted || showMuteAll"></ng-template>
</ng-container>

<ng-container *ngIf="!showAsIcon && selectedSpeakerDevice">
  <div fxLayout="row" fxFlex="100" fxLayoutGap="10px" fxLayoutAlign="center center">
    <mat-icon
      fxFlex="10"
      [ngClass]="speakerMuted ? 'off' : 'on'"
      (click)="toggleSpeakerMuted(speakerMuted ? false : true)"
      class="speaker-icon"
      >{{ speakerMuted ? 'volume_off' : 'volume_up' }}</mat-icon
    >
    <div fxFlex="80" *ngIf="speakerSelectStatus && !selectedSpeakerDevice">{{ speakerSelectStatus }}</div>
    <div fxFlex="80" *ngIf="selectedSpeakerDevice">
      <span ngClass.lt-md="hide">Speaker is {{ speakerMuted ? 'off ' : 'on ' }}</span>
      <span class="selected-option">{{ selectedSpeakerDevice.name }}</span>
    </div>
    <div fxFlex="10" fxLayoutAlign="center center">
      <ng-template [ngTemplateOutlet]="optionsTemplate"></ng-template>
    </div>
  </div>
</ng-container>

<ng-template #optionsTemplate>
  <div class="speaker-options" *ngIf="selectedSpeakerDevice">
    <span [matMenuTriggerFor]="speakerOptions">
      <mat-icon>expand_more</mat-icon>
    </span>
    <mat-menu #speakerOptions="matMenu" xPosition="before">
      <button
        mat-menu-item
        *ngFor="let availableSpeakerDevice of availableSpeakerDevices"
        (click)="changeSpeakerDevice(availableSpeakerDevice)"
        [ngClass]="{ 'selected-option': availableSpeakerDevice.id === selectedSpeakerDevice?.id }"
      >
        {{ availableSpeakerDevice.name }}
      </button>
    </mat-menu>
  </div>
</ng-template>
