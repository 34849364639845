import { cloneDeep } from 'lodash';
import { createLottieLayerDataFromAsset, getSectionLayer } from '../helpers';
import { Asset, LayerOptions, SectionId, SectionLayer, Transitions } from '../interfaces';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export interface UpdateSceneTransitionsEvent {
  sectionId?: string;
  transitions: Transitions;
  assetsToAdd: Asset[];
}

export class UpdateSceneTransitionsCommand extends WorkflowBaseBuilder<UpdateSceneTransitionsEvent> {
  run(event: UpdateSceneTransitionsEvent) {
    if (event.sectionId) {
      const sectionLayer = getSectionLayer(event.sectionId, this.source);
      if (!sectionLayer) {
        return this.error(`Can't find section with id: ${event.sectionId}`);
      }

      try {
        this.applyTransitions(sectionLayer, event);
      } catch (e) {
        return this.error(e.message);
      }
    } else {
      const mainTimeline = this.source.timelines.find((t) => t.type === 'main');
      for (const layer of mainTimeline.layers) {
        if (layer.type !== 'section') {
          continue;
        }
        if (layer.sectionId === SectionId.Outro) {
          continue;
        }

        try {
          this.applyTransitions(layer, event);
        } catch (e) {
          return this.error(e.message);
        }
      }
    }

    return this.ok();
  }

  private applyTransitions(sectionLayer: SectionLayer & LayerOptions, event: UpdateSceneTransitionsEvent) {
    const hasTransition = event.transitions && Object.keys(event.transitions).length > 0;

    if (!hasTransition) {
      delete sectionLayer.transitions;
      return;
    }

    sectionLayer.transitions = { ...cloneDeep(event.transitions) };

    if (sectionLayer.transitions?.crossLayer) {
      const lottieLayer = sectionLayer.transitions.crossLayer.layer;
      if (lottieLayer.type !== 'lottie') {
        throw new Error('Cross layer must be a lottie layer.');
      }

      lottieLayer.layerId = this.getUniqueId();

      const assetId = lottieLayer.assetId;
      let animationAsset = this.source.assets.find((a) => a.id === assetId);
      if (!animationAsset) {
        animationAsset = event.assetsToAdd.find((a) => a.id === assetId);
        if (!animationAsset) {
          throw new Error(`Cant find asset with id: ${assetId}`);
        }

        this.source.assets.push(animationAsset);
      }

      const { data, styles } = createLottieLayerDataFromAsset(animationAsset, this.source.globalSettings);
      lottieLayer.data = data;

      this.addAndReplaceStyles(styles);
    }
  }
}
