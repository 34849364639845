import * as Interfaces from './custom-fonts.interfaces';
import { Account } from '../../interfaces';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from 'libs/frontend/common/src/environments/environment';
import { CaseConverterInterceptorSkipHeader } from '../../interceptors';

@Injectable({
  providedIn: 'root',
})
export class CustomFontsService {
  private urls = {
    base: `${commonenv.nextGenApiUrl}custom-fonts`,
    byId: (id: number) => `${this.urls.base}/${id}`,
    byAccountId: (accountId?: number) =>
      `${this.urls.base}?limit=100${accountId != null ? '&accountId=' + accountId : ''}`,
    getAllVariants: () => `${this.urls.base}/variants/get-all`,
    getFontAccounts: () => `${this.urls.base}/accounts/get-with-custom-fonts`,
  };

  constructor(private readonly httpClient: HttpClient) {}

  getAll(accountId?: number) {
    return this.httpClient.get<Interfaces.CustomFonts[]>(this.urls.byAccountId(accountId));
  }

  getById(id: number) {
    return this.httpClient.get<Interfaces.CustomFonts>(this.urls.byId(id));
  }

  getAccountsWithCustomFonts() {
    return this.httpClient.get<Account[]>(this.urls.getFontAccounts());
  }

  getAllVariants() {
    return this.httpClient.get<string[]>(this.urls.getAllVariants());
  }

  create(data: Interfaces.UpsertCustomFontsRequest) {
    return this.httpClient.post<Interfaces.CustomFonts>(this.urls.base, data, {
      headers: new HttpHeaders({
        [CaseConverterInterceptorSkipHeader]: '',
      }),
    });
  }

  update(id: number, data: Interfaces.UpsertCustomFontsRequest) {
    return this.httpClient.put<Interfaces.CustomFonts>(this.urls.byId(id), data, {
      headers: new HttpHeaders({
        [CaseConverterInterceptorSkipHeader]: '',
      }),
    });
  }

  delete(id: number) {
    return this.httpClient.delete<void>(this.urls.byId(id));
  }
}
