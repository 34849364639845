import { NumberRange } from '@openreel/common';
import {
  Asset,
  AssetId,
  AssetsFileProviderType,
  Bounds,
  File,
  Source,
  TextCutRange,
  TransitionLayer,
  VideoZoomPanRange,
} from './workflow.interfaces';

export enum TextBoxPreset {
  Heading = 'heading',
  Subheading = 'subheading',
  Body = 'body',
}

export interface VideoLayerInfo {
  layerId: string;
  asset: Asset;
  assetFile: File;
  startAt: number;
  duration: number;
  tooltip: string;
  bounds: Bounds;
  isPlaceholder: boolean;
  hasAudio: boolean;
}

// Events
export interface LayerIdEvent {
  layerId: string;
}

export interface SectionsIdEvent {
  sectionId: string;
}

export interface AddClipsEvent {
  timelineId: string;
  clip: NewClip;
}

export interface NewClip {
  assetId?: string;
  assetFileId: AssetId;
  assetProviderType: AssetsFileProviderType;
  duration: number;
  personName?: string;
  name?: string;
  source?: Source;
  isPlaceholder?: boolean;
  textCuts?: TextCutRange[];
  trim?: NumberRange;
}

export interface SectionsMovedEvent {
  sectionId: string;
  sectionsAfterReorder: SectionsIdEvent[];
}

export interface SectionToggledEvent {
  isIntro: boolean;
  enabled: boolean;
}

export interface SectionMutedEvent {
  sectionId: string;
  muted: boolean;
}

export interface SectionVolumeEvent {
  sectionId: string;
  volume: number;
}

export interface SectionEvent {
  sectionId: string;
}

export interface ItemMovedEvent extends LayerIdEvent {
  newStartAt: number;
}

export interface ItemsMovedEvent {
  items: ItemMovedEvent[];
}

export interface SwapTimelinesEvent {
  previousTimelineId: string;
  currentTimelineId: string;
}

export interface CreateScenesFromSplitsEvent {
  sectionId: string;
  splits: NumberRange[];
}

export interface UpdateAssetsPrimaryColor {
  color: string;
}

export interface UpdateAssetZoom {
  assetId: string;
  newZoomPans: VideoZoomPanRange[];
}

export interface TemplateLottieTransition {
  name: string;
  asset: Asset;
  transitionLayer: TransitionLayer;
}
