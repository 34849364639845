import { clamp } from 'lodash';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export interface UpdateSceneDurationEvent {
  sectionId: string;
  durationMs: number;
}

export class UpdateSceneDurationCommand extends WorkflowBaseBuilder<UpdateSceneDurationEvent> {
  run(event: UpdateSceneDurationEvent) {
    const section = this.source.sections[event.sectionId];
    if (!section) {
      return this.error(`Can't find section with id: ${event.sectionId}`);
    }

    const mainTimelines = section.timelines.filter((t) => t.type === 'main');
    if (mainTimelines.length > 0) {
      return this.error(`Scene duration can be manually set only on scenes without main timelines.`);
    }

    if (event.durationMs <= 0) {
      return this.error(`Scene duration must be a positive number.`);
    }

    section.sectionDuration = clamp(event.durationMs, 1_000, 999_000);

    return this.ok();
  }
}
