import { Asset, SolidColor, TemplateSection, WorkflowDataDto } from './../interfaces/workflow.interfaces';
import { Z_INDEX_BACKGROUND } from '../constants';
import { createSection } from './baseWorkflow';
import { copyBuilderTimelinesToSection } from './copyBuilderTimelinesToSection';
import { TemplateBuilderParsedTimeline } from './../interfaces/template-builder.interfaces';

function createBuilderTimelines(workflow: WorkflowDataDto) {
  const assets: Asset[] = [];

  const builderTimelines: TemplateBuilderParsedTimeline[] = [
    { type: 'background', layers: [], zIndex: Z_INDEX_BACKGROUND },
  ];

  // Background timeline
  const backgroundTimeline = builderTimelines.find((t) => t.type === 'background');
  if (workflow.globalSettings.backgroundAsset.settings.enabled) {
    const globalBackgroundAssetId = workflow.globalSettings.backgroundAsset.settings.assetId;
    const globalBackgroundAsset = workflow.assets.find((a) => a.id === globalBackgroundAssetId);
    backgroundTimeline.layers.push({
      type: globalBackgroundAsset.type === 'clip' ? 'video' : 'image',
      existingAssetId: globalBackgroundAssetId,
      loop: true,
    });
  } else {
    backgroundTimeline.layers.push({
      type: 'color',
      color: (workflow.globalSettings.backgroundColor as SolidColor).color,
    });
  }

  return { builderTimelines, assets };
}

export function createMainSectionFromBuilder(workflow: WorkflowDataDto): Partial<TemplateSection> {
  const { builderTimelines, assets: backgroundAssets } = createBuilderTimelines(workflow);

  const section = createSection('main');
  const { assets, styles } = copyBuilderTimelinesToSection(section, builderTimelines, workflow.globalSettings);

  section.backgroundColor = workflow.globalSettings.backgroundColor;

  return {
    section,
    assets: [...backgroundAssets, ...assets],
    styles: [...styles],
  };
}
