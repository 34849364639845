import { areBoundsARsEqual } from '../helpers';
import { getLayerFromId } from '../helpers/timelines.helpers';
import { LayerIdEvent } from '../interfaces';
import { Bounds } from './../interfaces/workflow.interfaces';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export interface UpdateBoundsEvent extends LayerIdEvent {
  bounds: Bounds;
  maintainAspectRatio: boolean;
}

export class UpdateBoundsCommand extends WorkflowBaseBuilder<UpdateBoundsEvent> {
  run(event: UpdateBoundsEvent) {
    const layerInfo = getLayerFromId(event.layerId, this.source);
    if (layerInfo === null) {
      return { success: false };
    }

    const { layer, timeline } = layerInfo;
    let maintainAspectRatio = event.maintainAspectRatio;

    if (layer.type === 'lottie' && layer.isTextBox) {
      maintainAspectRatio = false;
    }

    const validationMessage = this.validateLayerBounds(event.bounds, layer.bounds, maintainAspectRatio);

    if (validationMessage) {
      return { success: false, errorMessage: validationMessage as string };
    }

    layer.bounds = { ...event.bounds };

    return this.ok();
  }

  private validateLayerBounds(newBounds: Bounds, oldBounds: Bounds, maintainAspectRatio: boolean) {
    if (!isNaN(newBounds.width) && newBounds.width < 0) {
      return 'Width must be greater than 0.';
    }

    if (!isNaN(newBounds.height) && newBounds.height < 0) {
      return 'Height must be greater than 0.';
    }

    if (oldBounds.height && maintainAspectRatio) {
      if (!areBoundsARsEqual(oldBounds, newBounds)) {
        return 'Aspect ratio cannot be changed.';
      }
    }

    return false;
  }
}
