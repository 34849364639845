import * as TimelineInterfaces from '../interfaces/timelines.interfaces';
import { getTimelineById } from '../helpers/timelines.helpers';
import { cloneDeep } from 'lodash';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export class SwapMainVideosCommand extends WorkflowBaseBuilder<TimelineInterfaces.SwapTimelinesEvent> {
  run(event: TimelineInterfaces.SwapTimelinesEvent) {
    const { previousTimelineId, currentTimelineId } = event;

    const previousTimelineById = getTimelineById(previousTimelineId, this.source.sections);
    const currentTimelineById = getTimelineById(currentTimelineId, this.source.sections);
    if (!previousTimelineById || !currentTimelineById) {
      return this.error('Can not find timeline to update.');
    }
    const { timeline: previousTimeline } = previousTimelineById;
    const { timeline: currentTimeline } = currentTimelineById;

    const previousTimelineLayers = cloneDeep(previousTimeline.layers);
    const currentTimelineLayers = cloneDeep(currentTimeline.layers);

    previousTimelineLayers.forEach((layer) => {
      [layer.bounds, layer.styles] = this.getTimelineBoundsAndStyles(currentTimeline);
    });
    currentTimelineLayers.forEach((layer) => {
      [layer.bounds, layer.styles] = this.getTimelineBoundsAndStyles(previousTimeline);
    });

    previousTimeline.layers = [...currentTimelineLayers];
    currentTimeline.layers = [...previousTimelineLayers];

    return this.ok();
  }
}
