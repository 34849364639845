import { Asset, GlobalSettings, Layer, Style, Visibility } from './../interfaces/workflow.interfaces';
import { createImageLayer } from './createImageLayer';
import { createLottieLayer } from './createLottieLayer';
import { createVideoLayer } from './createVideoLayer';
import { getLayerBounds } from './getLayerBounds';
import { getLayerStyles } from './getLayerStyles';
import { createColorLayer } from './createColorLayer';
import { TemplateBuilderParsedTimeline, TemplateBuilderParsedLayer } from './../interfaces/template-builder.interfaces';

export function createLayer(
  layerDef: TemplateBuilderParsedLayer,
  timelineDef: TemplateBuilderParsedTimeline,
  globalSettings: GlobalSettings
) {
  let layer: Layer;
  let layerAsset: Asset;
  let layerStyles: Style[] = [];

  switch (layerDef.type) {
    case 'lottie': {
      ({ layer, asset: layerAsset, styles: layerStyles } = createLottieLayer(layerDef, globalSettings));
      break;
    }
    case 'video': {
      ({ layer, asset: layerAsset } = createVideoLayer(layerDef));
      break;
    }
    case 'image': {
      ({ layer, asset: layerAsset } = createImageLayer(layerDef));
      break;
    }
    case 'color': {
      ({ layer, styles: layerStyles } = createColorLayer(layerDef));
      break;
    }
    default:
      throw new Error(`Unknown layer type: "${layerDef.type}"`);
  }

  // Visibility
  const visibility: Visibility = { startAt: layerDef.visibility?.startAt ?? 0 };
  if (!layerDef.loop && (layer.type === 'video' || layer.type === 'lottie')) {
    visibility.endAt = layerDef.visibility?.endAt ?? visibility.startAt + layerAsset.data.duration;
  }
  layer.visibility = visibility;

  // Bounds and styles
  layer.bounds = getLayerBounds(timelineDef?.bounds ?? layerDef.bounds);
  layer.styles = getLayerStyles(timelineDef?.styles ?? layerDef.styles);

  return { layer, layerAsset, layerStyles };
}
