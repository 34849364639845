/* eslint-disable @typescript-eslint/no-explicit-any */
import { cloneDeep } from 'lodash';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_2_8 } from '../transformation_2_8/schema_after';
import { WorkflowDataDto_2_9 } from './schema_after';

/*
  NOTE: Purpose of this transformation:
    - 1) remove layout global settings
*/
export const SchemaTransformation_2_9: SchemaTransformation = {
  version: '2.9',
  supportsPolyfill: false,

  run(oldSchema: WorkflowDataDto_2_8.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as unknown as WorkflowDataDto_2_9.WorkflowDataDto;
    delete (newSchema.globalSettings as any).layout;
    return newSchema;
  },
};
