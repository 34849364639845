// http://www.tcax.org/docs/ass-specs.htm

import { ProjectFont, pctToPx } from '@openreel/creator/common';

export interface ASSCue {
  startMS: number;
  endMS: number;
  style: string;
  text: string;
  marginL?: number; // 0 to fallback to positioning from style
  marginR?: number; // 0 to fallback to positioning from style
  marginV?: number; // 0 to fallback to positioning from style
  speaker?: string; // AKA Actor, used by Aegisub, not part of SSA v4 specs
  effect?: string; // unused
  layer?: number; // unused, possible zIndex
}

export type ASSAlignment = 'center' | 'NE' | 'N' | 'NW' | 'W' | 'SW' | 'S' | 'SE' | 'E';
export type ASSEncoding = 'ANSI' | 'Default';

export interface ASSStyle {
  name: string;
  font: ProjectFont;
  fontsize: number; // percentage relative to the video height
  primaryColor: string; // hex with alpha
  secondaryColor?: string; // unused, defaults to primary color hex with alpha
  outlineColor: string; // hex with alpha
  shadowColor: string; // hex with alpha
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  strikeOut?: boolean;
  scaleX?: number; // defaults to 100
  scaleY?: number; // defaults to 100
  spacing?: number;
  angle?: number;
  hasBox: boolean; // BorderStyle in ASS, can be 1 for no box and 3 for box
  outline: number; // percentage relative to the video height
  shadow: number; // percentage relative to the video height
  alignment?: ASSAlignment; // defaults to S
  marginL: number; // percentage relative to the video width
  marginR: number; // percentage relative to the video width
  marginV: number; // percentage relative to the video height
  encoding?: ASSEncoding;
}

export function toSRTTime(timeMS: number): string {
  const [hour, min, sec, ms] = extractTimeParts(timeMS);

  // eslint-disable-next-line max-len
  return `${hour.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}:${sec.toString().padStart(2, '0')},${ms
    .toString()
    .padStart(3, '0')}`;
}

export function toVTTTime(timeMS: number): string {
  const [hour, min, sec, ms] = extractTimeParts(timeMS);

  // eslint-disable-next-line max-len
  return `${hour.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}:${sec.toString().padStart(2, '0')}.${ms
    .toString()
    .padStart(3, '0')}`;
}

export function toASSTime(timeMS: number): string {
  const [hour, min, sec, ms] = extractTimeParts(timeMS);
  const ms2 = Math.round(ms / 10);

  // eslint-disable-next-line max-len
  return `${hour.toString()}:${min.toString().padStart(2, '0')}:${sec.toString().padStart(2, '0')}.${ms2
    .toString()
    .padStart(2, '0')}`;
}

export function toASSCue(
  {
    startMS,
    endMS,
    style,
    text,
    marginL = null,
    marginR = null,
    marginV = null,
    speaker = '', // AKA Actor
    effect = '', // unused
    layer = 0, // unused, possible zIndex
  }: ASSCue,
  resolution: { width: number; height: number }
): string {
  const start = toASSTime(startMS);
  const end = toASSTime(endMS);

  if (marginV !== null) {
    // 0 vertical margin is not supported by ASS because that makes it fallback to the global style margin
    marginV = marginV === 0 ? 1 : toASSPxHeight(marginV, resolution.height);
  } else {
    marginV = 0; // apply global style margin as default
  }
  if (marginL !== null) {
    marginL = marginL === 0 ? 1 : toASSPxWidth(marginL, resolution.width);
  } else {
    marginL = 0;
  }
  if (marginR !== null) {
    marginR = marginR === 0 ? 1 : toASSPxWidth(marginR, resolution.width);
  } else {
    marginR = 0;
  }

  return `Dialogue: ${layer},${start},${end},${style},${speaker},${marginL},${marginR},${marginV},${effect},${text}\n`;
}

export function toASSStyle(
  {
    name,
    fontsize,
    primaryColor,
    outlineColor,
    shadowColor,
    bold,
    italic,
    underline,
    strikeOut,
    scaleX = 100,
    scaleY = 100,
    spacing = 0,
    angle = 0,
    hasBox,
    outline,
    shadow,
    alignment = 'S',
    marginL,
    marginR,
    marginV,
    encoding = 'Default',
  }: ASSStyle,
  fontFamily: string,
  resolution: { width: number; height: number }
): string {
  const secondaryColor = primaryColor;
  const assAlignment = toASSAligment(alignment);
  const assEncoding = toASSEncoding(encoding);

  if (fontsize) {
    fontsize = toAssPxMinAxis(fontsize, resolution);
  }
  if (outline) {
    outline = toAssPxMinAxis(outline, resolution);
  }
  if (shadow) {
    shadow = toAssPxMinAxis(shadow, resolution);
  }
  if (marginV) {
    marginV = toASSPxHeight(marginV, resolution.height);
  }
  if (marginL) {
    marginL = toASSPxWidth(marginL, resolution.width);
  }
  if (marginR) {
    marginR = toASSPxWidth(marginR, resolution.width);
  }

  // eslint-disable-next-line max-len
  return `Style: ${name},${fontFamily},${fontsize},${toASSColor(primaryColor)},${toASSColor(
    secondaryColor
  )},${toASSColor(outlineColor)},${toASSColor(shadowColor)},${bold ? '-1' : '0'},${italic ? '-1' : '0'},${
    underline ? '-1' : '0'
  },${strikeOut ? '-1' : '0'},${scaleX},${scaleY},${spacing},${angle},${
    hasBox ? '3' : '1'
  },${outline},${shadow},${assAlignment},${marginL},${marginR},${marginV},${assEncoding}`;
}

function toASSAligment(alignment: ASSAlignment) {
  let ASSAlignment: string;
  switch (alignment) {
    case 'center':
      ASSAlignment = '5';
      break;
    case 'NE':
      ASSAlignment = '7';
      break;
    case 'N':
      ASSAlignment = '8';
      break;
    case 'NW':
      ASSAlignment = '9';
      break;
    case 'W':
      ASSAlignment = '4';
      break;
    case 'SW':
      ASSAlignment = '1';
      break;
    case 'S':
      ASSAlignment = '2';
      break;
    case 'SE':
      ASSAlignment = '3';
      break;
    case 'E':
      ASSAlignment = '6';
      break;
    default:
      ASSAlignment = '2';
  }

  return ASSAlignment;
}

function toASSEncoding(encoding: ASSEncoding) {
  let ASSEncoding: string;
  switch (encoding) {
    case 'ANSI':
      ASSEncoding = '0';
      break;
    default:
      ASSEncoding = '1';
  }

  return ASSEncoding;
}

function toASSPxWidth(pc: number, videoWidth: number) {
  return pctToPx(pc, videoWidth);
}

function toASSPxHeight(pc: number, videoHeight: number) {
  return pctToPx(pc, videoHeight);
}

function toAssPxMinAxis(pc: number, videoResolution: { width: number; height: number }) {
  return pctToPx(pc, Math.min(videoResolution.width, videoResolution.height));
}

function toASSColor(colorHex?: string) {
  if (!colorHex) {
    return '';
  }

  const colorHexABGR = colorHex
    .replace('#', '')
    .match(/.{1,2}/g)
    .reverse();
  const alpha = 0xff - parseInt(colorHexABGR[0], 16);
  colorHexABGR[0] = alpha.toString(16).toUpperCase();
  return `&H${colorHexABGR.join('')}`;
}

function extractTimeParts(timeMS: number) {
  const truncatedMS = Math.trunc(timeMS);
  const ms = Math.floor(truncatedMS % 1000);
  const timeSec = (truncatedMS - ms) / 1000;
  const sec = timeSec % 60;
  const timeMin = (timeSec - sec) / 60;
  const min = timeMin % 60;
  const hour = (timeMin - min) / 60;

  return [hour, min, sec, ms];
}
