import { File } from '../interfaces';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export interface UpdateBackgroundEvent {
  sectionId?: string;
  assetType: 'video' | 'color' | 'image';
  assetFile?: File;
  duration?: number;
  color?: string;
}

export function validateUpdateBackgroundEvent({
  assetType,
  assetFile,
  duration,
  color,
}: Omit<UpdateBackgroundEvent, 'sectionId'>) {
  switch (assetType) {
    case 'color':
      if (typeof color !== 'string') {
        return 'Color must be specified.';
      }
      break;
    case 'video':
      if (!assetFile) {
        return 'Video must be specified.';
      }
      if (!duration) {
        return 'Duration must be specified.';
      }
      break;
    case 'image':
      if (!assetFile) {
        return 'Image must be specified.';
      }
      break;
    default:
      return 'Invalid background type';
  }

  return '';
}

export class UpdateBackgroundCommand extends WorkflowBaseBuilder<UpdateBackgroundEvent> {
  run({ sectionId, assetType, assetFile, duration, color }: UpdateBackgroundEvent) {
    const errorMessage = validateUpdateBackgroundEvent({ assetType, assetFile, duration, color });
    if (errorMessage) {
      return this.error(errorMessage);
    }

    if (assetType === 'video' || assetType === 'image') {
      this.setAssetBackground(sectionId, assetFile, assetType, duration);
    } else if (assetType === 'color') {
      this.setColorBackground(sectionId, color);
    }

    return this.ok();
  }
}
