export enum PresentationMessage {
  PlayStatus = 'presentation_play_status',
  SelectSlide = 'select_slide',
  PresentationCreated = 'presentation_created',
  PresentationCleared = 'presentation_cleared',
  PresentationPdfTranscodeComplete = 'presentation_pdf_transcode_complete',
  DeviceStatusChange = 'presentation_device_status_change',
  PdfImageTranscodeFailed = 'presentation_pdf_image_transcode_failed'
}

export enum PresentationStatus {
  UploadingFailed = 'UploadingFailed',
  Uploading = 'Uploading',
  Processing = 'Processing',
  Presenting = 'Presenting',
  NotPresenting = 'NotPresenting',
}

interface SocketMetadata {
  SessionID: number;
  identityArr: string[];
}

export interface SelectSlideStatus extends SocketMetadata {
  assetId: number;
  SessionID: number;
  previousAssetId?: number;
  recordingDuration?: number;
  isSessionRecording?: boolean;
  videoId?: number;
}

export interface SetPlayStatus extends SocketMetadata {
  status: PresentationStatus;
}

export interface DevicesStatusChange extends SocketMetadata {
  identities: string[];
  enabled: boolean;
}

export interface Presentation extends SocketMetadata {
  id: number;
  slides: { url: string; assetId: number }[];
  currentAssetId: number;
  status: PresentationStatus;
  identity?: string;
  presenterName: string;
  fileName: string;
  isPresenterSubject: boolean;
  enabledIdentities?: string[];
  totalRecordDuration?: number;
  videoId?: number;
}

export interface PresentationRecordingAssetConfig {
  assetId: number;
  duration: number;
}

export interface PresentationPdfImageTranscodeFail extends SocketMetadata {
  assetId: number;
  presentationId: number;
  errorMessage: string;
  presenterName: string;
  presentationFileName: string;
  presenterIdentity: string;
}