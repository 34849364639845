import { getLayerFromId } from '../helpers/timelines.helpers';
import { TEXT_BOX_PRESET_FIELD_NAME } from '../constants';
import { Bounds, LayerIdEvent } from '../interfaces';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export interface UpdateTextBoxValueEvent extends LayerIdEvent {
  text: string;
  bounds?: Bounds;
}

export class UpdateTextBoxValueCommand extends WorkflowBaseBuilder<UpdateTextBoxValueEvent> {
  run({ layerId, text, bounds }: UpdateTextBoxValueEvent) {
    const layerData = getLayerFromId(layerId, this.source);

    if (layerData?.layer.type !== 'lottie') {
      return this.error('Layer type is not lottie.');
    }
    if (layerData?.timeline.type !== 'text-boxes') {
      return this.error('Timeline type is not text-boxes.');
    }

    const layerFieldData = layerData.layer.data[TEXT_BOX_PRESET_FIELD_NAME];
    const styleId = layerFieldData.styleId;

    this.applyLayerChanges(layerData.layer, {
      values: {
        [TEXT_BOX_PRESET_FIELD_NAME]: {
          type: 'text',
          value: text,
          styleId,
        },
      },
      assetChanges: {},
      styleChanges: {},
    });

    this.updateTextLayerBounds(layerData.layer, bounds);

    return this.ok();
  }
}
