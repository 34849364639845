import {
  Asset,
  AssetMetadata,
  ColorTag,
  GlobalSettings,
  Preset,
  PresetField,
} from './../interfaces/workflow.interfaces';
import { v4 as uuidv4 } from 'uuid';
import { TemplateBuilderAsset } from './../interfaces/template-builder.interfaces';
import { createLottieLayerDataFromAsset } from '../helpers';

export function parsePresetKey(lottieToken: string) {
  return lottieToken.toLowerCase().replace(/ /g, '_');
}

export function getLottieMetadata(builderAsset: TemplateBuilderAsset) {
  const colorTags: ColorTag[] = [];
  const preset: Preset = {};
  const data: AssetMetadata = {
    duration: builderAsset.duration,
  };

  const textColor = builderAsset?.fieldsTextColor ?? '#000000';

  builderAsset.fields.forEach((field, index) => {
    const presetField: PresetField = {
      type: field.type,
      lottiePath: field.path,
      order: index, // TODO:
      validationRules: {},
      tokenType: field.tokenType,
    };

    if (field.type === 'logo') {
      presetField.lottieAssetRefId = field.assetRefId;
    }

    if (['shape', 'fill'].includes(field.type)) {
      presetField.colorTag = 'primary';
      if (field.shapeShade) {
        presetField.colorShade = field.shapeShade;
      }

      if (!colorTags.find((ct) => ct.tag === 'primary')) {
        colorTags.push({
          name: 'Primary Color',
          tag: 'primary',
          color: '',
        });
      }
    }

    if (field.type === 'text') {
      presetField.label = field.textLabel;
      presetField.defaultValue = field.textDefaultValue;

      if (field.textChangeColor) {
        presetField.colorTag = 'text';

        if (!colorTags.find((ct) => ct.tag === 'text')) {
          colorTags.push({
            name: 'Text Color',
            tag: 'text',
            color: textColor,
          });
        }
      }

      if (typeof field.textRequired === 'boolean') {
        presetField.validationRules['required'] = field.textRequired;
      }
      if (typeof field.textMinLength === 'number') {
        presetField.validationRules['minlength'] = field.textMinLength;
      }
      if (typeof field.textMaxLength === 'number') {
        presetField.validationRules['maxlength'] = field.textMaxLength;
      }
    }

    preset[parsePresetKey(field.token)] = presetField;
  });

  return { colorTags, data, preset };
}

export function createLottieAsset(builderAsset: TemplateBuilderAsset, globalSettings: GlobalSettings) {
  const { colorTags, preset, data } = getLottieMetadata(builderAsset);

  const asset: Asset = {
    id: uuidv4(),
    type: 'json',
    file: {
      path: builderAsset.assetFileId,
      provider: 'or-assets',
    },
    data,
    colorTags,
    preset,
  };

  const { data: layerData, styles: layerStyles } = createLottieLayerDataFromAsset(asset, globalSettings);
  return { asset, layerData, layerStyles };
}
