import { PipeTransform, Pipe } from '@angular/core';
import { Recording, VideoStatus } from '../interfaces';

@Pipe({ name: 'groupTitleClip', standalone: true })
export class ClipGroupStatusPipe implements PipeTransform {
  transform(recordings: Recording[]) {
    let grpTitleRecording = recordings[0];
    recordings.forEach((recording) => {
      if (recording.status !== VideoStatus.UPLOADED) {
        grpTitleRecording = recording;
      }
    });
    return grpTitleRecording;
  }
}
