import { ColorLayer, LayerOptions, Style } from './../interfaces/workflow.interfaces';
import { v4 } from 'uuid';
import { TemplateBuilderParsedLayer } from './../interfaces/template-builder.interfaces';

export function createColorLayer(layerDef: TemplateBuilderParsedLayer) {
  const styles: Style[] = [{ id: v4(), color: layerDef.color }];

  const layer: LayerOptions & ColorLayer = {
    layerId: v4(),
    enabled: true,
    loop: true,
    type: 'color',
    bounds: { x: 0, y: 0, width: 100, height: 100 },
    styleId: styles[0].id,
    visibility: {
      startAt: 0,
    },
  };

  return { layer, styles };
}
