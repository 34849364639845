<div class="form" [formGroup]="form">
  <div class="colors" *ngIf="data.formType === 'color'">
    <h3 class="section-title">Primary Color</h3>

    <openreel-radio-group formControlName="color">
      <div
        openreelRadioGroupItem
        *ngFor="let color of data.colors"
        [value]="color.hex"
        [ngStyle]="{ backgroundColor: color.hex }"
        class="color"
      ></div>
    </openreel-radio-group>
  </div>

  <div class="images" *ngIf="data.formType === 'logo' || data.formType === 'watermark'">
    <div class="logos" *ngIf="data.logos.length">
      <h3 class="section-title">Logo</h3>

      <openreel-radio-group formControlName="logo">
        <ng-container *ngFor="let logo of data.logos">
          <div openreelRadioGroupItem class="logo" *ngFor="let format of logo.formats" [value]="format">
            <img class="logo-img" [src]="format.src" alt="Logo">
          </div>
        </ng-container>
      </openreel-radio-group>
    </div>

    <div class="watermarks" *ngIf="data.logos.length">
      <h3 class="section-title">Watermark</h3>

      <openreel-radio-group formControlName="watermark">
        <ng-container *ngFor="let logo of data.logos">
          <div openreelRadioGroupItem class="logo" *ngFor="let format of logo.formats" [value]="format">
            <img class="logo-img" [src]="format.src" alt="Logo">
          </div>
        </ng-container>
      </openreel-radio-group>
    </div>
  </div>
</div>
