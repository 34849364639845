import { toVTTTime } from '../../../utils';
import { CombinedTimebox } from '../../interfaces/combined-timebox.interface';
import { CombinedTimeboxesStrategy } from '../combined-timeboxes-strategy';

export class VTTStrategy extends CombinedTimeboxesStrategy {
  override getHeader(): string {
    return 'WEBVTT';
  }

  override getContent(): string {
    return this.content;
  }

  protected override addCue(timebox: CombinedTimebox): void {
    const startTime = this.toCueTime(timebox.from);
    const endTime = this.toCueTime(timebox.to);

    const text = timebox.texts
      .map((t) => (t.showSpeaker ? `${this.speakers.get(t.speakerId).name}: ` : '') + t.text)
      .join('\n');
    const cueText = `\n\n${startTime} --> ${endTime}\n${text}`;

    this.content += cueText;
  }

  protected override toCueTime(timeMs: number): string {
    return toVTTTime(this.offset + timeMs);
  }
}
