import { commonenv } from '@openreel/frontend/common/env/environment.develop';

export const environment = {
  ...commonenv,
  environmentName: 'develop',
  production: false,
  userPilotToken: '95cd14q4',
  nonSubjectsMixpanelToken: 'b664855979f3baa4afa562207e3d5b96',
  subjectsMixpanelToken: '14199c32316eb7343e8ad5333bc99946',
  facebookAppId: '1796714797388277',
  googleClientId: '594966789352-pl7d7ihcb92bm2nu878b3id7mbg6p7f5.apps.googleusercontent.com',
  googleApiKey: 'AIzaSyBZYEjyekmucK2BUQTUVYldehGSFtaJVvc',
  salesforceChatId: ['573EY000000001m', '572EY000000001N', '00D1I000001YfOv'],
  stripeApiKey:
    'pk_test_51LXQ5CKllPbW0Ven4b4R0sj0ZF4jVMqKBallvMBPBNIqbu5MyMwSlOzVzv1uij5H6nywaPu3PLqLC5uoNSNh5Eea00fajZkE13',
  graphql: 'https://capture.develop.openreel.cloud/graphql',
  graphqlWs: 'wss://capture.develop.openreel.cloud/graphql',
};
