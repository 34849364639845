import {
  Asset,
  SectionType,
  SolidColor,
  Style,
  TemplateSection,
  Transitions,
  WorkflowDataDto,
} from './../interfaces/workflow.interfaces';
import { INTRO_OUTRO_Z_INDEX_MAIN, INTRO_OUTRO_Z_INDEX_OVERLAY, Z_INDEX_BACKGROUND } from '../constants';
import { createVideoAsset } from './createVideoAsset';
import { createSection } from './baseWorkflow';
import { createTransitionsFromBuilder } from './createTransitionsFromBuilder';
import { copyBuilderTimelinesToSection } from './copyBuilderTimelinesToSection';
import {
  TemplateBuilderParsedTimeline,
  TemplateBuilderSectionAspectRatioFrom,
} from './../interfaces/template-builder.interfaces';
import { assetTypeToLayerType } from './assetTypeToLayerType';

function createBuilderTimelines(aspectRatioForm: TemplateBuilderSectionAspectRatioFrom, workflow: WorkflowDataDto) {
  const assets: Asset[] = [];

  const builderTimelines: TemplateBuilderParsedTimeline[] = [
    { type: 'background', layers: [], zIndex: Z_INDEX_BACKGROUND },
    { type: 'main', layers: [], zIndex: INTRO_OUTRO_Z_INDEX_MAIN },
  ];

  // Background timeline
  const backgroundTimeline = builderTimelines.find((t) => t.type === 'background');
  if (aspectRatioForm.background?.assetFileId) {
    const asset: Asset = createVideoAsset(
      aspectRatioForm.background.assetFileId,
      aspectRatioForm.background.duration ?? 0
    );
    assets.push(asset);

    backgroundTimeline.layers.push({
      type: 'video',
      existingAssetId: asset.id,
      loop: true,
    });
  } else if (workflow.globalSettings.backgroundAsset.settings.enabled) {
    const globalBackgroundAssetId = workflow.globalSettings.backgroundAsset.settings.assetId;
    const globalBackgroundAsset = workflow.assets.find((a) => a.id === globalBackgroundAssetId);
    backgroundTimeline.layers.push({
      type: globalBackgroundAsset.type === 'clip' ? 'video' : 'image',
      existingAssetId: globalBackgroundAssetId,
      loop: true,
    });
  } else {
    backgroundTimeline.layers.push({
      type: 'color',
      color: (workflow.globalSettings.backgroundColor as SolidColor).color,
    });
  }

  const sectionLayers = aspectRatioForm.layers || [];

  // Main timeline
  const mainLayer = sectionLayers.find((l) => l.type === 'main');
  const mainTimeline = builderTimelines.find((t) => t.type === 'main');

  if (mainLayer?.asset?.assetFileId) {
    mainTimeline.hasAudio = mainLayer.hasAudio;

    mainTimeline.layers.push({
      type: assetTypeToLayerType(mainLayer.asset.assetFileType),
      asset: mainLayer.asset,
      bounds: mainLayer.asset.bounds,
    });
  }

  // Overlay timeline
  const overlayLayer = sectionLayers.find((l) => l.type === 'overlays');

  if (overlayLayer?.asset?.assetFileId) {
    if (overlayLayer.startAt && mainLayer?.asset) {
      if (overlayLayer.startAt > mainLayer.asset.duration) {
        throw new Error(
          'Overlay must start before main layer finishes. Check overlay start time and main layer duration.'
        );
      }
    }
    const overlayTimeline: TemplateBuilderParsedTimeline = {
      type: 'overlays',
      layers: [],
      hasAudio: overlayLayer.hasAudio,
      zIndex: INTRO_OUTRO_Z_INDEX_OVERLAY,
    };

    overlayTimeline.layers.push({
      type: assetTypeToLayerType(overlayLayer.asset.assetFileType),
      asset: overlayLayer.asset,
      bounds: overlayLayer.asset.bounds,
      ...(overlayLayer.startAt ? { visibility: { startAt: overlayLayer.startAt } } : {}),
    });
    builderTimelines.push(overlayTimeline);
  }

  return { builderTimelines, assets };
}

export function createIntroOutroSectionFromBuilder(
  sectionId: string,
  aspectRatioForm: TemplateBuilderSectionAspectRatioFrom,
  workflow: WorkflowDataDto
): Partial<TemplateSection> {
  const sectionType = sectionId as SectionType;

  const { builderTimelines, assets: backgroundAssets } = createBuilderTimelines(aspectRatioForm, workflow);

  const section = createSection(sectionType);
  const { assets, styles } = copyBuilderTimelinesToSection(section, builderTimelines, workflow.globalSettings);

  section.backgroundColor = workflow.globalSettings.backgroundColor;

  // Add transitions
  let transitions: Transitions;
  const transitionAssets: Asset[] = [];
  const transitionStyles: Style[] = [];
  if (aspectRatioForm.transition?.type) {
    const transitionsDto = createTransitionsFromBuilder(aspectRatioForm.transition, workflow.globalSettings);
    if (transitionsDto) {
      transitions = transitionsDto.transitions;

      transitionAssets.push(...transitionsDto.assets);
      transitionStyles.push(...transitionsDto.styles);
    }
  }

  return {
    section,
    transitions,
    assets: [...backgroundAssets, ...assets, ...transitionAssets],
    styles: [...styles, ...transitionStyles],
  };
}
