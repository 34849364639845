import { Component, Input, OnInit } from '@angular/core';
import { DirectorApiService } from '@openreel/frontend/common';
@Component({
  selector: 'openreel-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css'],
})
export class TopbarComponent implements OnInit {
  @Input() title: string;
  username: string;
  constructor(private directorApiService: DirectorApiService) {}
  ngOnInit() {
    this.getUserDetails();
  }
  getUserDetails() {
    this.directorApiService.getUserDetails().subscribe(
      (res) => {
        this.username = res.fullname;
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
