import { Component, ChangeDetectionStrategy } from '@angular/core';
import { AuthService } from '@openreel/frontend/common';
import { RecordingsListComponent } from '../recordings-list/recordings-list.component';


@Component({
  selector: 'openreel-recordings-list-clip',
  templateUrl: './recordings-list.component.html',
  styleUrls: ['./recordings-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecordingsListClipComponent extends RecordingsListComponent {
 
  constructor(authService: AuthService) {
    super(authService)
  }
}
