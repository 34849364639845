import {
  Bounds,
  LayoutDto,
  PROJECT_TIMELINE_TYPES,
  Section,
  SectionId,
  SolidColor,
  WorkflowDataDto,
} from '../interfaces';
import { groupBy } from 'lodash';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export interface UpdateAspectRatioLayoutData {
  templateLayoutId: number;
  data: LayoutDto;
  aspectRatioData: { aspectRatioId: number; bounds: Bounds[] }[];
}

export interface UpdateAspectRatioEvent {
  targetAspectRatioId: number;
  targetWidth: number;
  targetHeight: number;
  layouts: UpdateAspectRatioLayoutData[];
  templateWorkflow: WorkflowDataDto;
}

export class UpdateAspectRatioCommand extends WorkflowBaseBuilder<UpdateAspectRatioEvent> {
  run(event: UpdateAspectRatioEvent) {
    this.source.globalSettings.resolution = {
      width: event.targetWidth,
      height: event.targetHeight,
    };

    const mainTimeline = this.source.timelines.find((t) => t.type === 'main');
    for (const layer of mainTimeline.layers) {
      if (layer.type !== 'section') {
        continue;
      }

      const section = this.source.sections[layer.sectionId];

      if (section.sectionType === 'intro' || section.sectionType === 'outro') {
        this.updateIntroOutro(layer.sectionId as SectionId, event);
      } else {
        this.updateMainSection(section, event);
      }
    }

    this.updateAllTextLayerBounds();

    return this.ok();
  }

  private updateIntroOutro(sectionId: SectionId, event: UpdateAspectRatioEvent) {
    const templateSections = event.templateWorkflow.templateSections[sectionId];
    if (templateSections.isEnabled) {
      const templateSection = templateSections.sections.find((s) => s.aspectRatioId === event.targetAspectRatioId);
      this.copyTemplateSection(sectionId, templateSection, false);

      // Apply primary color again to correctly update any shapes in new lotties
      this.applyGlobalColor((this.source.globalSettings.primaryColor as SolidColor).color, 'primary');
    } else {
      this.removeTemplateSection(sectionId);
    }
  }

  private updateMainSection(section: Section, event: UpdateAspectRatioEvent) {
    // We skip sections without marked layout
    if (!section.layout?.templateLayoutId) {
      return;
    }

    const foundLayout = event.layouts.find((l) => l.templateLayoutId === section.layout.templateLayoutId);

    // Section might be using an older layout that is no longer attached to the template
    if (!foundLayout) {
      return;
    }

    const foundLayoutAspectRatio = foundLayout.aspectRatioData.find(
      (ar) => ar.aspectRatioId === event.targetAspectRatioId
    );
    const foundLayoutTimelines = foundLayout.data.timelines.map((timeline, i) => ({
      ...timeline,
      bounds: foundLayoutAspectRatio.bounds[i],
    }));

    const sectionTimelinesFromLayout = section.timelines.filter((t) => !PROJECT_TIMELINE_TYPES.includes(t.type));

    // We go over layout timelines, get their bounds for new aspect ratio and apply the bounds to timeline (if found)
    const layoutTimelinesPerType = groupBy(foundLayoutTimelines, 'type');
    for (const [type, layoutTimelines] of Object.entries(layoutTimelinesPerType)) {
      layoutTimelines.forEach((layoutTimeline, index) => {
        const sectionTimelinesByType = sectionTimelinesFromLayout.filter((t) => t.type === type);
        if (sectionTimelinesByType.length === 0) {
          return;
        }

        if (sectionTimelinesByType.length <= index) {
          return;
        }

        const bounds = layoutTimeline.bounds;

        sectionTimelinesByType[index].bounds = { ...bounds };
        for (const layer of sectionTimelinesByType[index].layers) {
          layer.bounds = { ...bounds };
        }
      });
    }
  }
}
