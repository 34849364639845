/* eslint-disable @typescript-eslint/no-explicit-any */
import { cloneDeep } from 'lodash';
import { getLayers } from '../../../helpers';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_2_0 } from '../transformation_2_0/schema_after';
import { WorkflowDataDto_2_1 } from './schema_after';

/*
  NOTE: Purpose of this transformation:
    - migration shape type preset fields into fill type fields
*/
export const SchemaTransformation_2_1: SchemaTransformation = {
  version: '2.1',
  supportsPolyfill: false,

  run(oldSchema: WorkflowDataDto_2_0.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as unknown as WorkflowDataDto_2_1.WorkflowDataDto;

    for (const asset of newSchema.assets) {
      if (asset.type !== 'json' || !asset.data) {
        continue;
      }

      for (const [, field] of Object.entries(asset.preset)) {
        if (field.type === 'shape') {
          field.type = 'fill';
        }
      }
    }

    for (const { layer } of getLayers(newSchema as any)) {
      const newLayer = layer as WorkflowDataDto_2_1.Layer;

      if (newLayer.type !== 'lottie' || !newLayer.data) {
        continue;
      }

      for (const [, field] of Object.entries(newLayer.data)) {
        if (field.type === 'shape') {
          field.type = 'fill';
        }
      }
    }

    return newSchema;
  },
};
