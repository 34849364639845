import { Component, Input, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';
import {
  Recording,
  VideoStatus,
  isIosDevice,
  VideoType,
  VideoTranscodingStatus,
  DestinationIndication,
  TranscriptStatus,
  resolutionUnits,
  AuthService,
} from '@openreel/frontend/common';
import { TRANSCRIPTION, CAPTURE_ARCHIVE_CLIPS, CAPTURE_VIDEO_EXTRACT_AUDIO } from '@openreel/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'openreel-recording-item',
  templateUrl: './recording-item.component.html',
  styleUrls: ['./recording-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecordingItemComponent {
  @Input() recording: Recording;
  @Input() selectionEnabled = false;
  @Input() canManipulate = false;
  @Input() displayInfoInTwoRows = false; // for showing the recording details in 2 rows
  @Input() prefix;
  VideoStatus = VideoStatus;
  @Output() onItemSelect = new EventEmitter<boolean>();
  @Output() onArchiveClick = new EventEmitter();
  @Output() onRestoreClick = new EventEmitter();
  @Output() onDeleteClick = new EventEmitter();
  @Output() onTranscodeClick = new EventEmitter<string>();
  @Output() onAudioExtractClick = new EventEmitter<number>();
  @Output() onAudioDownloadClick = new EventEmitter<number>();
  @Output() onTranscriptClick = new EventEmitter<number>();
  @Output() onTranscriptShowClick = new EventEmitter<string>();
  @Output() onCommentShowClick = new EventEmitter<string>();
  @Output() onDownloadClick = new EventEmitter<string>();
  @Output() onCopyClick = new EventEmitter<string>();
  @Output() onPlayClick = new EventEmitter<string>();
  @Output() onFavouriteClick = new EventEmitter();
  @Output() onUploadClick = new EventEmitter();
  @Output() onUploadCancelClick = new EventEmitter();
  @Output() onTogglePauseUploadClick = new EventEmitter<number>();
  VideoType = VideoType;
  videoTranscodingStatus = VideoTranscodingStatus;
  transcriptStatus = TranscriptStatus;
  resolutionUnits = resolutionUnits;
  isOpen = false;
  showInfo = false;
  transcription = TRANSCRIPTION;
  captureArchiveClips = CAPTURE_ARCHIVE_CLIPS;
  captureVideoExtractAudio = CAPTURE_VIDEO_EXTRACT_AUDIO;

  constructor(private authService: AuthService, private toast: ToastrService) { }

  setShowInfo() {
    this.showInfo = !this.showInfo;
  }

  hasUploadError(di: DestinationIndication[]) {
    return di && di.some((i) => !i.hq || !i.original);
  }

  get canDelete() {
    return (
      this.canManipulate &&
      this.recording.status !== VideoStatus.RECORDING &&
      !(this.isIos() && this.recording.status === VideoStatus.UPLOADING)
    );
  }

  get canArchive() {
    return !this.recording.archived_at && this.canDelete;
  }

  get canRestore() {
    return this.recording.archived_at && this.canDelete;
  }

  isIos(): boolean {
    return isIosDevice(this.recording.identity);
  }

  async onRecordingDeleteClicked() {
    if (this.authService.cookieDeleted()) {
      return;
    }
    this.onDeleteClick.next(null);
  }

  async onRecordingArchiveClicked() {
    if (this.authService.cookieDeleted()) {
      return;
    }
    this.onArchiveClick.next(null);
  }

  async onRecordingRestoreClicked() {
    if (this.authService.cookieDeleted()) {
      return;
    }
    this.onRestoreClick.next(null);
  }

  async onRecordingTranscodeClicked(type: string) {
    this.onTranscodeClick.next(type);
  }

  async onRecordingDownloadClicked(type = VideoType.ORIGINAL) {
    if (this.authService.cookieDeleted()) {
      return;
    }
    this.onDownloadClick.next(type);
  }

  async onRecordingCopyClicked(type = VideoType.ORIGINAL) {
    this.toast.success('Successfully copied item to the clipboard', 'Success!');
    this.onCopyClick.next(type);
  }

  async onPlayClicked(type = VideoType.ORIGINAL) {
    this.onPlayClick.next(type);
  }

  async onFavouriteClicked() {
    this.onFavouriteClick.next(null);
  }

  async onSelectClicked() {
    this.recording.selected = !this.recording.selected;
    this.onItemSelect.emit(this.recording.selected);
  }

  async onRecordingUploadClicked() {
    this.onUploadClick.next(null);
  }

  async onRecordingUploadCancelClicked() {
    this.onUploadCancelClick.next(null);
  }

  async onRecordingAudioExtractClicked(videoId: number) {
    this.onAudioExtractClick.next(videoId);
  }

  async onRecordingAudioDownloadClicked(videoId: number) {
    this.onAudioDownloadClick.next(videoId)
  }

  async onRecordingTranscriptClicked(videoId: number) {
    this.onTranscriptClick.next(videoId);
  }

  async onTranscriptShowClicked(transcript: string) {
    this.onTranscriptShowClick.next(transcript);
  }

  onTranscriptDownloadClicked(recording: Recording) {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(recording.transcript.fullText));
    element.setAttribute('download', `${recording.video_name}.txt`);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  async onCommentShowClicked(comment: string) {
    this.onCommentShowClick.next(comment);
  }

  pauseUpload() {
    this.onTogglePauseUploadClick.next(0);
  }

  resumeUpload() {
    this.onTogglePauseUploadClick.next(1);
  }
}
