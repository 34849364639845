<form [formGroup]="form" #myForm="ngForm" (submit)="submit()">
  <h1 mat-dialog-title>
    {{ mode === 'create' ? 'Create a new script' : 'Edit script' }}
    <button type="button" class="cls-btn" (click)="onDialogCloseClicked()" mat-button>
      <mat-icon color="primary">close</mat-icon>
    </button>
  </h1>
  <div mat-dialog-content style="height: 520px">
    <div style="text-align: right">
      <div>
        <mat-slide-toggle [checked]="status === 'ready'" (change)="toggleStatus(status)">
          <span *ngIf="status === 'ready'">Active</span>
          <span *ngIf="status !== 'ready'">Inactive</span>
        </mat-slide-toggle>
      </div>
    </div>
    <div>
      <div>Script name</div>
      <mat-form-field class="title-form-field" appearance="outline" style="font-size: 12px">
        <input matInput formControlName="title" [errorStateMatcher]="matcher" />
      </mat-form-field>
      <div class="text-form-field">
        <openreel-rich-text-editor
          [editorContent]="form.controls.content.value"
          (editorContentChange)="updateTextContent($event)"
        ></openreel-rich-text-editor>
      </div>
    </div>
  </div>
  <div mat-dialog-actions style="justify-content: flex-end">
    <button
      mat-flat-button
      color="primary"
      style="width: 160px"
      type="submit"
      id="save-script-button"
      [disabled]="form.disabled"
    >
      Save Script
    </button>
  </div>
</form>
