import { Injectable, OnDestroy } from '@angular/core';
import {
  commonenv,
  EmailTestEmailResultsDto,
  EmailTestEntity,
  ROUTE_OAUTH_CONNECTIONS,
} from '@openreel/frontend/common';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseOauthService } from './base-oauth.service';
import { ConnectionAuthEntity } from '@openreel/common/automations';

@Injectable({
  providedIn: 'root',
})
export class GmailService implements OnDestroy {
  public urls = {
    testUrl: commonenv.automationsApiUrl + 'connections/gmail/test',
  };

  constructor(
    private readonly baseOauthService: BaseOauthService,
    private readonly httpClient: HttpClient,
  ) { }

  sendTest(data: EmailTestEntity): Observable<EmailTestEmailResultsDto> {
    return this.httpClient.post<EmailTestEmailResultsDto>(this.urls.testUrl, data);
  }

  login(): Promise<ConnectionAuthEntity> {
    this.baseOauthService.openOauthWindow(`/${ROUTE_OAUTH_CONNECTIONS}/gmail`, 'Google Login');

    return new Promise<ConnectionAuthEntity>((resolve) => {
      window['gmailCallback'] = (connection: ConnectionAuthEntity) => {
        resolve(connection);
        delete window['gmailCallback'];
      };
    });
  }

  ngOnDestroy() {
    delete window['gmailCallback'];
  }
}
