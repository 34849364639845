import { Bounds, SectionTimeline, TimelineContentType, TimelineType } from './workflow.interfaces';

export type LayoutType = 'simple' | 'interview' | 'presentation' | 'title' | 'other' | 'ai';

export const LAYOUT_TYPES_AVAILABLE_TO_USER: LayoutType[] = ['title', 'simple', 'interview', 'presentation', 'other'];

export const LAYOUT_TYPE_OTHER_MAIN_TIMELINES_COUNT = 4;
export const LAYOUT_TYPE_STANDARD_MAIN_TIMELINES_COUNT = 2;

export const TIMELINE_ORDER = new Map<TimelineType, number>([
  ['background', 0],
  ['main', 1],
  ['b-roll', 2],
  ['overlays', 3],
  ['watermark', 4],
]);

// DB
export interface LayoutDto {
  timelines: SectionTimeline[];
  mainClipsCount: number;
  sceneDuration: number;
  applyTemplateStyles: boolean;
  assignToNewTemplates: boolean;
}

// UI
export interface LayoutBuilderForm {
  name: string;
  layoutType: LayoutType;
  sceneDuration: number;
  applyTemplateStyles: boolean;
  assignToNewTemplates: boolean;
  timelines: LayoutTimeline[];
  aspectRatios: LayoutAspectRatio[];
}

export interface LayoutTimeline {
  id: string;
  type: TimelineType;
  title: string;
  hasAudio: boolean;
  zIndex?: number;
  pairId?: string;
  contentType?: TimelineContentType;
}

export interface LayoutAspectRatio {
  aspectRatioId: number;
  name: string;
  width: number;
  height: number;
  isDefault: boolean;
  aspectRatio: number;
  bounds: Bounds[];
}

// APIs
export interface WorkflowLayoutAspectRatioEntity {
  id: number;
  aspectRatioId: number;
  name: string;
  isDefault: boolean;
  width: number;
  height: number;
  aspectRatio: number;
  bounds: Bounds[];
  icon: string;
  iconPngUrl: string;
}

export interface WorkflowTemplateLayoutDto {
  id: number;
  layoutId: number;
  name: string;
  type: LayoutType;
  data: LayoutDto;
  aspectRatios: WorkflowLayoutAspectRatioEntity[];
}
