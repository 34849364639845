import { cloneDeep } from 'lodash';
import { TemplateSchemaTransformation } from '../../../interfaces';
import { TemplateWorkflowDataDto_1_1 } from './../template_transformation_1_1/schema_after';
import { TemplateWorkflowDataDto_1_2 } from './schema_after';

export const TemplateSchemaTransformation_1_2: TemplateSchemaTransformation = {
  version: '1.2',

  run(
    oldSchema: TemplateWorkflowDataDto_1_1.TemplateWorkflowDataDto
  ): TemplateWorkflowDataDto_1_2.TemplateWorkflowDataDto {
    const newSchema = cloneDeep(oldSchema) as TemplateWorkflowDataDto_1_2.TemplateWorkflowDataDto;
    for (const asset of newSchema.assets) {
      if ((asset as any).trims) {
        asset.textTrims = (asset as any).trims;
        delete (asset as any).trims;
      }
    }

    return newSchema;
  },
};
