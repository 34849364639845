export enum VideoMetadataType {
  SRT = 'srt',
  VTT = 'vtt',
  ASS = 'ass',
}

export const VideoMetadataExtension = {
  [VideoMetadataType.SRT]: 'srt',
  [VideoMetadataType.VTT]: 'vtt',
  [VideoMetadataType.ASS]: 'ass',
};
