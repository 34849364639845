import {
  AssetTranscript,
  AssetTranscriptWord,
  getAssetFinalTrims,
  getMaxTimelinesDuration,
  getSectionTimelines,
  getSortedSections,
  Layer,
  SectionTimeline,
  WorkflowDataDto,
} from '@openreel/creator/common';
import { cloneDeep, flatten, sortBy } from 'lodash';
import * as uuid from 'uuid';
import { CaptionEditorTimebox, CaptionWord } from './interfaces/caption.interface';
import { trimWords } from './trim-paragraphs';

export interface EditorTimeboxGeneratorConfigV2 {
  maxCharactersPerEditor: number;
  minCharactersPerEditor: number;
  minNewlineSentenceCharacters: number;
  maxEditorDuration: number;
  minEditorDuration: number;
  maxPauseInEditor: number;
  sentenceEndCharacters: string[];
  maxLookaheadWords: number;
}

const DEFAULT_CONFIG: EditorTimeboxGeneratorConfigV2 = {
  maxCharactersPerEditor: 80,
  minCharactersPerEditor: 40,
  minNewlineSentenceCharacters: 15,
  maxEditorDuration: 5000,
  minEditorDuration: 3500,
  maxPauseInEditor: 1000,
  sentenceEndCharacters: ['.', '?', '!'],
  maxLookaheadWords: 20,
};

export class CaptionsGenerator {
  private config: EditorTimeboxGeneratorConfigV2 = Object.assign({}, DEFAULT_CONFIG);
  private transcribables: {
    assetId: string;
    transcription: AssetTranscript;
    language: string;
  }[] = [];
  private workflow: WorkflowDataDto = null;

  constructor(
    workflow: WorkflowDataDto,
    transcribables: {
      assetId: string;
      transcription: AssetTranscript;
      language: string;
    }[],
    config?: Partial<EditorTimeboxGeneratorConfigV2>
  ) {
    this.workflow = workflow;
    this.transcribables = cloneDeep(transcribables);

    if (config) {
      this.setConfig(config);
    }
  }

  setConfig(config: Partial<EditorTimeboxGeneratorConfigV2>): void {
    Object.assign(this.config, config);
  }

  getEmptyTimebox(): CaptionEditorTimebox {
    return {
      id: uuid.v4(),
      text: '',
      start: 0,
      end: 0,
      words: [],
      speakerId: null,
    };
  }

  generateCaptions(): [string, CaptionEditorTimebox[]] {
    const sections = getSectionTimelines(this.workflow.sections, 'main', 'main');
    const sectionsTimelines = getSortedSections(sections, this.workflow).map((section) => ({
      timelines: section.timelines,
      duration: this.workflow.sections[section.sectionId].sectionDuration,
    }));

    let sectionOffset = 0;
    const words = sectionsTimelines
      .map(({ timelines }, index) => {
        if (index > 0) {
          sectionOffset += sectionsTimelines[index - 1].duration;
        }

        return this.getWordsForTimelines(timelines, sectionOffset);
      })
      .flat();

    const editorParagraphs = this.generateParagraphsFromLayersWords(words);
    const langauge = this.getFirstLanguage(sectionsTimelines.map((s) => s.timelines).flat());

    return [langauge, editorParagraphs];
  }

  private getWordsForTimelines(timelines: SectionTimeline[], offset: number): AssetTranscriptWord[][] {
    return this.getWordsForLayers(
      flatten(timelines.filter((timeline) => timeline.hasAudio).map((timeline) => timeline.layers)),
      offset
    );
  }

  private getWordsForLayers(layers: Layer[], offset: number): AssetTranscriptWord[][] {
    return layers.reduce((result, layer) => {
      if (layer.type !== 'video') {
        return result;
      }

      const asset = this.workflow.assets.find((ast) => ast.id === layer.assetId);
      const transcribable = this.transcribables.find((tr) => tr.assetId === asset.id);

      if (!transcribable?.transcription) {
        return result;
      }

      const transcriptWords = transcribable.transcription.words;
      const trims = getAssetFinalTrims(asset);

      const paragraphs = transcriptWords
        ? this.addWordsOffset(
            trimWords(transcriptWords, trims) as AssetTranscriptWord[],
            offset + (layer.visibility?.startAt ?? 0)
          )
        : [];

      result.push(paragraphs);

      return result;
    }, [] as AssetTranscriptWord[][]);
  }

  private addWordsOffset(words: AssetTranscriptWord[], offset: number): AssetTranscriptWord[] {
    for (const word of words) {
      word.start = word.start + offset;
      word.end = word.end + offset;
    }

    return words;
  }

  private generateParagraphsFromLayersWords(layersWords: AssetTranscriptWord[][]): CaptionEditorTimebox[] {
    const layersTimeboxes = layersWords.map((layerWords) => this.generateTimeboxesFromWords(layerWords));
    const mergedTimeboxes = sortBy(flatten(layersTimeboxes), (t) => t.start);

    return mergedTimeboxes;
  }

  private generateTimeboxesFromWords(words: AssetTranscriptWord[]): CaptionEditorTimebox[] {
    const timeboxes = [];

    let currentTimebox = this.getEmptyTimebox();

    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      const isNewline = word.type === 'newline';

      if (isNewline) {
        if (currentTimebox.words.length) {
          currentTimebox = this.getEmptyTimebox();
        }

        continue;
      }

      if (
        !this.canFeedTimebox(currentTimebox, word) ||
        !this.canFeedTimeboxLookahead(currentTimebox, word, words.slice(i + 1, i + 1 + this.config.maxLookaheadWords))
      ) {
        currentTimebox = this.getEmptyTimebox();
      }

      if (currentTimebox.words.length === 0) {
        timeboxes.push(currentTimebox);
      }
      this.feedTimebox(currentTimebox, word);
    }

    return timeboxes;
  }

  private canFeedTimebox(timebox: CaptionEditorTimebox, word: AssetTranscriptWord): boolean {
    if (!timebox.words.length) {
      return true;
    }

    if (word.speakerId !== timebox.speakerId) {
      return false;
    }

    if (word.type === 'mark') {
      return true;
    }

    if (word.start - timebox.end > this.config.maxPauseInEditor) {
      return false;
    }

    const previousWord = timebox.words[timebox.words.length - 1];

    if (
      this.isWordTheEndOfSentence(previousWord) &&
      (timebox.end - timebox.start > this.config.minEditorDuration ||
        timebox.text.length > this.config.minCharactersPerEditor)
    ) {
      return false;
    }

    if (timebox.text.length + word.text.length > this.config.maxCharactersPerEditor) {
      return false;
    }

    if (word.start - timebox.start > this.config.maxEditorDuration) {
      return false;
    }

    return true;
  }

  private canFeedTimeboxLookahead(
    timebox: CaptionEditorTimebox,
    word: AssetTranscriptWord,
    lookAheadWords: AssetTranscriptWord[]
  ): boolean {
    if (word.type === 'mark') {
      return true;
    }

    const sentenceEndIndex = lookAheadWords.findIndex((laWord) => this.isWordTheEndOfSentence(laWord));

    if (sentenceEndIndex === -1) {
      return true;
    }

    lookAheadWords = lookAheadWords.slice(0, sentenceEndIndex + 1);
    const charactersCount = lookAheadWords.reduce((length, laWord) => length + laWord.text.length + 1, 0);

    if (charactersCount > this.config.minNewlineSentenceCharacters) {
      return true;
    }

    const spaceLeftInTimebox = this.config.maxCharactersPerEditor - (timebox.text.length + word.text.length + 1);
    if (charactersCount < spaceLeftInTimebox) {
      return true;
    }

    return false;
  }

  private feedTimebox(timebox: CaptionEditorTimebox, word: AssetTranscriptWord): void {
    if (!timebox.speakerId) {
      timebox.speakerId = word.speakerId;
    }

    timebox.text += timebox.text && word.type === 'word' ? ` ${word.text}` : word.text;
    timebox.end = word.end;
    if (!timebox.words.length) {
      timebox.start = word.start;
    }

    timebox.words.push({
      text: word.text,
      start: word.start,
      end: word.end,
      confidence: word.confidence,
      speakerId: timebox.speakerId,
    });
  }

  private isWordTheEndOfSentence(word: CaptionWord | AssetTranscriptWord): boolean {
    return this.config.sentenceEndCharacters.includes(word.text[word.text.length - 1]);
  }

  private getFirstLanguage(timelines: SectionTimeline[]): string {
    for (const timeline of timelines) {
      if (!timeline.hasAudio) {
        continue;
      }

      for (const layer of timeline.layers) {
        if (layer.type !== 'video') {
          continue;
        }

        const asset = this.workflow.assets.find((ast) => ast.id === layer.assetId);
        const transcribable = this.transcribables.find((tr) => tr.assetId === asset.id);

        if (transcribable.language) {
          return transcribable.language;
        }
      }
    }

    return null;
  }
}
