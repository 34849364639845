import { cloneDeep } from 'lodash';
import { getLayers } from '../../../helpers';
import { WorkflowDataDto } from '../../../interfaces';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_1_2 } from '../transformation_1_2/schema_after';
import { WorkflowDataDto_1_3 } from './schema_after';

export const SchemaTransformation_1_3: SchemaTransformation = {
  version: '1.3',
  supportsPolyfill: false,

  run(oldSchema: WorkflowDataDto_1_2.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as WorkflowDataDto_1_3.WorkflowDataDto;
    for (const layerInfo of getLayers(newSchema as unknown as WorkflowDataDto)) {
      const layer = layerInfo.layer as WorkflowDataDto_1_3.Layer;
      if (layer.type !== 'lottie' || !layer.assetId) {
        continue;
      }

      const asset = newSchema.assets.find((a) => a.id === layer.assetId);
      if (asset) {
        layer.colorTags = asset.colorTags;
      }
    }
    return newSchema;
  },
};
