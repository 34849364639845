<h1 mat-dialog-title>
  {{ title }}
  <button mat-button class="dialog-close-button" style="float: right" (click)="closePopup()">
    <mat-icon color="primary">close</mat-icon>
  </button>
</h1>
<div mat-dialog-content fxLayout="column" fxLayoutGap="5px" class="dialog-content">
  <ng-container *ngIf="highlightWords?.length; else justText">
    <p [innerHtml]="fullText | highlight: highlightWords"></p>
  </ng-container>
  <ng-template #justText>
    <p>{{ fullText }}</p>
  </ng-template>
</div>
