import { cloneDeep } from 'lodash';
import {
  SchemaTransformation,
  SchemaTransformationOptions,
} from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_3_5 } from '../transformation_3_5/schema_after';
import { WorkflowDataDto_3_6 } from './schema_after';
import { v4 } from 'uuid';
import { getLayers } from '../../../helpers';

/*
  NOTE: Purpose of this transformation:
    - 1) add missing logo settings to older projects
    - 2) add missing background timelines + settings to older projects
    - 3) remove leftover watermark lotties
    - 4) add missing font weights
*/
export const SchemaTransformation_3_6: SchemaTransformation = {
  version: '3.6',
  supportsPolyfill: false,

  run(oldSchema: WorkflowDataDto_3_5.WorkflowDataDto, { template }: SchemaTransformationOptions) {
    const newSchema = cloneDeep(oldSchema) as unknown as WorkflowDataDto_3_6.WorkflowDataDto;
    const parsedTemplate = template as WorkflowDataDto_3_6.WorkflowDataDto;

    cleanupBackground(newSchema, parsedTemplate);
    cleanupLogo(newSchema, parsedTemplate);
    cleanupWatermarkLotties(newSchema);
    cleanupStyles(newSchema);
    cleanupAssets(newSchema);

    return newSchema;
  },
};

function cleanupBackground(
  newSchema: WorkflowDataDto_3_6.WorkflowDataDto,
  template: WorkflowDataDto_3_6.WorkflowDataDto
) {
  // Cleanup settings
  const hasProjectBackgroundVideoAsset = !!newSchema.globalSettings.backgroundVideo?.settings?.assetId;

  if (!newSchema.globalSettings.backgroundVideo) {
    newSchema.globalSettings.backgroundVideo = { ...template.globalSettings.backgroundVideo };
  }
  if (!newSchema.globalSettings.backgroundVideo.settings) {
    newSchema.globalSettings.backgroundVideo.settings = { ...template.globalSettings.backgroundVideo.settings };
  }

  if (!hasProjectBackgroundVideoAsset) {
    const templateBackgroundVideoAsset = template.assets.find(
      (a) => a.id === template.globalSettings.backgroundVideo.settings.assetId
    );

    newSchema.globalSettings.backgroundVideo.settings.assetId = templateBackgroundVideoAsset.id;
    newSchema.assets.push(templateBackgroundVideoAsset);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  delete (newSchema.globalSettings.backgroundVideo.settings as any).visible;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  delete (newSchema.globalSettings.backgroundVideo.settings as any).targetTags;

  // Cleanup timelines
  for (const [, section] of Object.entries(newSchema.sections)) {
    const existingBackgroundTimeline = section.timelines.find((t) => t.type === 'background');
    if (existingBackgroundTimeline && existingBackgroundTimeline.layers.length > 0) {
      continue;
    }

    // Add missing layer
    if (existingBackgroundTimeline) {
      const newStyle: WorkflowDataDto_3_6.Style = {
        id: v4(),
        color: (section.backgroundColor as WorkflowDataDto_3_6.SolidColor).color,
      };
      newSchema.styles.push(newStyle);

      existingBackgroundTimeline.layers.push({
        layerId: v4(),
        enabled: true,
        loop: true,
        type: 'color',
        bounds: { x: 0, y: 0, width: 100, height: 100 },
        styleId: newStyle.id,
        visibility: {
          startAt: 0,
        },
      });

      continue;
    }

    // Add missing timeline
    section.timelines = section.timelines.map((t) => ({ ...t, zIndex: t.zIndex + 1 }));
    section.timelines.push({
      type: 'background',
      title: '',
      hasAudio: false,
      layers: [],
      zIndex: 0,
    });
  }
}

function cleanupLogo(newSchema: WorkflowDataDto_3_6.WorkflowDataDto, template: WorkflowDataDto_3_6.WorkflowDataDto) {
  // Cleanup settings
  const hasProjectLogoVideoAsset = !!newSchema.globalSettings.logo?.settings?.assetId;

  if (!newSchema.globalSettings.logo) {
    newSchema.globalSettings.logo = { ...template.globalSettings.logo };
  }
  if (!newSchema.globalSettings.logo.settings) {
    newSchema.globalSettings.logo.settings = { ...template.globalSettings.logo.settings };
  }

  if (!hasProjectLogoVideoAsset) {
    const templatelogoAsset = template.assets.find((a) => a.id === template.globalSettings.logo.settings.assetId);

    newSchema.globalSettings.logo.settings.assetId = templatelogoAsset.id;
    newSchema.assets.push(templatelogoAsset);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  delete (newSchema.globalSettings.backgroundVideo.settings as any).visible;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  delete (newSchema.globalSettings.backgroundVideo.settings as any).targetTags;
}

function cleanupWatermarkLotties(newSchema: WorkflowDataDto_3_6.WorkflowDataDto) {
  for (const [, section] of Object.entries(newSchema.sections)) {
    const watermarkTimeline = section.timelines.find((t) => t.type === 'watermark');
    if (!watermarkTimeline) {
      continue;
    }

    if (watermarkTimeline.layers.length === 0) {
      continue;
    }

    if (watermarkTimeline.layers[0].type === 'lottie') {
      watermarkTimeline.layers = [];
    }
  }
}

function cleanupStyles(newSchema: WorkflowDataDto_3_6.WorkflowDataDto) {
  for (const { layer } of getLayers(newSchema as any)) {
    if (typeof layer.styles?.backgroundColor === 'string') {
      layer.styles.backgroundColor = {
        type: 'solid',
        color: layer.styles.backgroundColor,
      };
    }

    if (layer.type !== 'lottie') {
      continue;
    }

    for (const [, field] of Object.entries(layer.data)) {
      if (field.type !== 'text') {
        continue;
      }

      const style = newSchema.styles.find((s) => s.id === field.styleId);
      if (!style) {
        continue;
      }

      if (!style.fontWeight) {
        style.fontWeight = 400;
      }
    }
  }
}

function cleanupAssets(newSchema: WorkflowDataDto_3_6.WorkflowDataDto) {
  for (const asset of newSchema.assets) {
    if (!asset.preset) {
      continue;
    }

    for (const [, field] of Object.entries(asset.preset)) {
      if (typeof field.validationRules?.required === 'string') {
        field.validationRules.required = ['true', 'required'].includes(field.validationRules.required);
      }
    }
  }
}
