import { cloneDeep } from 'lodash';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_3_8 } from '../transformation_3_8/schema_after';
import { getLayers, pctToPx } from '../../../helpers';

/*
  NOTE: Purpose of this transformation:
    - 1) transform all text effect valuesto be relative to MIN(X_AXIS, Y_AXIS) instead of being absolute pixels
    - 2) convert font size to be relative to MIN(X_AXIS, Y_AXIS) instead of being relative to height
*/
export const SchemaTransformation_4_0: SchemaTransformation = {
  version: '4.0',
  supportsPolyfill: false,

  run(oldSchema: WorkflowDataDto_3_8.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as unknown as WorkflowDataDto_3_8.WorkflowDataDto;
    const referencePx = Math.min(newSchema.globalSettings.resolution.width, newSchema.globalSettings.resolution.height);

    for (const { layer } of getLayers(newSchema as never)) {
      if (layer.type === 'lottie' && layer.isTextBox) {
        switch (layer.textEffect?.type) {
          case 'echo':
            layer.textEffect.offset = (layer.textEffect.offset / referencePx) * 100;
            break;
          case 'outline':
            layer.textEffect.thickness = (layer.textEffect.thickness / referencePx) * 100;
            break;
          case 'splice':
            layer.textEffect.thickness = (layer.textEffect.thickness / referencePx) * 100;
            layer.textEffect.offset = (layer.textEffect.offset / referencePx) * 100;
            break;
          case 'hollow':
            layer.textEffect.thickness = (layer.textEffect.thickness / referencePx) * 100;
            break;
          case 'glow':
            layer.textEffect.blur = (layer.textEffect.blur / referencePx) * 100;
            break;
          case 'glitch':
            layer.textEffect.offset = (layer.textEffect.offset / referencePx) * 100;
            break;
          case 'shadow':
            layer.textEffect.blur = (layer.textEffect.blur / referencePx) * 100;
            layer.textEffect.offset = (layer.textEffect.offset / referencePx) * 100;
            break;
        }
      }
    }

    for (let i = 0; i < newSchema.styles.length; i++) {
      if (newSchema.styles[i].fontSize) {
        const fontSizePx = pctToPx(newSchema.styles[i].fontSize, newSchema.globalSettings.resolution.height);
        newSchema.styles[i].fontSize = (fontSizePx / referencePx) * 100;
      }
    }

    return newSchema;
  },
};
