import { cloneDeep } from 'lodash';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_4_4 } from '../transformation_4_4/schema_after';
import { WorkflowDataDto_4_5 } from './schema_after';
import { v4 as uuidv4 } from 'uuid';

/*
  NOTE: Purpose of this transformation:
    - 1) add default globalsoundtrack settings
*/
export const SchemaTransformation_4_5: SchemaTransformation = {
  version: '4.5',
  supportsPolyfill: false,

  run(oldSchema: WorkflowDataDto_4_4.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as unknown as WorkflowDataDto_4_5.WorkflowDataDto;

    const asset: WorkflowDataDto_4_5.Asset = {
      id: uuidv4(),
      type: 'audio',
      isGlobal: true,
    };
    newSchema.assets.push(asset);

    newSchema.globalSettings.soundtrack = {
      introVolume: 80,
      mainVolume: 20,
      outroVolume: 80,
      assetId: asset.id,
    };

    return newSchema;
  },
};
