import { File } from '../interfaces';
import { isEqual } from 'lodash';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export interface UpdateWatermarkEvent {
  assetFile: File;
  sectionId?: string;
}

export class UpdateWatermarkCommand extends WorkflowBaseBuilder<UpdateWatermarkEvent> {
  run({ assetFile, sectionId }: UpdateWatermarkEvent) {
    const isGlobalUpdate = sectionId === null || sectionId === undefined || sectionId === '';
    const isRemove = !assetFile;
    const globalAssetId = this.source.globalSettings.watermark.settings.assetId;

    if (isGlobalUpdate) {
      if (isRemove) {
        this.removeGlobalWatermark();
        this.updateWatermarkSection(null, null);
      } else {
        this.setGlobalWatermark(assetFile);
        this.updateWatermarkSection(null, globalAssetId);
      }
    } else {
      if (isRemove) {
        this.updateWatermarkSection(sectionId, null);
      } else {
        const globalAssetId = this.source.globalSettings.watermark.settings.assetId;
        const globalAsset = this.getAsset(globalAssetId);
        if (isEqual(globalAsset.file, assetFile)) {
          this.updateWatermarkSection(sectionId, globalAssetId);
        } else {
          const newAsset = this.addAsset(assetFile.path, 'image');
          this.updateWatermarkSection(sectionId, newAsset.id);
        }
      }
    }
    return this.ok();
  }
}
