/* eslint-disable @typescript-eslint/no-explicit-any */
import { cloneDeep } from 'lodash';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_0_6 } from '../transformation_0_6/schema_after';
import { WorkflowDataDto_0_7 } from '../transformation_0_7/schema_after';
import { v4 as uuidv4 } from 'uuid';

export const SchemaTransformation_0_7: SchemaTransformation = {
  version: '0.7',
  supportsPolyfill: false,

  run(oldSchema: WorkflowDataDto_0_6.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as unknown as WorkflowDataDto_0_7.WorkflowDataDto;

    if (!oldSchema.features.textOverlays || oldSchema.features.textOverlays.length === 0) {
      newSchema.globalSettings.textOverlays = null;
      return newSchema;
    }

    const firstTextOverlay = oldSchema.features.textOverlays[0];
    let textOverlayAssetId = null;

    if (firstTextOverlay.properties.type === 'lottie') {
      // Lottie
      textOverlayAssetId = firstTextOverlay.properties.assetId;
      for (const [, section] of Object.entries(newSchema.sections).filter(
        ([, section]) => section.sectionType === 'main'
      )) {
        const textTimeline = section.timelines.find((t) => t.type === 'overlays');
        if (textTimeline) {
          textTimeline.bounds = firstTextOverlay.properties.bounds;
        }
      }
    } else if (firstTextOverlay.properties.type === 'sublayers') {
      // Sublayers
      textOverlayAssetId = firstTextOverlay.properties.items[0].assetId;
      for (const [, section] of Object.entries(newSchema.sections).filter(
        ([, section]) => section.sectionType === 'main'
      )) {
        const textTimeline = section.timelines.find((t) => t.type === 'overlays');
        if (!textTimeline) {
          continue;
        }

        // Add lottie overlay timelines
        const newTimelines = firstTextOverlay.properties.items.map(
          (sublayer) =>
            ({
              id: uuidv4(),
              type: 'overlays',
              layers: [],
              bounds: sublayer.bounds,
              isLayerSelectable: true,
            } as WorkflowDataDto_0_7.Timeline)
        );

        section.timelines.push(...newTimelines);

        // Remove sublayers text overlay
        section.timelines = section.timelines.filter((t) => t.id !== textTimeline.id);

        // Fix timeline defs
        newSchema.features.timelines = newSchema.features.timelines.filter((t) => t.id !== textTimeline.id);
        newSchema.features.timelines.push(
          ...newTimelines.map(({ id }): WorkflowDataDto_0_7.TimelineDef => ({ id, type: 'overlays' }))
        );

        // Disable text overlay widget
        if (newSchema.features?.wizard?.textOverlays) {
          newSchema.features.wizard.textOverlays.enabled = false;
        }
      }
    }

    newSchema.globalSettings.textOverlays = {
      assetId: textOverlayAssetId,
    };

    if (newSchema.features?.wizard?.textOverlays as any) {
      delete (newSchema.features.wizard.textOverlays as any).timelineId;
    }

    return newSchema;
  },
};
