import { cloneDeep } from 'lodash';
import { WorkflowDataDto } from '../interfaces';

export function prepareWorkflowForTrimmer(sourceWorkflow: WorkflowDataDto, selectedSectionId?: string) {
  let workflow = cloneDeep(sourceWorkflow);

  const soundtrackAsset = workflow.assets.find((a) => a.id === workflow.globalSettings.soundtrack.assetId);
  if (soundtrackAsset) {
    delete soundtrackAsset.file;
  }

  if (selectedSectionId) {
    workflow = keepOnlySection(workflow, selectedSectionId);

    workflow.sections[selectedSectionId].timelines = workflow.sections[selectedSectionId].timelines
      .filter((timeline) => timeline.type === 'main' || timeline.type === 'background')
      .map((timeline) =>
        timeline.type === 'background'
          ? timeline
          : {
              ...timeline,
              layers: [],
            }
      );
  }

  return workflow;
}

export function keepOnlySection(sourceWorkflow: WorkflowDataDto, sectionId: string) {
  const workflow = cloneDeep(sourceWorkflow);

  const mainTimeline = workflow.timelines.find((t) => t.type === 'main');
  mainTimeline.layers = mainTimeline.layers
    .filter((layer) => layer.type === 'section' && layer.sectionId === sectionId)
    .map((layer) => ({
      ...layer,
      transitions: null,
    }));

  workflow.sections = {
    [sectionId]: workflow.sections[sectionId],
  };

  return workflow;
}
