import { cloneDeep } from 'lodash';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_3_8 } from '../transformation_3_8/schema_after';
import { getLayers, pctToPx } from '../../../helpers';
import { TEXT_BOX_PRESET_FIELD_NAME } from '../../../constants';

/*
  NOTE: Purpose of this transformation:
    - 1) transform all text effect valuesto be relative to MIN(X_AXIS, Y_AXIS) instead of being absolute pixels
    - 2) convert font size to be relative to MIN(X_AXIS, Y_AXIS) instead of being relative to height
*/
export const SchemaTransformation_4_1: SchemaTransformation = {
  version: '4.1',
  supportsPolyfill: false,

  run(oldSchema: WorkflowDataDto_3_8.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as unknown as WorkflowDataDto_3_8.WorkflowDataDto;
    const referencePx = Math.min(newSchema.globalSettings.resolution.width, newSchema.globalSettings.resolution.height);

    for (const { layer } of getLayers(newSchema as never)) {
      if (layer.type === 'lottie' && layer.isTextBox) {
        const style = newSchema.styles.find((s) => s.id === layer.data[TEXT_BOX_PRESET_FIELD_NAME].styleId);
        const fontSizePx = pctToPx(style.fontSize, newSchema.globalSettings.resolution.height);

        switch (layer.textEffect?.type) {
          case 'echo':
          case 'glitch':
            layer.textEffect.offset = pctToPx(layer.textEffect.offset, referencePx);
            layer.textEffect.offset = (layer.textEffect.offset / fontSizePx) * 100;
            break;
          case 'outline':
          case 'hollow':
            layer.textEffect.thickness = pctToPx(layer.textEffect.thickness, referencePx);
            layer.textEffect.thickness = (layer.textEffect.thickness / fontSizePx) * 100;
            break;
          case 'splice':
            layer.textEffect.thickness = pctToPx(layer.textEffect.thickness, referencePx);
            layer.textEffect.thickness = (layer.textEffect.thickness / fontSizePx) * 100;
            layer.textEffect.offset = pctToPx(layer.textEffect.offset, referencePx);
            layer.textEffect.offset = (layer.textEffect.offset / fontSizePx) * 100;
            break;
          case 'glow':
            layer.textEffect.blur = pctToPx(layer.textEffect.blur, referencePx);
            layer.textEffect.blur = (layer.textEffect.blur / fontSizePx) * 100;
            break;
          case 'shadow':
            layer.textEffect.blur = pctToPx(layer.textEffect.blur, referencePx);
            layer.textEffect.blur = (layer.textEffect.blur / fontSizePx) * 100;
            layer.textEffect.offset = pctToPx(layer.textEffect.offset, referencePx);
            layer.textEffect.offset = (layer.textEffect.offset / fontSizePx) * 100;
            break;
        }
      }
    }

    return newSchema;
  },
};
