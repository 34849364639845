import { WorkflowDataDto, WorkflowTemplateLayoutDto } from '@openreel/creator/common';
import { FontDto } from '@openreel/common';
import { AspectRatioEntity, WorkflowLayoutEntity } from '../layouts/workflow-layouts.interfaces';
import { WorkflowTagEntity } from '../tags/workflow-tags.interface';
import { Soundtrack } from '../soundtracks/soundtracks.interfaces';
import { CreateAssetRequest } from '../assets/assets.interfaces';

export interface DefaultVideoAssetEntity {
  fileId: number;
  duration: number;
}

export interface DefaultVideoPlaceholderAsset {
  assetFileId: number;
  duration: number;
}

export interface TemplateBuilderLayoutMetadataEntity {
  coverImageAssetId?: number;
  sampleVideoAssetId?: number;
}

export interface TemplateBuilderListEntity {
  id: number;
  name: string;
  schemaVersion: string;
  template: WorkflowDataDto;
  isOpenreelTemplate: boolean;
  isBlueprint: boolean;
  projectsCount?: number;
  tags: WorkflowTagEntity[];
  updatedAt: Date;
  createdAt: Date;
}

export interface TemplateBuilderEntity {
  id: number;
  name: string;
  isOpenreelTemplate: boolean;
  isBlueprint: boolean;
  schemaVersion: string;
  template: WorkflowDataDto;
  applyBrandKitLogo: boolean;
  applyBrandKitWatermark: boolean;
  applyBrandKitBackgroundVideo: boolean;
  applyBrandKitSoundtrack: boolean;
  defaultAspectRatioId: number;
  layoutsGlobal: WorkflowLayoutEntity[];
  layoutsTemplate: WorkflowLayoutEntity[];
  layoutsSelected: WorkflowTemplateLayoutDto[];
  defaultSimpleLayoutId: number;
  defaultInterviewLayoutId: number;
  defaultPresentationLayoutId: number;
  simpleLayoutMetadata: TemplateBuilderLayoutMetadataEntity;
  interviewLayoutMetadata: TemplateBuilderLayoutMetadataEntity;
  presentationLayoutMetadata: TemplateBuilderLayoutMetadataEntity;
  fonts: FontDto[];
  defaultVideoAsset: DefaultVideoAssetEntity;
  aspectRatios: AspectRatioEntity[];
  tags: WorkflowTagEntity[];
  customField0: string;
  customField1: string;
  customField2: string;
  soundtrackId: number;
  soundtracks: Soundtrack[];
}

export class UpdateTemplateBuilderLayoutTypeRequest {
  defaultLayoutId?: number;
  coverImageAssetId?: number;
  sampleVideoAssetId?: number;
}

export interface UpdateTemplateBuilderRequest {
  name: string;
  isOpenreelTemplate: boolean;
  isBlueprint: boolean;
  defaultAspectRatioId: number;
  template: WorkflowDataDto;
  applyBrandKitLogo: boolean;
  applyBrandKitWatermark: boolean;
  applyBrandKitBackgroundVideo: boolean;
  applyBrandKitSoundtrack: boolean;
  selectedLayoutIds: number[];
  simpleLayout: UpdateTemplateBuilderLayoutTypeRequest;
  interviewLayout: UpdateTemplateBuilderLayoutTypeRequest;
  presentationLayout: UpdateTemplateBuilderLayoutTypeRequest;
  selectedTagIds: number[];
  customField0: string;
  customField1: string;
  customField2: string;
  soundtrackId: number;
}

export interface UpdateTemplateBlueprintRequest {
  workflow: WorkflowDataDto;
  aspectRatioId?: number;
}

export interface CreateTemplateAssetRequest extends CreateAssetRequest {
  templateId: number;
}
