import { cloneDeep } from 'lodash';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_1_5 } from '../transformation_1_5/schema_after';
import { WorkflowDataDto_1_6 } from './schema_after';

/*
  NOTE: Purpose of this transformation:
    - remove freemium timeline from scenes as its no longer in use
*/
export const SchemaTransformation_1_6: SchemaTransformation = {
  version: '1.6',
  supportsPolyfill: false,

  run(oldSchema: WorkflowDataDto_1_5.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as WorkflowDataDto_1_6.WorkflowDataDto;

    for (const [, section] of Object.entries(newSchema.sections)) {
      section.timelines = section.timelines.filter((t) => (t.type as string) !== 'freemium');
    }

    return newSchema;
  },
};
