import { cloneDeep } from 'lodash';
import { SectionType, WorkflowDataDto } from '../interfaces';

export function prepareWorkflowForIntroOutro(sourceWorkflow: WorkflowDataDto, isIntro: boolean) {
  const workflow = cloneDeep(sourceWorkflow);

  // Remove soundtrack
  const soundtrackAsset = workflow.assets.find((a) => a.id === workflow.globalSettings.soundtrack.assetId);
  delete soundtrackAsset.file;

  // Remove all but one section + enable it if its disabled + remove transition if there
  const sectionType: SectionType = isIntro ? 'intro' : 'outro';
  const mainTimeline = workflow.timelines.find((t) => t.type === 'main');
  mainTimeline.layers = mainTimeline.layers
    .filter((l) => l.type === 'section' && workflow.sections[l.sectionId].sectionType === sectionType)
    .map((layer) => ({
      ...layer,
      transitions: null,
      enabled: true,
    }));

  return workflow;
}
