<div *ngIf="!listViewEnabled" class="recordings-list">
  <ng-container
    *ngFor="
      let group of paginatedGroups | async;
      let i = index;
      let len = count;
      trackBy: trackGroupsByFn
    "
  >
    <ng-container
      *ngFor="let recording of group.recordings; trackBy: trackRecordingsByFn"
    >
      <openreel-recording-item
        [prefix]="i + 1"
        [canManipulate]="canManipulate"
        [recording]="recording"
        [selectionEnabled]="selectionEnabled"
        (onItemSelect)="onRecordingSelect.emit()"
        (onRestoreClick)="onRecordingRestoreClicked(recording)"
        (onArchiveClick)="onRecordingArchiveClicked(recording)"
        (onDeleteClick)="onRecordingDeleteClicked(recording)"
        (onAudioExtractClick)="onRecordingAudioExtractClicked($event)"
        (onAudioDownloadClick)="onRecordingAudioDownloadClicked($event)"
        (onTranscodeClick)="onRecordingTranscodeClicked(recording, $event)"
        (onDownloadClick)="onRecordingDownloadClicked(recording, $event)"
        (onCopyClick)="onRecordingCopyClicked(recording, $event)"
        (onFavouriteClick)="onRecordingFavouriteClicked(recording)"
        (onPlayClick)="onRecordingPlayClicked(recording, $event)"
        (onUploadClick)="onRecordingUploadClicked(recording)"
        (onUploadCancelClick)="onRecordingUploadCancelClicked(recording)"
        (onTogglePauseUploadClick)="onTogglePauseUpload(recording, $event)"
        (onCommentShowClick)="onCommentShowClicked($event)"
        (onTranscriptShowClick)="onTranscriptShowClicked($event)"
      >
      </openreel-recording-item>
    </ng-container>
  </ng-container>
</div>

<div *ngIf="listViewEnabled">
  <mat-expansion-panel
    class="recording-item"
    *ngFor="
      let group of paginatedGroups | async;
      let i = index;
      let len = count;
      trackBy: trackGroupsByFn
    "
  >
    <mat-expansion-panel-header>
      <div fxFlexFill fxLayout.lt-lg="column">
        <mat-panel-title
          fxFlex="100"
          fxLayoutAlign="start center"
          fxLayoutGap="15px"
        >
          <mat-icon
            *ngIf="selectionEnabled"
            svgIcon="check-round"
            class="selection-icon"
            (click)="toggleGroupSelection(group, $event)"
            [ngClass]="{ selected: isGroupSelected(group) }"
          ></mat-icon>
          <img
            class="recording-item-thumbnail"
            [src]="group.recordings[0]?.thumb"
            openreelFallback="assets/common/images/placeholder.jpg"
            alt="thumb-nail"
          />
          <span>
            {{ group.name ? group.name + ' ' : '' }}
            {{ group.created_at | date }}
          </span>
        </mat-panel-title>
        <mat-panel-description
          fxFlex="100"
          fxLayout="row"
          fxLayoutAlign="end center"
          class="recording-details-wrapper"
        >
          <ng-container
            *ngIf="
              (group.recordings | groupTitleClip).status ==
              VideoStatus.RECORDING
            "
          >
            <div
              class="video-status status-recording"
              fxFlex="30"
              fxFlex.xs="100"
            >
              Recording
            </div>
            <mat-progress-bar
              *ngIf="
                (group.recordings | groupTitleClip).uploadDuringRecording &&
                !(group.recordings | groupTitleClip).uploadPaused
              "
              class="uploading-progress-bar"
              mode="indeterminate"
            ></mat-progress-bar>
          </ng-container>
          <ng-container
            *ngIf="
              (group.recordings | groupTitleClip).status == VideoStatus.RECORDED
            "
          >
            <div
              class="video-status status-uploading"
              fxFlex="40"
              fxFlex.xs="100"
            >
              Please Upload
            </div>
          </ng-container>
          <ng-container
            *ngIf="
              (group.recordings | groupTitleClip).status ==
              VideoStatus.UPLOADING
            "
          >
            <div
              class="video-status status-uploading"
              fxFlex="30"
              fxFlex.xs="100"
            >
              Uploading
            </div>
            <mat-progress-bar
              class="uploading-progress-bar"
              mode="determinate"
              [value]="(group.recordings | groupTitleClip).upload_percentage"
            ></mat-progress-bar>
            <p
              *ngIf="
                (group.recordings | groupTitleClip).upload_percentage &&
                !(group.recordings | groupTitleClip).uploadDuringRecording
              "
            >
              {{
                (group.recordings | groupTitleClip).upload_percentage
                  | number: '1.0-0'
              }}%
            </p>
          </ng-container>
          <ng-container
            *ngIf="
              (group.recordings | groupTitleClip).status ==
                VideoStatus.UPLOADED &&
              !(group.recordings | groupTitleClip).archived_at
            "
          >
            <div
              class="video-status"
              [ngClass]="[
                'status-transcoding-' +
                  (group.recordings | groupTitleClip).video_status
              ]"
              fxFlex="30"
              fxFlex.xs="100"
            >
              {{ (group.recordings | groupTitleClip).video_status | titlecase }}
            </div>
          </ng-container>
          <ng-container
            *ngIf="group.recordings[0].s3_integrated == binaryStringFields.true"
          >
            <div
              *ngIf="
                group.recordings[0].file_to_keep == binaryStringFields.true
              "
              style="width: 58px"
            >
              <img
                openreelfallback="assets/common/images/logo.png"
                style="width: 19px; vertical-align: middle"
                src="assets/common/images/logo.png"
                alt="OpenReel"
              />
              +
              <h2
                style="
                  color: lightslategrey;
                  vertical-align: middle;
                  display: inline;
                  font-size: 17px;
                "
              >
                S3
              </h2>
            </div>
            <div
              *ngIf="
                group.recordings[0].file_to_keep == binaryStringFields.false
              "
            >
              <h2 style="color: lightslategrey; font-size: 17px">S3</h2>
            </div>
          </ng-container>
          <span class="video-count-wrapper">
            <mat-icon svgIcon="video-camera"></mat-icon>
            <span class="video-count">{{ group.recordings.length }}</span>
          </span>
        </mat-panel-description>
      </div>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
      <div class="recordings-wrapper">
        <ng-container
          *ngFor="
            let recording of group.recordings;
            trackBy: trackRecordingsByFn
          "
        >
          <openreel-recording-item
            [canManipulate]="canManipulate"
            [recording]="recording"
            [selectionEnabled]="selectionEnabled"
            (onRestoreClick)="onRecordingRestoreClicked(recording)"
            (onArchiveClick)="onRecordingArchiveClicked(recording)"
            (onDeleteClick)="onRecordingDeleteClicked(recording)"
            (onTranscodeClick)="onRecordingTranscodeClicked(recording, $event)"
            (onItemSelect)="onRecordingSelect.emit()"
            (onTranscriptClick)="onRecordingTranscriptClicked($event)"
            (onTranscriptShowClick)="onTranscriptShowClicked($event)"
            (onCommentShowClick)="onCommentShowClicked($event)"
            (onDownloadClick)="onRecordingDownloadClicked(recording, $event)"
            (onCopyClick)="onRecordingCopyClicked(recording, $event)"
            (onFavouriteClick)="onRecordingFavouriteClicked(recording)"
            (onPlayClick)="onRecordingPlayClicked(recording, $event)"
            (onUploadClick)="onRecordingUploadClicked(recording)"
            (onUploadCancelClick)="onRecordingUploadCancelClicked(recording)"
            (onTogglePauseUploadClick)="onTogglePauseUpload(recording, $event)"
          >
          </openreel-recording-item>
        </ng-container>
      </div>
    </ng-template>
  </mat-expansion-panel>
</div>

<mat-paginator
  *ngIf="enablePagination"
  itemsPerPageLabel="Clip Groups Per Page"
  [length]="(recordingGroups | async).length"
  [pageSize]="slicer"
  [pageSizeOptions]="[5, 10, 25]"
  (page)="onPaginateChange($event)"
>
</mat-paginator>
