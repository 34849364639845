export enum AllRoles {
  Director = 'admin',
  Collaborator = 'operator',
  Watcher = 'watcher',
  Subject = 'subject',
  RootAdmin = 'root_admin',
}

export const PROJECT_ROLES = [AllRoles.Director];
export const SESSION_ROLES = [AllRoles.Director, AllRoles.Collaborator, AllRoles.Watcher];

export enum SessionRoles {
  Director = AllRoles.Director,
  Collaborator = AllRoles.Collaborator,
  Watcher = AllRoles.Watcher,
  Subject = AllRoles.Subject,
}