import { cloneDeep } from 'lodash';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_4_7 } from '../transformation_4_7/schema_after';
import { WorkflowDataDto_4_8 } from './schema_after';

/*
  NOTE: Purpose of this transformation:
    - 1) rename backgroundVideo to backgroundAsset
*/
export const SchemaTransformation_4_8: SchemaTransformation = {
  version: '4.8',
  supportsPolyfill: false,

  run(oldSchema: WorkflowDataDto_4_7.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as unknown as WorkflowDataDto_4_8.WorkflowDataDto;

    newSchema.globalSettings.backgroundAsset = oldSchema.globalSettings.backgroundVideo;
    delete (newSchema.globalSettings as any).backgroundVideo;

    return newSchema;
  },
};
