import { NumberRange } from '@openreel/common';
import { AssetTranscriptTrimmerWord, AssetTranscriptWord } from '@openreel/creator/common';
import { last } from 'lodash';
import { getTextCutToken } from './utils/word.utils';

// eslint-disable-next-line max-lines-per-function
export function trimWords(
  words: AssetTranscriptWord[],
  trims: NumberRange[],
  options?: {
    offset?: number;
    assetId?: string;
    assetDuration?: number;
    sectionId?: string;
    addCutTokens?: boolean;
    trimRange?: NumberRange;
  }
): AssetTranscriptTrimmerWord[] {
  // The algorithm assumes that the time-orders of words and trims are ascending,
  // and the trims do not overlap
  const result: AssetTranscriptTrimmerWord[] = [];

  const textStart = options?.trimRange?.from ?? 0;
  const textEnd = options?.trimRange?.to ?? options?.assetDuration ?? 0;

  if (!trims?.length && options?.addCutTokens) {
    result.push(
      getTextCutToken(
        words,
        { from: textStart, to: textEnd },
        { assetId: options.assetId, sectionId: options.sectionId, uiStart: 0 }
      )
    );
    addOffsetToWords(result, options?.offset);

    return result;
  }

  if (!words) {
    return result;
  }

  let prevTrimEndAt = textStart;
  let prevEndIndex = -1;
  let totalSkippedDuration = textStart;

  for (const trim of trims) {
    const currentSkippedDuration = trim.from - prevTrimEndAt;

    const lastWord = last(result);
    if (lastWord) {
      if (lastWord.end + totalSkippedDuration >= trim.from) {
        lastWord.end -= currentSkippedDuration;
      } else {
        lastWord.end = Math.min(lastWord.end, prevTrimEndAt - totalSkippedDuration);
      }
    }

    const { startIndex, endIndex } = searchWordIndicesInRange(words, trim, prevEndIndex + 1);

    if (startIndex > 0 && startIndex - prevEndIndex > 1) {
      addSkippedNewLinesToTrimmedWords(
        words,
        result,
        { from: prevEndIndex + 1, to: startIndex - 1 },
        options?.assetId,
        options?.sectionId
      );
    }

    if (options?.addCutTokens) {
      const cutStart = prevTrimEndAt;
      const cutEnd = trim.from;

      if (cutEnd - cutStart > 0) {
        result.push(
          getTextCutToken(
            words,
            { from: cutStart, to: cutEnd },
            {
              assetId: options.assetId,
              sectionId: options.sectionId,
              uiStart: cutStart - totalSkippedDuration,
            }
          )
        );
      }
    }

    totalSkippedDuration += currentSkippedDuration;
    prevTrimEndAt = trim.to;

    if (startIndex !== -1) {
      const wordsSlice: AssetTranscriptTrimmerWord[] = words.slice(startIndex, endIndex + 1).map((word, index) => ({
        ...word,
        start: Math.max(word.start - totalSkippedDuration + Math.max(trim.from - word.start, 0), 0),
        end: word.end - totalSkippedDuration,
        assetId: options?.assetId ?? null,
        sectionId: options?.sectionId ?? null,
        originalIndex: startIndex + index,
        originalStart: word.start,
        originalEnd: word.end,
      }));

      result.push(...wordsSlice);
      prevEndIndex = endIndex;
    }
  }

  normalizeNewLines(result);

  const lastWord = last(result);
  const lastTrim = last(trims);
  if (lastWord) {
    lastWord.end = Math.min(lastWord.end, lastTrim.to - totalSkippedDuration);
  }

  if (options?.addCutTokens) {
    const cutStart = lastTrim.to;
    const cutEnd = textEnd;

    if (cutEnd - cutStart > 0) {
      result.push(
        getTextCutToken(
          words,
          { from: cutStart, to: cutEnd },
          {
            assetId: options.assetId,
            sectionId: options.sectionId,
            uiStart: Math.max(lastTrim.to - totalSkippedDuration),
          }
        )
      );
    }
  }

  addOffsetToWords(result, options?.offset);

  return result;
}

function addOffsetToWords(words: (AssetTranscriptWord | AssetTranscriptTrimmerWord)[], offset: number) {
  if (!offset) {
    return;
  }

  words.forEach((word) => {
    word.start += offset;
    word.end += offset;
  });
}

export function searchWordIndicesInRange(words: AssetTranscriptWord[], range: NumberRange, searchFrom = 0) {
  const firstWord = words[searchFrom];
  const lastWord = last(words);

  if (!firstWord) {
    return { startIndex: -1, endIndex: -1, done: true };
  }

  // Range is out of the words slice
  if (range.to < firstWord.start || range.from > lastWord.end) {
    return { startIndex: -1, endIndex: -1, done: false };
  }

  let startIndex = searchFrom;
  let endIndex = words.length - 1;
  let startFound = false;
  let endFound = false;
  let done = false;
  let pivot: number;

  if (range.from < firstWord.end) {
    pivot = startIndex;
    startFound = true;
  }
  if (range.to > lastWord.start) {
    pivot = endIndex;
    endFound = true;
    done = true;
  }

  if (startFound && endFound) {
    return { startIndex, endIndex, done };
  }

  let start: number, end: number;
  if (!startFound && !endFound) {
    // Find a word index (pivot) which intersects the trim using "Binary Search" algorithm
    for (
      start = startIndex, end = endIndex, pivot = Math.floor((startIndex + endIndex) / 2);
      start <= end;
      pivot = Math.floor((start + end) / 2)
    ) {
      if (range.from >= words[pivot].end) {
        start = pivot + 1;
        continue;
      }

      if (range.to <= words[pivot].start) {
        end = pivot - 1;
        continue;
      }

      break;
    }

    // No word found in the range
    if (start > end) {
      return { startIndex: -1, endIndex: -1, done: false };
    }
  }

  if (!startFound) {
    startIndex = findWordsRangeStartInTrim(words, range, startIndex, pivot);
  }

  if (!endFound) {
    endIndex = findWordsRangeEndInTrim(words, range, endIndex, pivot);
  }

  return { startIndex, endIndex, done };
}

// Find the smallest word index intresecting the trim using Binary search
function findWordsRangeStartInTrim(
  words: AssetTranscriptWord[],
  trimRange: NumberRange,
  startIndex: number,
  rangePivotIndex: number
) {
  let start: number, end: number, index: number;

  for (
    start = startIndex, end = rangePivotIndex, index = Math.floor((start + end) / 2);
    start <= end;
    index = Math.floor((start + end) / 2)
  ) {
    if (trimRange.from >= words[index].end) {
      start = index + 1;
      continue;
    }

    if (start === end) {
      index = end;
      break;
    }

    end = index;
  }

  return index;
}

// Find the biggest word index intresecting the trim using Binary search
function findWordsRangeEndInTrim(
  words: AssetTranscriptWord[],
  trimRange: NumberRange,
  endIndex: number,
  rangePivotIndex: number
) {
  let start: number, end: number, index: number;

  for (
    start = rangePivotIndex, end = endIndex, index = Math.ceil((start + end) / 2);
    start <= end;
    index = Math.ceil((start + end) / 2)
  ) {
    if (trimRange.to <= words[index].start) {
      end = index - 1;
      continue;
    }

    if (start === end) {
      index = start;
      break;
    }

    start = index;
  }

  return index;
}

function addSkippedNewLinesToTrimmedWords(
  words: AssetTranscriptWord[],
  result: AssetTranscriptTrimmerWord[],
  skippedIndexRange: NumberRange,
  assetId?: string,
  sectionId?: string
) {
  const newLines = getNewLinesInRange(words, skippedIndexRange);
  for (const { word, index } of newLines) {
    result.push({
      ...word,
      start: last(result)?.end ?? 0,
      end: last(result)?.end ?? 0,
      assetId: assetId ?? null,
      sectionId: sectionId ?? null,
      originalIndex: index,
      originalStart: word.start,
      originalEnd: word.end,
    });
  }
}

function getNewLinesInRange(words: AssetTranscriptWord[], indexRange: NumberRange) {
  const result: { index: number; word: AssetTranscriptWord }[] = [];

  for (let index = indexRange.from; index <= indexRange.to; index++) {
    const word = words[index];

    if (word.type === 'newline') {
      result.push({ index, word });
    }
  }

  return result;
}

function normalizeNewLines(words: AssetTranscriptTrimmerWord[]) {
  for (let i = 0; i < words.length; i++) {
    const currentWord = words[i];
    const nextWord = words[i + 1] ?? null;

    if (currentWord.type !== 'newline') {
      continue;
    }

    if (!nextWord || nextWord.type === 'newline') {
      words.splice(i, 1);
      i--;
      continue;
    }

    currentWord.start = currentWord.end = nextWord.start;
    currentWord.speakerId = nextWord.speakerId;
  }
}
