import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_2_2 } from '../transformation_2_2/schema_after';
import { WorkflowDataDto_2_3 } from './schema_after';

/*
  NOTE: Purpose of this transformation:
    - check and transform workflow transition layers to have unique layerIds
*/
export const SchemaTransformation_2_3: SchemaTransformation = {
  version: '2.3',
  supportsPolyfill: false,

  run(oldSchema: WorkflowDataDto_2_2.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as unknown as WorkflowDataDto_2_3.WorkflowDataDto;
    const layerIds = new Set<string>();

    newSchema.timelines[0].layers
      .filter((layer) => layer.type === 'section' && layer.transitions?.crossLayer)
      .map((layer) => layer.transitions.crossLayer.layer)
      .forEach((layer) => {
        if (layerIds.has(layer.layerId)) {
          layer.layerId = uuidv4();
        }
        layerIds.add(layer.layerId);
      });

    return newSchema;
  },
};
