import { LayerOptions, LottieLayer } from '../interfaces';
import { SectionEvent, TextBoxPreset } from '../interfaces/timelines.interfaces';
import {
  TEXT_BOX_LAYER_BODY,
  TEXT_BOX_LAYER_HEADING,
  TEXT_BOX_LAYER_SUBHEADING,
  TEXT_BOX_PRESET_FIELD_NAME,
} from '../constants/text-box-lottie.constants';
import { WorkflowBaseBuilder } from './workflow-base.builder';
import { DEFAULT_TEXT_ALIGN, DEFAULT_TEXT_LETTER_SPACING, DEFAULT_TEXT_LINE_HEIGHT, getLayerFromId } from '../helpers';

export interface AddTextBoxLayerEvent extends SectionEvent {
  preset: TextBoxPreset;
  text?: string;
  textColor?: string;
  selectedLayerIds?: string[];
  skipMeasurements?: boolean;
}

export class AddTextBoxLayerCommand extends WorkflowBaseBuilder<AddTextBoxLayerEvent, LayerOptions & LottieLayer> {
  run(event: AddTextBoxLayerEvent) {
    const layer = this.addTextBoxLayer(event.sectionId);

    const { data: presetData, bounds: presetBounds } = this.getPreset(event.preset);
    layer.data[TEXT_BOX_PRESET_FIELD_NAME] = {
      ...layer.data[TEXT_BOX_PRESET_FIELD_NAME],
      ...presetData,
      ...(event.text ? { value: event.text } : {}),
    };
    layer.bounds = presetBounds;

    const style = this.source.styles.find((s) => s.id === layer.data[TEXT_BOX_PRESET_FIELD_NAME].styleId);
    if (event.textColor) {
      style.color = event.textColor;
    }
    style.fontSize = presetBounds.height;
    style.lineHeight = DEFAULT_TEXT_LINE_HEIGHT;
    style.letterSpacing = DEFAULT_TEXT_LETTER_SPACING;
    style.textAlign = DEFAULT_TEXT_ALIGN;

    if (!event.skipMeasurements) {
      // TODO: PNRL-2329: this should be removed once we have skottie measuring in the backend
      this.updateTextLayerBounds(layer, layer.bounds);

      layer.bounds = {
        ...layer.bounds,
        x: 50 - layer.bounds.width / 2,
        y: 50 - layer.bounds.height / 2,
        width: layer.bounds.width,
        height: layer.bounds.height,
      };
    }

    const bottommostLayerY: number | null = this.getSelectedLayerYPosition(event.selectedLayerIds);
    if (bottommostLayerY !== null && layer.bounds.height + bottommostLayerY <= 100) {
      layer.bounds = { ...layer.bounds, y: bottommostLayerY };
    }

    return this.ok(layer);
  }

  private getSelectedLayerYPosition(selectedLayerIds?: string[]) {
    if (!selectedLayerIds || selectedLayerIds.length === 0) {
      return null;
    }

    let bottommostLayerY: number | null = null;
    if (selectedLayerIds?.length > 0) {
      const selectedTextBoxLayers = [];
      for (const layerId of selectedLayerIds) {
        const layerInfo = getLayerFromId(layerId, this.source);
        if (!layerInfo) {
          continue;
        }

        if (layerInfo.layer.type === 'lottie' && layerInfo.layer.isTextBox) {
          selectedTextBoxLayers.push(layerInfo.layer);
        }
      }

      if (selectedTextBoxLayers.length > 0) {
        bottommostLayerY = Math.max(...selectedTextBoxLayers.map((layer) => layer.bounds.y + layer.bounds.height));
      }
    }

    return bottommostLayerY;
  }

  private getPreset(preset: TextBoxPreset) {
    switch (preset) {
      case TextBoxPreset.Heading:
        return TEXT_BOX_LAYER_HEADING;
      case TextBoxPreset.Subheading:
        return TEXT_BOX_LAYER_SUBHEADING;
      case TextBoxPreset.Body:
        return TEXT_BOX_LAYER_BODY;
    }
  }
}
