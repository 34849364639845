<div
  fxLayout="row"
  fxLayout.lt-sm="column"
  fxLayoutGap="20px"
  class="stream-test-container"
  fxLayoutAlign="center center"
>
  <div fxFlex="70" fxFlex.lt-md="50" fxFlex.lt-sm="40">
    <div [ngClass]="{ hide: showPreview }">
      <openreel-video-stream
        [stream]="webcamStream"
        [enableVideo]="!webcamDisabled"
        [streamStatus]="streamStatus$ | async"
        [isRecording]="isRecording"
        [mirrored]="mirrored"
      ></openreel-video-stream>
    </div>
    <video
      class="video-preview"
      playsinline
      autoplay
      controls
      [ngClass]="{ hide: !showPreview }"
      id="testVideoPlayer"
    ></video>
  </div>
  <div
    fxFlex="30"
    fxFlex.lt-md="50"
    fxFlex.lt-sm="100"
    class="recording-info"
    fxLayout="column"
    fxLayoutGap="15px"
    fxLayoutAlign="center center"
  >
    <div class="title">Test Audio and Video</div>
    <div class="details">Record a sample to check your audio and video. Your recording will not be saved anywhere.</div>
    <div fxLayout="column" fxLayoutGap="20px">
      <openreel-webcam-select
        (webcamDeviceChange)="onWebcamDeviceChange($event)"
        (webcamDisabledStatusChange)="onWebcamDisabledStatusChange($event)"
        [selectedWebcamDevice]="selectedVideoDevice"
        [webcamDisabled]="webcamDisabled"
      ></openreel-webcam-select>
      <openreel-mic-select
        (audioDeviceChange)="onAudioDeviceChange($event)"
        (audioMuteStatusChange)="onAudioMuteStatusChange($event)"
        [selectedAudioDevice]="selectedAudioDevice"
        [audioMuted]="audioMuted"
      ></openreel-mic-select>
      <openreel-speaker-select
        (speakerDeviceChange)="onSpeakerDeviceChange($event)"
        (speakerMuteStatusChange)="onSpeakerMuteStatusChange($event)"
        [selectedSpeakerDevice]="selectedSpeakerDevice"
        [speakerMuted]="speakerMuted"
      ></openreel-speaker-select>
    </div>

    <div class="actions" fxLayout="row" fxLayoutGap="10px">
      <button
        mat-flat-button
        color="warn"
        *ngIf="!isRecording && !showPreview && selectedVideoDevice && selectedAudioDevice"
        (click)="startTesting()"
      >
        Record a test
      </button>
      <button mat-flat-button color="warn" *ngIf="isRecording" (click)="stopTesting()">Stop Testing</button>
      <button mat-flat-button color="warn" (click)="retest()" *ngIf="showPreview">Retest</button>
      <button mat-stroked-button [disabled]="isRecording" (click)="onDialogCloseClicked()">Back to session</button>
    </div>
  </div>
</div>
