import { SchemaTransformation, TemplateSchemaTransformation } from '../interfaces';
import { SchemaTransformation_0_2 } from './project/transformation_0_2/transformation_0_2';
import { SchemaTransformation_0_3 } from './project/transformation_0_3/transformation_0_3';
import { SchemaTransformation_0_4 } from './project/transformation_0_4/transformation_0_4';
import { SchemaTransformation_0_5 } from './project/transformation_0_5/transformation_0_5';
import { SchemaTransformation_0_6 } from './project/transformation_0_6/transformation_0_6';
import { SchemaTransformation_0_7 } from './project/transformation_0_7/transformation_0_7';
import { SchemaTransformation_0_8 } from './project/transformation_0_8/transformation_0_8';
import { SchemaTransformation_0_9 } from './project/transformation_0_9/transformation_0_9';
import { SchemaTransformation_1_0 } from './project/transformation_1_0/transformation_1_0';
import { SchemaTransformation_1_1 } from './project/transformation_1_1/transformation_1_1';
import { SchemaTransformation_1_2 } from './project/transformation_1_2/transformation_1_2';
import { SchemaTransformation_1_3 } from './project/transformation_1_3/transformation_1_3';
import { SchemaTransformation_1_4 } from './project/transformation_1_4/transformation_1_4';
import { SchemaTransformation_1_5 } from './project/transformation_1_5/transformation_1_5';
import { SchemaTransformation_1_6 } from './project/transformation_1_6/transformation_1_6';
import { SchemaTransformation_1_7 } from './project/transformation_1_7/transformation_1_7';
import { SchemaTransformation_1_8 } from './project/transformation_1_8/transformation_1_8';
import { SchemaTransformation_1_9 } from './project/transformation_1_9/transformation_1_9';
import { SchemaTransformation_2_0 } from './project/transformation_2_0/transformation_2_0';
import { SchemaTransformation_2_1 } from './project/transformation_2_1/transformation_2_1';
import { SchemaTransformation_2_2 } from './project/transformation_2_2/transformation_2_2';
import { SchemaTransformation_2_3 } from './project/transformation_2_3/transformation_2_3';
import { SchemaTransformation_2_4 } from './project/transformation_2_4/transformation_2_4';
import { SchemaTransformation_2_5 } from './project/transformation_2_5/transformation_2_5';
import { SchemaTransformation_2_6 } from './project/transformation_2_6/transformation_2_6';
import { SchemaTransformation_2_7 } from './project/transformation_2_7/transformation_2_7';
import { SchemaTransformation_2_8 } from './project/transformation_2_8/transformation_2_8';
import { SchemaTransformation_2_9 } from './project/transformation_2_9/transformation_2_9';
import { SchemaTransformation_3_0 } from './project/transformation_3_0/transformation_3_0';
import { SchemaTransformation_3_1 } from './project/transformation_3_1/transformation_3_1';
import { SchemaTransformation_3_2 } from './project/transformation_3_2/transformation_3_2';
import { SchemaTransformation_3_3 } from './project/transformation_3_3/transformation_3_3';
import { SchemaTransformation_3_4 } from './project/transformation_3_4/transformation_3_4';
import { SchemaTransformation_3_5 } from './project/transformation_3_5/transformation_3_5';
import { SchemaTransformation_3_6 } from './project/transformation_3_6/transformation_3_6';
import { SchemaTransformation_3_7 } from './project/transformation_3_7/transformation_3_7';
import { SchemaTransformation_3_8 } from './project/transformation_3_8/transformation_3_8';
import { SchemaTransformation_4_0 } from './project/transformation_4_0/transformation_4_0';
import { SchemaTransformation_4_1 } from './project/transformation_4_1/transformation_4_1';
import { SchemaTransformation_4_2 } from './project/transformation_4_2/transformation_4_2';
import { SchemaTransformation_4_3 } from './project/transformation_4_3/transformation_4_3';
import { SchemaTransformation_4_4 } from './project/transformation_4_4/transformation_4_4';
import { SchemaTransformation_4_5 } from './project/transformation_4_5/transformation_4_5';
import { SchemaTransformation_4_6 } from './project/transformation_4_6/transformation_4_6';
import { SchemaTransformation_4_7 } from './project/transformation_4_7/transformation_4_7';
import { SchemaTransformation_4_8 } from './project/transformation_4_8/transformation_4_8';
import { SchemaTransformation_4_9 } from './project/transformation_4_9/transformation_4_9';
import { SchemaTransformation_5_0 } from './project/transformation_5_0/transformation_5_0';
import { SchemaTransformation_5_1 } from './project/transformation_5_1/transformation_5_1';
import { SchemaTransformation_5_2 } from './project/transformation_5_2/transformation_5_2';
import { SchemaTransformation_5_3 } from './project/transformation_5_3/transformation_5_3';
import { TemplateSchemaTransformation_1_1 } from './template/template_transformation_1_1/template_transformation_1_1';
import { TemplateSchemaTransformation_1_2 } from './template/template_transformation_1_2/template_transformation_1_2';
import { TemplateSchemaTransformation_1_3 } from './template/template_transformation_1_3/template_transformation_1_3';
import { TemplateSchemaTransformation_1_4 } from './template/template_transformation_1_4/template_transformation_1_4';
import { TemplateSchemaTransformation_1_5 } from './template/template_transformation_1_5/template_transformation_1_5';
import { TemplateSchemaTransformation_1_6 } from './template/template_transformation_1_6/template_transformation_1_6';
import { TemplateSchemaTransformation_1_7 } from './template/template_transformation_1_7/template_transformation_1_7';
import { TemplateSchemaTransformation_1_8 } from './template/template_transformation_1_8/template_transformation_1_8';

export const PROJECT_SCHEMA_TRANSFORMATIONS: SchemaTransformation[] = [
  SchemaTransformation_0_2,
  SchemaTransformation_0_3,
  SchemaTransformation_0_4,
  SchemaTransformation_0_5,
  SchemaTransformation_0_6,
  SchemaTransformation_0_7,
  SchemaTransformation_0_8,
  SchemaTransformation_0_9,
  SchemaTransformation_1_0,
  SchemaTransformation_1_1,
  SchemaTransformation_1_2,
  SchemaTransformation_1_3,
  SchemaTransformation_1_4,
  SchemaTransformation_1_5,
  SchemaTransformation_1_6,
  SchemaTransformation_1_7,
  SchemaTransformation_1_8,
  SchemaTransformation_1_9,
  SchemaTransformation_2_0,
  SchemaTransformation_2_1,
  SchemaTransformation_2_2,
  SchemaTransformation_2_3,
  SchemaTransformation_2_4,
  SchemaTransformation_2_5,
  SchemaTransformation_2_6,
  SchemaTransformation_2_7,
  SchemaTransformation_2_8,
  SchemaTransformation_2_9,
  SchemaTransformation_3_0,
  SchemaTransformation_3_1,
  SchemaTransformation_3_2,
  SchemaTransformation_3_3,
  SchemaTransformation_3_4,
  SchemaTransformation_3_5,
  SchemaTransformation_3_6,
  SchemaTransformation_3_7,
  SchemaTransformation_3_8,
  SchemaTransformation_4_0,
  SchemaTransformation_4_1,
  SchemaTransformation_4_2,
  SchemaTransformation_4_3,
  SchemaTransformation_4_4,
  SchemaTransformation_4_5,
  SchemaTransformation_4_6,
  SchemaTransformation_4_7,
  SchemaTransformation_4_8,
  SchemaTransformation_4_9,
  SchemaTransformation_5_0,
  SchemaTransformation_5_1,
  SchemaTransformation_5_2,
  SchemaTransformation_5_3,
];

export const TEMPLATE_SCHEMA_TRANSFORMATIONS: TemplateSchemaTransformation[] = [
  TemplateSchemaTransformation_1_1,
  TemplateSchemaTransformation_1_2,
  TemplateSchemaTransformation_1_3,
  TemplateSchemaTransformation_1_4,
  TemplateSchemaTransformation_1_5,
  TemplateSchemaTransformation_1_6,
  TemplateSchemaTransformation_1_7,
  TemplateSchemaTransformation_1_8,
];
