import { Asset, AssetId } from '../interfaces';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export interface AddLogoEvent {
  assetFileId: AssetId;
  fileName: string;
}

export class GlobalSettingsLogoAddCommand extends WorkflowBaseBuilder<AddLogoEvent> {
  run({ assetFileId, fileName }) {
    const newAsset: Asset = {
      id: this.getUniqueId(),
      file: {
        path: assetFileId,
        provider: 'or-assets',
      },
      fileName,
      type: 'image',
    };
    this.source.globalSettings.logo.uploaded.push(newAsset);
    return this.ok();
  }
}
