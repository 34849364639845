import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from '@openreel/frontend/common';
import { Observable } from 'rxjs';

import { PermissionedGroup } from '../../interfaces/permissioned-group.interface';

@Injectable({
  providedIn: 'root',
})
export class PermissionedGroupsService {

  private readonly urls = {
    systemPermissionedGroup: commonenv.nextGenApiUrl + 'permissioned-groups/system',
  };

  constructor(private readonly http: HttpClient) {
  }

  getSystemPermissionedGroup(): Observable<PermissionedGroup> {
    return this.http.get<PermissionedGroup>(this.urls.systemPermissionedGroup);
  }
}
