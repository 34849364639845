import { TemplateBuilderAsset, TemplateBuilderPlaceholders } from '../interfaces/template-builder.interfaces';

export function createDefaultVideoPlaceholders(assetId: number, assetDurationMs: number) {
  const placeholders: TemplateBuilderPlaceholders = {
    simple: createPlaceholderVideoObject(assetId, assetDurationMs),
    leftInterview: createPlaceholderVideoObject(assetId, assetDurationMs),
    rightInterview: createPlaceholderVideoObject(assetId, assetDurationMs),
    leftPresentation: createPlaceholderVideoObject(assetId, assetDurationMs),
    rightPresentation: createPlaceholderVideoObject(assetId, assetDurationMs),
  };

  return placeholders;
}

function createPlaceholderVideoObject(assetId: number, assetDurationMs: number): TemplateBuilderAsset {
  return {
    assetFileId: assetId,
    assetFileType: 'clip',
    bounds: { x: 0, y: 0, width: 100, height: 100 },
    duration: assetDurationMs,
    fields: [],
    fieldsTextColor: '#000000',
  };
}
