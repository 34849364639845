import { AssetType } from '../interfaces';
import { WorkflowTemplatesBaseBuilder } from './workflow-templates-base.builder';

export interface BuilderAssetChangeCommandEvent {
  assetType: 'logo' | 'watermark' | 'background-video';
  assetFileId: number | null;
}

export class BuilderAssetChangeCommand extends WorkflowTemplatesBaseBuilder<BuilderAssetChangeCommandEvent> {
  run(event: BuilderAssetChangeCommandEvent) {
    const hasAsset = !!event.assetFileId;

    const property = this.getAssetProperty(event);

    this.source.globalSettings[property].settings.enabled = hasAsset;

    const assetId = this.source.globalSettings[property].settings.assetId;
    const asset = this.getAsset(assetId);

    if (hasAsset) {
      asset.file = { path: event.assetFileId, provider: 'or-assets' };
      this.source.globalSettings[property].uploaded.push({
        id: asset.id,
        file: { ...asset.file },
        fileName: this.getUploadedAssetDescription(event),
        type: this.getUploadedAssetType(event),
      });
    } else {
      delete asset.file;
      this.source.globalSettings[property].uploaded = [];
    }

    return this.ok();
  }

  private getAssetProperty(event: BuilderAssetChangeCommandEvent): 'logo' | 'watermark' | 'backgroundVideo' {
    switch (event.assetType) {
      case 'logo':
        return 'logo';
      case 'watermark':
        return 'watermark';
      case 'background-video':
        return 'backgroundVideo';
    }
  }

  private getUploadedAssetType(event: BuilderAssetChangeCommandEvent): AssetType {
    switch (event.assetType) {
      case 'logo':
      case 'watermark':
        return 'image';
      case 'background-video':
        return 'clip';
    }
  }

  private getUploadedAssetDescription(event: BuilderAssetChangeCommandEvent) {
    switch (event.assetType) {
      case 'logo':
        return 'Template Logo';
      case 'watermark':
        return 'Template Watermark';
      case 'background-video':
        return 'Template Background Video';
    }
  }
}
