import { Inject, Pipe, PipeTransform } from '@angular/core';
import { FeatureEnabledService } from '@openreel/frontend/common/services';

@Pipe({
  name: 'featureEnabled',
  standalone: true,
})
export class FeatureEnabledPipe implements PipeTransform {
  constructor(
    @Inject(FeatureEnabledService)
    private featureEnabledService: FeatureEnabledService
  ) {}

  transform(value: string) {
    return this.featureEnabledService.featureEnabled(value);
  }
}
