import { cloneDeep } from 'lodash';
import { TemplateSchemaTransformation } from '../../../interfaces';
import { TemplateWorkflowDataDto_1_3 } from '../template_transformation_1_3/schema_after';
import { TemplateWorkflowDataDto_1_4 } from './schema_after';

export const TemplateSchemaTransformation_1_4: TemplateSchemaTransformation = {
  version: '1.4',

  run(
    oldSchema: TemplateWorkflowDataDto_1_3.TemplateWorkflowDataDto
  ): TemplateWorkflowDataDto_1_4.TemplateWorkflowDataDto {
    const newSchema = cloneDeep(oldSchema) as unknown as TemplateWorkflowDataDto_1_4.TemplateWorkflowDataDto;

    newSchema.globalSettings.placeholders = {
      interviewAssetIds: {},
      presentationAssetIds: {},
    };

    const oldPlaceholders = oldSchema.features.layouts?.placeholders?.mainClips;
    const newPlaceholders = newSchema.globalSettings.placeholders;

    if (oldPlaceholders.simpleAssetId) {
      newPlaceholders.simpleAssetId = oldPlaceholders.simpleAssetId;
    }

    if (oldPlaceholders?.interviewAssetIds && oldPlaceholders.interviewAssetIds.length > 0) {
      newPlaceholders.interviewAssetIds.left = oldPlaceholders.interviewAssetIds[0];
      if (oldPlaceholders.interviewAssetIds.length > 1) {
        newPlaceholders.interviewAssetIds.right = oldPlaceholders.interviewAssetIds[1];
      }
    }

    if (oldPlaceholders?.presentationAssetIds && oldPlaceholders.presentationAssetIds.length > 0) {
      newPlaceholders.presentationAssetIds.left = oldPlaceholders.presentationAssetIds[0];
      if (oldPlaceholders.presentationAssetIds.length > 1) {
        newPlaceholders.presentationAssetIds.right = oldPlaceholders.presentationAssetIds[1];
      }
    }

    return newSchema;
  },
};
