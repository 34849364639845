import { Member, Recording, SessionSettings, SingleInvitation, UserData } from '@openreel/frontend/common';
import { SessionType } from '@openreel/common';
import { AutomationStepsIcons, AutomationStepsTypes } from '@openreel/common/automations';

export interface ProjectCreator {
  fullname: string;
  image: string;
  thumb_image?: string;
}

export interface ProjectListCountResponse {
  count: number;
}

export interface ProjectData {
  id: number;
  name: string;
  status: string;
  creator: number;
  account_id: number;
  createdByUser: ProjectCreator;
  project_thumb: string;
  updatedAt: Date;
  createdAt: Date;
  total_members: number;
  settings?: SessionSettings;
  total_clips: number;
  project_type: ProjectType;
  automationInstance: ProjectAutomationInstance;
  project_members: ProjectMember[];
}

export interface ProjectAutomationInstance {
  id: number;
  name: string;
  status: string;
  template: ProjectAutomationInstanceTemplate;
}

export interface ProjectAutomationInstanceTemplate {
  id: number;
  name: string;
  steps: ProjectAutomationInstanceTemplateStep[];
}

export interface ProjectAutomationInstanceTemplateStep {
  id: number;
  stepType: {
    id: number;
    name: string;
    icon: AutomationStepsIcons;
    type: AutomationStepsTypes;
    app: {
      id: number;
      name: string;
      icon: AutomationStepsIcons;
      vendor: {
        id: number;
        name: string;
        icon: AutomationStepsIcons;
        type: AutomationStepsTypes;
      };
    };
  };
}
export interface ProjectStatistics {
  total_recorded_duration: number;
  total_videos: number;
}
export interface ProjectSessionData {
  ovra_sessions_id: number;
  session_id: number;
  project_id: number;
  session_name: string;
  session_otp: string;
  created_at: Date;
  invited_members_except_me: SingleInvitation[];
  session_type: SessionType;
  allowedToEnter?: boolean;
  start_time: Date;
  end_time: Date;
}

export enum ProjectType {
  WIZARD = 'wizard',
  CAPTURE = 'capture',
  AUTOMATION = 'automation',
}

export interface CreateProjectRequest {
  name: string;
  members: Array<Member>;
}

export interface CreateProjectResponse {
  status: number;
  message: string;
  data: CreateProjectData;
}

export interface CreateProjectData {
  project: ProjectData;
  creator_details: ProjectCreator;
}

export interface ProjectListRequest {
  limit: number;
  page: number;
  sessionId?: number;
  searchString?: string;
  orderField: string;
  orderSort: string;
  startDate?: string;
  endDate?: string;
  hasClips?: boolean;
}

export interface ProjectListCountRequest {
  isArchived: boolean;
  startDate?: string | null;
  endDate?: string | null;
}

export interface ProjectListResponse {
  status: number;
  data: Array<ProjectData>;
  message: string;
}
export interface ProjectDetailsRequest {
  project_id: number;
}

export interface ProjectDetails {
  project: ProjectData;
  project_statistics: ProjectStatistics;
  members: Array<UserData>;
  sessions: Array<ProjectSessionData>;
  session_invite_limit: number;
  projectAccess?: boolean;
}

export interface ProjectDetailsResponse {
  status: number;
  message: string;
  data: ProjectDetails;
}

export interface ProjectUpdateRequest {
  project_id: number;
  name?: string;
  members?: Array<Member>;
}
export interface ProjectUpdateResponse {
  status: number;
  message: string;
}

export interface ProjectRecordingsResponse {
  count: number;
  rows: Recording[];
}

export interface DashboardSummaryProjectsResponse {
  status: number;
  data: DashboardSummaryProject[];
}

export interface DashboardSummaryProject {
  id: number;
  account_id: string;
  name: string;
  status: string;
  last_access_at: string;
  createdAt: string;
}

export interface ArchivedProjectsResponse {
  data?: ProjectData[];
  status?: number;
  message?: string;
}

export interface ProjectMember {
  id: number;
  fullname: string;
  thumb_image: string;
  email: string;
}
