import { LayerIdEvent } from '../interfaces/timelines.interfaces';
import { getLayerFromId, isBoundsFullScreen } from '../helpers/timelines.helpers';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export class UpdateVideoOverlayPipCommand extends WorkflowBaseBuilder<LayerIdEvent> {
  run({ layerId }: LayerIdEvent) {
    const layerInfo = getLayerFromId(layerId, this.source);
    if (layerInfo === null) {
      console.warn(`Layer with ID ${layerId} not found.`);
      return { success: false };
    }

    const { layer, timeline } = layerInfo;

    const isFullscreenCurrently = isBoundsFullScreen(layer.bounds);

    if (isFullscreenCurrently) {
      layer.bounds = timeline.bounds;
      layer.styles = timeline.styles;
    } else {
      layer.bounds = {
        x: 0,
        y: 0,
        width: 100,
        height: 100,
      };
      delete layer.styles;
    }

    return this.ok();
  }
}
