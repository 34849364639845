import { Asset, SectionTimeline, Style } from './workflow.interfaces';

export const LAYER_COMBINATIONS_DEFAULT_VALUE: LayerCombinationsDto = {
  aspectRatio: 1,
  backgroundColor: '#ffffff',
  assets: [],
  styles: [],
  timelines: [],
};

export interface LayerCombinationsDto {
  aspectRatio: number;
  backgroundColor: string;
  assets: Asset[];
  styles: Style[];
  timelines: SectionTimeline[];
}
