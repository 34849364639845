import { cloneDeep } from 'lodash';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_3_4 } from '../transformation_3_4/schema_after';
import { WorkflowDataDto_3_5 } from './schema_after';

/*
  NOTE: Purpose of this transformation:
    - 1) remove crossLayer transitions that do not have a matching asset
*/
export const SchemaTransformation_3_5: SchemaTransformation = {
  version: '3.5',
  supportsPolyfill: false,

  run(oldSchema: WorkflowDataDto_3_4.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as unknown as WorkflowDataDto_3_5.WorkflowDataDto;

    const mainTimeline = newSchema.timelines.find((t) => t.type === 'main');
    for (const layer of mainTimeline.layers) {
      if (!layer.transitions?.crossLayer) {
        continue;
      }

      const transitionAssetId = layer.transitions.crossLayer.layer.assetId;
      const transitionAsset = newSchema.assets.find((a) => a.id === transitionAssetId);

      if (!transitionAsset) {
        delete layer.transitions;
      }
    }

    return newSchema;
  },
};
