import { NumberRange } from '@openreel/common';
import { clamp } from 'lodash';
import {
  convertOriginalTimeRangeToUITime,
  convertUITimeRangeToOriginalTime,
  getAssetDuration,
  getAssetDurationAfterTextTrimmer,
  getAssetFinalDuration,
  getAssetTimelineTrim,
  getSectionTimelines,
} from '../helpers';
import { Asset, LayerOptions, VideoLayer } from '../interfaces';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export interface CreateSectionsFromSplitsEvent {
  sectionId: string;
  splits: NumberRange[];
}

export class SplitSceneCommand extends WorkflowBaseBuilder<CreateSectionsFromSplitsEvent> {
  run(event: CreateSectionsFromSplitsEvent) {
    const currentSplit = event.splits[0];
    const newSectionsSplits = event.splits.slice(1);

    // First duplicate scenes + trim as defined
    this.createScenes(event, newSectionsSplits);

    // Finally update original section trim
    this.updateSceneTrim(event.sectionId, currentSplit);

    return this.ok();
  }

  private updateSceneTrim(sectionId: string, split: NumberRange) {
    const timelines = getSectionTimelines(this.source.sections, 'main', 'main', sectionId)[0].timelines;

    for (const timeline of timelines) {
      const layer = timeline.layers.find((i) => i.type === 'video');

      const asset = this.getAsset((layer as LayerOptions & VideoLayer).assetId);
      asset.trim = this.convertSplitIntoAssetTrim(split, asset);

      const newDuration = getAssetFinalDuration(asset);
      layer.visibility.endAt = layer.visibility.startAt + newDuration;
    }
  }

  private createScenes(event: CreateSectionsFromSplitsEvent, newSections: NumberRange[]) {
    const mainTimeline = this.source.timelines.find((t) => t.type === 'main');
    const sourceSceneIndex = mainTimeline.layers.findIndex(
      (l) => l.type === 'section' && l.sectionId === event.sectionId
    );

    for (let i = 0; i < newSections.length; i++) {
      const newSectionSplit = newSections[i];

      // Copy section
      const insertionIndex = sourceSceneIndex + i + 1;
      const newSectionId = this.copySection(event.sectionId, insertionIndex);

      this.updateSceneTrim(newSectionId, newSectionSplit);
    }
  }

  private convertSplitIntoAssetTrim(split: NumberRange, asset: Asset) {
    const durationAfterTextTrimmer = getAssetDurationAfterTextTrimmer(asset);
    const finalDuration = getAssetFinalDuration(asset);

    const correctedSplit: NumberRange = {
      from: Math.min(split.from, finalDuration),
      to: Math.min(split.to, finalDuration),
    };

    const currentUITrim = convertOriginalTimeRangeToUITime(
      getAssetTimelineTrim(asset),
      getAssetDuration(asset),
      asset.textCuts
    );
    const offset = currentUITrim.from;
    const updatedUIFrim: NumberRange = {
      from: clamp(offset + correctedSplit.from, 0, durationAfterTextTrimmer),
      to: clamp(offset + correctedSplit.to, 0, durationAfterTextTrimmer),
    };

    return convertUITimeRangeToOriginalTime(updatedUIFrim, getAssetDuration(asset), asset.textCuts);
  }
}
