import { AssetLayer } from '../interfaces';
import { getTimelineById } from '../helpers/timelines.helpers';
import { getTimelinesByTypeAndSectionId } from '../helpers';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export interface RemoveMainTimelineEvent {
  timelineId: string;
}

export class RemoveMainTimelineCommand extends WorkflowBaseBuilder<RemoveMainTimelineEvent> {
  run(event: RemoveMainTimelineEvent) {
    const timelineInfo = getTimelineById(event.timelineId, this.source.sections);

    if (!timelineInfo) {
      return this.error(`Can't find timeline to remove. Timeline id: ${event.timelineId}`);
    }

    if (timelineInfo.timeline.type !== 'main') {
      return this.error('Timeline is not a main timeline.');
    }

    const { sectionId, timeline } = timelineInfo;

    const assetIds = timeline.layers.map((layer) => (layer as AssetLayer).assetId);
    this.removeAssets(assetIds);

    const section = this.source.sections[sectionId];

    section.timelines = section.timelines.filter((t) => t.id !== timeline.id);

    const maineTimelines = getTimelinesByTypeAndSectionId(this.source.sections, 'main', sectionId);
    if (maineTimelines.length === 0) {
      this.removeSection(sectionId);
    } else {
      this.updateSectionDuration(section);
    }

    timeline.layers = [];

    return this.ok();
  }
}
