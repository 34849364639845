import { LayerIdEvent } from '../interfaces/timelines.interfaces';
import { getLayerFromId } from '../helpers/timelines.helpers';
import { cloneDeep } from 'lodash';
import { Asset, ImageLayer, LayerOptions, LottieLayer } from '../interfaces';
import { WorkflowBaseBuilder } from './workflow-base.builder';
import { TEXT_BOX_PRESET_FIELD_NAME } from '../constants';

const DUPLICATE_POSITION_OFFSET = 1;

export interface DuplicateLayerEvent extends LayerIdEvent {
  targetSectionId?: string;
}

export class DuplicateLayerCommand extends WorkflowBaseBuilder<DuplicateLayerEvent, LayerOptions> {
  run(event: DuplicateLayerEvent) {
    const layerInfo = getLayerFromId(event.layerId, this.source);
    if (layerInfo === null) {
      return { success: false };
    }

    const { layer, timeline } = layerInfo;

    let sectionId = layerInfo.sectionId;
    if (event.targetSectionId) {
      sectionId = event.targetSectionId;
    }

    const layerId = this.getUniqueId();

    const newLayer = cloneDeep({
      ...layer,
      layerId,
      bounds: {
        ...layer.bounds,
        x: layer.bounds.x + DUPLICATE_POSITION_OFFSET,
        y: layer.bounds.y + DUPLICATE_POSITION_OFFSET,
      },
    });

    if (layer.type === 'lottie' && layer.isTextBox) {
      const style = cloneDeep({
        ...this.source.styles.find((s) => s.id === layer.data[TEXT_BOX_PRESET_FIELD_NAME].styleId),
        id: this.getUniqueId(),
      });
      this.addAndReplaceStyles([style]);

      (newLayer as LottieLayer).data[TEXT_BOX_PRESET_FIELD_NAME].styleId = style.id;
    } else if (layer.type === 'image' && timeline.type === 'images') {
      const oldAsset = this.source.assets.find((a) => a.id === layer.assetId);
      const newAsset: Asset = cloneDeep({
        ...oldAsset,
        id: this.getUniqueId(),
      });
      this.source.assets.push(newAsset);

      (newLayer as ImageLayer).assetId = newAsset.id;
    } else {
      console.error('Layer duplication is only supported on text box and image layers.');
      return { success: false };
    }

    const newTimeline = this.addNewTimelineToSection(sectionId, timeline.type);
    newTimeline.bounds = cloneDeep(timeline.bounds);
    newTimeline.layers.push(newLayer);

    return this.ok(newLayer);
  }
}
