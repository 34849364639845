import { toSRTTime } from '../../../utils';
import { CombinedTimebox } from '../../interfaces/combined-timebox.interface';
import { CombinedTimeboxesStrategy } from '../combined-timeboxes-strategy';

export class SRTStrategy extends CombinedTimeboxesStrategy {
  override getHeader(): string {
    return '';
  }

  override getContent(): string {
    return this.content;
  }

  protected override addCue(timebox: CombinedTimebox, cueIndex: number): void {
    if (cueIndex > 0) {
      this.content += '\n\n';
    }

    const startTime = this.toCueTime(timebox.from);
    const endTime = this.toCueTime(timebox.to);

    const text = timebox.texts
      .map((t) => (t.showSpeaker ? `${this.speakers.get(t.speakerId).name}: ` : '') + t.text)
      .join('\n');
    const cueText = `${cueIndex + 1}\n${startTime} --> ${endTime}\n${text}`;

    this.content += cueText;
  }

  protected override toCueTime(timeMs: number): string {
    return toSRTTime(this.offset + timeMs);
  }
}
