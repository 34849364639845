import { cloneDeep } from 'lodash';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_1_3 } from '../transformation_1_3/schema_after';
import { WorkflowDataDto_1_4 } from './schema_after';
import { v4 as uuidv4 } from 'uuid';

/*
  NOTE: Purpose of this transformation:
    - move primary color from wizard to globalSettings
    - remove font from wizard
    - add missing background timelines to all main sections (if template has it)
*/
export const SchemaTransformation_1_4: SchemaTransformation = {
  version: '1.4',
  supportsPolyfill: false,

  run(
    oldSchema: WorkflowDataDto_1_3.WorkflowDataDto,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { template }: { template: any }
  ) {
    const newSchema = cloneDeep(oldSchema) as WorkflowDataDto_1_4.WorkflowDataDto;

    const primaryColor = getPrimaryColor(oldSchema);
    newSchema.globalSettings.primaryColor = {
      type: 'solid',
      color: primaryColor,
    };

    const fontIndex = getFontIndex(oldSchema);
    newSchema.globalSettings.fontIndex = fontIndex;

    if (newSchema.features.wizard) {
      newSchema.features.wizard.elements = newSchema.features.wizard.elements.filter((el) => el.type === 'asset');
    }

    addMissingBackgroundTimelines(newSchema, template);

    return newSchema;
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getPrimaryColor(oldSchema: WorkflowDataDto_1_3.WorkflowDataDto) {
  const oldPrimaryColor = oldSchema.features.wizard?.elements.find(
    (e) => e.type === 'primary-color'
  ) as WorkflowDataDto_1_3.ElementPrimaryColorFeature;
  return oldPrimaryColor?.value;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getFontIndex(oldSchema: WorkflowDataDto_1_3.WorkflowDataDto) {
  const oldFont = oldSchema.features.wizard?.elements.find(
    (e) => e.type === 'font'
  ) as WorkflowDataDto_1_3.ElementFontFeature;
  return oldFont?.fontIndex ?? 0;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function addMissingBackgroundTimelines(newSchema: WorkflowDataDto_1_4.WorkflowDataDto, template: any) {
  const templateMainSections = Object.entries(template.sections).filter(
    ([, section]: [string, WorkflowDataDto_1_3.Section]) => section.sectionType === 'main'
  );
  const templateMainSection =
    templateMainSections.length > 0 ? (templateMainSections[0][1] as WorkflowDataDto_1_3.Section) : null;

  if (!templateMainSection) {
    return;
  }
  const templateBackgroundTimeline = templateMainSection.timelines.find((t) => t.type === 'background');
  if (!templateBackgroundTimeline) {
    return;
  }

  for (const [, section] of Object.entries(newSchema.sections)) {
    if (section.sectionType !== 'main') {
      continue;
    }

    const hasBackgroundTimeline = section.timelines.some((t) => t.type === 'background');
    if (hasBackgroundTimeline) {
      continue;
    }

    section.timelines.push({ ...templateBackgroundTimeline, id: uuidv4() });
  }
}
