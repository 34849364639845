/* eslint-disable @typescript-eslint/no-explicit-any */
import { cloneDeep } from 'lodash';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_2_4 } from '../transformation_2_4/schema_after';
import { WorkflowDataDto_2_5 } from './schema_after';

/*
  NOTE: Purpose of this transformation:
    - 1) remove isLayerSelectable property from Timeline since now every timeline/layer is selectable except background
    - 2) zIndex property on Timeline is now mandatory
    - 3) populate zIndex property on all timelines
*/
export const SchemaTransformation_2_5: SchemaTransformation = {
  version: '2.5',
  supportsPolyfill: false,

  run(oldSchema: WorkflowDataDto_2_4.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as unknown as WorkflowDataDto_2_5.WorkflowDataDto;

    for (const [, section] of Object.entries(newSchema.sections)) {
      let zIndexCounter = 0;
      const backgroundTimeline = section.timelines.find((t) => t.type === 'background');
      if (backgroundTimeline) {
        backgroundTimeline.zIndex = 0;
        zIndexCounter++;
      }

      const mainTimelines = section.timelines.filter((t) => t.type === 'main');
      for (const timeline of mainTimelines) {
        const hasComposite = timeline.layers.length === 1 && timeline.layers[0].type === 'timelines';

        if (hasComposite) {
          const timelineLayer = timeline.layers[0] as WorkflowDataDto_2_5.TimelinesLayer;

          for (const layerTimeline of timelineLayer.children) {
            layerTimeline.zIndex = zIndexCounter;
            zIndexCounter++;
          }
        } else {
          timeline.zIndex = zIndexCounter;
          zIndexCounter++;
        }
      }

      const overlayTimelines = section.timelines.filter((t) => t.type === 'overlays');
      for (const timeline of overlayTimelines) {
        timeline.zIndex = zIndexCounter;
        zIndexCounter++;
      }

      const brollTimeline = section.timelines.find((t) => t.type === 'b-roll');
      if (brollTimeline) {
        brollTimeline.zIndex = zIndexCounter;
        zIndexCounter++;
      }

      const watermarkTimeline = section.timelines.find((t) => t.type === 'watermark');
      if (watermarkTimeline) {
        watermarkTimeline.zIndex = zIndexCounter;
      }
    }

    return newSchema;
  },
};
