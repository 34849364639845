import { ApprovalStatuses, FontDto } from '@openreel/common';
import {
  AssetId,
  BrandKitPermissions,
  ChangeTemplateResult,
  CompanySoundtrack,
  TemplateSettings,
  WorkflowDataDto,
  WorkflowProjectCreationSource,
  WorkflowRenderStatus,
  WorkflowTemplateLayoutDto,
} from '@openreel/creator/common';
import { ASSStyle } from '@openreel/transcription';
import { UserRoleType } from '../../interfaces';
import { BrandKitDto } from '../../services';
import { AssetDto } from '../assets/assets.interfaces';
import { AspectRatioEntity } from '../layouts/workflow-layouts.interfaces';
import { TemplateDto } from '../template/workflow-template.interfaces';
import { HostableVideoStatuses } from '../../hosting';

export interface WorkflowsJoinRoomSocketEvent {
  deviceType: 'WEB';
  userType: UserRoleType;
  token: string;
  captureProjectId: number;
}

export interface WorkflowsProjectsListVideoEntity {
  id: number;
  state: 'rendering' | 'done' | 'failed' | 'cancelled';
  updatedAt: Date;
  hostable?: {
    id: number;
    hostedVideo?: {
      state: HostableVideoStatuses;
    };
  };
}

export interface WorkflowsProjectsListEntity {
  id: number;
  name: string;
  captureProjectId?: number;
  createdBy: number;
  createdAt: Date;
  updatedAt: Date;
  srtUrl?: string;
  thumbnailUrl: string;
  video?: WorkflowsProjectsListVideoEntity;
  approvalStatus?: ApprovalStatuses;
  useApprovalFlow?: boolean;
  duration: number;
  creationSource: WorkflowProjectCreationSource;
}

export interface CreateWorkflowProjectRequest {
  templateId: number;
  templateLayoutId?: number;
}

export interface UpdateWorkflowProjectRequest {
  id: number;
  workflow: WorkflowDataDto;
  templateId?: number;
  templateLayoutId?: number;
  clientVersion: string;
  aspectRatioId?: number;
  name?: string;
}

export interface RemoveAiVideo {
  sectionId: string;
  timelineId: string;
  currentWorkflow: WorkflowDataDto;
}

export interface ChangeTemplate {
  templateId: number;
  currentTemplateId: number;
  currentWorkflow?: WorkflowDataDto;
  saveCurrentWorkflow?: boolean;
}

export interface WorkflowProjectSignedAsset {
  projectAssetId: AssetId;
  asset: AssetDto;
}

export interface WorkflowProjectCreatedDto {
  id: number;
}

export interface WorkflowProjectChangeTemplateDto {
  updatedWorkflow: WorkflowDataDto;
  templateId: number;
  templateLayoutId: number;
  templateWorkflow: WorkflowDataDto;
  resources: WorkflowProjectResourcesDto;
  templateLayouts: WorkflowTemplateLayoutDto[];
  changeTemplateResult: ChangeTemplateResult;
}

export interface WorkflowProjectSimpleDto {
  id: number;
  name: string;
  version: string;
  templateId: number;
  captureProjectId: number;
  createdBy: number;
  workflow: WorkflowDataDto;
}

export interface WorkflowProjectDto {
  id: number;
  name: string;
  version: string;
  workflow: WorkflowDataDto;
  templateId: number;
  template: TemplateDto;
  captureProjectId: number;
  createdBy: number;
  video?: WorkflowProjectVideoDto;
  brandKit?: BrandKitDto;
  showBrandKitUpgradeMessage: boolean;
  // NOTE: because we have no way to measure text boxes on the backend currently we need to property to let
  // the frontend know it should measure all textboxes on project load
  // TODO: PNRL-2329: this should be removed once we have skottie measuring in the backend
  textBoxesNeedRemeasuring: boolean;
  brandKitPermissions?: BrandKitPermissions;
  templateSettings?: TemplateSettings;
  hasCaptions?: boolean;
  captionsState: CaptionsState;
  layouts?: WorkflowTemplateLayoutDto[];
  signedAssets?: WorkflowProjectSignedAsset[];
  fonts?: FontDto[];
  soundtracks?: CompanySoundtrack[];
  subtitleFont?: FontDto;
  templateLayoutId?: number;
  thumbnailUrl?: string;
  thumbnailVideoId?: number;
  thumbnailType?: ThumbnailType;
  useApprovalFlow?: boolean;
  comments: ProjectComments;
  commentsToken: string | null;
  selectedAspectRatio: AspectRatioEntity;
  selectedLayout: WorkflowTemplateLayoutDto;
  aspectRatios: AspectRatioEntity[];
  hostableVideoId?: number;
  updatedTemplateResults?: {
    result: ChangeTemplateResult;
    updatedWorkflow: WorkflowDataDto;
  };
}

export interface WorkflowProjectPreviewDto {
  id: number;
  name: string;
  version: string;
  workflow: WorkflowDataDto;
  fonts?: FontDto[];
  subtitleFont?: FontDto;
}

export interface WorkflowProjectResourcesDto {
  fonts: FontDto[];
}

export enum ThumbnailType {
  Video = 'video',
  Template = 'template',
}

export interface WorkflowProjectVideoDto {
  triggeredAt?: Date;
  renderedAt?: Date;
  signedUrl?: string;
  state: WorkflowRenderStatus;
}

export interface TranscribeProgress {
  state: TranscribeState;
  progress: number;
  projectId: number;
  captionsUpToDate: boolean;
  captureProjectId?: number;
}

export interface ThumbnailSpritesheetFinished {
  captureProjectId: number;
  videoId: number;
  signedUrl: string;
}

export interface ApprovalStatusChange {
  captureProjectId: number;
  projectId: number;
  approvalId: number;
  approvalStatus: ApprovalStatuses;
}

export type TranscribeState = 'transcribing' | 'done' | 'failed' | 'cancelled';

export interface CaptionsState {
  upToDate: boolean;
  userEdited: boolean;
  burnIn: boolean;
  globalStyle: ASSStyle;
}

export interface WorkflowRenderToLibraryParams {
  title: string;
  permission: 'private';
}

export interface WorkflowArchivedData {
  id: number;
  name: string;
  deleted_at: Date;
}

export interface ProjectComments {
  count: number;
  hasUnreadComments: boolean;
}
