import { cloneDeep } from 'lodash';
import { TemplateSchemaTransformation } from '../../../interfaces';
import { TemplateWorkflowDataDto_1_1 } from './schema_after';
import { v4 as uuidv4 } from 'uuid';

export const TemplateSchemaTransformation_1_1: TemplateSchemaTransformation = {
  version: '1.1',

  run(oldSchema: any): TemplateWorkflowDataDto_1_1.TemplateWorkflowDataDto {
    const newSchema = cloneDeep(oldSchema) as TemplateWorkflowDataDto_1_1.TemplateWorkflowDataDto;
    if (!newSchema.globalSettings.soundtrack) {
      const asset: TemplateWorkflowDataDto_1_1.Asset = {
        id: uuidv4(),
        type: 'audio',
        isGlobal: true,
      };
      newSchema.assets.push(asset);

      newSchema.globalSettings.soundtrack = {
        introVolume: 80,
        mainVolume: 20,
        outroVolume: 80,
        assetId: asset.id,
      };
    }

    return newSchema;
  },
};
