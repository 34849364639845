import * as Interfaces from './soundtracks.interfaces';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from 'libs/frontend/common/src/environments/environment';
import { CaseConverterInterceptorSkipHeader } from '../../interceptors';
import { Account } from '../../interfaces';

@Injectable({
  providedIn: 'root',
})
export class SoundtracksService {
  private urls = {
    base: `${commonenv.nextGenApiUrl}soundtracks`,
    byAccountId: (accountId?: number) =>
      `${this.urls.base}?limit=100${accountId != null ? '&accountId=' + accountId : ''}`,
    reorderAll: `${commonenv.nextGenApiUrl}soundtracks/reorder`,
    getAudioAccounts: () => `${this.urls.base}/accounts/get-with-soundtracks`,
    byId: (id: number) => `${this.urls.base}/${id}`,
  };

  constructor(private readonly httpClient: HttpClient) {}

  getAll(accountId?: number) {
    return this.httpClient.get<Interfaces.Soundtrack[]>(this.urls.byAccountId(accountId));
  }

  reorderAll(ids: number[]) {
    return this.httpClient.post<Interfaces.Soundtrack[]>(this.urls.reorderAll, { ids });
  }

  getById(id: number) {
    return this.httpClient.get<Interfaces.Soundtrack>(this.urls.byId(id));
  }

  getAccountsWithSoundtrack() {
    return this.httpClient.get<Account[]>(this.urls.getAudioAccounts());
  }

  create(data: Interfaces.UpsertSoundtrackRequest) {
    return this.httpClient.post<Interfaces.Soundtrack>(this.urls.base, data, {
      headers: new HttpHeaders({
        [CaseConverterInterceptorSkipHeader]: '',
      }),
    });
  }

  update(id: number, data: Interfaces.UpsertSoundtrackRequest) {
    return this.httpClient.put<Interfaces.Soundtrack>(this.urls.byId(id), data, {
      headers: new HttpHeaders({
        [CaseConverterInterceptorSkipHeader]: '',
      }),
    });
  }

  delete(id: number) {
    return this.httpClient.delete<void>(this.urls.byId(id));
  }
}
