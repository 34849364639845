import { getSectionsOfType } from '../helpers';
import { Bounds, LayoutDto, LayoutType, ProjectFont, SectionId } from '../interfaces';
import { WorkflowBaseBuilder } from '../builders/workflow-base.builder';
import { WorkflowTemplatesBaseBuilder } from './workflow-templates-base.builder';

export interface ProjectCreateLayoutProperties {
  templateLayoutId: number;
  layoutType: LayoutType;
  layoutData: LayoutDto;
  aspectRatioBounds: Bounds[];
}

export interface BuilderProjectCreateCommandEvent {
  isBlueprintTemplate: boolean;
  // Properties for layout templates
  defaultLayout?: ProjectCreateLayoutProperties;
  defaultFont?: ProjectFont;
  defaultAspectRatio?: { id: number; width: number; height: number };
}

export class BuilderProjectCreateCommand extends WorkflowTemplatesBaseBuilder<BuilderProjectCreateCommandEvent> {
  run(event: BuilderProjectCreateCommandEvent) {
    this.updateGlobalFont(event.defaultFont, !!WorkflowBaseBuilder.textBoxMeasurer);

    if (!event.isBlueprintTemplate) {
      this.setupInitialIntroOutroSections(event.defaultAspectRatio.id);

      this.applyLayoutToDefaultMainScene(event);

      this.source.globalSettings.resolution = {
        width: event.defaultAspectRatio.width,
        height: event.defaultAspectRatio.height,
      };
    }

    return this.ok();
  }

  private setupInitialIntroOutroSections(aspectRatioId: number) {
    const introSections = this.source.templateSections[SectionId.Intro];
    if (introSections.isEnabled) {
      const introSection = introSections.sections.find((s) => s.aspectRatioId === aspectRatioId);
      this.copyTemplateSection(SectionId.Intro, introSection, true);
    } else {
      this.removeTemplateSection(SectionId.Intro);
    }

    const outroSections = this.source.templateSections[SectionId.Outro];
    if (outroSections.isEnabled) {
      const outroSection = outroSections.sections.find((s) => s.aspectRatioId === aspectRatioId);
      this.copyTemplateSection(SectionId.Outro, outroSection, true);
    } else {
      this.removeTemplateSection(SectionId.Outro);
    }
  }

  private applyLayoutToDefaultMainScene({
    defaultLayout: { templateLayoutId, layoutType, layoutData, aspectRatioBounds },
  }: BuilderProjectCreateCommandEvent) {
    const mainSections = getSectionsOfType(this.source.sections, 'main');
    if (mainSections.length !== 1) {
      throw new Error('Layout templates must have exactly one main section');
    }

    const mainSectionId = mainSections[0].sectionId;

    this.applyLayout(mainSectionId, templateLayoutId, layoutType, layoutData, aspectRatioBounds, true);

    this.duplicateAssetsForSection(mainSections[0].section);
  }
}
