import { HttpErrorResponse } from '@angular/common/http';
import { InjectionToken } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { splitNameAndExtension } from '@openreel/common';
import { ErrorType, FileMetadata, FileService } from '@openreel/frontend/common';
import {
  EMPTY,
  Observable,
  Subject,
  catchError,
  concatMap,
  debounceTime,
  distinctUntilChanged,
  forkJoin,
  isObservable,
  of,
  switchMap,
  takeUntil,
  tap,
  throwError,
  withLatestFrom,
} from 'rxjs';
import {
  ACCEPTED_FILE_EXTENSIONS,
  FileValidatorBaseService,
  OpenreelUploaderExtraOptions,
  UploadCredentials,
  UploadTypes,
  UploaderBaseService,
} from './openreel-uploader.component';

export const OPENREEL_UPLOAD_MANAGER = new InjectionToken<OpenreelUploadManager>('OPENREEL_UPLOAD_MANAGER_TOKEN');

export interface FileInfo {
  file: File;
  type: UploadTypes;
  extra?: OpenreelUploaderExtraOptions;
}

export enum FileUploadStatus {
  NotStarted = 'not_started',
  SourceIdAssigned = 'sourceId_assigned',
  SourceIdFailed = 'sourceId_failed',
  ValidationStarted = 'validation_started',
  ValidationCompleted = 'validation_completed',
  ValidationFailed = 'validation_failed',
  MetadataReceived = 'metadata_received',
  UploadStarted = 'upload_started',
  UploadProgress = 'upload_progress',
  UploadCompleted = 'upload_completed',
  UploadFailed = 'upload_failed',
  UploadCanceled = 'upload_canceled',
}

export interface UploadState {
  remainingFiles: FileInfo[];

  isUploadInProgress: boolean;

  //Processing file state
  status: FileUploadStatus;
  file?: File;
  type?: UploadTypes;
  extra?: OpenreelUploaderExtraOptions;
  metadata?: FileMetadata | null;
  sourceId?: number | string | null;
  progress: number;
  error?: string | null;
}

const INITIAL_STATE: UploadState = {
  remainingFiles: [],
  isUploadInProgress: false,
  status: FileUploadStatus.NotStarted,
  progress: 0,
};

export class OpenreelUploadManager<T extends UploadState = UploadState> extends ComponentStore<T> {
  protected _uploaderService: UploaderBaseService<UploadCredentials>;
  private _fileValidatorService: FileValidatorBaseService;
  private validatePdf = false;
  constructor(
    uploaderService: UploaderBaseService<UploadCredentials>,
    fileValidatorService: FileValidatorBaseService,
    protected readonly fileService: FileService
  ) {
    super(INITIAL_STATE as T);
    this._uploaderService = uploaderService;
    this._fileValidatorService = fileValidatorService;
  }

  private readonly cancelUploadSub = new Subject<void>();
  private readonly cancelUpload$ = this.cancelUploadSub.pipe(
    tap(() => {
      this._setProcessingFileStatus(FileUploadStatus.UploadCanceled);
      this._resetProcessingFileInfo();
    })
  );

  public readonly isUploadInProgress$ = this.select((state) => state.isUploadInProgress);
  public readonly status$ = this.select((state) => state.status);
  public readonly progress$ = this.select((state) => state.progress);
  public readonly file$ = this.select((state) => state.file);
  public readonly metadata$ = this.select((state) => state.metadata);
  public readonly sourceId$ = this.select((state) => state.sourceId);
  public readonly extra$ = this.select((state) => state.extra);

  public readonly remainingFiles$ = this.select((state) => state.remainingFiles);

  public readonly processingFileState$ = this.select((state) => ({
    status: state.status,
    file: state.file,
    type: state.type,
    extra: state.extra,
    metadata: state.metadata,
    sourceId: state.sourceId,
    progress: state.progress,
    error: state.error,
  })).pipe(
    distinctUntilChanged((prev, curr) => prev.status == curr.status && curr.status != FileUploadStatus.UploadProgress)
  );

  protected readonly _addFiles = this.updater((state, fileInfos: FileInfo[]) => ({
    ...state,
    remainingFiles: [...state.remainingFiles, ...fileInfos],
  }));

  private readonly _removeFile = this.updater((state, fileIndex: number) => {
    const remainingFiles = state.remainingFiles.filter((file, index) => index !== fileIndex);
    return { ...state, remainingFiles };
  });

  private readonly _clearFiles = this.updater((state) => ({
    ...state,
    remainingFiles: [],
  }));

  protected readonly _setUploadInProgress = this.updater((state, inProgress: boolean) => ({
    ...state,
    isUploadInProgress: inProgress,
  }));

  private readonly _setProcessingFileInfoAndRemainingFiles = this.updater(
    (state, [fileInfo, remainingFiles]: [FileInfo, FileInfo[]]) => ({
      ...state,
      remainingFiles: remainingFiles,
      status: FileUploadStatus.NotStarted,
      file: fileInfo.file,
      type: fileInfo.type,
      extra: fileInfo.extra,
      progress: 0,
      metadata: null,
      sourceId: null,
      error: null,
    })
  );

  private readonly _setProcessingFileStatus = this.updater((state, status: FileUploadStatus) => ({
    ...state,
    status,
  }));

  private readonly _setFileValidationFailed = this.updater((state, error: string) => ({
    ...state,
    status: FileUploadStatus.ValidationFailed,
    error,
  }));

  private readonly _setFileMetadata = this.updater((state, [file, metadata]: [File, FileMetadata]) => ({
    ...state,
    status: FileUploadStatus.MetadataReceived,
    file,
    metadata,
  }));

  private readonly _setFileUploadStarted = this.updater((state, id: string | number) => ({
    ...state,
    status: FileUploadStatus.UploadStarted,
    sourceId: id,
  }));

  private readonly _setSourceIdAndUpdateStatus = this.updater((state, id: string | number) => ({
    ...state,
    status: FileUploadStatus.SourceIdAssigned,
    sourceId: id,
  }));

  private readonly _setFileUploadProgress = this.updater((state, progress: number) => ({
    ...state,
    status: FileUploadStatus.UploadProgress,
    progress,
  }));

  private readonly _setFileUploadError = this.updater((state, error: string) => ({
    ...state,
    status: FileUploadStatus.UploadFailed,
    error,
  }));

  private readonly _resetProcessingFileInfo = this.updater((state) => ({
    ...state,
    status: FileUploadStatus.NotStarted,
    file: null,
    type: null,
    extra: null,
    progress: 0,
    metadata: null,
    sourceId: null,
    error: null,
  }));

  addFiles(files: FileInfo[]) {
    this._addFiles(files);
  }

  toValidatePdf() {
    this.validatePdf = true;
  }

  removeFile(index: number) {
    this._removeFile(index);
  }

  clearFiles() {
    this._clearFiles();
  }

  startUpload() {
    this._startUpload();
  }

  resetCurrentFileInfo() {
    this._resetProcessingFileInfo();
  }

  cancelCurrentUpload() {
    this.cancelUploadSub.next();
  }

  setSourceId(sourceId: string | number) {
    this._setSourceIdAndUpdateStatus(sourceId);
  }

  protected _getFileType(uploadType: UploadTypes) {
    return ACCEPTED_FILE_EXTENSIONS[uploadType].type;
  }

  protected readonly _startUpload = this.effect(($: Observable<void>) =>
    $.pipe(
      withLatestFrom(this.isUploadInProgress$, this.remainingFiles$),
      tap(([_, isInProgress, files]: [void, boolean, FileInfo[]]) => {
        if (isInProgress || !files.length) return;
        this._setUploadInProgress(true);
        this._startNextFileUpload();
      })
    )
  );

  private readonly _startNextFileUpload = this.effect(($: Observable<void>) =>
    $.pipe(
      debounceTime(50),
      withLatestFrom(this.remainingFiles$),
      tap(([_, files]) => {
        if (!files.length) this._setUploadInProgress(false);
      }),
      concatMap(([_, files]) => {
        if (!files.length) return EMPTY;
        const [fileInfo, ...rest] = files;
        return of([fileInfo, rest] as const);
      }),
      tap(([fileInfo, remainingFiles]) => this._setProcessingFileInfoAndRemainingFiles([fileInfo, remainingFiles])),
      switchMap(([fileInfo]) =>
        this.uploadFile(fileInfo).pipe(
          takeUntil(this.cancelUpload$),
          tap({
            error: (err: HttpErrorResponse | Error) => {
              if (err instanceof HttpErrorResponse && err?.error?.errorType === ErrorType.NoStorage) {
                this._clearFiles();
              }
              this._resetProcessingFileInfo();
            },
            finalize: () => {
              this._startNextFileUpload();
            },
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  protected uploadFile(fileInfo: FileInfo) {
    return of(fileInfo).pipe(
      // Validation step
      tap((_) => this._setProcessingFileStatus(FileUploadStatus.ValidationStarted)),
      switchMap((fileInfo) =>
        this.validate(fileInfo).pipe(
          tap({
            next: () => this._setProcessingFileStatus(FileUploadStatus.ValidationCompleted),
            error: (error: Error) => {
              this._setFileValidationFailed(error?.message);
            },
          })
        )
      ),
      // Validation step end

      // Metadata generation step
      switchMap((_) =>
        this.generateMetadata(fileInfo).pipe(tap(([metadata, file]) => this._setFileMetadata([file, metadata])))
      ),
      // Metadata generation step end

      // Upload step
      switchMap(([metadata, file]) =>
        this.fetchCredentials({ file: file, type: fileInfo.type }, metadata).pipe(
          tap({
            next: (credentials) => this._setFileUploadStarted(credentials.id),
            error: (err: HttpErrorResponse | Error) => this._setFileUploadError(this._getMessageFromError(err)),
          }),
          switchMap((credentials) =>
            this._uploaderService.upload(credentials.credential, file, credentials.id).pipe(
              tap({
                next: (event) => {
                  const progress = !event.total ? 0 : Math.round((event.loaded / event.total) * 100);
                  this._setFileUploadProgress(progress);
                },
                error: (error: Error) => {
                  this._setFileUploadError(error?.message);
                },
                complete: () => this._setProcessingFileStatus(FileUploadStatus.UploadCompleted),
              })
            )
          )
        )
      )
      // Upload step end
    );
  }

  protected validate(fileInfo: FileInfo) {
    const [name, extension] = splitNameAndExtension(fileInfo.file.name);
    if (!extension) return throwError(() => new Error('Invalid file type'));
    if (!this._fileValidatorService && !this.validatePdf) {
      return of(null);
    }
    const message = this._fileValidatorService?.validate(fileInfo.file);
    const validation$ = isObservable(message) ? message : of(message);

    return validation$.pipe(
      switchMap((errorMessage) => (!errorMessage ? of(errorMessage) : throwError(() => new Error(errorMessage))))
    );
  }

  protected generateMetadata(fileInfo: FileInfo) {
    const fileType = this._getFileType(fileInfo.type);

    return forkJoin([
      this.fileService.getFileMetadata(fileType, fileInfo.file),
      this.fileService.stripMetadata(fileType, fileInfo.file),
    ]);
  }

  protected fetchCredentials(fileInfo: FileInfo, metadata: FileMetadata) {
    const [name, extension] = splitNameAndExtension(fileInfo.file.name);
    return this._uploaderService.getUploadCredentials({
      type: this._getFileType(fileInfo.type),
      extension,
      name,
      fileSize: fileInfo.file.size,
      fileResolution: metadata?.resolution,
      duration: metadata?.durationMs,
      feature: fileInfo.type,
    });
  }

  protected _getMessageFromError(err: HttpErrorResponse | Error): string {
    const message = (err instanceof HttpErrorResponse && err.error?.message) || err?.message;
    return message;
  }
}
