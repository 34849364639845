import {
  Asset,
  LayerStyles,
  ProjectFont,
  SectionId,
  SolidColor,
  TemplateBuilderPlaceholders,
  TemplateBuilderSectionAspectRatioFrom,
  TemplateBuilderSectionForm,
  TemplateSection,
} from '../interfaces';
import { createIntroOutroSectionFromBuilder, createVideoAsset } from '../template-builder';
import { WorkflowTemplatesBaseBuilder } from './workflow-templates-base.builder';

const DEFAULT_VIDEO_STYLES: LayerStyles = {
  border: { x: 0, y: 0, color: '#ffffffff' },
  borderRadius: [
    { x: 0, y: 0 },
    { x: 0, y: 0 },
    { x: 0, y: 0 },
    { x: 0, y: 0 },
  ],
  backgroundColor: { type: 'solid', color: '#ffffffff' },
  objectFit: 'cover',
};

const DEFAULT_SECTIONS: TemplateBuilderSectionForm[] = [
  {
    sectionId: SectionId.Intro,
    isEnabled: false,
    useSameSectionForAllAspectRatios: true,
    aspectRatios: [],
  },
  {
    sectionId: SectionId.Outro,
    isEnabled: false,
    useSameSectionForAllAspectRatios: true,
    aspectRatios: [],
  },
];

const DEFAULT_ASPECT_RATIO: TemplateBuilderSectionAspectRatioFrom = {
  aspectRatioId: null,
  layers: [
    { type: 'main', asset: null, hasAudio: false, startAt: null },
    { type: 'overlays', asset: null, hasAudio: false, startAt: null },
  ],
  background: null,
  transition: null,
};

export interface BuilderTemplateCreateCommandEvent {
  defaultFont: ProjectFont;
  defaultAspectRatio: { id: number; width: number; height: number };
  defaultPlaceholders: TemplateBuilderPlaceholders;
  aspectRatios: { id: number }[];
}

export class BuilderTemplateCreateCommand extends WorkflowTemplatesBaseBuilder<BuilderTemplateCreateCommandEvent> {
  run(event: BuilderTemplateCreateCommandEvent) {
    this.fillGlobalAssetsLogo();
    this.fillGlobalAssetsWatermark();
    this.fillGlobalBackground();
    this.fillGlobalAssetsSoundtrack();
    this.fillLayerStyles();

    // Add intro/outro sections
    for (const { sectionId, isEnabled, useSameSectionForAllAspectRatios } of DEFAULT_SECTIONS) {
      const baseTemplateSection = createIntroOutroSectionFromBuilder(
        sectionId,
        DEFAULT_ASPECT_RATIO,
        this.source
      ) as TemplateSection;

      this.source.templateSections[sectionId] = {
        sectionId,
        isEnabled,
        useSameSectionForAllAspectRatios,
        sections: event.aspectRatios.map(({ id }) => ({ ...baseTemplateSection, aspectRatioId: id })),
      };
    }

    // Add default main section (empty for now)
    this.addDefaultMainSection();

    // Apply default aspect ratio
    this.applyDefaultAspectRatio(event.defaultAspectRatio);

    // Apply placeholders
    this.upsertPlaceholders(event.defaultPlaceholders);

    // Apply to all sections
    this.fillBackgroundColor();
    this.updateGlobalFont(event.defaultFont);

    return this.ok();
  }

  private fillGlobalAssetsLogo() {
    const asset: Asset = {
      id: this.getUniqueId(),
      type: 'image',
      isGlobal: true,
    };
    this.source.assets.push(asset);

    this.source.globalSettings.logo.settings.assetId = asset.id;
  }

  private fillGlobalAssetsWatermark() {
    const asset: Asset = {
      id: this.getUniqueId(),
      type: 'image',
      isGlobal: true,
    };
    this.source.assets.push(asset);

    this.source.globalSettings.watermark.settings.assetId = asset.id;
  }

  private fillGlobalBackground() {
    const asset: Asset = {
      ...createVideoAsset(null, 0),
      isGlobal: true,
    };
    this.source.assets.push(asset);

    this.source.globalSettings.backgroundAsset.settings.assetId = asset.id;
  }

  private fillGlobalAssetsSoundtrack() {
    const asset: Asset = {
      id: this.getUniqueId(),
      type: 'audio',
      isGlobal: true,
    };
    this.source.assets.push(asset);

    this.source.globalSettings.soundtrack.assetId = asset.id;
  }

  private fillLayerStyles() {
    this.source.features.layouts = { styles: DEFAULT_VIDEO_STYLES };
  }

  private fillBackgroundColor() {
    const backgroundColor: SolidColor = { type: 'solid', color: '#ffffff' };

    this.source.globalSettings.backgroundColor = backgroundColor;

    for (const [, section] of Object.entries(this.source.sections)) {
      section.backgroundColor = backgroundColor;
    }
  }
}
