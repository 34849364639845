import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { combineLatest, interval } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import {
  AudioDevice,
  Cleanupable,
  VideoDevice,
  VideoSource,
  VideoStream,
  MediaStreamService,
  LobbyDeviceSelection,
  FeatureFlaggingService,
  AudioStream,
} from '@openreel/frontend/common';
import { Store } from '@ngrx/store';
import {
  selectAudioStream,
  selectAvailableAudioDevices,
  selectAvailableScreencastSources,
  selectAvailableVideoDevices,
  selectAvailableWebcamDevices,
  selectVideoStream,
  selectVideoSource,
  selectAvailableAudioOutputDevices,
} from '../../../app-state/session/stream/stream.selectors';
import {
  changeVideoSource,
  closeAllVideoStreams,
  openAudioInputDevice,
  openScreencastSource,
  openWebcamInputDevice,
  requestDevicesList,
} from '../../../app-state/session/stream/stream.actions';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { OpenreelAudioVideoStreamTestComponent } from '@openreel/ui/stream/openreel-audio-video-stream-test';
import { CAPTURE_PRO_SPEAKER_SELECTION, CAPTURE_PRO_TEST_AUDIO_VIDEO } from '@openreel/common';
import { selectSessionDetails, selectSessionId } from '../../../director/director.selectors';
// TODO: In the future, this component should also offer ability to make a test
// recording of self, and preview the recording, similar how Skype has test
// call feature.
/**
 * This is a small component that lets user change camera/microphone. The data
 * (selected mic/webcam) is stored in SessionConfig service. From this service
 *  all other services can pick up what is currently selected mic/webcam.
 */
@Component({
  selector: 'openreel-lobby',
  templateUrl: './lobby.component.html',
  styleUrls: ['./lobby.component.scss'],
})
export class LobbyComponent extends Cleanupable implements OnInit, OnDestroy {
  @Output()
  nextPressed = new EventEmitter<LobbyDeviceSelection>();

  @Output()
  selectionChange = new EventEmitter<LobbyDeviceSelection>();
  /**
   * If this is true, the lobby will automatically select first available
   * streams, so user can get done with this step sooner
   */
  @Input()
  quickSelect = false;
  /**
   * Used to manually update video element's video feed
   */
  @ViewChild('videoElement') videoElement: ElementRef<HTMLVideoElement>;

  /**
   * This system's possible video sources. Right now, we show webcam/desktop in
   * this list
   */
  supportedVideoSources: VideoSource[] = [];

  /**
   * All the possible video devices.
   */
  supportedVideoDevices: VideoDevice[] = [];
  /**
   * Video devices for the selected video source.
   */
  videoDevices: VideoDevice[] = [];
  /**
   * Selected video device identifier. This is used to uniquely identify this
   * device in our system
   */
  selectedVideoDevice: VideoDevice;
  /**
   * A full list of audio devices.
   */
  audioDevices: AudioDevice[] = [];

  audioOutputDevices: AudioDevice[] = [];
  /**
   * The selected audio device.
   */
  selectedAudioDevice: AudioDevice;

  selectedAudioOutputDevice: AudioDevice;
  /**
   * Enum used for html
   */
  VideoSource = VideoSource;
  /**
   * Whether there is a transition between video devices.
   */
  isTransitioningVideo = false;
  /**
   * Whether there is a transition between audio devices.
   */
  isTransitioningAudio = false;
  /**
   * Currently selected audio device's track. It is passed into the audio meter.
   */
  audioStreamTrack: MediaStreamTrack;
  /**
   * Currently selected video device's track. It is passed into the video component.
   */
  videoStreamTrack: MediaStreamTrack;
  /**
   * The audio error string to show in the interface.
   */
  audioStreamError: string;
  /**
   * The video error string to show in the interface.
   */
  videoStreamError: string;
  /**
   * Flag to manage streams remaining open after pressing next.
   */
  keepStreamsOpen = false;

  /**
   * Can proceed to after the lobby or not
   */
  canPressNext = false;

  /**
   * If the video is mirrored or not
   */
  mirrored = true;

  /**
   * An Interval to calculate if it's OK to press Next
   * The reason why
   */
  private canPressNextInterval;

  audioStream$ = this.store.select(selectAudioStream);
  videoStream$ = this.store.select(selectVideoStream);

  /**
   * The currently selected video source
   */
  selectedVideoSource$ = this.store.select(selectVideoSource);
  selectedVideoSource: VideoSource = VideoSource.WEBCAM;
  availableWebcamDevices$ = this.store.select(selectAvailableWebcamDevices);
  availableScreenshareSources$ = this.store.select(selectAvailableScreencastSources);
  availableVideoDevices$ = this.store.select(selectAvailableVideoDevices);

  availableAudioDevices$ = this.store.select(selectAvailableAudioDevices);
  availableAudioOutputDevices$ = this.store.select(selectAvailableAudioOutputDevices);
  enableTestAudioVideo$ = this.featureFlaggingService.isFeatureFlagEnabled(CAPTURE_PRO_TEST_AUDIO_VIDEO);
  enableSpeakerSelection$ = this.featureFlaggingService.isFeatureFlagEnabled(CAPTURE_PRO_SPEAKER_SELECTION);

  constructor(
    private readonly mediaStreamService: MediaStreamService,
    // private readonly audioStreamService: AudioStreamService
    private store: Store,
    private readonly dialog: MatDialog,
    private featureFlaggingService: FeatureFlaggingService
  ) {
    super();
    this.supportedVideoSources = this.mediaStreamService.getSupportedSources();

    this.canPressNextInterval = interval(1000)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.calculateCanPressNext();
      });
  }

  async ngOnDestroy() {
    super.ngOnDestroy();
  }

  async ngOnInit() {
    this.keepStreamsOpen = false;

    this.subscriptions.push(
      this.availableVideoDevices$.subscribe((videoDevices) => {
        this.onVideoDeviceListChange(videoDevices);
      }),
      this.selectedVideoSource$.pipe(filter((videoSource) => videoSource != null)).subscribe((videoSource) => {
        this.selectedVideoSource = videoSource;
        this.changeVideoSource();
      }),

      this.availableAudioDevices$.subscribe((audioDevices) => {
        this.onAudioDeviceListChange(audioDevices);
      }),
      this.availableAudioOutputDevices$.subscribe((audioDevices) => {
        this.onAudioOutputDeviceListChange(audioDevices);
      }),
      this.audioStream$.subscribe((audioStream) => {
        this.onAudioStreamChange(audioStream);
      }),
      this.videoStream$
        .pipe(
          debounceTime(300), // To avoid the play/pause issue on chrome. Should eventually be replaced for a more long term fix
          filter((videoStream) => videoStream != null)
        )
        .subscribe((videoStream) => {
          this.onVideoStreamChange(videoStream);
        }),
      combineLatest([this.store.select(selectSessionDetails), this.store.select(selectSessionId)])
        .pipe(filter(([details, id]) => Boolean(details) || (Boolean(details) === false && Boolean(id) === false)))
        .subscribe(() => this.store.dispatch(requestDevicesList({ keepStream: true })))
    );
  }

  /* Video-related code */

  private onVideoDeviceListChange(videoDevices: VideoDevice[]) {
    if (videoDevices.length !== this.supportedVideoDevices.length) {
      this.selectedVideoDevice = null;
      this.supportedVideoDevices = videoDevices;
      this.changeVideoSource();
    }
  }

  async changeVideoSource() {
    this.videoDevices = this.supportedVideoDevices.filter((device) => device.source === this.selectedVideoSource);
    if (
      this.videoDevices.length > 0 &&
      (!this.selectedVideoDevice || this.selectedVideoDevice.source !== this.selectedVideoSource)
    ) {
      this.selectedVideoDevice = this.videoDevices[0];
      await this.changeVideoDevice();
    }
  }

  private async onVideoStreamChange(videoStream: VideoStream) {
    this.selectedVideoDevice = this.videoDevices.find((device) => device.id === videoStream.device.id);
    //store webcam device in session storage to persist the selection on audio video test popup
    sessionStorage.setItem('openreel-selected-webcam', JSON.stringify(this.selectedVideoDevice));
    this.videoStreamTrack = videoStream?.track;
    if (this.videoStreamTrack) {
      this.videoStreamTrack.addEventListener('ended', () => {
        this.videoStreamError = 'Video stream not available. Please change the video source.';
      });
    }

    if (!this.videoElement) {
      this.isTransitioningVideo = false;
      return;
    }

    this.selectionChange.emit({
      audioInput: this.selectedAudioDevice,
      video: this.selectedVideoDevice,
      audioOutput: this.selectedAudioOutputDevice,
    });

    if (videoStream) {
      if (!videoStream.error) {
        this.videoElement.nativeElement.srcObject = new MediaStream([this.videoStreamTrack]);
        this.isTransitioningVideo = false;
        this.videoElement.nativeElement.onerror = () => {
          this.videoStreamError = 'Could not start the stream';
        };

        this.checkQuickSelect();
      } else {
        this.handleVideoStreamError(videoStream);
        this.isTransitioningVideo = false;
      }
    }

    this.calculateCanPressNext();
  }

  private handleVideoStreamError(videoStream: VideoStream) {
    if (videoStream.device.source === VideoSource.DESKTOP) {
      if (videoStream.error.name === 'NotAllowedError') {
        this.videoStreamError = "Can't share your screen. You must grant permissions.";
      }
    } else {
      if (videoStream.error.name === 'NotAllowedError') {
        this.videoStreamError = 'You must grant permissions to use the camera.';
      }
    }

    if (!this.videoStreamError) {
      this.videoStreamError = videoStream.error?.message;
    }
  }

  async changeVideoDevice() {
    if (this.isTransitioningVideo) {
      return;
    }
    this.isTransitioningVideo = true;
    this.videoStreamError = null;
    this.store.dispatch(closeAllVideoStreams());
    if (this.selectedVideoSource === VideoSource.WEBCAM) {
      this.store.dispatch(openWebcamInputDevice({ device: this.selectedVideoDevice }));
    } else {
      this.store.dispatch(openScreencastSource());
    }
    this.store.dispatch(changeVideoSource({ source: this.selectedVideoSource }));
    this.calculateCanPressNext();
  }

  openAudioVideoTest() {
    const dialogRef = this.dialog.open(OpenreelAudioVideoStreamTestComponent, {
      hasBackdrop: false,
      disableClose: true,
      width: '80vw',
      height: '75vh',
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.selectedAudioDevice = result.selectedAudioDevice;
      this.selectedAudioOutputDevice = result.selectedSpeakerDevice;
      this.changeAudioDevice();
      this.changeAudioOutputDevice();
      if (this.selectedVideoSource === VideoSource.WEBCAM) {
        this.selectedVideoDevice = result.selectedVideoDevice;
        this.changeVideoDevice();
      }
    });
  }

  /* Audio-related code */

  private async onAudioDeviceListChange(audioDevices: AudioDevice[]) {
    if (audioDevices.length !== this.audioDevices.length) {
      this.audioDevices = audioDevices;
      this.selectedAudioDevice = null;
      await this.refreshAudioDeviceList();
    }
  }

  private onAudioOutputDeviceListChange(audioDevices: AudioDevice[]) {
    if (audioDevices.length !== this.audioOutputDevices.length) {
      this.audioOutputDevices = audioDevices;
      this.selectedAudioOutputDevice = null;
      this.refreshAudioOutputDeviceList();
    }
  }

  private refreshAudioOutputDeviceList() {
    if (this.audioOutputDevices.length > 0 && !this.selectedAudioOutputDevice) {
      const defaultDevice = this.audioOutputDevices.find((device) => device.isDefault);
      this.selectedAudioOutputDevice = defaultDevice ? defaultDevice : this.audioOutputDevices[0];
      this.changeAudioOutputDevice();
    }
  }

  private async refreshAudioDeviceList() {
    if (this.audioDevices.length > 0 && !this.selectedAudioDevice) {
      const defaultDevice = this.audioDevices.find((device) => device.isDefault);
      this.selectedAudioDevice = defaultDevice ? defaultDevice : this.audioDevices[0];
      await this.changeAudioDevice();
    }
  }

  async changeAudioDevice() {
    if (this.isTransitioningAudio) {
      return;
    }

    this.isTransitioningAudio = true;
    this.audioStreamError = null;

    try {
      this.store.dispatch(openAudioInputDevice({ device: this.selectedAudioDevice }));
    } catch (error) {
      this.isTransitioningAudio = false;
      this.videoStreamError = 'Could not open the selected audio stream.';
    }
    this.calculateCanPressNext();
  }

  private onAudioStreamChange(audioStream: AudioStream) {
    this.selectedAudioDevice = this.audioDevices.find((device) => device.id === audioStream.device.id);
    //store audio device in session storage to persist the selection on audio video test popup
    sessionStorage.setItem('openreel-selected-audio', JSON.stringify(this.selectedAudioDevice));

    this.audioStreamTrack = audioStream?.track;
    if (this.audioStreamTrack) {
      this.audioStreamTrack.addEventListener('ended', () => {
        this.audioStreamError = 'Audio stream not available. Please change the audio source.';
      });
    }
    this.isTransitioningAudio = false;

    if (this.selectedAudioDevice) {
      this.selectionChange.emit({
        audioInput: this.selectedAudioDevice,
        video: this.selectedVideoDevice,
        audioOutput: this.selectedAudioOutputDevice,
      });
    }

    if (!audioStream?.error) {
      this.checkQuickSelect();
    } else {
      if (audioStream.error.name === 'NotAllowedError') {
        this.audioStreamError = 'You must grant permissions to use the microphone.';
      } else {
        this.audioStreamError = audioStream?.error.message;
      }
    }

    this.calculateCanPressNext();
  }

  changeAudioOutputDevice() {
    this.selectedAudioOutputDevice = this.audioOutputDevices.find(
      (device) => device.id === this.selectedAudioOutputDevice.id
    );
    //store audio output device in session storage to persist the selection on audio video test popup
    sessionStorage.setItem('openreel-selected-speaker', JSON.stringify(this.selectedAudioOutputDevice));
    if (this.selectedAudioOutputDevice) {
      this.selectionChange.emit({
        audioInput: this.selectedAudioDevice,
        video: this.selectedVideoDevice,
        audioOutput: this.selectedAudioOutputDevice,
      });
    }
  }

  calculateCanPressNext() {
    this.canPressNext =
      !this.isTransitioningAudio &&
      !this.isTransitioningVideo &&
      this.selectedAudioDevice &&
      this.selectedVideoDevice &&
      !this.audioStreamError &&
      !this.videoStreamError &&
      this.audioStreamTrack?.readyState === 'live' &&
      this.videoStreamTrack?.readyState === 'live';
  }

  /**
   * Check if all streams are configured and ready so we can get out of this
   * step, if we want to quickly get rid of this step.
   */
  private checkQuickSelect() {
    if (this.quickSelect && this.canPressNext) {
      this.confirmed();
    }
  }

  /**
   * Confirm button pressed from UI
   */
  confirmed() {
    this.nextPressed.next(null);
  }
}
