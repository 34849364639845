import { bubbleSort, doTimeRangesIntersect, rangeByEndSorter } from '@openreel/common';
import { cloneDeep, range } from 'lodash';
import { CaptionEditorTimebox, CaptionSpeaker } from '../../interfaces/caption.interface';
import { CombinedTimebox, TimeboxInfo } from '../interfaces/combined-timebox.interface';
import { SubtitleBuilderStrategy } from './subtitle-builder-strategy';

export abstract class CombinedTimeboxesStrategy extends SubtitleBuilderStrategy {
  protected timeboxes: TimeboxInfo[];
  private combinedTimeboxes: CombinedTimebox[];

  protected content = '';

  constructor(timeboxes: CaptionEditorTimebox[], speakers: CaptionSpeaker[], protected readonly offset: number) {
    super(speakers);

    this.timeboxes = timeboxes.map((timebox) => ({
      id: timebox.id,
      from: timebox.start,
      to: timebox.end,
      text: timebox.text,
      speakerId: timebox.speakerId,
      intersectedSpeakerIds: new Set(timebox.speakerId ? [timebox.speakerId] : []),
      showSpeaker: false,
    }));
  }

  buildCues(): void {
    this.normalizeTimeboxes();

    for (let i = 0; i < this.combinedTimeboxes.length; i++) {
      this.addCue(this.combinedTimeboxes[i], i);
    }
  }

  protected abstract addCue(timebox: CombinedTimebox, cueIndex: number): void;

  protected abstract toCueTime(timeMs: number): string;

  protected normalizeTimeboxes() {
    this.setIntersectingSpeakers();
    this.setShowSpeakerInTimeboxes();

    this.buildCombinedTimeboxes();
  }

  private setIntersectingSpeakers() {
    for (let i = 0; i < this.timeboxes.length; i++) {
      const currentTimebox = this.timeboxes[i];

      for (let j = i + 1; j < this.timeboxes.length; j++) {
        const nextTimebox = this.timeboxes[j];

        if (!doTimeRangesIntersect(currentTimebox, nextTimebox)) {
          break;
        }

        const currentTimeboxSpeakers = [...currentTimebox.intersectedSpeakerIds];
        const nextTimeboxSpeakers = [...nextTimebox.intersectedSpeakerIds];

        currentTimeboxSpeakers.forEach((speakerId) => nextTimebox.intersectedSpeakerIds.add(speakerId));
        nextTimeboxSpeakers.forEach((speakerId) => currentTimebox.intersectedSpeakerIds.add(speakerId));
      }
    }
  }

  private setShowSpeakerInTimeboxes() {
    const indexMap = range(0, this.timeboxes.length);

    const timeboxesSortedByEndTime = bubbleSort<TimeboxInfo>(
      [...this.timeboxes],
      rangeByEndSorter,
      (i1, i2) => ([indexMap[indexMap[i1]], indexMap[indexMap[i2]]] = [indexMap[indexMap[i2]], indexMap[indexMap[i1]]])
    );

    for (let i = 0; i < this.timeboxes.length; i++) {
      const currentTimebox = this.timeboxes[i];

      if (!currentTimebox.speakerId || !currentTimebox.intersectedSpeakerIds.size) {
        continue;
      }

      if (currentTimebox.intersectedSpeakerIds.size > 1) {
        currentTimebox.showSpeaker = true;
        continue;
      }

      const previousShownTimebox = this.findPreviousShownTimebox(i, timeboxesSortedByEndTime, indexMap);
      if (!previousShownTimebox) {
        currentTimebox.showSpeaker = true;
        continue;
      }

      if (
        currentTimebox.intersectedSpeakerIds.size !== previousShownTimebox.intersectedSpeakerIds.size ||
        [...currentTimebox.intersectedSpeakerIds][0] !== [...previousShownTimebox.intersectedSpeakerIds][0]
      ) {
        currentTimebox.showSpeaker = true;
      }
    }
  }

  private findPreviousShownTimebox(currentIndex: number, timeboxesSortedByEndTime: TimeboxInfo[], indexMap: number[]) {
    const currentIndexByEnd = indexMap[currentIndex];
    let previousIndexByEnd: number;

    for (previousIndexByEnd = currentIndexByEnd - 1; previousIndexByEnd >= 0; previousIndexByEnd--) {
      if (timeboxesSortedByEndTime[previousIndexByEnd].to <= timeboxesSortedByEndTime[currentIndex].from) {
        break;
      }
    }

    return timeboxesSortedByEndTime[previousIndexByEnd] ?? null;
  }

  private buildCombinedTimeboxes() {
    this.combinedTimeboxes = this.timeboxes.map((t) => ({
      from: t.from,
      to: t.to,
      texts: [
        {
          text: t.text,
          speakerId: t.speakerId,
          showSpeaker: t.showSpeaker,
        },
      ],
    }));

    for (let i = 0; i < this.combinedTimeboxes.length; i++) {
      const currentTimebox = this.combinedTimeboxes[i];

      for (let j = i + 1; j < this.combinedTimeboxes.length; j++) {
        const nextTimebox = this.combinedTimeboxes[j];

        if (!doTimeRangesIntersect(currentTimebox, nextTimebox)) {
          break;
        }

        const result = this.combineTimeboxes(currentTimebox, nextTimebox);

        if (result.postCombinedTimebox !== nextTimebox) {
          this.combinedTimeboxes.splice(j, 1);

          if (result.postCombinedTimebox) {
            const position = this.findTimeboxPosition(this.combinedTimeboxes, result.postCombinedTimebox, j);
            this.combinedTimeboxes.splice(position, 0, result.postCombinedTimebox);
          }

          j--;
        }

        if (result.preCombinedTimebox !== currentTimebox) {
          this.combinedTimeboxes.splice(i, 1);

          if (result.preCombinedTimebox) {
            this.combinedTimeboxes.splice(i, 0, result.preCombinedTimebox);
          } else {
            i--;
          }
        }

        if (result.combinedTimebox) {
          const position = this.findTimeboxPosition(this.combinedTimeboxes, result.combinedTimebox, j);
          this.combinedTimeboxes.splice(position, 0, result.combinedTimebox);
        }
      }
    }
  }

  // NOTE: This function assumes that currentTimebox and nextTimebox are in the correct order and have intersection
  private combineTimeboxes(
    currentTimebox: CombinedTimebox,
    nextTimebox: CombinedTimebox
  ): {
    preCombinedTimebox: CombinedTimebox;
    combinedTimebox: CombinedTimebox;
    postCombinedTimebox: CombinedTimebox;
  } {
    const currentTimeboxTo = currentTimebox.to;

    let preCombinedTimebox: CombinedTimebox = currentTimebox;
    let combinedTimebox: CombinedTimebox = null;
    let postCombinedTimebox: CombinedTimebox = nextTimebox;

    combinedTimebox = {
      from: Math.max(currentTimebox.from, nextTimebox.from),
      to: Math.min(currentTimebox.to, nextTimebox.to),
      texts: [...nextTimebox.texts, ...currentTimebox.texts],
    };

    if (nextTimebox.from === currentTimebox.from) {
      preCombinedTimebox = null;
    } else {
      preCombinedTimebox.to = nextTimebox.from;
    }

    if (nextTimebox.to === currentTimeboxTo) {
      postCombinedTimebox = null;
    } else if (nextTimebox.to > currentTimeboxTo) {
      postCombinedTimebox.from = currentTimeboxTo;
    } else {
      postCombinedTimebox = cloneDeep(currentTimebox);
      postCombinedTimebox.from = nextTimebox.to;
      postCombinedTimebox.to = currentTimeboxTo;
    }

    return {
      preCombinedTimebox,
      combinedTimebox,
      postCombinedTimebox,
    };
  }

  private findTimeboxPosition(timeboxes: CombinedTimebox[], timebox: CombinedTimebox, pivot: number) {
    let index = pivot;

    for (; index < timeboxes.length; index++) {
      if (timebox.from <= timeboxes[index].from) {
        break;
      }
    }

    for (index--; index >= 0; index--) {
      if (timebox.from >= timeboxes[index].from) {
        index++;
        break;
      }
    }

    return index;
  }
}
