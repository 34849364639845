import { AssetId, Bounds, ImageLayer, LayerOptions } from '../interfaces';
import { SectionEvent } from '../interfaces/timelines.interfaces';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export interface AddImageLayerEvent extends SectionEvent {
  assetFileId: AssetId;
  width: number;
  height: number;
}

const IMAGE_BOUNDS_STARTING_WIDTH_PCT = 40;

export class AddImageLayerCommand extends WorkflowBaseBuilder<AddImageLayerEvent, LayerOptions & ImageLayer> {
  run(event: AddImageLayerEvent) {
    const projectAspectRatio =
      this.source.globalSettings.resolution.width / this.source.globalSettings.resolution.height;
    const imageAspectRatio = event.width / event.height;
    const initialHeightPct = (IMAGE_BOUNDS_STARTING_WIDTH_PCT / imageAspectRatio) * projectAspectRatio;

    const bounds: Bounds = {
      x: 50 - IMAGE_BOUNDS_STARTING_WIDTH_PCT / 2,
      y: 50 - initialHeightPct / 2,
      width: IMAGE_BOUNDS_STARTING_WIDTH_PCT,
      height: initialHeightPct,
    };

    const layer = this.addImageLayer(event.sectionId, event.assetFileId, bounds);

    return this.ok(layer);
  }
}
