import { Asset, GlobalSettings, Style, Transitions } from './../interfaces/workflow.interfaces';
import { createLottieAsset } from './createLottieAsset';
import { getLayerBounds } from './getLayerBounds';
import { v4 } from 'uuid';
import { TemplateBuilderSectionTransition } from './../interfaces/template-builder.interfaces';

export function createTransitionsFromBuilder(
  builderTransition: TemplateBuilderSectionTransition,
  globalSettings: GlobalSettings
) {
  if (!builderTransition?.type || builderTransition.type === 'none') {
    return null;
  }

  const assets: Asset[] = [];
  const styles: Style[] = [];

  const transitions: Transitions = {};

  switch (builderTransition.type) {
    case 'fade': {
      transitions['exit'] = {
        type: 'fade-out',
        duration: builderTransition.duration,
        color: builderTransition.color,
      };
      break;
    }
    case 'push': {
      transitions['cross'] = {
        type: 'push',
        duration: builderTransition.duration,
        direction: builderTransition.direction,
        timingFunction: builderTransition.motionCurve,
      };
      break;
    }
    case 'cross': {
      transitions['cross'] = {
        type: 'fade-out',
        duration: builderTransition.duration,
      };
      break;
    }
    case 'crossLayer': {
      if (builderTransition.asset?.assetFileId) {
        const { asset, layerData, layerStyles } = createLottieAsset(builderTransition.asset, globalSettings);

        assets.push(asset);
        styles.push(...layerStyles);

        transitions['crossLayer'] = {
          type: 'layer',
          layer: {
            layerId: v4(),
            type: 'lottie',
            enabled: true,
            bounds: getLayerBounds(builderTransition.asset.bounds),
            assetId: asset.id,
            loop: false,
            renderer: 'svg',
            data: layerData,
            styles: {
              objectFit: builderTransition.fit || 'contain',
            },
          },
          duration: builderTransition.duration,
          midpoint: builderTransition.midpoint,
        };
      }
      break;
    }
  }

  return { transitions, assets, styles };
}
