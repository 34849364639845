import { BrandKitAssets, BrandKitPermissions, FileAssetType, GlobalSettingsAsset } from '../interfaces';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export interface UpdateProjectFromBrandKitTemplateSettings {
  applyBrandKitLogo: boolean;
  applyBrandKitWatermark: boolean;
  applyBrandKitBackgroundVideo: boolean;
  applyBrandKitSoundtrack: boolean;
}

export interface UpdateProjectFromBrandKitEventSountrack {
  id: number;
  assetId: number;
}

export interface UpdateProjectFromBrandKitEvent {
  isProjectCreation: boolean;
  templateSettings: UpdateProjectFromBrandKitTemplateSettings;
  brandKitAssets: BrandKitAssets;
  brandKitPermissions: BrandKitPermissions;
  projectAction: 'create' | 'update';
  canOverwriteFonts: boolean;
  soundtracks: UpdateProjectFromBrandKitEventSountrack[];
}

export class UpdateProjectFromBrandKitCommand extends WorkflowBaseBuilder<UpdateProjectFromBrandKitEvent, boolean> {
  private shouldCheckPermissions: boolean;

  run(event: UpdateProjectFromBrandKitEvent) {
    // NOTE: on create project we apply brand kit always since there are no user changes to keep
    this.shouldCheckPermissions = event.projectAction === 'update';

    this.transformAssets(event);
    if (event.canOverwriteFonts) {
      this.transformFonts(event);
    }
    this.transformColors(event);
    this.transformSoundtrack(event);

    return this.ok();
  }

  private transformAssets(event: UpdateProjectFromBrandKitEvent) {
    const types: FileAssetType[] = [];
    if (event.templateSettings.applyBrandKitLogo) {
      types.push('logo');
    }
    if (event.templateSettings.applyBrandKitWatermark) {
      types.push('watermark');
    }
    if (event.templateSettings.applyBrandKitBackgroundVideo) {
      types.push('background-video');
    }

    const brandKitAssets = event.brandKitAssets.assets;

    types
      .map((type) => ({
        type,
        defaultBrandKitAsset: brandKitAssets.data.find((asset) => asset.type === type && asset.isDefault),
      }))
      .filter(({ defaultBrandKitAsset }) => !!defaultBrandKitAsset)
      .forEach(({ type, defaultBrandKitAsset }) => {
        if (
          this.shouldCheckPermissions &&
          this.canUserEditAssetType(defaultBrandKitAsset.type, event.brandKitPermissions)
        ) {
          return;
        }

        const { asset } = defaultBrandKitAsset;

        let globalAsset: GlobalSettingsAsset;
        switch (type) {
          case 'logo':
            globalAsset = this.source.globalSettings.logo;
            break;
          case 'watermark':
            globalAsset = this.source.globalSettings.watermark;
            break;
          case 'background-video':
            globalAsset = this.source.globalSettings.backgroundAsset;
            break;
        }

        const globalAssetId = globalAsset?.settings?.assetId;
        if (!globalAssetId) {
          return;
        }

        // Update settings
        globalAsset.settings.enabled = true;

        // Update global asset
        const affectedAsset = this.source.assets.find((a) => a.id === globalAssetId);
        if (affectedAsset) {
          affectedAsset.file = asset.file;
          affectedAsset.type = asset.type;
        }

        // Update layer types (only for background since its asset can flip between video and image)
        if (type === 'background-video') {
          for (const [, section] of Object.entries(this.source.sections)) {
            const backgroundTimeline = section.timelines.find((t) => t.type === 'background');
            this.updateBackgroundAssetForTimelines([backgroundTimeline], affectedAsset.id);
          }
        }
      });
  }

  private transformFonts(event: UpdateProjectFromBrandKitEvent) {
    if (this.shouldCheckPermissions && event.brandKitPermissions.font.user.canEdit) {
      return;
    }

    // NOTE: if the text measurer is set, we update the text bounds
    this.updateGlobalFont(event.brandKitAssets.font, !!WorkflowBaseBuilder.textBoxMeasurer);
  }

  private transformSoundtrack(event: UpdateProjectFromBrandKitEvent) {
    if (
      (this.shouldCheckPermissions && event.brandKitPermissions.soundtrack.user.canEdit) ||
      !event.templateSettings.applyBrandKitSoundtrack
    ) {
      return;
    }

    const soundtrack = event.soundtracks.find((st) => st.id === event.brandKitAssets.soundtrack.soundtrackId);

    if (soundtrack) {
      const sourceAsset = this.source.assets.find(
        (asset) => asset.id === this.source.globalSettings.soundtrack.assetId
      );
      sourceAsset.file = {
        path: soundtrack.assetId,
        provider: 'or-assets',
      };

      this.source.globalSettings.soundtrack = {
        ...this.source.globalSettings.soundtrack,
        ...event.brandKitAssets.soundtrackSettings,
      };
    }
  }

  private transformColors(event: UpdateProjectFromBrandKitEvent) {
    if (this.shouldCheckPermissions && event.brandKitPermissions.colors.user.canEdit) {
      return;
    }

    for (const brandKitColor of event.brandKitAssets.colors.data) {
      this.applyGlobalColor(brandKitColor.asset.value, brandKitColor.tag);

      if (brandKitColor.tag === 'primary') {
        this.updatePrimaryColor(brandKitColor.asset.value);
      }
    }
  }

  private canUserEditAssetType(fileAssetType: FileAssetType, brandKitPermissions: BrandKitPermissions) {
    return fileAssetType === 'background-video'
      ? brandKitPermissions.background.user.canEdit
      : brandKitPermissions[fileAssetType].user.canEdit;
  }
}
