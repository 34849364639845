import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  AuthService,
  ClipBulkAction,
  FeatureFlaggingService,
  Recording,
  UserRoleType,
} from '@openreel/frontend/common';
import { WORKFLOWS_AI_COMPOSER } from '@openreel/common';
import { groupBy } from 'lodash';

@Component({
  selector: 'openreel-bulk-action',
  templateUrl: './bulk-action.component.html',
  styleUrls: ['./bulk-action.component.scss'],
})
export class BulkActionComponent implements OnInit, OnChanges {
  public bulkActions: ClipBulkAction[];
  bulkModeEnabled = false;

  @Output()
  bulkStatusChange = new EventEmitter<boolean>();

  @Output()
  bulkActionChange = new EventEmitter<ClipBulkAction>();

  @Input()
  userRoleType: UserRoleType;

  @Input()
  isDirector: boolean;

  @Input()
  viewArchivedClips: boolean;

  @Input()
  isSelfRecordClip: boolean;

  constructor(private authService: AuthService, private featureFlaggingService: FeatureFlaggingService) {}

  ngOnInit() {
    this.refreshBulkActions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.viewArchivedClips?.currentValue !== changes?.viewArchivedClips?.previousValue) {
      this.refreshBulkActions();
    }
  }

  onBulkModeToggleClicked() {
    this.bulkModeEnabled = !this.bulkModeEnabled;
    this.bulkStatusChange.emit(this.bulkModeEnabled);
  }
  onBulkApplyClicked(event: MouseEvent, action: ClipBulkAction) {
    if (this.authService.cookieDeleted()) {
      return;
    }
    if (action === ClipBulkAction.SELECT || action === ClipBulkAction.UNSELECT) {
      event.stopPropagation();
    }
    this.bulkActionChange.emit(action);
  }

  refreshBulkActions() {
    this.bulkActions = this.getBulkActionsForRoleType();
  }

  private getBulkActionsForRoleType() {
    let actions = [];

    if (this.viewArchivedClips) {
      actions = [ClipBulkAction.SELECT, ClipBulkAction.UNSELECT, ClipBulkAction.RESTORE, ClipBulkAction.DELETE];
    } else if (this.userRoleType === UserRoleType.Watcher) {
      actions = [
        ClipBulkAction.SELECT,
        ClipBulkAction.UNSELECT,
        ClipBulkAction.DOWNLOAD_ORIGINAL,
        ClipBulkAction.DOWNLOAD_MP4,
      ];
    } else if (this.isSelfRecordClip) {
      actions = [
        ClipBulkAction.SELECT,
        ClipBulkAction.UNSELECT,
        ClipBulkAction.DOWNLOAD_ORIGINAL,
        ClipBulkAction.DELETE,
      ];
    } else {
      actions = Object.values(ClipBulkAction).filter((action) => action !== ClipBulkAction.RESTORE);
    }

    return actions;
  }
}
