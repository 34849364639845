import { LayoutTimeline, SectionTimeline } from '../interfaces';

export function mapTimelineToLayoutTimeline(timeline: SectionTimeline): LayoutTimeline {
  return {
    id: timeline.id,
    title: timeline.title,
    type: timeline.type,
    hasAudio: timeline.hasAudio,
    zIndex: timeline.zIndex,
    pairId: timeline.pairId,
    contentType: timeline.contentType,
  };
}
