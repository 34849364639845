import { cloneDeep } from 'lodash';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_0_7 } from '../transformation_0_7/schema_after';
import { WorkflowDataDto_0_8 } from '../transformation_0_8/schema_after';
import { v4 as uuidv4 } from 'uuid';

function setUniqueIdsToTimelinesAndLayers(schema: WorkflowDataDto_0_8.WorkflowDataDto) {
  const mainSections = Object.entries(schema.sections).filter(([, section]) => section.sectionType === 'main');

  for (const [, section] of mainSections) {
    const hiddenTimelines = section.timelines.filter((t) => (t.type as string) === 'hidden');
    hiddenTimelines.forEach((ht) => {
      if (ht.id === 'freemium') {
        ht.type = 'freemium';
        ht.id = uuidv4();
      }
      if (ht.id === 'watermark') {
        ht.type = 'watermark';
        ht.id = uuidv4();
      }

      ht.layers.forEach((l) => (l.layerId = uuidv4()));
    });
  }
}

function copyWatermarkToGlobalSettings(schema: WorkflowDataDto_0_8.WorkflowDataDto) {
  const features = schema.features as any;

  if (!features.watermark) {
    schema.globalSettings.watermarkLayer = null;
    return;
  }

  schema.globalSettings.watermarkLayer = {
    enabled: features.watermark.enabled,
    type: features.watermark.properties.type,
    assetId: features.watermark.properties.assetId,
    tags: features.watermark.properties.tags,
  };
}

export const SchemaTransformation_0_8: SchemaTransformation = {
  version: '0.8',
  supportsPolyfill: false,

  run(oldSchema: WorkflowDataDto_0_7.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as unknown as WorkflowDataDto_0_8.WorkflowDataDto;

    setUniqueIdsToTimelinesAndLayers(newSchema);
    copyWatermarkToGlobalSettings(newSchema);

    return newSchema;
  },
};
