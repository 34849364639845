import { createAction, props } from '@ngrx/store';
import { PossibleSessionState } from '@openreel/frontend/common';
import { Recording, SessionBase, SubjectSession, TranscriptStatus } from 'libs/frontend/common/src/interfaces';
import { SessionUserDetails } from 'libs/frontend/common/src/services/session/session.interfaces';
import {
  CameraPresetCreateProps,
  CameraPresetProps,
  LoginProps,
  SessionIdProps,
  SessionProject,
  SessionSettings,
} from './interfaces/session-props.interface';

export const setSessionId = createAction('[Session] SET SESSION ID', props<SessionIdProps>());

export const otpLoginSuccess = createAction(
  '[Session] OTP login success (Subject)',
  props<{ sessionBase: SubjectSession; name: string }>()
);

export const setRemoteSessionDataSubject = createAction('[Session] GET SUBJECT DETAILS', props<SubjectSession>());

export const setSessionData = createAction('[Session] SET SESSION DATA (Director)', props<SessionBase>());

export const setCompanySlug = createAction('[Session] SET COMPANY SLUG', props<{ companySlugName: string }>());

export const setPresentationId = createAction(
  '[Session] SET SESSION PRESENTATION ID',
  props<{ presentationId: number }>()
);

export const newProjectDetails = createAction('[Session] NEW PROJECT DETAILS', props<SessionProject>());

export const newSessionSettings = createAction('[Session] NEW SESSION SETTINGS', props<SessionSettings>());

export const triggerSessionSettingsAction = createAction('[Session] TRIGGER SETTINGS');

export const fetchInvitedUsers = createAction('[Session] FETCH INVITED USERS');

export const setSessionTimerValue = createAction(
  '[Session] SET SESSION TIMER VALUE',
  props<{ value: string; identity: string }>()
);

export const setSessionRecordingState = createAction(
  '[Session] SET SESSION RECORDING STATE',
  props<{ value: PossibleSessionState; identity: string }>()
);

// Director
export const addCameraPreset = createAction('[Session] ADD CAMERA PRESET', props<CameraPresetCreateProps>());

export const cameraPresetAdded = createAction('[Session] CAMERA PRESET ADDED', props<CameraPresetProps>());

// Session
/**
 * Login Sequence, needs to be moved.
 */
export const subjectLoginAction = createAction('[Session] LOGIN SUBJECT', props<LoginProps>());
// Not used, but should be
export const joinWebsocket = createAction('[Session] Join Websocket Subject');
export const joinWebsocketRoom = createAction('[Session] Join Room');
// Not used, but should be
export const joinVideo = createAction('[Session] Join Video');

export const subjectResetAction = createAction('[Session] SUBJECT RESET');
/**
 * End Login Sequence
 */

export const windowClose = createAction('[Session] Window Close');
export const getStreamToken = createAction('[Session] GET STREAM TOKEN');

export const removeInviteeImmediately = createAction('[Session] REMOVE INVITEE IMMEDIATELY');

export const createNewSession = createAction(
  '[Session] Create New Session',
  props<{ createSessionUserDetails: SessionUserDetails }>()
);

export const updateSession = createAction(
  '[Session] Update Session',
  props<{ updateSessionUserDetails: SessionUserDetails }>()
);

export const sessionLive = createAction('[Session] Session Live');

export const copySubjectAccessLink = createAction('[Session] Director Copy Subject Access Link');

export const sendInviteFromSession = createAction(
  '[Session] Director Sent an Invite from Session',
  props<{ invitationDetails }>()
);

export const fetchSessionRecordings = createAction(
  '[Session] FETCH Recordings',
  props<{ sessionId: number; page?: number; limit?: number }>()
);

export const fetchingSessionRecordings = createAction('[Session] FETCHING Recordings', props<{ status: boolean }>());

export const setSessionRecordings = createAction('[Session] SET Recordings', props<{ recordings: Recording[] }>());

export const setSessionRecordingsCount = createAction('[Session] SET Recordings Count', props<{ count: number }>());

export const appendSessionRecordings = createAction(
  '[Session] Append Recordings',
  props<{ recordings: Recording[] }>()
);

export const selectUnselectSessionRecordings = createAction(
  '[Session] Recordings Toggle',
  props<{ value: boolean; isArchivedRecordings?: boolean }>()
);

export const updateSessionRecording = createAction(
  '[Session] UPDATE Recording',
  props<{ id: number; recording: Partial<Recording> }>()
);

export const deleteSessionRecording = createAction('[Session] DELETE Recording', props<{ id: number }>());

export const archiveSessionRecording = createAction('[Session] ARCHIVE Recording', props<{ recordings: number[] }>());

export const addSessionRecording = createAction('[Session] ADD Recording', props<{ recording: Recording }>());

export const sendUnsupportedBrowserNotification = createAction('[Session] Send Unsupported Browser Notification');

export const transcriptVideoRequest = createAction(
  '[Session] Recording Transcript Request',
  props<{ videoId: number }>()
);

export const transcriptVideoDone = createAction(
  '[Session] Recording Transcript Done',
  props<{ status: TranscriptStatus; full_text: string; video_id: number }>()
);
export const setAdjustmentsPanelId = createAction('[Session] Set Adjustments Panel Id', props<{ identity: string }>());

export const subjectLogout = createAction('[SESSION] Subject Session Logout');
export const watcherOrCollabLogout = createAction('[SESSION] Watcher or Collaborator Session Logout');
export const logout = createAction('[User] Logout');
