/* eslint-disable @typescript-eslint/no-explicit-any */
const REGEX_LABEL = '#(.*?);';
const REGEX_FIELD = (field: string) => `;${field}=([a-zA-Z0-9:]*?);`;

export interface CreatorExtractedData {
  duration: number;
  nodes: CreatorNode[];
}

export type CreatorNodeType = 'text' | 'shape' | 'logo' | 'fill';

export interface CreatorNode {
  token: string;
  path: string;
  type: CreatorNodeType;
  // Image
  assetRefId?: string;
}

export type ProcessorType = 'text' | 'fill' | 'image' | 'shape';

export abstract class ProcessorBase {
  type: ProcessorType;
  abstract test: (node: any) => boolean;
  abstract process: (node: any) => CreatorNode;

  protected rgbRatioToHex(rgbRatio: number[]): string {
    if (rgbRatio.length === 0 || rgbRatio.length > 4) {
      return null;
    }

    let hexColor = '#';
    rgbRatio.forEach((value) => {
      hexColor += Math.floor(value * 255)
        .toString(16)
        .padStart(2, '0');
    });

    return hexColor;
  }

  protected extractField(name: string, field: string) {
    const fieldRegex = new RegExp(REGEX_FIELD(field));
    const match = fieldRegex.exec(name);
    if (match && match[1]) {
      return match[1];
    }

    return false;
  }

  protected extractLabel(name: string) {
    const labelRegex = new RegExp(REGEX_LABEL);
    const match = labelRegex.exec(name);
    if (match && match[1]) {
      return match[1];
    }

    throw `No UI Label! Layer name: ${name}`;
  }

  protected extractKey(name: string) {
    const label = this.extractLabel(name);
    return label.toLowerCase().replace(/[\W_]+/g, '_');
  }
}
