import { APP_INITIALIZER, ApplicationRef, ErrorHandler, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import {
  ConsoleLoggingService,
  ElectronService,
  ILocalRecorderService,
  INativeLibService,
  ISocketIoLibService,
  LocalRecorderService,
  LoggingService,
  MediaStreamModule,
  OpenreelCommonModule,
  SocketIoProvider,
  ThemeService,
  ROUTE_UNSUPPORTED_BROWSER,
  SocialConfigService,
  SocialConfigFactory,
  ROUTE_UGC_SUBJECT,
  FeatureFlaggingService,
  CreatorMixpanelService,
  ROUTE_CAPTURE_LITE,
  ROUTE_EXTENSION_EDITOR,
  ROUTE_SUBJECT,
  ROUTE_DIRECTOR,
  ROUTE_COLLABORATOR_INVITE,
  ROUTE_EDITOR,
  ROUTE_OAUTH_CONNECTIONS,
  ROUTE_VIDEO_PREVIEW,
  ROUTE_GDPR,
  ROUTE_SUBJECT_OTP,
  GUEST_MAGIC_LINK,
  AuthService,
  ROUTE_LIBRARY_PROJECTS,
  ROUTE_DASHBOARD,
  ROUTE_HOST,
} from '@openreel/frontend/common';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { RootStoreModule } from './app-state/root-store.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { NativeLibBrowserService, NativeLibElectronService } from './core/extlib/native.extlib';
import { FullStoryService } from './core/fullstory/fullstory.service';
import { NavigationModule } from './navigation/navigation.module';
import { SharedModule } from './shared/shared.module';
import { SsoModule } from './sso/sso.module';
import { SocialLoginModule } from 'angularx-social-login';
import { UnsupportedBrowserComponent } from './shared/unsupported-browser/unsupported-browser.component';
import { AccessibeService } from '@openreel/ui/accessibe';
import { SalesforceChatModule, SALESFORCE_CHAT_ID } from '@openreel/ui/salesforce-chat';
import { FacebookModule } from 'ngx-facebook';
import { environment } from '../environments/environment';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { SentryErrorHandler } from './shared/services/sentry.service';
import { GraphqlClientModule } from './graphql/graphql.module';
import { UploadIndicatorComponent } from '@openreel/frontend/common/components/upload-indicator';
import { PerformanceScreenPopupComponent } from '@openreel/frontend/common/components/performance-screen-popup';
import { GenericAlertComponent } from '@openreel/frontend/common/components/generic-alert';
import { VideoRetryModule } from './video-retry/video-retry.module';
import { HOME_PAGE_CONFIG, NAVIGATION_CONFIG } from '@openreel/frontend/common/components/menu/navigation-config.token';
import { AUTOMATIONS, HOSTING, SEARCH_PAGE, WORKFLOWS_TEMPLATE } from '@openreel/common';
import { map } from 'rxjs/operators';

const routes: Routes = [
  {
    path: ROUTE_UNSUPPORTED_BROWSER,
    component: UnsupportedBrowserComponent,
  },
  {
    path: 'login',
    pathMatch: 'full',
    redirectTo: `${ROUTE_DIRECTOR}/login`,
  },
  {
    path: GUEST_MAGIC_LINK,
    pathMatch: 'full',
    redirectTo: `${ROUTE_DIRECTOR}/${GUEST_MAGIC_LINK}`,
  },
  {
    path: ROUTE_DIRECTOR,
    loadChildren: () => import('./director/director.module').then((m) => m.DirectorModule),
  },
  {
    path: 'subject',
    pathMatch: 'full',
    redirectTo: ROUTE_SUBJECT_OTP,
  },
  {
    path: ROUTE_SUBJECT,
    loadChildren: () => import('./subject/subject.module').then((m) => m.SubjectModule),
  },
  {
    path: ROUTE_COLLABORATOR_INVITE,
    loadChildren: () => import('./collaborator/collaborator.module').then((m) => m.CollaboratorModule),
  },
  {
    path: ROUTE_EDITOR,
    loadChildren: () => import('./editor/editor.module').then((m) => m.EditorModule),
  },
  {
    path: `${ROUTE_VIDEO_PREVIEW}/:video_id`,
    loadChildren: () => import('./video-preview/video-preview.module').then((m) => m.VideoPreviewModule),
  },
  {
    path: ROUTE_GDPR,
    loadChildren: () => import('./gdpr/gdpr.module').then((m) => m.GdprModule),
  },
  {
    path: ROUTE_EXTENSION_EDITOR,
    loadChildren: () => import('./extension-editor/extension-editor.module').then((m) => m.ExtensionEditorModule),
  },
  {
    path: ROUTE_UGC_SUBJECT,
    loadChildren: () => import('../app/ugc-subject/ugc-subject.module').then((m) => m.UgcSubjectModule),
  },
  {
    path: ROUTE_CAPTURE_LITE,
    loadChildren: () => import('./capture-lite/capture-lite.module').then((m) => m.CaptureLiteModule),
  },
  {
    path: ROUTE_OAUTH_CONNECTIONS,
    loadChildren: () => import('./oauth-connections/oauth-connections.module').then((m) => m.OauthConnectionsModule),
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    CoreModule,
    FlexLayoutModule,
    RouterModule.forRoot(routes),
    ReactiveFormsModule,
    NavigationModule,
    VideoRetryModule,
    OpenreelCommonModule,
    ToastrModule.forRoot(),
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
    }),
    RootStoreModule,
    FacebookModule.forRoot(),

    GraphqlClientModule,
    SalesforceChatModule,
    MediaStreamModule,
    SsoModule,
    SocialLoginModule,
    UploadIndicatorComponent,
    PerformanceScreenPopupComponent,
    GenericAlertComponent,
  ],
  providers: [
    AccessibeService,
    ToastrService,
    CreatorMixpanelService,
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    {
      provide: ILocalRecorderService,
      useFactory: () => new LocalRecorderService(),
    },
    {
      provide: LoggingService,
      useClass: ConsoleLoggingService,
    },
    {
      provide: ISocketIoLibService,
      useClass: SocketIoProvider,
    },
    {
      provide: INativeLibService,
      useFactory: (electronService: ElectronService) =>
        electronService.isElectron ? new NativeLibElectronService(electronService) : new NativeLibBrowserService(),
      deps: [ElectronService],
    },
    FullStoryService,
    ThemeService,
    {
      provide: 'SocialAuthServiceConfig',
      useFactory: SocialConfigFactory,
      deps: [SocialConfigService],
    },
    {
      provide: SALESFORCE_CHAT_ID,
      useValue: environment.salesforceChatId,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: HOME_PAGE_CONFIG,
      deps: [AuthService],
      useFactory: (authService: AuthService) => {
        return authService.userDetails$.pipe(
          map((userDetails) => {
            return {
              url: userDetails.isPremiumPackage ? ROUTE_LIBRARY_PROJECTS : ROUTE_DASHBOARD,
            };
          }),
        );
      },
    },
    {
      provide: NAVIGATION_CONFIG,
      deps: [AuthService],
      useFactory: (authService: AuthService) => {
        return authService.userDetails$.pipe(
          map((userDetails) => {
            return [
              {
                icon: 'home-automations',
                title: 'Automations',
                link: '/automations',
                featureFlag: AUTOMATIONS,
                placement: 'top',
                openInNewTab: false,
              },
              ...(userDetails?.isPremiumPackage ? [] : [
                {
                  icon: 'home-line',
                  title: 'Dashboard',
                  link: '/dashboard',
                  placement: 'top',
                  openInNewTab: false,
                },
                {
                  icon: 'layers-three-01',
                  title: 'Projects',
                  link: '/projects',
                  placement: 'top',
                  openInNewTab: false,
                },
              ]),
              {
                icon: 'folder',
                title: 'Library',
                link: '/library',
                placement: 'top',
                featureFlag: HOSTING,
                openInNewTab: false,
              },
              {
                icon: 'image-user',
                title: 'Templates',
                link: '/templates',
                placement: 'top',
                featureFlag: WORKFLOWS_TEMPLATE,
                openInNewTab: false,
              },
              ...(userDetails?.site_users?.webinarAllowed
                ? [
                    {
                      icon: 'calendar',
                      title: 'Events',
                      link: environment.webinarAppUrl,
                      placement: 'top',
                      external: true,
                      openInNewTab: true,
                    },
                  ]
                : []),
              {
                icon: 'search-lg',
                title: 'Search',
                link: '/search',
                placement: 'top',
                featureFlag: SEARCH_PAGE,
                openInNewTab: false,
              },
              {
                icon: 'life-buoy-01',
                title: 'Help',
                link: 'https://openreel.my.site.com/helpcenter/s/',
                placement: 'bottom',
                external: true,
                openInNewTab: true,
              },
              {
                icon: 'settings-01',
                title: 'Settings',
                link: '/settings',
                placement: 'bottom',
                openInNewTab: false,
              },
            ];
          })
        );
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(applicationRef: ApplicationRef, featureFlagService: FeatureFlaggingService) {
    featureFlagService.getGlobalFeatureFlags();
    // const originalTick = applicationRef.tick;
    // applicationRef.tick = function () {
    //   const windowPerfomance = window.performance;
    //   const before = windowPerfomance.now();
    //   const retValue = originalTick.apply(this);
    //   const after = windowPerfomance.now();
    //   const runTime = after - before;
    //   return retValue;
    // };
  }
}
