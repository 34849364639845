export enum GlobalAssetTypes {
  Logo,
  Watermark,
  BackgroundVideo,
  TextOverlay,
}

export const Z_INDEX_BACKGROUND = 0;
export const INTRO_OUTRO_Z_INDEX_MAIN = 1;
export const INTRO_OUTRO_Z_INDEX_OVERLAY = 2;
