import { cloneDeep, first, last, sortBy } from 'lodash';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_5_0 } from '../transformation_5_0/schema_after';
import { WorkflowDataDto_5_1 } from './schema_after';

/*
  NOTE: Purpose of this transformation:
    - 1) invert textTrims, so they represent the trimmed out parts and not kept parts
*/
export const SchemaTransformation_5_1: SchemaTransformation = {
  version: '5.1',
  supportsPolyfill: false,

  run(oldSchema: WorkflowDataDto_5_0.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as unknown as WorkflowDataDto_5_1.WorkflowDataDto;

    for (const asset of newSchema.assets) {
      if (asset.type !== 'clip') {
        continue;
      }

      if (asset.textTrims?.length) {
        asset.textTrims = sortBy(asset.textTrims, (trim) => trim.from);
        asset.textTrims = invertTextTrims(asset.data.duration, asset.textTrims);
      }

      if (!asset.textTrims?.length) {
        delete asset.textTrims;
      }
    }

    return newSchema;
  },
};

function invertTextTrims(duration: number, textTrims: WorkflowDataDto_5_1.TextTrimRange[]) {
  const invertedTrims: WorkflowDataDto_5_1.TextTrimRange[] = [];

  if (first(textTrims).from > 0) {
    invertedTrims.push({ from: 0, to: first(textTrims).from, type: 'manual' });
  }

  for (let i = 0; i < textTrims.length - 1; i++) {
    const currentTrim = textTrims[i];
    const nextTrim = textTrims[i + 1];

    if (nextTrim.from - currentTrim.to > 0) {
      invertedTrims.push({
        from: currentTrim.to,
        to: nextTrim.from,
        type: 'manual',
      });
    }
  }

  if (last(textTrims).to < duration) {
    invertedTrims.push({ from: last(textTrims).to, to: duration, type: 'manual' });
  }

  return invertedTrims;
}
