import { Preset } from './../interfaces/workflow.interfaces';

export function doesAssetHaveLogoField(preset: Preset) {
  if (!preset) {
    return false;
  }

  for (const [, field] of Object.entries(preset)) {
    if (field.type === 'logo') {
      return true;
    }
  }

  return false;
}
