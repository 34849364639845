import { cloneDeep } from 'lodash';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_3_7 } from '../transformation_3_7/schema_after';
import { WorkflowDataDto_3_8 } from './schema_after';
import { getLayers } from '../../../helpers';

/*
  NOTE: Purpose of this transformation:
    - 1) transform border values to use MIN(X_AXIS, Y_AXIS) multiplier
*/
export const SchemaTransformation_3_8: SchemaTransformation = {
  version: '3.8',
  supportsPolyfill: false,

  run(oldSchema: WorkflowDataDto_3_7.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as unknown as WorkflowDataDto_3_8.WorkflowDataDto;

    for (const { layer } of getLayers(newSchema as never)) {
      if (layer.styles?.border) {
        layer.styles.border.x = (layer.styles.border.x * 1920) / 1080;
      }

      if (layer.styles?.borderRadius) {
        for (const borderRadius of layer.styles.borderRadius) {
          borderRadius.x = (borderRadius.x * 1920) / 1080;
        }
      }
    }

    return newSchema;
  },
};
