import { WorkflowBaseBuilder } from './workflow-base.builder';

export interface UpdateSoundtrackSettingsEvent {
  introVolume: number;
  mainVolume: number;
  outroVolume: number;
}

export class GlobalSettingsSoundtrackSettingsUpdateCommand extends WorkflowBaseBuilder<UpdateSoundtrackSettingsEvent> {
  run(settings: UpdateSoundtrackSettingsEvent) {
    this.source.globalSettings.soundtrack = {
      ...this.source.globalSettings.soundtrack,
      ...settings,
    };
    return this.ok();
  }
}
