import { cloneDeep } from 'lodash';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_5_1 as PrevWorkflow } from '../transformation_5_1/schema_after';
import { WorkflowDataDto_5_2 as Workflow } from './schema_after';

/*
  NOTE: Purpose of this transformation:
    - 1) invert textTrims, so they represent the trimmed out parts and not kept parts
*/
export const SchemaTransformation_5_2: SchemaTransformation = {
  version: '5.2',
  supportsPolyfill: false,

  run(oldSchema: PrevWorkflow.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as Workflow.WorkflowDataDto;

    for (const asset of newSchema.assets) {
      if (asset.type !== 'clip') {
        continue;
      }

      if ((asset as PrevWorkflow.Asset).textTrims) {
        asset.textCuts = (asset as PrevWorkflow.Asset).textTrims;
        asset.textCuts.forEach((cut) => {
          if (!cut.type) {
            cut.type = 'manual';
          }
        });
      }

      delete (asset as PrevWorkflow.Asset).textTrims;
    }

    return newSchema;
  },
};
