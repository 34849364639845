import { Layer } from '../interfaces';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export interface AddTextOverlayEvent {
  sectionId: string;
}

export class AddTextOverlayCommand extends WorkflowBaseBuilder<AddTextOverlayEvent, Layer[]> {
  run({ sectionId }: AddTextOverlayEvent) {
    const layers = this.addTextOverlayToSection(sectionId);
    return this.ok(layers);
  }
}
