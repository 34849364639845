import { cloneDeep } from 'lodash';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_4_5 } from '../transformation_4_5/schema_after';
import { WorkflowDataDto_4_6 } from './schema_after';

/*
  NOTE: Purpose of this transformation:
    - 1) migrate trimFrom/trimTo from asset into trims array
*/
export const SchemaTransformation_4_6: SchemaTransformation = {
  version: '4.6',
  supportsPolyfill: false,

  run(oldSchema: WorkflowDataDto_4_5.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as unknown as WorkflowDataDto_4_6.WorkflowDataDto;

    for (const asset of newSchema.assets) {
      if (typeof (asset as any).trimFrom !== 'number' && typeof (asset as any).trimTo !== 'number') {
        continue;
      }

      const from = (asset as any).trimFrom ?? 0;
      const to = (asset as any).trimTo ?? asset.data?.duration ?? 0;

      if (to === 0) {
        continue;
      }

      asset.trims = [{ from, to }];

      delete (asset as any).trimFrom;
      delete (asset as any).trimTo;
    }

    return newSchema;
  },
};
