import { createAction, props } from '@ngrx/store';
import {
  IdentityProps,
  InvitedUserProp,
  InvitedUsersProp,
  MultiPartProps,
  ParticipantProp,
  UpdateParticipantProp,
} from './interfaces/participant-props.interface';
import { BooleanProp } from '../../interfaces/global-props.interface';
import {
  AudioDevice,
  CaptureTeleprompterStatus,
  DeviceInfoResponse,
  DeviceInfoResult,
  VideoConstraints,
  VideoSource,
} from '@openreel/frontend/common';
import { Orientation } from '../../../director/participant-settings/view-settings/view-settings.component';

const typePrefix = '[Session Participant]';

export const setMyParticipant = createAction(`${typePrefix} SET MY PARTICIPANT`, props<ParticipantProp>());

export const fetchMyDeviceInfoSuccess = createAction(
  `${typePrefix} FETCH MY DEVICE INFO SUCCESS`,
  props<{ response: DeviceInfoResult }>()
);

export const updateMyParticipant = createAction(`${typePrefix} UPDATE MY PARTICIPANT`, props<UpdateParticipantProp>());

export const participantJoin = createAction(`${typePrefix} PARTICIPANT JOIN`, props<ParticipantProp>());

export const invitedUsersUpdated = createAction(`${typePrefix} INVITED USERS UPDATED`, props<InvitedUsersProp>());

export const invitedUserUpdated = createAction(`${typePrefix} INVITED USER UPDATED`, props<InvitedUserProp>());

export const updateParticipant = createAction(`${typePrefix} PARTICIPANT UPDATE`, props<UpdateParticipantProp>());

export const setParticipants = createAction(`${typePrefix} SET PARTICIPANTS`, props<MultiPartProps>());

export const dominantSubjectSelected = createAction(`${typePrefix} DOMINANT SUBJECT SELECTED`, props<IdentityProps>());

export const storeMuteStatusLocalStorage = createAction(`${typePrefix} STORE MUTE STATUS`);

export const toggleMuteStatus = createAction(`${typePrefix} TOGGLE MUTE STATUS`, props<BooleanProp>());

export const mirrorVideo = createAction(`${typePrefix} MIRROR VIDEO`, props<BooleanProp>());

export const toggleProAudioStatus = createAction(`${typePrefix} TOGGLE PRO AUDIO STATUS`, props<{ status: boolean }>());

export const updateParticipantGain = createAction(`${typePrefix} UPDATE PARTICIPANT GAIN`, props<{ value: number }>());

export const toggleVideoEnabledStatus = createAction(`${typePrefix} TOGGLE VIDEO ENABLED STATUS`, props<BooleanProp>());

export const fetchSubjectDeviceInfoSuccess = createAction(
  `${typePrefix} FETCH SUBJECT DEVICE INFO SUCCESS`,
  props<{ deviceInfo: DeviceInfoResponse; identity: string }>()
);

export const fetchDeviceInfoFailure = createAction(
  `${typePrefix} FETCH SUBJECT DEVICE INFO FAILURE`,
  props<{ error: Error; identity: string }>()
);

export const updateParticipantRecordingStatus = createAction(
  `${typePrefix} UPDATE PARTICIPANT RECORDING STATUS`,
  props<{ identity: string; value: boolean }>()
);

export const updateParticipantGuideStatus = createAction(
  `${typePrefix} UPDATE PARTICIPANT GUIDE STATUS`,
  props<{ identity: string; status: boolean }>()
);

export const updateGuideEnabledIds = createAction(
  `${typePrefix} UPDATE GUIDE ENABLED IDS`,
  props<{ ids: string[] }>()
);

export const updateParticipantFramerate = createAction(
  `${typePrefix} UPDATE PARTICIPANT FRAME RATE`,
  props<{ identity: string; value: string }>()
);

export const updateParticipantResolution = createAction(
  `${typePrefix} UPDATE PARTICIPANT RESOLUTION`,
  props<{ identity: string; value: string }>()
);

export const updateParticipantLensSelection = createAction(
  `${typePrefix} UPDATE PARTICIPANT LENS SELECTION`,
  props<{ identity: string; value: string }>()
);

export const updateParticipantOrientationSelection = createAction(
  `${typePrefix} UPDATE PARTICIPANT ORIENTATION SELECTION`,
  props<{ identity: string; value: Orientation }>()
);

export const updateParticipantSource = createAction(
  `${typePrefix} UPDATE PARTICIPANT SOURCE`,
  props<{ identity: string; value: VideoSource }>()
);

export const updateParticipantAutoExposureLock = createAction(
  `${typePrefix} UPDATE PARTICIPANT AUTO EXPOSURE LOCK`,
  props<{ identity: string; value: boolean }>()
);

export const updateParticipantBrightnessSliderChange = createAction(
  `${typePrefix} UPDATE PARTICIPANT BRIGHTNESS CHANGE`,
  props<{
    identity: string;
    details: {
      type: keyof VideoConstraints;
      value: number;
    };
  }>()
);

export const updateParticipantWhiteBalanceSliderChange = createAction(
  `${typePrefix} UPDATE PARTICIPANT WHITE BALANCE CHANGE`,
  props<{
    identity: string;
    details: {
      type: keyof VideoConstraints;
      value: number;
    };
  }>()
);

export const resetParticipantAllSettings = createAction(
  `${typePrefix} RESET PARTICIPANT SETTINGS`,
  props<{ identity: string }>()
);

export const updateParticipantAvailableAudioDevices = createAction(
  `${typePrefix} UPDATE PARTICIPANT AUDIO DEVICES LIST`,
  props<{ devices: AudioDevice[] }>()
);

export const updateParticipantAvailableAudioOutputDevices = createAction(
  `${typePrefix} UPDATE PARTICIPANT AUDIO OUTPUT LIST`,
  props<{ devices: AudioDevice[] }>()
);

export const updateParticipantAudioSettings = createAction(
  `${typePrefix} UPDATE PARTICIPANT AUDIO SETTINGS`,
  props<{
    identity: string;
    details: {
      type: string;
      value: string | boolean | number;
    };
  }>()
);

export const updateParticipantManualFocusStatus = createAction(
  `${typePrefix} UPDATE PARTICIPANT MANUAL FOCUS STATUS`,
  props<{ identity: string; value: boolean }>()
);

export const toggleParticipantAdjustmentsFloatingPanel = createAction(
  `${typePrefix} TOGGLE PARTICIPANT ADJUSTMENTS PANEL`,
  props<{ identity: string; value: boolean }>()
);

export const setTeleprompterStatus = createAction(
  `${typePrefix} SET TELEPROMPTER STATUS`,
  props<{
    identity: string;
    status: CaptureTeleprompterStatus;
    teleprompterScriptId: number;
    teleprompterHidden: boolean;
  }>()
);
