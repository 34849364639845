import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { Cleanupable, VideoDevice, filterDevices } from '@openreel/frontend/common';
import { BehaviorSubject, debounceTime, fromEvent, tap } from 'rxjs';

@Component({
  selector: 'openreel-webcam-select',
  templateUrl: './openreel-webcam-select.component.html',
  styleUrls: ['./openreel-webcam-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FlexLayoutModule, MatIconModule, MatLegacyButtonModule, MatLegacyMenuModule],
})
export class OpenreelWebcamSelectComponent extends Cleanupable implements OnInit, OnDestroy, OnChanges {
  availableWebcamDevices: VideoDevice[];
  webcamSelectStatus$ = new BehaviorSubject<string>(null);
  webcamError = false;
  webcamStream: MediaStream;

  @Input() showAsIcon: boolean;
  @Input() selectedWebcamDevice: VideoDevice;
  @Input() webcamDisabled: boolean;

  @Output() webcamDeviceChange = new EventEmitter<VideoDevice>();
  @Output() webcamDisabledStatusChange = new EventEmitter<boolean>();

  async ngOnInit(): Promise<void> {
    if (!this.selectedWebcamDevice) {
      this.getCameraPermission();
    } else {
      await this.getDevices();
    }

    this.mortalize(
      fromEvent(navigator.mediaDevices, 'devicechange').pipe(
        debounceTime(500),
        tap(() => this.getDevices())
      )
    ).subscribe();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedWebcamDevice) {
      sessionStorage.setItem('openreel-selected-webcam', JSON.stringify(changes.selectedWebcamDevice.currentValue));
    }
  }

  private async getCameraPermission() {
    try {
      this.webcamSelectStatus$.next('waiting for camera permission');
      const video = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
      if (video) {
        this.webcamStream = video;
        await this.getDevices();
        if (!this.availableWebcamDevices?.length) {
          await this.getDevices();
          this.updateStatus();
        }
      }
    } catch (error) {
      this.webcamSelectStatus$.next(error?.message ?? 'camera permission denied');
      if (error.name === 'NotReadableError') {
        await this.getDevices();
        this.updateStatus();
      } else if (error.name === 'AbortError') {
        this.updateStatus();
      } else {
        this.availableWebcamDevices = [];
        this.webcamError = true;
      }
    }
  }

  private async selectDefaultDevice() {
    const selectedWebcam = this.availableWebcamDevices?.find(
      (device) => device.id === JSON.parse(sessionStorage.getItem('openreel-selected-webcam'))?.id
    );
    const defaultSelectedDevice = selectedWebcam || this.availableWebcamDevices[0];
    await this.changeWebcamDevice(defaultSelectedDevice);
    this.selectedWebcamDevice = defaultSelectedDevice;
  }

  private updateStatus() {
    if (this.availableWebcamDevices?.length) {
      this.webcamSelectStatus$.next('Select Camera');
      this.webcamError = false;
    } else {
      this.webcamError = true;
      this.webcamSelectStatus$.next('Error selecting camera. Please make sure camera is not used by other apps.');
    }
  }

  private async getDevices() {
    try {
      this.webcamSelectStatus$.next('fetching available camera options');
      const devices = await navigator.mediaDevices.enumerateDevices();
      const { videoDevices } = filterDevices(devices);
      const sortedList = videoDevices.sort((a, b) => b.height - a.height);
      this.availableWebcamDevices = sortedList;
      //stop the stream track
      if (this.webcamStream) this.webcamStream.getTracks().forEach((t) => t.stop());
      this.webcamSelectStatus$.next(null);
      // set default web cam device
      await this.selectDefaultDevice();
    } catch (error) {
      this.webcamSelectStatus$.next(error?.message ?? 'error fetching available camera options');
      this.availableWebcamDevices = [];
    }
  }

  async changeWebcamDevice(device: VideoDevice) {
    if (this.selectedWebcamDevice?.id !== device?.id) {
      this.webcamDeviceChange.next(device);
    }
  }

  toggleWebcam(status: boolean) {
    sessionStorage.setItem('openreel-webcam-disabled', `${status}`);
    this.webcamDisabledStatusChange.next(status);
  }
}
