import { getLayerFromId } from '../helpers/timelines.helpers';
import { LayerIdEvent } from '../interfaces';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export interface UpdateLayerFitPositionEvent extends LayerIdEvent {
  objectPosition: number[];
}

export class UpdateLayerFitPositionCommand extends WorkflowBaseBuilder<UpdateLayerFitPositionEvent> {
  run(event: UpdateLayerFitPositionEvent) {
    const layerInfo = getLayerFromId(event.layerId, this.source);
    if (layerInfo === null) {
      return { success: false };
    }

    const { layer } = layerInfo;

    layer.styles = { ...layer.styles, objectPosition: event.objectPosition };

    return this.ok();
  }
}
