import { cloneDeep } from 'lodash';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_1_4 } from '../transformation_1_4/schema_after';
import { WorkflowDataDto_1_5 } from './schema_after';

/*
  NOTE: Purpose of this transformation:
    - remove need of wizard object from workflow
    - replace it with globalSettings.[ELEMENT].settings, where ELEMENT = logo | background-video
*/
export const SchemaTransformation_1_5: SchemaTransformation = {
  version: '1.5',
  supportsPolyfill: false,

  run(oldSchema: WorkflowDataDto_1_4.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as WorkflowDataDto_1_5.WorkflowDataDto;

    if (oldSchema.features.wizard?.elements && oldSchema.features.wizard.elements.length > 0) {
      const logoElement = oldSchema.features.wizard.elements.find(
        (el) => el.id === WorkflowDataDto_1_4.ELEMENT_ASSET_LOGO_ID
      );
      const backgroundVideoElement = oldSchema.features.wizard.elements.find(
        (el) => el.id === WorkflowDataDto_1_4.ELEMENT_ASSET_BACKGROUND_VIDEO_ID
      );

      if (logoElement) {
        newSchema.globalSettings.logo.settings = {
          assetId: logoElement.asset.id,
          visible: true,
          enabled: true,
          targetTags: logoElement.targetTags,
        };
      } else {
        newSchema.globalSettings.logo.settings = {
          assetId: null,
          visible: false,
          enabled: false,
          targetTags: [],
        };
      }

      if (backgroundVideoElement) {
        newSchema.globalSettings.backgroundVideo.settings = {
          assetId: backgroundVideoElement.asset.id,
          visible: true,
          enabled: true,
          targetTags: backgroundVideoElement.targetTags,
        };
      } else {
        newSchema.globalSettings.backgroundVideo.settings = {
          assetId: null,
          visible: false,
          enabled: false,
          targetTags: [],
        };
      }
    }
    delete newSchema.features.wizard;
    return newSchema;
  },
};
