import { Bounds, LayoutDto, LayoutType, NewClip, SectionTimeline } from '../interfaces';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export interface AddSectionEvent {
  timelines: SectionTimeline[];
  templateLayoutId?: number; // Selected templateLayoutId for new scene
  layoutType?: LayoutType; // Selected layout type for new scene
  layoutData?: LayoutDto; // Selected layout data for new scene
  aspectRatioBounds?: Bounds[]; // Bounds for layout timelines
  useSourceForVideoPlacement?: boolean; // Place videos in order or by source
  mainClips?: NewClip[]; // Clips to use
  sectionIndex?: number; // Where to place the section
}

export interface AddSectionEventResult {
  addedSectionId: string;
  mainClipsLayerIds: string[];
}

export class AddSectionCommand extends WorkflowBaseBuilder<AddSectionEvent, AddSectionEventResult> {
  run(event: AddSectionEvent) {
    const result = this.createSection(event);
    this.addTextOverlayToSection(result.addedSectionId);
    return this.ok(result);
  }
}
