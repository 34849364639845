import { SectionMutedEvent } from '../interfaces';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export class MuteSceneCommand extends WorkflowBaseBuilder<SectionMutedEvent> {
  run({ sectionId, muted }: SectionMutedEvent) {
    const section = this.source.sections[sectionId];
    if (!section) {
      throw new Error(`Cannot find section with ID: ${sectionId}`);
    }

    section.audio.muted = muted;

    if (!muted && section.audio.volume === 0) {
      section.audio.volume = 1;
    }

    return this.ok();
  }
}
