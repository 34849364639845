import { cloneDeep } from 'lodash';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_4_6 } from '../transformation_4_6/schema_after';
import { WorkflowDataDto_4_7 } from './schema_after';

/*
  NOTE: Purpose of this transformation:
    - 1) rename trims to textTrims
    - 2) add trim property for future timeline trim (not used at the moment)
*/
export const SchemaTransformation_4_7: SchemaTransformation = {
  version: '4.7',
  supportsPolyfill: false,

  run(oldSchema: WorkflowDataDto_4_6.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as unknown as WorkflowDataDto_4_7.WorkflowDataDto;

    for (const asset of newSchema.assets) {
      if ((asset as any).trims) {
        asset.textTrims = (asset as any).trims;
        delete (asset as any).trims;
      }
    }

    return newSchema;
  },
};
