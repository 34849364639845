import { cloneDeep } from 'lodash';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_5_1 as PrevWorkflow } from '../transformation_5_1/schema_after';
import { WorkflowDataDto_5_2 as Workflow } from './../transformation_5_2/schema_after';
import { v4 } from 'uuid';

/*
  NOTE: Purpose of this transformation:
    - 1) add IDs to timelines (if missing)
*/
export const SchemaTransformation_5_3: SchemaTransformation = {
  version: '5.3',
  supportsPolyfill: false,

  run(oldSchema: PrevWorkflow.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as Workflow.WorkflowDataDto;

    for (const [, section] of Object.entries(newSchema.sections)) {
      for (const timeline of section.timelines) {
        if (!timeline.id) {
          timeline.id = v4();
        }
      }
    }

    return newSchema;
  },
};
