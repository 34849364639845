<div fxLayout="column">
  <div class="video-header">
    <div class="webcam-locked-message color-warn" *ngIf="videoStreamError">
      {{ videoStreamError }}
    </div>
    <div class="webcam-locked-message color-warn" *ngIf="audioStreamError">
      {{ audioStreamError }}
    </div>
    <div class="test-audio-video-icon" (click)="openAudioVideoTest()" *ngIf="enableTestAudioVideo$ | async">
      <mat-icon>info</mat-icon>
      <span>Test your audio and video</span>
    </div>
  </div>
  <div fxLayout="row" fxLayoutGap="10px">
    <div fxFlex="50">
      <video #videoElement [class.mirrored]="mirrored" autoplay></video>
      <audio #audioElement autoplay></audio>
      <div>
        <openreel-volume-meter [audioTrack]="audioStreamTrack" [vertical]="false"></openreel-volume-meter>
      </div>
    </div>
    <div fxFlex="50" class="controls-wrapper">
      <div fxLayout="row" fxLayoutAlign="start center">
        <mat-icon color="primary">camera</mat-icon>
        <mat-form-field fxFlex="1 1">
          <mat-select
            [(ngModel)]="selectedVideoSource"
            (selectionChange)="changeVideoSource()"
            [disabled]="isTransitioningVideo || isTransitioningAudio"
          >
            <mat-option *ngFor="let src of supportedVideoSources" [value]="src">
              {{ VideoSource[src] }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <mat-icon color="primary">videocam</mat-icon>
        <mat-form-field fxFlex="1 1">
          <mat-select
            [(ngModel)]="selectedVideoDevice"
            (selectionChange)="changeVideoDevice()"
            [disabled]="isTransitioningVideo || isTransitioningAudio"
          >
            <mat-option *ngFor="let dev of videoDevices" [value]="dev">
              {{ dev.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <mat-icon color="primary">mic_none</mat-icon>
        <mat-form-field fxFlex="1 1">
          <mat-select
            [(ngModel)]="selectedAudioDevice"
            (selectionChange)="changeAudioDevice()"
            [disabled]="isTransitioningVideo || isTransitioningAudio"
          >
            <mat-option *ngFor="let dev of audioDevices" [value]="dev">
              {{ dev.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="enableSpeakerSelection$ | async">
        <mat-icon color="primary">volume_up</mat-icon>
        <mat-form-field fxFlex="1 1">
          <mat-select
            [(ngModel)]="selectedAudioOutputDevice"
            (selectionChange)="changeAudioOutputDevice()"
            [disabled]="isTransitioningAudio"
          >
            <mat-option *ngFor="let dev of audioOutputDevices" [value]="dev">
              {{ dev.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div style="padding: 15px 0;">
    <button
      mat-raised-button
      color="primary"
      (click)="confirmed()"
      [disabled]="!canPressNext"
    >
      Enter session
    </button>
  </div>
</div>
