import { ASSStyle } from '../utils';

export const ASS_DEFAULT_STYLE: ASSStyle = {
  name: 'or_default',
  font: null,
  fontsize: 5.18, // 56px
  primaryColor: '#FFFFFFFF',
  outlineColor: '#27272FB3',
  shadowColor: '#27272FB3',
  hasBox: true,
  outline: 0.37, // 4px
  shadow: 0,
  marginL: 0.52, // 10px
  marginR: 0.52, // 10px
  marginV: 4.62, // 50px
};
