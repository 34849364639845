import { cloneDeep } from 'lodash';
import {
  SchemaTransformation,
  SchemaTransformationOptions,
} from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_3_3 } from '../transformation_3_3/schema_after';
import { WorkflowDataDto_3_4 } from './schema_after';

/*
  NOTE: Purpose of this transformation:
    - 1) update legacy template projects that were created from repo
*/
export const SchemaTransformation_3_4: SchemaTransformation = {
  version: '3.4',
  supportsPolyfill: false,

  run(
    oldSchema: WorkflowDataDto_3_3.WorkflowDataDto,
    { template, selectedLayout, layouts }: SchemaTransformationOptions
  ) {
    const newSchema = cloneDeep(oldSchema) as unknown as WorkflowDataDto_3_4.WorkflowDataDto;
    const parsedTemplate = template as WorkflowDataDto_3_4.WorkflowDataDto;

    // Watermark
    if (!newSchema.globalSettings.watermark?.settings?.visible) {
      newSchema.globalSettings.watermark = { ...parsedTemplate.globalSettings.watermark };

      const templateWatermarkAsset = parsedTemplate.assets.find(
        (a) => a.id === parsedTemplate.globalSettings.watermark.settings.assetId
      );
      newSchema.assets.push(templateWatermarkAsset);

      for (const [, section] of Object.entries(newSchema.sections)) {
        if (section.sectionType !== 'main') {
          continue;
        }

        if (section.timelines.find((t) => t.type === 'watermark')) {
          continue;
        }

        const maxZIndex = section.timelines.reduce((prev, curr) => Math.max(prev, curr.zIndex), 0);

        const layout = selectedLayout ?? layouts[0];
        const layoutWatermarkTimeline = layout.data.timelines.find((t) => t.type === 'watermark');

        section.timelines.push({
          ...layoutWatermarkTimeline,
          zIndex: maxZIndex + 1,
        });
      }
    }

    if (!newSchema.globalSettings.watermark?.settings?.assetId) {
      const templateWatermarkAsset = parsedTemplate.assets.find(
        (a) => a.id === parsedTemplate.globalSettings.watermark.settings.assetId
      );

      newSchema.globalSettings.watermark.settings.assetId = templateWatermarkAsset.id;
      newSchema.assets.push(templateWatermarkAsset);
    }

    // Layouts (for now we dont copy placeholders)
    if (!newSchema.features.layouts) {
      newSchema.features.layouts = {
        ...parsedTemplate.features.layouts,
        placeholders: {
          mainClips: {
            simpleAssetId: '',
            interviewAssetIds: [],
            presentationAssetIds: [],
          },
        },
      };
    }

    return newSchema;
  },
};
