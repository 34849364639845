/* eslint-disable @typescript-eslint/no-explicit-any */
import { cloneDeep } from 'lodash';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_2_6 } from '../transformation_2_6/schema_after';
import { WorkflowDataDto_2_7 } from './schema_after';

/*
  NOTE: Purpose of this transformation:
    - 1) title and hasAudio property on SectionTimeline is now mandatory
*/
export const SchemaTransformation_2_7: SchemaTransformation = {
  version: '2.7',
  supportsPolyfill: false,

  run(oldSchema: WorkflowDataDto_2_6.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as unknown as WorkflowDataDto_2_7.WorkflowDataDto;

    for (const [, section] of Object.entries(newSchema.sections)) {
      for (const timeline of section.timelines) {
        timeline.title = timeline.title ?? '';
        timeline.hasAudio = timeline?.hasAudio ?? false;
      }
    }

    return newSchema;
  },
};
