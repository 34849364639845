import { cloneDeep } from 'lodash';
import { WorkflowDataDto } from '../interfaces';

export function prepareWorkflowForCaptions(sourceWorkflow: WorkflowDataDto) {
  const workflow = cloneDeep(sourceWorkflow);

  const soundtrackAsset = workflow.assets.find((a) => a.id === workflow.globalSettings.soundtrack.assetId);
  delete soundtrackAsset.file;

  const mainTimeline = workflow.timelines.find((t) => t.type === 'main');
  mainTimeline.layers = mainTimeline.layers
    .filter(
      (layer) => layer.type === 'section' && layer.enabled && workflow.sections[layer.sectionId].sectionType === 'main'
    )
    .map((layer) => ({
      ...layer,
      transitions: null,
    }));

  return workflow;
}
