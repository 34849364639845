import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'highlight',
  standalone: true,
})
export class TextHighlightPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(text: string, highlightWords: string[]): unknown {
    if (!text || !highlightWords?.length) {
      return text;
    }
    let highlightedText = text;
    for (const highlightWord of highlightWords) {
      const wordWithoutSplChars = highlightWord.replace(/[&\/\\#,+=()$~%.'":*?<>{}]/g, '');
      const regex = new RegExp(`(?<!<[^>])${wordWithoutSplChars}(?![^<]*>)`, 'gi');
      const match = regex.exec(wordWithoutSplChars);

      const matchedIndex = highlightedText?.toLowerCase().indexOf(wordWithoutSplChars?.toLowerCase());
      const word = highlightedText?.substring(matchedIndex, matchedIndex + wordWithoutSplChars?.length);
      if (match[0]) {
        const html = `<span class="text-highlight">${word ?? match[0]}</span>`;
        highlightedText = highlightedText.replace(regex, html);
      }
    }
    const sanitizedText = this.sanitizer.sanitize(SecurityContext.HTML, highlightedText);
    return this.sanitizer.bypassSecurityTrustHtml(sanitizedText);
  }
}
