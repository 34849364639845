import { UpdateBackgroundEvent } from '../builders/update-background.command';
import { SolidColor } from '../interfaces';
import { WorkflowBaseBuilder } from '../builders/workflow-base.builder';

export type BuilderBackgroundChangeCommandEvent = Omit<UpdateBackgroundEvent, 'sectionId'>;

export class BuilderBackgroundChangeCommand extends WorkflowBaseBuilder<BuilderBackgroundChangeCommandEvent> {
  run(event: BuilderBackgroundChangeCommandEvent) {
    const { assetType, assetFile, duration, color } = event;
    if (assetType === 'video' || assetType === 'image') {
      this.setAssetBackground(null, assetFile, assetType, duration);
    } else if (assetType === 'color') {
      this.setColorBackground(null, color);
    }

    const backgroundAssetId = this.source.globalSettings.backgroundAsset.settings.assetId;
    const backgroundColor = (this.source.globalSettings.backgroundColor as SolidColor).color;
    for (const [, templateSectionsPerType] of Object.entries(this.source.templateSections)) {
      for (const { section, styles } of templateSectionsPerType.sections) {
        const backgroundTimeline = section.timelines.find((t) => t.type === 'background');

        if (assetType === 'video' || assetType === 'image') {
          this.updateBackgroundAssetForTimelines([backgroundTimeline], backgroundAssetId);
        } else if (assetType === 'color') {
          this.updateBackgroundColorForTimelines([backgroundTimeline], backgroundColor, styles);
        }
      }
    }

    return this.ok();
  }
}
