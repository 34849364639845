<div *ngIf="!bulkModeEnabled">
  <div *ngIf="isDirector" class="bulk-icon-container">
    <mat-icon
      svgIcon="bulk-check"
      *ngIf="!bulkModeEnabled"
      (click)="onBulkModeToggleClicked()"
      class="icon bulk-mode-toggle-icon"
    ></mat-icon>
  </div>
  <div *ngIf="!isDirector" class="bulk-icon-container">
    <mat-icon (click)="onBulkModeToggleClicked()" class="icon bulk-mode-toggle-icon">radio_button_unchecked</mat-icon>
    <p>Bulk select</p>
  </div>
</div>
<div *ngIf="bulkModeEnabled" class="bulk-action-selector">
  <span class="action-close"
    ><mat-icon svgIcon="close" color="primary" (click)="onBulkModeToggleClicked()"></mat-icon
  ></span>
  <button class="action-btn" [matMenuTriggerFor]="actionMenu" mat-raised-button color="primary">
    <mat-icon class="check-icon" svgIcon="bulk-check-white"></mat-icon>
    <mat-icon class="caret-icon" svgIcon="caret"></mat-icon>
  </button>
  <mat-menu #actionMenu="matMenu">
    <ng-container *ngFor="let bulkAction of bulkActions">
      <button mat-menu-item (click)="onBulkApplyClicked($event, bulkAction)">
        {{ bulkAction }}
      </button>
    </ng-container>
  </mat-menu>
</div>
