import { AssetId } from '../interfaces';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export class GlobalSettingsWatermarkDeleteCommand extends WorkflowBaseBuilder<{ assetFileId: AssetId }> {
  run({ assetFileId }) {
    const index = this.source.globalSettings.watermark.uploaded.findIndex((asset) => asset.file.path === assetFileId);

    if (index === -1) {
      return this.error('Watermark not found');
    }

    this.source.globalSettings.watermark.uploaded.splice(index, 1);

    return this.ok();
  }
}
