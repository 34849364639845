import { MenuLoggingValue } from './../interfaces/creator-mixpanel.interfaces';

export const VIDEO_OPTIONS = {
  DESIGN_AND_STYLING: 'design-and-styling',
  TEMPLATES: 'templates',
  BACKGROUND_VIDEO: 'background',
  LOGO: 'logo',
  WATERMARK: 'watermark',
  PRIMARY_COLOR: 'primary-color',
  FONT_FAMILY: 'font-family',
  ADVANCED: 'advanced',
  CAPTIONS: 'captions',
  SOUNDTRACK: 'soundtrack',
  SOUNDTRACK_SELECT: 'select',
  ADD_TEXT_BOX: 'text-box',
  ADD_IMAGE: 'image',
  UPLOAD: 'upload',
  INTRO: 'intro',
  OUTRO: 'outro',
  EDIT: 'edit',
  TRANSITION: 'transition',
  LAYOUT: 'layout',
  SPLIT_SCENE: 'split-scene',
  VIDEO_OVERLAY: 'video-overlay',
  COMMENTS: 'comments',
  AUDIO: 'audio',
  ASPECT_RATIO: 'aspect-ratio',
  LAYER: 'layer',
  ZOOM: 'zoom',
  TEXT_EDITING: 'text_editing',
  SPEAKERS: 'speakers',
};

export const VIDEO_OPTIONS_LABELS = {
  [VIDEO_OPTIONS.DESIGN_AND_STYLING]: 'Design & Styling',
  [VIDEO_OPTIONS.TEMPLATES]: 'Templates',
  [VIDEO_OPTIONS.LOGO]: 'Logo',
  [VIDEO_OPTIONS.WATERMARK]: 'Watermark',
  [VIDEO_OPTIONS.PRIMARY_COLOR]: 'Primary Color',
  [VIDEO_OPTIONS.FONT_FAMILY]: 'Font Family',
  [VIDEO_OPTIONS.ADVANCED]: 'Advanced',
  [VIDEO_OPTIONS.SOUNDTRACK]: 'Soundtrack',
  [VIDEO_OPTIONS.SOUNDTRACK_SELECT]: 'Select Soundtrack',
  [VIDEO_OPTIONS.ADD_TEXT_BOX]: 'Add Text Box',
  [VIDEO_OPTIONS.ADD_IMAGE]: 'Add Image',
  [VIDEO_OPTIONS.BACKGROUND_VIDEO]: 'Background',
  [VIDEO_OPTIONS.UPLOAD]: 'Upload',
  [VIDEO_OPTIONS.INTRO]: 'Edit Intro',
  [VIDEO_OPTIONS.OUTRO]: 'Edit Outro',
  [VIDEO_OPTIONS.TRANSITION]: 'Edit Transition',
  [VIDEO_OPTIONS.LAYOUT]: 'Layout',
  [VIDEO_OPTIONS.VIDEO_OVERLAY]: 'Video Overlay',
  [VIDEO_OPTIONS.COMMENTS]: 'Comments',
  [VIDEO_OPTIONS.AUDIO]: 'Audio',
  [VIDEO_OPTIONS.ASPECT_RATIO]: 'Aspect Ratio',
  [VIDEO_OPTIONS.SPLIT_SCENE]: 'Split Scene',
  [VIDEO_OPTIONS.CAPTIONS]: 'Captions',
  [VIDEO_OPTIONS.LAYER]: 'Layer',
  [VIDEO_OPTIONS.ZOOM]: 'Adjust Zooming',
  [VIDEO_OPTIONS.TEXT_EDITING]: 'Text Editing',
  [VIDEO_OPTIONS.SPEAKERS]: 'Speakers Name',
};

export const VIDEO_OPTIONS_SUBTITLES = {
  [VIDEO_OPTIONS.VIDEO_OVERLAY]: 'Add a video on top',
};

export const HAS_UPLOAD = new Set([VIDEO_OPTIONS.LOGO, VIDEO_OPTIONS.WATERMARK, VIDEO_OPTIONS.BACKGROUND_VIDEO]);
export const SECTION_ROOT_NODES = new Set([VIDEO_OPTIONS.INTRO, VIDEO_OPTIONS.OUTRO]);

export const MENU_LOGGING: { [key: string]: MenuLoggingValue } = {
  [VIDEO_OPTIONS.DESIGN_AND_STYLING]: 'design_and_styling',
  [VIDEO_OPTIONS.TEMPLATES]: 'templates',
  [VIDEO_OPTIONS.LOGO]: 'logo',
  [VIDEO_OPTIONS.WATERMARK]: 'watermark',
  [VIDEO_OPTIONS.PRIMARY_COLOR]: 'primary_color',
  [VIDEO_OPTIONS.FONT_FAMILY]: 'font_family',
  [VIDEO_OPTIONS.ADVANCED]: 'advanced',
  [VIDEO_OPTIONS.BACKGROUND_VIDEO]: 'background_video',
  [VIDEO_OPTIONS.UPLOAD]: 'upload',
  [VIDEO_OPTIONS.LAYOUT]: 'layout',
  [VIDEO_OPTIONS.SPLIT_SCENE]: 'split_scene',
  [VIDEO_OPTIONS.VIDEO_OVERLAY]: 'video_overlay',
  [VIDEO_OPTIONS.COMMENTS]: 'comments',
  [VIDEO_OPTIONS.TEXT_EDITING]: 'text_editing',
  [VIDEO_OPTIONS.SPEAKERS]: 'speakers',
};
