import { SectionLayer } from '../interfaces';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export interface DuplicateSectionEvent {
  sectionId: string;
}

export interface DuplicateSectionEventResult {
  addedSectionId: string;
}

export class DuplicateSectionCommand extends WorkflowBaseBuilder<DuplicateSectionEvent, DuplicateSectionEventResult> {
  run(event: DuplicateSectionEvent) {
    const sectionIndex = this.source.timelines.find((t) => t.type === 'main').layers.findIndex(
      (l) => (l as SectionLayer).sectionId === event.sectionId
    );

    const newSectionId = this.copySection(event.sectionId, sectionIndex + 1);
    const section = this.source.sections[newSectionId];

    this.addLayoutPlaceholders(newSectionId, section.layout.layoutType, true);

    return this.ok({ addedSectionId: newSectionId });
  }
}
