import { getLayerFromId } from '../helpers/timelines.helpers';
import { getAssetDurationAfterTextTrimmer } from '../helpers';
import { LayerIdEvent } from '../interfaces/timelines.interfaces';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export interface UpdateLayerVisibilityEvent extends LayerIdEvent {
  newStartAt: number;
  newEndAt: number;
}

export class UpdateLayerVisibilityCommand extends WorkflowBaseBuilder<UpdateLayerVisibilityEvent> {
  run({ layerId, newStartAt, newEndAt }: UpdateLayerVisibilityEvent) {
    const layerInfo = getLayerFromId(layerId, this.source);
    if (!layerInfo) {
      return this.error(`Layer with id ${layerId} was not found!`);
    }

    const { layer, sectionId } = layerInfo;
    const section = this.source.sections[sectionId];

    if (newStartAt > newEndAt) {
      return this.error('End time must be greater then start time.');
    }
    if (newStartAt < 0) {
      return this.error('Start time must be greater then zero.');
    }
    if (newEndAt > section.sectionDuration) {
      return this.error('End time must be less then scene duration.');
    }
    if (layer.type === 'video') {
      const asset = this.source.assets.find((a) => a.id === layer.assetId);
      const assetDuration = getAssetDurationAfterTextTrimmer(asset);

      const newVisibilityDuration = newEndAt - newStartAt;
      if (newVisibilityDuration > assetDuration) {
        return this.error(`New visibility must be less then asset's duration.`);
      }
    }

    layer.visibility = {
      ...layer.visibility,
      startAt: newStartAt,
      endAt: newEndAt,
    };

    return this.ok();
  }
}
