import { cloneDeep } from 'lodash';
import { v4 } from 'uuid';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_1_7 } from '../transformation_1_7/schema_after';
import { WorkflowDataDto_1_8 } from './schema_after';

/*
  NOTE: Purpose of this transformation:
    - move global background color from features into globalSettings
    - add global background color if missing
    - for each section, copy global background color into it
    - for each section, removed enabled flag
    - in case template has layouts, add missing background timelines (this is for Atticus)
    - fill empty background timelines with ColorLayer(s)
*/
export const SchemaTransformation_1_8: SchemaTransformation = {
  version: '1.8',
  supportsPolyfill: false,

  run(oldSchema: WorkflowDataDto_1_7.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as unknown as WorkflowDataDto_1_8.WorkflowDataDto;

    const backgroundColor = oldSchema.features.styles?.backgroundColor ?? '#000000';

    const hasLayouts = !!oldSchema.features.layouts;

    newSchema.globalSettings.backgroundColor = {
      type: 'solid',
      color: backgroundColor,
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    delete (newSchema.features as any).styles;

    for (const [, section] of Object.entries(newSchema.sections)) {
      section.backgroundColor = newSchema.globalSettings.backgroundColor;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      delete (section as any).enabled;

      // Add missing background timelines to Atticus template
      if (hasLayouts && !section.timelines.find((t) => t.type === 'background')) {
        section.timelines.push({
          id: v4(),
          type: 'background',
          layers: [],
        });
      }

      // Fill empty background timelines with color layers
      const emptyBackgroundTimeline = section.timelines.find(
        (t) => t.type === 'background' && (t.layers.length === 0 || !t.layers[0].enabled)
      );

      if (emptyBackgroundTimeline) {
        const newStyle: WorkflowDataDto_1_8.Style = { id: v4(), color: backgroundColor };
        newSchema.styles.push(newStyle);

        emptyBackgroundTimeline.layers = [
          {
            loop: true,
            type: 'color',
            bounds: { x: 0, y: 0, width: 100, height: 100 },
            styleId: newStyle.id,
            layerId: v4(),
            visibility: {
              startAt: 0,
            },
          },
        ];
      }
    }

    return newSchema;
  },
};
