import { SectionId, SectionLayer } from '../interfaces';
import { WorkflowTemplatesBaseBuilder } from './workflow-templates-base.builder';

export interface BuilderSwitchTemplateTypeCommandEvent {
  isBlueprint: boolean;
}

export class BuilderSwitchTemplateTypeCommand extends WorkflowTemplatesBaseBuilder<BuilderSwitchTemplateTypeCommandEvent> {
  run(event: BuilderSwitchTemplateTypeCommandEvent) {
    if (event.isBlueprint) {
      this.switchToBlueprint();
    } else {
      this.switchToLayout();
    }

    return this.ok();
  }

  private switchToLayout() {
    this.removeAllMainSections();

    this.addDefaultMainSection();
  }

  private switchToBlueprint() {
    this.removeAllMainSections();
  }

  private removeAllMainSections() {
    const mainTimeline = this.source.timelines.find((t) => t.type === 'main');

    const mainScenes = mainTimeline.layers.filter(
      (l) => l.type === 'section' && this.source.sections[l.sectionId].sectionType === 'main'
    ) as SectionLayer[];

    for (const mainScene of mainScenes) {
      delete this.source.sections[mainScene.sectionId];
    }

    mainTimeline.layers = mainTimeline.layers.filter(
      (l) => l.type === 'section' && (l.sectionId === SectionId.Intro || l.sectionId === SectionId.Outro)
    );
  }
}
