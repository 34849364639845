import { cloneDeep } from 'lodash';
import * as uuid from 'uuid';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_0_3 } from '../transformation_0_3/schema_after';
import { WorkflowDataDto_0_4 } from './schema_after';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function addGlobalSettings(schema: WorkflowDataDto_0_4.WorkflowDataDto, brandKit: any) {
  schema.globalSettings = {
    logo: { uploaded: [] },
    watermark: { uploaded: [] },
    backgroundVideo: { uploaded: [] },
  };

  const brandKitAssets = brandKit?.assets.assets.data;
  const brandKitLogos = brandKitAssets?.filter((asset) => asset.type === 'logo') || [];
  const brandKitWatermarks = brandKitAssets?.filter((asset) => asset.type === 'watermark') || [];
  const brandKitBackgroundVideos = brandKitAssets?.filter((asset) => asset.type === 'background-video') || [];

  const logo = schema.features?.wizard?.elements.find(
    (element) => element.type === 'asset' && element.targetTags.includes('logo')
  ) as WorkflowDataDto_0_4.ElementAssetFeature;

  const watermark = schema.features?.watermark?.enabled
    ? schema.assets?.find((asset) => asset.id === schema.features.watermark.properties?.assetId)
    : null;

  const backgroundVideo = schema.features?.wizard?.elements.find(
    (element) => element.type === 'asset' && element.targetTags.includes('background-video')
  ) as WorkflowDataDto_0_4.ElementAssetFeature;

  if (logo && !brandKitLogos.find((bkLogo) => bkLogo.asset.file?.path === logo.asset.file?.path)) {
    schema.globalSettings.logo.uploaded.push({
      id: uuid.v4(),
      file: {
        path: logo.asset.file?.path,
        provider: 'or-assets',
      },
      fileName: 'Logo',
      type: 'image',
    });
  }

  if (watermark && !brandKitWatermarks.find((bkWatermark) => bkWatermark.asset.file?.path === watermark.file?.path)) {
    schema.globalSettings.watermark.uploaded.push({
      id: uuid.v4(),
      file: {
        path: watermark.file?.path,
        provider: 'or-assets',
      },
      fileName: 'Watermark',
      type: 'image',
    });
  }

  if (
    backgroundVideo &&
    !brandKitBackgroundVideos.find((bkBackground) => bkBackground.asset.file?.path === backgroundVideo.asset.file?.path)
  ) {
    schema.globalSettings.backgroundVideo.uploaded.push({
      id: uuid.v4(),
      file: {
        path: backgroundVideo.asset.file?.path,
        provider: 'or-assets',
      },
      fileName: 'Background Video',
      type: 'clip',
    });
  }
}

export const SchemaTransformation_0_4: SchemaTransformation = {
  version: '0.4',
  supportsPolyfill: false,

  run(
    oldSchema: WorkflowDataDto_0_3.WorkflowDataDto,
    { brandKit }: { brandKit: unknown }
  ): WorkflowDataDto_0_4.WorkflowDataDto {
    const newSchema = cloneDeep(oldSchema) as WorkflowDataDto_0_4.WorkflowDataDto;

    addGlobalSettings(newSchema, brandKit);

    return newSchema;
  },
};
