import { CaptionSpeaker } from '../../interfaces/caption.interface';

export abstract class SubtitleBuilderStrategy {
  protected speakers = new Map<string, CaptionSpeaker>();

  constructor(speakers: CaptionSpeaker[]) {
    for (const speaker of speakers) {
      this.speakers.set(speaker.id, speaker);
    }
  }

  abstract getHeader(): string;

  abstract getContent(): string;

  abstract buildCues(): void;
}
