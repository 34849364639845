import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {
  AudioDevice,
  isMobile,
  MediaRecorderDataAvailableEvent,
  startStream,
  stopStream,
  VideoDevice,
} from '@openreel/frontend/common';
import { RecordStreamStatus } from 'libs/ui/src/interfaces';
import { Subject } from 'rxjs';
import { OpenreelVideoStreamComponent } from '../openreel-video-stream';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { OpenreelWebcamSelectComponent } from '../openreel-webcam-select';
import { OpenreelMicSelectComponent } from '../openreel-mic-select';
import { OpenreelSpeakerSelectComponent } from '../openreel-speaker-select';

// sample recording time in milliseconds, 5 Secs
const SAMPLE_RECORDING_TIME = 6000;
const BITS_PER_RESOLUTION_PER_FPS = 250;
const MINIMUM_BITRATE = BITS_PER_RESOLUTION_PER_FPS * 720 * 30; // equivalent to the calculated bitrate of 720p 30 fps

@Component({
  selector: 'openreel-openreel-audio-video-stream-test',
  templateUrl: './openreel-audio-video-stream-test.component.html',
  styleUrls: ['./openreel-audio-video-stream-test.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatLegacyButtonModule,
    OpenreelVideoStreamComponent,
    OpenreelWebcamSelectComponent,
    OpenreelMicSelectComponent,
    OpenreelSpeakerSelectComponent,
  ],
})
export class OpenreelAudioVideoStreamTestComponent implements OnInit {
  isLightThemed = true;
  isPopup = true;
  sampleRecordingTime = SAMPLE_RECORDING_TIME;

  showPreview: boolean;
  isRecording: boolean;
  mediaRecorder: MediaRecorder;
  webcamStream: MediaStream;
  selectedVideoDevice: VideoDevice;
  selectedAudioDevice: AudioDevice;
  streamStatus$ = new Subject<RecordStreamStatus>();
  webcamDisabled: boolean;
  audioMuted: boolean;
  mirrored: boolean;
  speakerMuted: boolean;
  selectedSpeakerDevice: AudioDevice;

  constructor(private dialog: MatDialogRef<OpenreelAudioVideoStreamTestComponent>) {}

  ngOnInit(): void {
    this.streamStatus$.next('requesting');
  }

  onWebcamDeviceChange(videoDevice: VideoDevice) {
    this.selectedVideoDevice = videoDevice;
    this.mirrored = !isMobile() ? true : this.selectedVideoDevice?.name?.toLowerCase()?.includes('front');
    this.updateStream();
  }

  onAudioDeviceChange(info: { device: AudioDevice }) {
    this.selectedAudioDevice = info.device;
    this.updateStream();
  }

  onSpeakerDeviceChange(speakerDevice: AudioDevice) {
    this.selectedSpeakerDevice = speakerDevice;
    this.setSpeakerSelection();
  }

  async updateStream() {
    if (this.selectedVideoDevice && this.selectedAudioDevice) {
      try {
        this.streamStatus$.next('initializing');
        stopStream(this.webcamStream);
        this.webcamStream = await startStream({
          video: {
            deviceId: this.selectedVideoDevice?.id,
          },
          audio: {
            deviceId: this.selectedAudioDevice?.id,
          },
        });
        this.streamStatus$.next('loading');
      } catch (error) {}
    }
  }

  startTesting() {
    this.isRecording = true;
    const settings = this.webcamStream.getVideoTracks()[0].getSettings();
    const videoBitsPerSecond = Math.max(
      BITS_PER_RESOLUTION_PER_FPS * (settings.height ?? 720) * (settings.frameRate ?? 24),
      MINIMUM_BITRATE
    );
    const options: MediaRecorderOptions = {
      videoBitsPerSecond,
      audioBitsPerSecond: 128 * 1000, // The maximum is 128,000 bps
    };
    this.mediaRecorder = new MediaRecorder(this.webcamStream, options);
    this.mediaRecorder.ondataavailable = (evt) => this.handleTestDataAvailable(evt);
    /**
     * iOS hack
     * By default iOS enables every track before starting to record.
     * If we want to record disabled screen or muted mic first we have to toggle the tracks
     * */
    this.mediaRecorder.onstart = () => {
      this.webcamStream.getTracks().forEach((t) => {
        t.enabled = !t.enabled;
        t.enabled = !t.enabled;
      });
    };
    this.mediaRecorder.start();
    setTimeout(() => {
      this.stopTesting();
    }, SAMPLE_RECORDING_TIME);
  }

  stopTesting() {
    this.mediaRecorder.stop();
    this.showPreview = true;
    this.isRecording = false;
  }

  private handleTestDataAvailable(event: MediaRecorderDataAvailableEvent) {
    const recordedBlobs = [];
    if (event.data && event.data.size > 0) {
      recordedBlobs.push(event.data);
    }
    const superBuffer = new Blob(recordedBlobs, { type: event.data.type });
    const videoElem = document.getElementById('testVideoPlayer') as HTMLVideoElement;
    if (videoElem) {
      videoElem.src = window.URL.createObjectURL(superBuffer);
      this.setSpeakerSelection();
    }
  }

  onDialogCloseClicked(): void {
    stopStream(this.webcamStream);
    this.dialog.close({
      selectedAudioDevice: this.selectedAudioDevice,
      selectedVideoDevice: this.selectedVideoDevice,
      selectedSpeakerDevice: this.selectedSpeakerDevice,
    });
  }

  retest() {
    this.showPreview = false;
  }

  onWebcamDisabledStatusChange(webcamDisabled: boolean) {
    const videoTracks = this.webcamStream?.getVideoTracks();
    if (videoTracks?.length) {
      videoTracks[0].enabled = !webcamDisabled;
      this.webcamDisabled = webcamDisabled;
    }
  }

  onAudioMuteStatusChange(audioMuted: boolean) {
    const audioTracks = this.webcamStream?.getAudioTracks();
    if (audioTracks?.length) {
      audioTracks[0].enabled = !audioMuted;
      this.audioMuted = audioMuted;
    }
  }

  onSpeakerMuteStatusChange(speakerMuted: boolean) {
    this.speakerMuted = speakerMuted;
    this.setSpeakerSelection();
  }

  setSpeakerSelection() {
    const videoElement = document.getElementById('testVideoPlayer') as HTMLVideoElement;
    if (videoElement) {
      videoElement.muted = this.speakerMuted;
    }
    if (videoElement && this.selectedSpeakerDevice) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (videoElement as any).setSinkId(this.selectedSpeakerDevice.id);
    }
  }
}
