import { getLayerFromId } from '../helpers/timelines.helpers';
import { AssetId } from '../interfaces';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export interface UpdateImageLayerEvent {
  layerId: string;
  assetFileId: AssetId;
  width: number;
  height: number;
}

export class UpdateImageLayerCommand extends WorkflowBaseBuilder<UpdateImageLayerEvent> {
  run(event: UpdateImageLayerEvent) {
    const layerData = getLayerFromId(event.layerId, this.source);
    const layer = layerData.layer;

    if (layer.type !== 'image') {
      return this.error(`Not an image layer: ${layer.layerId}`);
    }

    const projectAspectRatio =
      this.source.globalSettings.resolution.width / this.source.globalSettings.resolution.height;
    const imageAspectRatio = event.width / event.height;
    const initialHeightPct = (layer.bounds.width / imageAspectRatio) * projectAspectRatio;

    layer.bounds.height = initialHeightPct;

    const asset = this.source.assets.find((a) => a.id === layer.assetId);
    asset.file.path = event.assetFileId;

    return this.ok();
  }
}
