import { cloneDeep } from 'lodash';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_3_8 } from '../transformation_3_8/schema_after';
import { WorkflowDataDto_4_4 } from './schema_after';

/*
  NOTE: Purpose of this transformation:
    - 1) add midpoint for crossLayer transitions
*/
export const SchemaTransformation_4_4: SchemaTransformation = {
  version: '4.4',
  supportsPolyfill: true,

  run(oldSchema: WorkflowDataDto_3_8.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as unknown as WorkflowDataDto_4_4.WorkflowDataDto;

    const mainTimeline = newSchema.timelines.find((t) => t.type === 'main');
    const sectionLayersWithCrossLayerTransition = mainTimeline.layers.filter(
      (l) => l.type === 'section' && l.transitions?.crossLayer
    );

    for (const layer of sectionLayersWithCrossLayerTransition) {
      const asset = newSchema.assets.find((a) => a.id === layer.transitions.crossLayer.layer.assetId);

      layer.transitions.crossLayer.duration = asset.data?.duration;

      if (!layer.transitions.crossLayer.midpoint) {
        layer.transitions.crossLayer.midpoint = layer.transitions.crossLayer.duration / 2;
      }
    }

    return newSchema;
  },
};
