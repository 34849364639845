/* eslint-disable @typescript-eslint/no-explicit-any */
import { cloneDeep } from 'lodash';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_2_9 } from '../transformation_2_9/schema_after';

/*
  NOTE: Purpose of this transformation:
    - 1) remove zoomToFit from timeline.controls
*/
export const SchemaTransformation_3_1: SchemaTransformation = {
  version: '3.1',
  supportsPolyfill: false,

  run(oldSchema: WorkflowDataDto_2_9.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as unknown as WorkflowDataDto_2_9.WorkflowDataDto;

    for (const [, section] of Object.entries(newSchema.sections)) {
      for (const timeline of section.timelines) {
        delete timeline.controls?.zoomToFit;
      }
    }

    return newSchema;
  },
};
