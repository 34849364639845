import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { AudioDevice, Cleanupable, filterDevices, stopStream } from '@openreel/frontend/common';
import { BehaviorSubject, debounceTime, fromEvent, tap, throttleTime } from 'rxjs';

@Component({
  selector: 'openreel-mic-select',
  templateUrl: './openreel-mic-select.component.html',
  styleUrls: ['./openreel-mic-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FlexLayoutModule, MatIconModule, MatLegacyButtonModule, MatLegacyMenuModule],
})
export class OpenreelMicSelectComponent extends Cleanupable implements OnInit, OnDestroy, OnChanges {
  availableAudioDevices: AudioDevice[] = [];
  audioSelectStatus$ = new BehaviorSubject<string>(null);
  audioError = false;

  @Input() audioMuted: boolean;
  @Input() showAsIcon: boolean;
  @Input() showMuteAll = false;
  @Input() selectedAudioDevice: AudioDevice;
  @Output() audioDeviceChange = new EventEmitter<{ device: AudioDevice; forceChange: boolean }>();
  @Output() audioMuteStatusChange = new EventEmitter<boolean>();
  @Output() muteAll = new EventEmitter();

  async ngOnInit(): Promise<void> {
    if (!this.selectedAudioDevice) {
      this.getMicPermission();
    } else {
      await this.getDevices();
    }

    this.mortalize(
      fromEvent(navigator.mediaDevices, 'devicechange').pipe(
        debounceTime(500),
        tap(() => this.getDevices())
      )
    ).subscribe();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedAudioDevice) {
      sessionStorage.setItem('openreel-selected-audio', JSON.stringify(changes.selectedAudioDevice.currentValue));
    }
  }

  private async getMicPermission() {
    console.log('get getMicPermission');
    try {
      this.audioSelectStatus$.next('waiting for mic permission');
      const audioStream = await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
      stopStream(audioStream);
      await this.getDevices();
      this.audioError = false;
    } catch (error) {
      this.audioError = true;
      this.audioSelectStatus$.next(error.message ?? 'mic permission denied');
      this.availableAudioDevices = [];
    }
  }

  private async getDevices() {
    this.audioSelectStatus$.next('fetching available audio options');
    const devices = await navigator.mediaDevices.enumerateDevices();
    const { audioDevices } = filterDevices(devices);
    this.availableAudioDevices = audioDevices;
    this.audioSelectStatus$.next(null);
    this.setDefaultDevice();
  }

  setDefaultDevice() {
    const currentDevice = JSON.parse(sessionStorage.getItem('openreel-selected-audio'));
    // set default audio device
    const selectedAudio = this.availableAudioDevices?.find(
      (device) => JSON.stringify(device) === JSON.stringify(currentDevice)
    );
    const defaultSelectedDevice = selectedAudio || this.availableAudioDevices[0];
    const forceChange = currentDevice && !selectedAudio;
    this.changeAudioDevice(defaultSelectedDevice, forceChange);
  }

  changeAudioDevice(device: AudioDevice, forceChange: boolean) {
    if (JSON.stringify(this.selectedAudioDevice) !== JSON.stringify(device)) {
      this.audioDeviceChange.next({ device, forceChange });
    }
  }

  toggleAudioMuted(status: boolean) {
    sessionStorage.setItem('openreel-audio-muted', `${status}`);
    this.audioMuteStatusChange.next(status);
  }
}
