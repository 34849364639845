import { sortBy } from 'lodash';
import { WorkflowDataDto_3_2 } from './schema_after';

export function getSectionDuration(section: WorkflowDataDto_3_2.Section) {
  const mainTimelines = section?.timelines.filter((t) => t.type === 'main');
  if (mainTimelines.length === 0) {
    return section.sectionDuration;
  }

  return getMaxTimelinesDuration(mainTimelines);
}

export const getMaxTimelinesDuration = (timelines: WorkflowDataDto_3_2.SectionTimeline[]) => {
  const mainTimelines = timelines.filter((t) => t.type === 'main');
  return mainTimelines.reduce((prev, curr) => Math.max(prev, getTimelineDuration(curr)), 0);
};

export function getTimelineDuration(timeline: WorkflowDataDto_3_2.SectionTimeline): number {
  if (timeline.layers.length === 0) {
    return 0;
  }

  const sortedLayers = sortBy(timeline.layers, (layer) => layer.visibility?.startAt || 0);
  return sortedLayers[sortedLayers.length - 1].visibility?.endAt - sortedLayers[0].visibility?.startAt;
}
