import { Asset, CompanySoundtrack } from '../interfaces';
import { WorkflowTemplatesBaseBuilder } from './workflow-templates-base.builder';
import { v4 as uuidv4 } from 'uuid';

export interface BuilderSoundtrackChangeCommandEvent {
  soundtrack: CompanySoundtrack;
}

export class BuilderSoundtrackChangeCommand extends WorkflowTemplatesBaseBuilder<BuilderSoundtrackChangeCommandEvent> {
  run(event: BuilderSoundtrackChangeCommandEvent) {
    let asset: Asset;
    if (this.source.globalSettings.soundtrack?.assetId) {
      asset = this.getAsset(this.source.globalSettings.soundtrack.assetId);
    } else {
      const assetId = uuidv4();
      asset = {
        id: assetId,
        type: 'audio',
        isGlobal: true,
      };
      this.source.assets.push(asset);

      this.source.globalSettings.soundtrack.assetId = assetId;
    }

    if (event.soundtrack) {
      asset.file = { path: event.soundtrack.assetId, provider: 'or-assets' };
    } else {
      delete asset.file;
    }

    return this.ok();
  }
}
