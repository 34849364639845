import * as TimelineInterfaces from '../interfaces/timelines.interfaces';
import { getLayerFromId } from '../helpers/timelines.helpers';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export class RemoveLayerCommand extends WorkflowBaseBuilder<TimelineInterfaces.LayerIdEvent> {
  run(event: TimelineInterfaces.LayerIdEvent) {
    const layerInfo = getLayerFromId(event.layerId, this.source);

    if (!layerInfo) {
      return this.error(`Can't find layer to remove. Layer id: ${event.layerId}`);
    }

    const { timeline } = layerInfo;

    timeline.layers = [];

    return this.ok();
  }
}
