import { UpdateAssetZoom } from '../interfaces';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export class UpdateAssetZoomCommand extends WorkflowBaseBuilder<UpdateAssetZoom> {
  run({ newZoomPans, assetId }: UpdateAssetZoom) {
    this.getAsset(assetId).zoomPans = newZoomPans;

    return this.ok();
  }
}
