import { TemplateBuilderPlaceholders } from '../interfaces';
import { WorkflowTemplatesBaseBuilder } from './workflow-templates-base.builder';

export interface BuilderPlaceholdersChangeCommandEvent {
  placeholders: TemplateBuilderPlaceholders;
}

export class BuilderPlaceholdersChangeCommand extends WorkflowTemplatesBaseBuilder<BuilderPlaceholdersChangeCommandEvent> {
  run(event: BuilderPlaceholdersChangeCommandEvent) {
    this.upsertPlaceholders(event.placeholders);
    return this.ok();
  }
}
