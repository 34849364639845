import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, Observable, Subject, combineLatest, auditTime, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Operator } from './acl-operator.type';
import { AclPermission } from './acl-permission.type';
import { AclDirective } from './acl.directive';

@Directive({
  selector: '[hasPermission]',
  standalone: true,
})
export class HasPermissionDirective implements OnInit, OnDestroy {

  @Input() set hasPermission(permission: AclPermission | AclPermission[]) {
    this.requestedPermissionSubject.next(permission);
  }

  @Input() set hasPermissionOperator(operator: Operator) {
    this.operatorSubject.next(operator);
  }

  @Input() readonly hasPermissionElse: TemplateRef<unknown>;

  private readonly operator$: Observable<Operator>;
  private readonly requestedPermission$: Observable<AclPermission | AclPermission[]>;

  private readonly operatorSubject = new BehaviorSubject<Operator>('OR');
  private readonly requestedPermissionSubject = new ReplaySubject<AclPermission | AclPermission[]>(1);
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly templateRef: TemplateRef<unknown>,
    private readonly acl: AclDirective,
    private readonly cdr: ChangeDetectorRef,
  ) {
    if (!this.acl) {
      throw new Error('Parent AclDirective is required');
    }

    this.requestedPermission$ = this.requestedPermissionSubject.asObservable();
    this.operator$ = this.operatorSubject.asObservable();
  }

  ngOnInit(): void {
    this.subscribeToRequestedPermissionChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private subscribeToRequestedPermissionChanges(): void {
    combineLatest([this.requestedPermission$, this.operator$])
      .pipe(
        auditTime(0),
        takeUntil(this.destroy$),
      )
      .subscribe(([requestedPermission, operator]) => {
        this.viewContainerRef.clear();

        const hasPermission = this.acl.aclFunction(requestedPermission, operator);

        if (hasPermission) {
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        } else {
          if (this.hasPermissionElse) {
            this.viewContainerRef.createEmbeddedView(this.hasPermissionElse);
          }
        }

        this.cdr.markForCheck();
      });
  }
}
