import { AssetTranscript, WorkflowDataDto } from '@openreel/creator/common';
import { ASSStyle, CaptionsGenerator } from '@openreel/transcription';
import { CaptionsDto } from '../../captions/interfaces/captions.interfaces';
import { WorkflowSpeaker } from '../interfaces/asset-transcription.interface';

export function assetTranscriptionsDataToCaptionsDto(
  workflow: WorkflowDataDto,
  speakers: WorkflowSpeaker[],
  transcriptions: {
    assetId: string;
    transcription: AssetTranscript;
    language: string;
  }[],
  globalStyle: ASSStyle
) {
  const timeboxGenerator = new CaptionsGenerator(workflow, transcriptions);

  const [, timeboxes] = timeboxGenerator.generateCaptions();

  const captions: CaptionsDto = {
    speakers: speakers.map((speaker) => ({
      id: speaker.uid,
      name: speaker.name,
      color: speaker.color,
    })),
    timeboxes: timeboxes.map((timebox) => ({
      ...timebox,
      startMs: timebox.start,
      endMs: timebox.end,
      marginV: globalStyle.marginV,
    })),
    globalStyle,
  };

  return captions;
}
