import {
  Asset,
  LayerOptions,
  LayoutType,
  LottieLayer,
  PROJECT_FREE_ENTRY_TIMELINE_TYPES,
  SolidColor,
  Transitions,
  VideoLayer,
  WorkflowDataDto,
} from '../interfaces';
import {
  createLottieLayerDataFromAsset,
  getLayers,
  getSectionLayer,
  getSectionTimelines,
  getSectionsOfType,
  getTextLayerWithAsset,
  getTimelinesByTypeAndSectionId,
  hasTextOverlay,
} from '../helpers';
import { WorkflowBaseBuilder } from './workflow-base.builder';
import { cloneDeep } from 'lodash';

interface TemplateLayout {
  id: number;
  layoutId: number;
  type: LayoutType;
}

export interface ChangeTemplateEvent {
  oldTemplate: WorkflowDataDto;
  newTemplate: WorkflowDataDto;
  oldTemplateLayouts?: TemplateLayout[];
  newTemplateLayouts?: TemplateLayout[];
}

export interface ChangeTemplateResultLabel {
  key: string;
  value: string;
  label: string;
  order?: number;
}

export interface ChangeTemplateResult {
  notMigratedLabels: {
    ok: boolean;
    intro: ChangeTemplateResultLabel[];
    outro: ChangeTemplateResultLabel[];
    textOverlays: ChangeTemplateResultLabel[][];
  };
}

export class ChangeTemplateCommand extends WorkflowBaseBuilder<ChangeTemplateEvent, ChangeTemplateResult> {
  run({ oldTemplate, newTemplate, newTemplateLayouts, oldTemplateLayouts }: ChangeTemplateEvent) {
    const result: ChangeTemplateResult = {
      notMigratedLabels: {
        ok: true,
        intro: [],
        outro: [],
        textOverlays: [],
      },
    };

    if (hasTextOverlay(this.source.globalSettings) && hasTextOverlay(newTemplate.globalSettings)) {
      const oldTextOverlayAsset = this.getAsset(this.source.globalSettings.textOverlays.assetId);
      const newTextOverlayAsset = newTemplate.assets.find(
        (a) => a.id === newTemplate.globalSettings.textOverlays?.assetId
      );
      const notMigratedLabels = this.migrateTextOverlayAsset(oldTextOverlayAsset, newTextOverlayAsset);
      if (notMigratedLabels.length) {
        result.notMigratedLabels.ok = false;
        result.notMigratedLabels.textOverlays = notMigratedLabels;
      }
    }

    // Update settings
    const templateGlobalSettings = cloneDeep(newTemplate.globalSettings);
    this.source.globalSettings.placeholders = templateGlobalSettings.placeholders;
    this.source.features.layouts = cloneDeep(newTemplate.features.layouts);
    this.source.globalSettings.textOverlays = newTemplate.globalSettings.textOverlays;

    // Update assets (only copy placeholder assets + other non-global assets (such as intro & outro assets))
    // We do not copy logo, watermark, background, soundtrack and other global assets
    const oldAssets = this.source.assets;
    this.source.assets = newTemplate.assets.filter((a) => a.isPlaceholder || !a.isGlobal);
    this.addAndReplaceAssets(oldAssets);

    const oldStyles = this.source.styles;
    this.source.styles = newTemplate.styles;
    this.addAndReplaceStyles(oldStyles);

    // Update styles
    for (const style of this.source.styles) {
      if (style.fontWeight || style.fontStyle) {
        style.font = this.source.globalSettings.font;
      }
    }

    // Get intro/outro migration warnings
    const outroNotMigratedLabels = this.migrateIntroOutro('intro', newTemplate);
    if (outroNotMigratedLabels.length) {
      result.notMigratedLabels.ok = false;
      result.notMigratedLabels.intro.push(...outroNotMigratedLabels);
    }

    const introNotMigratedLabels = this.migrateIntroOutro('outro', newTemplate);
    if (introNotMigratedLabels.length) {
      result.notMigratedLabels.ok = false;
      result.notMigratedLabels.outro.push(...introNotMigratedLabels);
    }

    // Other
    this.updateTransitions(oldTemplate, newTemplate);
    if (newTemplateLayouts !== undefined && oldTemplateLayouts !== undefined) {
      this.updateSectionLayouts(newTemplateLayouts, oldTemplateLayouts);
    }
    this.updateMainTimelinesStyles();
    this.applyGlobalColor((this.source.globalSettings.primaryColor as SolidColor).color, 'primary');
    this.migrateGlobalLottiesToCurrentLogoAsset();
    this.migrateBackgroundAssetToNewGlobalAsset();

    getSectionsOfType(this.source.sections, 'main').forEach(({ sectionId }) => {
      this.migrateLottiesToCurrentLogoAsset(sectionId);
    });

    return this.ok(result);
  }

  private migrateIntroOutro(sectionId: 'intro' | 'outro', template: WorkflowDataDto) {
    // if target template does not have the section, keep the old section intact
    const templateSectionTimelines = getSectionLayer(sectionId, template);
    if (!template.sections[sectionId] || !templateSectionTimelines) {
      return [];
    }

    // if source template does not have the section, copy the section from the target template without any other changes
    if (!this.source.sections[sectionId]) {
      this.source.sections[sectionId] = template.sections[sectionId];
      this.source.timelines.find((t) => t.type === 'main').layers.push(templateSectionTimelines);
      this.migrateLottiesToCurrentLogoAsset(sectionId);
      return [];
    }

    const oldTextOverlayLayers: (LayerOptions & LottieLayer)[] = [];
    const newTextOverlayLayers: (LayerOptions & LottieLayer)[] = [];
    const notMigratedLabels: ChangeTemplateResultLabel[] = [];

    for (const { layer } of getLayers(this.source, { types: ['lottie'], sectionIds: [sectionId] })) {
      if (layer.type === 'lottie' && !layer.isTextBox) {
        oldTextOverlayLayers.push(layer);
      }
    }
    for (const { layer } of getLayers(template, { types: ['lottie'], sectionIds: [sectionId] })) {
      if (layer.type === 'lottie' && !layer.isTextBox) {
        newTextOverlayLayers.push(layer);
      }
    }

    oldTextOverlayLayers.forEach((oldTextOverlayLayer) => {
      const oldTextOverlayAsset = this.getAsset(oldTextOverlayLayer.assetId);
      const oldData = oldTextOverlayLayer.data;
      let notMigratedKeys: string[] = [];
      const migratedKeys: string[] = [];

      const migrateTextOverlay = (newTextOverlayLayer: LayerOptions & LottieLayer) => {
        const newTextOverlayAsset = template.assets.find((a) => a.id === newTextOverlayLayer.assetId);
        const newData = newTextOverlayLayer.data;

        const results = this.migrateLottieData(oldTextOverlayAsset, newTextOverlayAsset, oldData, newData);
        notMigratedKeys.push(...results.notMigratedKeys);
        migratedKeys.push(...results.migratedKeys);
      };

      const exactTextOverlayMatch = newTextOverlayLayers.find(
        (newTextOverlayLayer) => newTextOverlayLayer.assetId === oldTextOverlayLayer.assetId
      );
      if (exactTextOverlayMatch) {
        migrateTextOverlay(exactTextOverlayMatch);
      } else if (newTextOverlayLayers.length) {
        newTextOverlayLayers.forEach((newTextOverlayLayer) => migrateTextOverlay(newTextOverlayLayer));
      } else {
        for (const key in oldData) {
          if (oldData[key].type === 'text' && oldData[key].value) {
            notMigratedKeys.push(key);
          }
        }
      }

      notMigratedKeys = notMigratedKeys.filter((key) => !migratedKeys.includes(key));
      const notMigratedLabelsForLayer = notMigratedKeys.map((key) => {
        return {
          key,
          label: oldTextOverlayAsset.preset[key].label ?? key,
          value: oldData[key].value,
          order: oldTextOverlayAsset.preset[key].order,
        };
      }).sort((a, b) => a.order - b.order);
      notMigratedLabels.push(...notMigratedLabelsForLayer);
    });

    const newTemplateSection = cloneDeep(template.sections[sectionId]);
    delete newTemplateSection.backgroundColor;

    const oldBackgroundTimeline = this.source.sections[sectionId].timelines.find((t) => t.type === 'background');
    const oldBackgroundColor = this.source.sections[sectionId].backgroundColor;
    if (oldBackgroundTimeline) {
      const backgroundTimelineIndex = newTemplateSection.timelines.findIndex((t) => t.type === 'background');
      if (backgroundTimelineIndex !== -1) {
        if (oldBackgroundTimeline.layers[0].type === 'color' && oldBackgroundColor?.type === 'solid') {
          this.updateBackgroundColorForTimelines([oldBackgroundTimeline], oldBackgroundColor.color, this.source.styles);
        }

        newTemplateSection.timelines[backgroundTimelineIndex] = oldBackgroundTimeline;
      }
    }

    const freeEntryTimelines = this.source.sections[sectionId].timelines.filter((t) =>
      PROJECT_FREE_ENTRY_TIMELINE_TYPES.includes(t.type)
    );
    newTemplateSection.timelines.push(...freeEntryTimelines);

    this.source.sections[sectionId] = {
      ...this.source.sections[sectionId],
      ...newTemplateSection,
    };

    this.migrateLottiesToCurrentLogoAsset(sectionId);

    return notMigratedLabels.filter((label, index) => notMigratedLabels.findIndex((n) => n.key === label.key) === index);
  }

  private migrateLottiesToCurrentLogoAsset(sectionId: string) {
    const logoAssetId = this.source.globalSettings.logo.settings.assetId;
    const section = this.source.sections[sectionId];
    for (const timeline of section.timelines) {
      for (const layer of timeline.layers) {
        if (layer.type !== 'lottie') {
          continue;
        }

        const asset = this.source.assets.find((a) => a.id === layer.assetId);

        // Update preset
        Object.entries(asset.preset || {})
          .filter(([, presetField]) => presetField.type === 'logo')
          .forEach(([, presetField]) => (presetField.assetId = logoAssetId));

        // Update data
        Object.entries(layer.data || {})
          .filter(([, field]) => field.type === 'logo')
          .forEach(([, field]) => (field.assetId = logoAssetId));
      }
    }
  }

  private migrateGlobalLottiesToCurrentLogoAsset() {
    if (this.source.globalSettings.textOverlays) {
      const logoAssetId = this.source.globalSettings.logo.settings.assetId;
      const asset = this.source.assets.find((a) => a.id === this.source.globalSettings.textOverlays.assetId);

      // Update preset
      Object.entries(asset.preset || {})
        .filter(([, presetField]) => presetField.type === 'logo')
        .forEach(([, presetField]) => (presetField.assetId = logoAssetId));
    }
  }

  private migrateBackgroundAssetToNewGlobalAsset() {
    if (this.source.globalSettings.backgroundAsset) {
      const backgroundTimelines = getTimelinesByTypeAndSectionId(this.source.sections, 'background');
      backgroundTimelines.forEach((timeline) => {
        const videoLayers = timeline.layers.filter((layer) => layer.type === 'video') as VideoLayer[];
        videoLayers.forEach((layer) => {
          layer.assetId = this.source.globalSettings.backgroundAsset.settings.assetId;
        });
      });
    }
  }

  private migrateTextOverlayAsset(oldTextOverlayAsset: Asset, newTextOverlayAsset: Asset) {
    const oldTextOverlayAssetLayers = getTextLayerWithAsset(this.source, oldTextOverlayAsset.id);
    const notMigratedLabels: ChangeTemplateResultLabel[][] = [];
    oldTextOverlayAssetLayers.forEach((layer) => {
      const { data: newData, styles } = createLottieLayerDataFromAsset(newTextOverlayAsset, this.source.globalSettings);
      this.addAndReplaceStyles(styles);
      const oldData = layer.data;
      layer.data = newData;
      layer.assetId = newTextOverlayAsset.id;

      const notMigratedKeys = this.migrateLottieData(
        oldTextOverlayAsset,
        newTextOverlayAsset,
        oldData,
        newData,
      ).notMigratedKeys;
      const notMigratedLabelsForLayer = notMigratedKeys.map((key): ChangeTemplateResultLabel => {
        return {
          key,
          label: oldTextOverlayAsset.preset[key].label ?? key,
          value: oldData[key].value,
          order: oldTextOverlayAsset.preset[key].order,
        }
      }).sort((a, b) => a.order - b.order);
      if (notMigratedLabelsForLayer.length > 0) {
        notMigratedLabels.push(notMigratedLabelsForLayer);
      }
    });

    return notMigratedLabels;
  }

  private updateTransitions(oldTemplate: WorkflowDataDto, newTemplate: WorkflowDataDto) {
    const oldIntroLayer = getSectionLayer('intro', oldTemplate);
    const oldMainLayer = getSectionLayer('main', oldTemplate);
    const oldIntroLottieTransitionAssetId = oldIntroLayer?.transitions?.crossLayer?.layer?.assetId;
    const oldMainLottieTransitionAssetId = oldMainLayer?.transitions?.crossLayer?.layer?.assetId;

    const newIntroLayer = getSectionLayer('intro', newTemplate);
    const newMainLayer = getSectionLayer('main', newTemplate);
    const newIntroTransitions = newIntroLayer?.transitions;
    const newMainTransitions = newMainLayer?.transitions;

    const lottieTransitionsMap = new Map<string, Transitions>([
      [oldIntroLottieTransitionAssetId, newIntroTransitions],
      [oldMainLottieTransitionAssetId, newMainTransitions],
    ]);

    const mainTimeline = this.source.timelines.find((t) => t.type === 'main');
    for (const layer of mainTimeline.layers) {
      if (!layer.transitions?.crossLayer) {
        continue;
      }

      const sectionLottieTransitionAssetId = layer.transitions.crossLayer.layer.assetId;
      const newTransitions = lottieTransitionsMap.get(sectionLottieTransitionAssetId);
      if (newTransitions) {
        layer.transitions = newTransitions;
        continue;
      }

      delete layer.transitions;
    }
  }

  private updateSectionLayouts(newTemplateLayouts: TemplateLayout[], oldTemplateLayouts: TemplateLayout[]) {
    for (const sectionId in this.source.sections) {
      if (this.source.sections[sectionId].sectionType === 'main') {
        let currentTemplateLayout = oldTemplateLayouts.find(
          (tl) => tl.id === this.source.sections[sectionId].layout?.templateLayoutId
        );
        if (!currentTemplateLayout) {
          // if the current template does not have access to the layout set on the section anymore, try to find another layout with the same type
          currentTemplateLayout = oldTemplateLayouts.find(
            (tl) => tl.type === this.source.sections[sectionId].layout?.layoutType
          );
        }

        let templateLayout: TemplateLayout;
        if (currentTemplateLayout) {
          templateLayout = newTemplateLayouts.find((tl) => tl.layoutId === currentTemplateLayout.layoutId);
          if (!templateLayout) {
            // if the new template does not have access to the detected layout on the current template, try to find another layout with the same type
            templateLayout = newTemplateLayouts.find((tl) => tl.type === currentTemplateLayout.type);
          }
        }

        if (templateLayout) {
          this.source.sections[sectionId].layout.templateLayoutId = templateLayout.id;
        } else {
          // finally if no suitable layout with the same type was found, remove the layout from the section
          this.source.sections[sectionId].layout = null;
        }
      }
    }
  }

  private updateMainTimelinesStyles() {
    const mainTimelines = getSectionTimelines(this.source.sections, 'main', 'main');
    for (const { timelines } of mainTimelines) {
      timelines.forEach((timeline) => {
        timeline.styles = this.source.features.layouts.styles;
        for (const layer of timeline.layers) {
          layer.styles = this.source.features.layouts.styles;
        }
      });
    }
  }
}
