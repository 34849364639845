import { cloneDeep } from 'lodash';
import { ProjectFont, TemplateSchemaTransformation, TemplateSchemaTransformationOptions } from '../../../interfaces';
import { TemplateWorkflowDataDto_1_4 } from '../template_transformation_1_4/schema_after';
import { TemplateWorkflowDataDto_1_5 } from './schema_after';

export const TemplateSchemaTransformation_1_5: TemplateSchemaTransformation = {
  version: '1.5',

  run(
    oldSchema: TemplateWorkflowDataDto_1_4.WorkflowDataDto,
    { defaultFont }: TemplateSchemaTransformationOptions
  ): TemplateWorkflowDataDto_1_5.WorkflowDataDto {
    const newSchema = cloneDeep(oldSchema) as unknown as TemplateWorkflowDataDto_1_5.WorkflowDataDto;

    if (!newSchema.globalSettings.font) {
      const font: ProjectFont = {
        id: defaultFont.id,
        source: defaultFont.source,
      };

      newSchema.globalSettings.font = font;

      for (const style of newSchema.styles) {
        style.font = font;
      }

      for (const [, templateSectionGroup] of Object.entries(newSchema.templateSections)) {
        for (const templateSection of templateSectionGroup.sections) {
          for (const style of templateSection.styles) {
            style.font = font;
          }
        }
      }
    }

    return newSchema;
  },
};
