import { AssetId } from '../interfaces';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export interface AddWatermarkEvent {
  assetFileId: AssetId;
  fileName: string;
}

export class GlobalSettingsWatermarkAddCommand extends WorkflowBaseBuilder<AddWatermarkEvent> {
  run({ assetFileId, fileName }) {
    this.source.globalSettings.watermark.uploaded.push({
      id: this.getUniqueId(),
      file: {
        path: assetFileId,
        provider: 'or-assets',
      },
      fileName,
      type: 'image',
    });

    return this.ok();
  }
}
