import 'froala-editor/js/plugins/paragraph_format.min.js';
import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { commonenv } from '../../environments/environment';
import { FroalaCommand, initFroalaCustomIconTemplate, registerFrolaCommand } from './rich-text-custom-command';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { CommonModule } from '@angular/common';

enum FloaraTheme {
  'LIGHT' = 'royal',
  'GRAY' = 'gray',
  'DARK' = 'dark',
}

@Component({
  selector: 'openreel-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
  standalone: true,
  imports: [CommonModule, FroalaEditorModule, FroalaViewModule],
})
export class RichTextEditorComponent implements OnDestroy, OnChanges, AfterContentInit {
  @Input() editorContent: string;
  @Input() showToolbar = true;
  @Input() height: number;
  @Input() isDark: boolean;
  @Input() customEditorCommands: FroalaCommand[];
  @Input() isPlayable: boolean;
  @Input() teleprompterCounting$: Observable<boolean> = of(false);
  @Output() editorContentChange = new EventEmitter<string>();
  @Output() cursorPositionChange = new EventEmitter<string>();
  @Output() highlightedTextChange = new EventEmitter<string>();
  editor = null;
  options = {
    key: commonenv.froalaKey,
    placeholderText: 'Edit Your Content Here!',
    theme: FloaraTheme.LIGHT,
    charCounterCount: false,
    toolbarInline: false,
    toolbarVisibleWithoutSelection: true,
    height: 250,
    attribution: false,
    pasteDeniedAttrs: ['style', 'id', 'class'],
    paragraphFormat: {
      N: 'Paragraph',
      H1: 'Heading 1',
      H2: 'Heading 2',
      H3: 'Heading 3',
      H4: 'Heading 4',
    },
    paragraphFormatSelection: true,
    toolbarButtons: ['bold', 'italic', 'paragraphFormat'],
    events: {
      initialized: () => {
        this.setEditorContent(this.editorContent);
      },
      contentChanged: () => {
        this.contentChanged();
      },
      mouseup: () => {
        if (this.customEditorCommands?.includes(FroalaCommand.SELECTION_PLAY)) {
          this.checkSelectionText();
        }
      },
      mousedown: () => {
        if (this.customEditorCommands?.includes(FroalaCommand.SELECTION_PLAY)) {
          this.clearSelection();
        }
      },
      keyup: (event: Event) => {
        event.stopPropagation();
      },
      keydown: (event: Event) => {
        event.stopPropagation();
      },
    },
  };

  private subscriptions = [];

  textEditor: Element;
  mouseLeave: () => void;
  focusOut: () => void;

  constructor() {
    initFroalaCustomIconTemplate();
    registerFrolaCommand(
      FroalaCommand.SELECTION_PLAY,
      () => this.onSelectionPlay(),
      ($btn) => this.onRefreshSelectionPlay($btn)
    );
    registerFrolaCommand(
      FroalaCommand.CURSOR_PLAY,
      () => this.onCursorPlay(),
      ($btn) => this.onRefreshCursorPlay($btn)
    );
  }
  ngOnChanges(changes: SimpleChanges) {
    const options = { ...this.options };

    let hasChanges = false;

    if (changes.height && this.height && options.height !== this.height) {
      options.height = this.height;
      hasChanges = true;
    }
    if (changes.isDark && this.isDark) {
      options.theme = FloaraTheme.DARK;
    }
    if (changes.customEditorCommands && this.customEditorCommands) {
      options.toolbarButtons = [...this.customEditorCommands, ...options.toolbarButtons];
    }

    this.options = options;

    if (hasChanges) {
      if (this.editor?.opts) {
        this.editor.opts = {
          ...this.editor.opts,
          height: options.height,
        };
      }

      this.editor?.size?.refresh();
    }

    this.ngAfterContentInit();
  }

  ngAfterContentInit() {
    setTimeout(() => {
      const play = document.getElementById('Play-1');
      const playSelected = document.getElementById('Play Selected-1');

      const objectElement = (el: HTMLElement) => ({
        show: () => {
          el.style.display = 'block';
        },
        hide: () => {
          el.style.display = 'none';
        },
      });

      if (play) {
        this.onRefreshCursorPlay(objectElement(play));
        this.focusOut = () => this.onRefreshCursorPlay(objectElement(play));
      }
      if (playSelected) {
        this.onRefreshSelectionPlay(objectElement(playSelected));
        this.mouseLeave = () => this.onRefreshSelectionPlay(objectElement(playSelected));
      }

      this.textEditor = document.getElementsByClassName('fr-wrapper').item(0);
      this.textEditor?.addEventListener('mouseleave', this.mouseLeave);
      document.querySelector('.fr-element.fr-view')?.addEventListener('focusout', this.focusOut);
    }, 500);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.textEditor?.removeEventListener('mouseleave', this.mouseLeave);
    document?.querySelector('.fr-element.fr-view')?.removeEventListener('focusout', this.focusOut);
  }

  initFroala(e) {
    e.initialize();
    this.editor = e.getEditor();
  }
  setEditorContent(content: string) {
    if (this.editor?.html) {
      this.editor.html.set(content);

      this.subscriptions.push(
        this.teleprompterCounting$.subscribe((counting) => {
          if (counting) {
            this.editor.edit.off();
            this.editor.toolbar.disable();
          } else {
            this.editor.edit.on();
            this.editor.toolbar.enable();
          }
        })
      );

      if (this.showToolbar) {
        this.editor.toolbar.show();
      } else {
        this.editor.toolbar.hide();
      }
    }
  }
  contentChanged() {
    this.editorContent = this.editor.html.get();
    this.editorContentChange.emit(this.editorContent);
  }
  checkSelectionText() {
    const selectedText: string = this.editor.selection.text().trim();
    if (!selectedText) {
      this.highlightedTextChange.emit(null);
    }
  }
  clearSelection() {
    this.editor.selection.clear();
  }
  onCursorPlay() {
    //insert the marker
    this.editor.markers.insert();
    //get the HTML With marker
    const htmlWithMarker = this.editor.html.get(true);
    //remove the marker
    this.editor.markers.remove();
    this.cursorPositionChange.emit(htmlWithMarker);
  }
  onRefreshCursorPlay($btn) {
    if (this.isPlayable && document.activeElement === document.querySelector('.fr-element.fr-view')) {
      $btn.show();
    } else {
      $btn.hide();
    }
  }

  onSelectionPlay() {
    this.editor.markers.insert();
    const selectedHtml = this.editor.html.getSelected();
    this.editor.markers.remove();
    this.highlightedTextChange.emit(selectedHtml);
  }
  onRefreshSelectionPlay($btn) {
    const selectedText: string = this.editor.selection.text().trim();
    if (selectedText.length > 0 && this.isPlayable) {
      $btn.show();
    } else {
      $btn.hide();
    }
  }
}
