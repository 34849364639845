/* eslint-disable @typescript-eslint/no-explicit-any */
import { cloneDeep } from 'lodash';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_2_9 } from '../transformation_2_9/schema_after';
import { WorkflowDataDto_3_2 } from './schema_after';
import { getSectionDuration } from './helpers';
/*
  NOTE: Purpose of this transformation:
    - 1) update section duration for all sections
*/
export const SchemaTransformation_3_2: SchemaTransformation = {
  version: '3.2',
  supportsPolyfill: false,

  run(oldSchema: WorkflowDataDto_2_9.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as unknown as WorkflowDataDto_3_2.WorkflowDataDto;

    for (const [, section] of Object.entries(newSchema.sections)) {
      section.sectionDuration = getSectionDuration(section);
    }

    return newSchema;
  },
};
