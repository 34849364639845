import { cloneDeep } from 'lodash';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_0_5 } from '../transformation_0_5/schema_after';
import { WorkflowDataDto_0_6 } from './schema_after';

export const SchemaTransformation_0_6: SchemaTransformation = {
  version: '0.6',
  supportsPolyfill: false,

  run(oldSchema: WorkflowDataDto_0_5.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as WorkflowDataDto_0_6.WorkflowDataDto;

    const mainClipProperties = oldSchema.features.mainClips;
    const mainTimelines = newSchema.sections.main.timelines.filter((t) => t.type === 'main');

    const timelinesToUpdate: WorkflowDataDto_0_6.Timeline[] = [];
    if (mainClipProperties.properties.type === 'video') {
      mainTimelines[0].bounds = mainClipProperties.properties.bounds;
      mainTimelines[0].styles = mainClipProperties.properties.styles;

      timelinesToUpdate.push(mainTimelines[0]);
    } else {
      const mainClipTimelineProperties =
        mainClipProperties.properties as WorkflowDataDto_0_5.MainClipFeatureTimelinesProperties;

      const timelinesLayer = mainTimelines[0].layers[0] as WorkflowDataDto_0_6.TimelinesLayer;

      timelinesToUpdate.push(...timelinesLayer.children);
      timelinesLayer.children.forEach((mainTimeline, index) => {
        mainTimeline.bounds = mainClipTimelineProperties.children[index].layers[0].bounds;
        mainTimeline.styles = mainClipTimelineProperties.children[index].layers[0].styles;
      });
    }

    timelinesToUpdate.forEach((timeline) => {
      timeline.layers.forEach((layer) => {
        layer.bounds = timeline.bounds;
        layer.styles = timeline.styles;
      });
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    delete (newSchema.features as any).mainClips;
    return newSchema;
  },
};
