import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'clipDuration', standalone: true })
export class ClipDurationPipe implements PipeTransform {
  transform(seconds: number) {
    if (seconds >= 60) {
      return Math.round(seconds / 60) + ' mins';
    }
    return (seconds > 0 ? Math.round(seconds) : 0) + ' secs';
  }
}
