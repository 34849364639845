import { LayerStyles, BUILDER_UI_RATIO_WIDTH, BUILDER_UI_RATIO_HEIGHT } from './../interfaces/workflow.interfaces';
import { TemplateBuilderStyles } from './../interfaces/template-builder.interfaces';
import { pxToPct } from '../helpers';

export function getLayerStyles(styles: TemplateBuilderStyles) {
  const layerStyles: LayerStyles = {};

  if (styles?.backgroundColor) {
    if (styles.backgroundColor.type === 'solid') {
      layerStyles.backgroundColor = {
        type: 'solid',
        color: styles?.backgroundColor.color,
      };
    } else {
      layerStyles.backgroundColor = {
        type: 'gradient',
        angle: styles.backgroundColor.angle,
        colors: [styles.backgroundColor.gradientColorStart, styles.backgroundColor.gradientColorEnd],
      };
    }
  }

  const minAxisValue = Math.min(BUILDER_UI_RATIO_WIDTH, BUILDER_UI_RATIO_HEIGHT);

  if (styles?.border) {
    layerStyles.border = {
      x: pxToPct(styles.border.x, minAxisValue),
      y: pxToPct(styles.border.y, minAxisValue),
      color: styles.border.color,
    };
  }

  if (styles?.borderRadius) {
    layerStyles.borderRadius = [
      ...styles.borderRadius.map(({ x, y }) => ({
        x: pxToPct(x, minAxisValue),
        y: pxToPct(y, minAxisValue),
      })),
    ];
  }

  if (styles?.objectFit) {
    layerStyles.objectFit = styles.objectFit;
  }

  return layerStyles;
}
