import { AssetTranscript, AssetTranscriptSpeaker, AssetsFileProviderType } from '@openreel/creator/common';
import { TranscriptResponseParagraph } from './interfaces/transcript.interface';
import { getOrCreateSpeaker } from './utils/speaker.utils';
import { wordToAssetWords } from './utils/word.utils';

export function createAssetTranscript(
  assetId: number,
  assetProvider: AssetsFileProviderType,
  paragraphs: TranscriptResponseParagraph[]
) {
  const speakers = new Map<string, AssetTranscriptSpeaker>();
  const transcript: AssetTranscript = {
    words: [],
  };

  for (const paragraph of paragraphs) {
    let isFirstWord = true;
    let prevSpeakerId: string = null;

    for (let j = 0; j < paragraph.words.length; j++) {
      const word = paragraph.words[j];
      const speaker = word.speaker ? getOrCreateSpeaker(assetId, assetProvider, word.speaker, speakers) : null;
      const speakerId = speaker?.uid ?? null;
      const assetWords = wordToAssetWords(word, speakerId);

      if (transcript.words.length && (j === 0 || (prevSpeakerId !== speakerId && !isFirstWord))) {
        // Adds a new line before each paragraph or if speaker has changed
        transcript.words.push({
          text: '',
          type: 'newline',
          start: word.start,
          end: word.start,
          confidence: word.confidence,
          speakerId: speakerId,
        });
      }

      prevSpeakerId = speakerId;
      isFirstWord = false;

      transcript.words.push(...assetWords);
    }
  }

  return { transcript, speakers: [...speakers.values()] };
}
