import { cloneDeep } from 'lodash';
import { TemplateSchemaTransformation } from '../../../interfaces';
import { TemplateWorkflowDataDto_1_6 } from '../template_transformation_1_6/schema_after';
import { TemplateWorkflowDataDto_1_7 } from './schema_after';

export const TemplateSchemaTransformation_1_7: TemplateSchemaTransformation = {
  version: '1.7',

  run(oldSchema: TemplateWorkflowDataDto_1_6.WorkflowDataDto): TemplateWorkflowDataDto_1_7.WorkflowDataDto {
    const newSchema = cloneDeep(oldSchema) as unknown as TemplateWorkflowDataDto_1_7.WorkflowDataDto;

    newSchema.templateSections['intro'].useSameSectionForAllAspectRatios = true;
    newSchema.templateSections['outro'].useSameSectionForAllAspectRatios = true;

    return newSchema;
  },
};
