/* eslint-disable @typescript-eslint/no-explicit-any */
import { cloneDeep } from 'lodash';
import { v4 } from 'uuid';
import { getLayers } from '../../../helpers';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_1_8 } from '../transformation_1_8/schema_after';
import { WorkflowDataDto_1_9 } from './schema_after';

/*
  NOTE: Purpose of this transformation: cleanup of workflows so we can start validating project against JSON schema
    - remove NULL colors from styles
    - replace NULL fontWeight with 400 in styles
    - remove NULL transitions
    - remove NULL assetIds from globalSettings
    - add missing primary color
    - remove NULL layouts
    - rename layoutId into templateLayoutId
    - replace gradient NULL color with black
    - remove NULL globalSettings.textOverlays
    - cleanup assets
    - cleanup timelines
    - cleanup layers
*/
export const SchemaTransformation_1_9: SchemaTransformation = {
  version: '1.9',
  supportsPolyfill: false,

  run(oldSchema: WorkflowDataDto_1_8.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as unknown as WorkflowDataDto_1_9.WorkflowDataDto;

    // remove NULL colors from styles
    // replace NULL fontWeight with 400 in styles
    newSchema.styles.forEach((style) => {
      if (style.color === null) {
        delete style.color;
      }

      if (!style.fontWeight) {
        style.fontWeight = 400;
      }
    });

    // remove NULL transitions
    const mainTimeline = newSchema.timelines.find((t) => t.type === 'main');
    mainTimeline.layers.forEach((layer) => {
      if (layer.transitions === null) {
        delete layer.transitions;
      }
    });

    // remove NULL assetIds from globalSettings
    if (newSchema.globalSettings.backgroundVideo?.settings?.assetId === null) {
      delete newSchema.globalSettings.backgroundVideo.settings.assetId;
    }
    if (newSchema.globalSettings.logo?.settings?.assetId === null) {
      delete newSchema.globalSettings.logo.settings.assetId;
    }
    if (newSchema.globalSettings.watermark?.settings?.assetId === null) {
      delete newSchema.globalSettings.watermark.settings.assetId;
    }

    // add missing primary color
    const primaryColor = newSchema.globalSettings.primaryColor;
    if (primaryColor?.type === 'solid' && !primaryColor?.color) {
      primaryColor.color = '#000000';
    }

    // remove NULL layouts
    // rename layoutId into templateLayoutId
    if (newSchema.globalSettings.layout === null) {
      delete newSchema.globalSettings.layout;
    }
    for (const [, section] of Object.entries(newSchema.sections)) {
      if (section.layout === null) {
        delete section.layout;
      }

      if (!section.layout) {
        continue;
      }

      const sectionLayout = section.layout as any;
      if (!sectionLayout.templateLayoutId && sectionLayout.layoutId) {
        sectionLayout.templateLayoutId = sectionLayout.layoutId;
        delete sectionLayout.layoutId;
      }
    }

    if (newSchema.features.layouts?.styles?.border) {
      newSchema.features.layouts.styles.border.x = +newSchema.features.layouts.styles.border.x;
      newSchema.features.layouts.styles.border.y = +newSchema.features.layouts.styles.border.y;
    }

    if (newSchema.features.layouts?.styles?.borderRadius) {
      newSchema.features.layouts.styles.borderRadius.forEach((br) => {
        br.x = +br.x;
        br.y = +br.y;
      });
    }

    // replace gradient NULL color with black
    if (newSchema.features.layouts?.styles?.backgroundColor?.type === 'gradient') {
      newSchema.features.layouts.styles.backgroundColor.colors.forEach((gradientColor) => {
        if (gradientColor.color === null) {
          gradientColor.color = '#000000';
        }
      });
    }

    // remove NULL globalSettings.textOverlays
    if (newSchema.globalSettings.textOverlays === null) {
      delete newSchema.globalSettings.textOverlays;
    }

    if (typeof newSchema.globalSettings.fontIndex === 'undefined' || newSchema.globalSettings.fontIndex === null) {
      newSchema.globalSettings.fontIndex = 0;
    }

    // cleanup assets
    newSchema.assets.forEach((asset) => {
      cleanupAsset(asset);
    });
    newSchema.globalSettings.backgroundVideo?.uploaded.forEach((asset) => cleanupAsset(asset));
    newSchema.globalSettings.logo?.uploaded.forEach((asset) => cleanupAsset(asset));
    newSchema.globalSettings.watermark?.uploaded.forEach((asset) => cleanupAsset(asset));

    // cleanup timelines
    newSchema.timelines.forEach((timeline) => cleanupTimeline(timeline));
    for (const [, section] of Object.entries(newSchema.sections)) {
      section.timelines = section.timelines.filter(
        (t) => (t.type as any) !== 'freemium' && (t.type as any) !== 'hidden'
      );

      section.timelines.forEach((timeline) => {
        cleanupTimeline(timeline);

        if (timeline.layers.length === 1 && timeline.layers[0].type === 'timelines') {
          timeline.layers[0].children.forEach((childTimeline) => cleanupTimeline(childTimeline));
        }
      });
    }

    // cleanup layers
    for (const { layer } of getLayers(newSchema as any)) {
      cleanupLayer(layer as WorkflowDataDto_1_9.Layer);
    }

    // cleanup fonts
    newSchema.fonts.forEach((font) => {
      if (typeof (font as any).weight === 'number') {
        (font as any).weight = (font as any).weight.toString();
      }
    });

    return newSchema;
  },
};

function cleanupLayer(layer: WorkflowDataDto_1_9.Layer) {
  if (layer.type === 'image' || layer.type === 'video' || layer.type === 'lottie') {
    if (layer.assetId === null) {
      delete layer.assetId;
    }
  }

  if (layer.type === 'lottie') {
    for (const [, field] of Object.entries(layer.data)) {
      if (field.type === 'shape') {
        delete field.value;
      }
      if (field.assetId === null) {
        delete field.assetId;
      }
    }
  }

  if (layer.bounds?.x === null) {
    layer.bounds.x = 0;
  }
  if (layer.bounds?.y === null) {
    layer.bounds.y = 0;
  }
  if (layer.bounds?.height === null) {
    delete layer.bounds.height;
  }

  if (layer.transitions === null) {
    delete layer.transitions;
  }

  if ((layer as any).editing === null) {
    delete (layer as any).editing;
  }

  if ((layer as any).loop === null) {
    (layer as any).loop = false;
  }

  if (layer.type === 'lottie' && layer.colorTags === null) {
    layer.colorTags = [];
  }

  if (typeof layer.styles?.backgroundColor === 'string') {
    layer.styles.backgroundColor = {
      type: 'solid',
      color: layer.styles.backgroundColor,
    };
  }

  if (layer.styles?.borderRadius === null) {
    delete layer.styles.borderRadius;
  }

  if (typeof layer.styles?.borderRadius === 'object' && !Array.isArray(layer.styles.borderRadius)) {
    layer.styles.borderRadius = [layer.styles.borderRadius];
  }

  if (layer.styles?.border) {
    layer.styles.border.x = +layer.styles.border.x;
    layer.styles.border.y = +layer.styles.border.y;
  }

  if (layer.styles?.borderRadius) {
    layer.styles.borderRadius.forEach((br) => {
      br.x = +br.x;
      br.y = +br.y;
    });
  }
}

function cleanupTimeline(timeline: WorkflowDataDto_1_9.Timeline) {
  if (timeline.bounds === null) {
    delete timeline.bounds;
  }
  if (timeline.title === null) {
    delete timeline.title;
  }
  if (timeline.controls === null) {
    delete timeline.controls;
  }
  if (timeline.hasAudio === null) {
    delete timeline.hasAudio;
  }
  if (timeline.isLayerSelectable === null) {
    delete timeline.isLayerSelectable;
  }
  if (timeline.zIndex === null) {
    delete timeline.zIndex;
  }
  if (timeline.id === null) {
    timeline.id = v4();
  }

  if (typeof timeline.styles?.backgroundColor === 'string') {
    timeline.styles.backgroundColor = {
      type: 'solid',
      color: timeline.styles.backgroundColor,
    };
  }

  if (typeof timeline.styles?.borderRadius === 'object' && !Array.isArray(timeline.styles.borderRadius)) {
    timeline.styles.borderRadius = [timeline.styles.borderRadius];
  }

  if (timeline.styles?.border) {
    timeline.styles.border.x = +timeline.styles.border.x;
    timeline.styles.border.y = +timeline.styles.border.y;
  }

  if (timeline.styles?.borderRadius) {
    timeline.styles.borderRadius.forEach((br) => {
      br.x = +br.x;
      br.y = +br.y;
    });
  }
}

function cleanupAsset(asset: WorkflowDataDto_1_9.Asset) {
  if (!asset.type) {
    asset.type = 'clip';
  }

  if (asset.type !== 'json') {
    delete asset.preset;
    delete asset.colorTags;
  }

  if (!asset.file) {
    asset.file = {
      path: null,
      provider: 'or-assets',
    };
  }

  if (asset.file.provider === null) {
    asset.file.provider = 'or-assets';
  }

  if (asset.type !== 'clip') {
    delete asset.trimFrom;
    delete asset.trimTo;
  }

  if (asset.fileName === null) {
    delete asset.fileName;
  }

  if (asset.name === null) {
    delete asset.name;
  }

  if (asset.isPlaceholder === null) {
    delete asset.isPlaceholder;
  }

  if (asset.isGlobal === null) {
    delete asset.isGlobal;
  }

  if (asset.tags === null) {
    asset.tags = [];
  }

  if (asset.trimFrom === null) {
    delete asset.trimFrom;
  }

  if (asset.trimTo === null) {
    delete asset.trimTo;
  }
}
