<h1 mat-dialog-title>
  Live Broadcast Destinations
  <button (click)="onDialogCloseClicked()" mat-button style="float: right">
    <mat-icon color="primary">close</mat-icon>
  </button>
</h1>
<div mat-dialog-content fxLayout="column" fxLayoutGap="5px" class="dialog-content">
  <mat-form-field appearance="fill">
    <mat-label>Select Service</mat-label>
    <mat-select [formControl]="currentDestination" (selectionChange)="changeDestination()">
      <mat-option *ngFor="let data of destinations" [value]="data">{{ data.name }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Stream URL</mat-label>
    <input matInput [readOnly]="fetchingUrl" [formControl]="streamUrl" placeholder="Enter URL" />
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Stream Key</mat-label>
    <input matInput [readOnly]="fetchingUrl" [formControl]="streamKey" placeholder="Enter Stream Key" />
  </mat-form-field>
  <div class="btn-wrapper">
    <img
      *ngIf="currentDestination.value.value === 'youtube' && !fetchingUrl"
      (click)="getYoutubeStreamUrl()"
      class="dest-icon"
      tooltip="Get Stream URL using google sign in"
      src="assets/common/images/google_login_logo.png"
      alt="Sign in with Google"
    />
    <button
      class="stream-url-btn"
      tooltip="Get Stream URL using faceboook sign in"
      mat-raised-button
      color="primary"
      *ngIf="currentDestination.value.value === 'facebook' && !fetchingUrl"
      (click)="getFacebookStreamUrl()"
    >
      <img width="25" src="assets/common/images/fb_logo.png" alt="" />
      <span>Log in With Facebook</span>
    </button>

    <button mat-raised-button color="primary" (click)="saveDestination()">Save</button>
  </div>
</div>
