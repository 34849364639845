import { LayerIdEvent, LayerOptions, LottieLayer, TextEffect } from '../interfaces';
import { getLayerFromId } from '../helpers';
import { cloneDeep } from 'lodash';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export interface UpdateTextBoxEffectEvent extends LayerIdEvent {
  textEffect?: TextEffect;
}

export class UpdateTextBoxEffectCommand extends WorkflowBaseBuilder<
  UpdateTextBoxEffectEvent,
  LayerOptions & LottieLayer
> {
  run({ layerId, textEffect }: UpdateTextBoxEffectEvent) {
    const { layer } = getLayerFromId(layerId, this.source);

    if (layer.type !== 'lottie') {
      return this.error('Layer is not a lottie.');
    }

    if (!layer.isTextBox) {
      return this.error('Layer is not a text box layer.');
    }

    layer.textEffect = cloneDeep(textEffect);

    return this.ok(layer);
  }
}
