import { v4 as uuidv4 } from 'uuid';
import { Asset } from './../interfaces/workflow.interfaces';

export function createImageAsset(assetFileId: number) {
  const asset: Asset = {
    id: uuidv4(),
    type: 'image',
    file: {
      path: assetFileId,
      provider: 'or-assets',
    },
  };

  return asset;
}
