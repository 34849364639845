import { SectionId, TemplateBuilderSectionAspectRatioFrom, TemplateSection } from '../interfaces';
import { createIntroOutroSectionFromBuilder } from '../template-builder/createIntroOutroSection';
import { WorkflowTemplatesBaseBuilder } from './workflow-templates-base.builder';

export interface BuilderSectionAspectRatioChangeCommandEvent {
  sectionId: SectionId.Intro | SectionId.Outro;
  aspectRatioId: number;
  aspectRatioForm: TemplateBuilderSectionAspectRatioFrom;
}

export class BuilderSectionAspectRatioChangeCommand extends WorkflowTemplatesBaseBuilder<BuilderSectionAspectRatioChangeCommandEvent> {
  run(event: BuilderSectionAspectRatioChangeCommandEvent) {
    this.saveTemplateSectionData(event);

    const sourceTemplateSection = this.source.templateSections[event.sectionId];
    if (sourceTemplateSection.isEnabled) {
      const sectionToCopy = sourceTemplateSection.sections.find((s) => s.aspectRatioId === event.aspectRatioId);
      this.copyTemplateSection(event.sectionId, sectionToCopy, true);
    } else {
      this.removeTemplateSection(event.sectionId);
    }

    return this.ok();
  }

  private saveTemplateSectionData(event: BuilderSectionAspectRatioChangeCommandEvent) {
    const sourceTemplateSection = this.source.templateSections[event.sectionId];

    const newTemplateSection = createIntroOutroSectionFromBuilder(
      event.sectionId,
      event.aspectRatioForm,
      this.source
    ) as TemplateSection;

    const sectionIndicesToUpdate: number[] = [];

    if (sourceTemplateSection.useSameSectionForAllAspectRatios) {
      sectionIndicesToUpdate.push(...sourceTemplateSection.sections.map((_, index) => index));
    } else {
      const sectionToChangeIndex = sourceTemplateSection.sections.findIndex(
        (s) => s.aspectRatioId === event.aspectRatioId
      );
      sectionIndicesToUpdate.push(sectionToChangeIndex);
    }

    for (const idx of sectionIndicesToUpdate) {
      sourceTemplateSection.sections[idx] = {
        ...this.preserveIdsInSection(sourceTemplateSection.sections[idx], newTemplateSection),
        aspectRatioId: sourceTemplateSection.sections[idx].aspectRatioId,
      };
    }

    const hasMainLayer = newTemplateSection.section.timelines.find((t) => t.type === 'main').layers.length > 0;
    if (hasMainLayer) {
      sourceTemplateSection.isEnabled = true;
    }

    if (sourceTemplateSection.isEnabled) {
      const logoAsset = this.getAsset(this.source.globalSettings.logo.settings.assetId);
      if (!logoAsset.file) {
        this.updateLogo(null, null);
      }
    }
  }

  private preserveIdsInSection(oldSection: TemplateSection, newSection: TemplateSection) {
    const oldMainTimeline = oldSection.section.timelines.find((t) => t.type === 'main');
    const oldOverlayTimeline = oldSection.section.timelines.find((t) => t.type === 'overlays');

    const newMainTimeline = newSection.section.timelines.find((t) => t.type === 'main');
    const newOverlayTimeline = newSection.section.timelines.find((t) => t.type === 'overlays');

    newMainTimeline.id = oldMainTimeline.id;
    if (newMainTimeline.layers.length > 0 && oldMainTimeline.layers.length > 0) {
      newMainTimeline.layers[0].layerId = oldMainTimeline.layers[0].layerId;
    }

    if (newOverlayTimeline && oldOverlayTimeline) {
      newOverlayTimeline.id = oldOverlayTimeline.id;
      if (newOverlayTimeline.layers.length > 0 && oldOverlayTimeline.layers.length > 0) {
        newOverlayTimeline.layers[0].layerId = oldOverlayTimeline.layers[0].layerId;
      }
    }

    return newSection;
  }
}
