/* eslint-disable @typescript-eslint/no-explicit-any */
import { cloneDeep } from 'lodash';
import { SchemaTransformation } from '../../../interfaces/schema-transformation.interfaces';
import { WorkflowDataDto_2_9 } from '../transformation_2_9/schema_after';

/*
  NOTE: Purpose of this transformation:
    - 1) update lottie path for text box assets
*/
export const SchemaTransformation_3_0: SchemaTransformation = {
  version: '3.0',
  supportsPolyfill: false,

  run(oldSchema: WorkflowDataDto_2_9.WorkflowDataDto) {
    const newSchema = cloneDeep(oldSchema) as unknown as WorkflowDataDto_2_9.WorkflowDataDto;

    const asset = newSchema.assets?.find((asset) => asset.id === 'text-box-element-asset');
    if (asset) {
      asset.preset['text'].lottiePath = 'layers';
    }

    return newSchema;
  },
};
