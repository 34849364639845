import { Injectable } from '@angular/core';
import { FacebookService, InitParams, LoginResponse } from 'ngx-facebook';
import { environment } from '../../../../environments/environment';
interface LiveStreamUrl {
  stream_url: string;
  secure_stream_url: string;
}

@Injectable({ providedIn: 'root' })
export class FacebookStreamingService {
  constructor(private fb: FacebookService) {}
  initFacebook() {
    if (environment.facebookAppId) {
      const initParams: InitParams = {
        appId: environment.facebookAppId,
        xfbml: true,
        version: 'v2.8',
      };
      this.fb.init(initParams);
    }
  }
  initLogin(): Promise<LoginResponse> {
    return new Promise((resolve, reject) => {
      this.fb
        .login({ scope: 'publish_video', return_scopes: true })
        .then((response: LoginResponse) => resolve(response))
        .catch((error: Error) => reject(error));
    });
  }
  getLiveVideoDetails(token: string): Promise<LiveStreamUrl> {
    return new Promise((resolve, reject) => {
      this.fb
        .api('/me/live_videos?status=LIVE_NOW&access_token=' + token, 'post')
        .then((response: LiveStreamUrl) => resolve(response))
        .catch((error: Error) => reject(error));
    });
  }
}
