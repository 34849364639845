import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { RecordStreamStatus } from 'libs/ui/src/interfaces';

@Component({
  selector: 'openreel-video-stream',
  templateUrl: './openreel-video-stream.component.html',
  styleUrls: ['./openreel-video-stream.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FlexLayoutModule, MatLegacyButtonModule, MatIconModule],
})
export class OpenreelVideoStreamComponent implements AfterViewInit {
  @Input() isScreenShareStream: boolean;
  @Input() isInternalUser: boolean;
  @Input() logoUrl: string;
  @Input() isRecording: boolean;
  @Input() enableVideo: boolean;
  @Input() participantName: string;
  @Input() streamStatus: RecordStreamStatus;
  @Input() mirrored: boolean;
  @Input() isMe: boolean;
  @Input() storage: number;
  @Input() fullScreenControl = true;


  private _stream: MediaStream;
  public isFullscreen = false;

  @Input() set stream(stream: MediaStream) {
    this._stream = stream;
    this.setStream();
  }
  get stream() {
    return this._stream;
  }

  @ViewChild('videoPlayer') videoPlayer: ElementRef;
  @ViewChild('videoWrapperElement')
  videoWrapperElement: ElementRef<HTMLElement>;

  ngAfterViewInit(): void {
    this.setStream();
  }

  toggleFullscreen() {
    this.isFullscreen = !this.isFullscreen;
  }

  async changeFullScreen() {
    if (this.isFullscreen) {
      await document?.exitFullscreen();
    } else {
      await this.videoWrapperElement.nativeElement.requestFullscreen();
    }
  }

  private setStream() {
    if (this._stream && this.videoPlayer) {
      const currentStream = this.videoPlayer.nativeElement.srcObject;
      if (!currentStream || (currentStream && currentStream.getVideoTracks()[0] !== this._stream.getVideoTracks()[0])) {
        this.videoPlayer.nativeElement.srcObject = this._stream;
      }
    }
  }

  private getInitials(name: string) {
    const filterNameArr = name.split('(');
    const filterName = filterNameArr[0].trim();
    const names = filterName.split(' ');
    let initials = names[0].substring(0, 1);
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1);
    }
    return initials.toUpperCase();
  }
}
