import { NumberRange, TimeAndIndexRange } from '@openreel/common';
import {
  AssetTranscriptTermType,
  AssetTranscriptTextCutToken,
  AssetTranscriptTrimmerWord,
  AssetTranscriptWord,
} from '@openreel/creator/common';
import { orderBy, sortBy } from 'lodash';
import { TranscriptResponseWord } from '../interfaces/transcript.interface';
import { searchWordIndicesInRange } from '../trim-paragraphs';

enum PunctuationMark {
  Comma = ',',
  Semicolon = ';',
  Ellipsis = '\u2026',
  Colon = ':',
  QuestionMark = '?',
  ExclamationPoint = '!',
  Period = '.',
}

// eslint-disable-next-line max-len
export const WORD_END_MARKS = `${PunctuationMark.Comma}${PunctuationMark.Semicolon}${PunctuationMark.Colon}${PunctuationMark.Period}${PunctuationMark.Ellipsis}${PunctuationMark.QuestionMark}${PunctuationMark.ExclamationPoint}`;

export function wordsToText(words: { text: string; type: AssetTranscriptTermType }[]): string {
  let result = '';

  for (let i = 0; i < words.length; i++) {
    const word = words[i];

    if (word.type !== 'word' && word.type !== 'mark') {
      continue;
    }

    if (!result.length || word.type === 'mark') {
      result += word.text;
    } else {
      result += ` ${word.text}`;
    }
  }

  return result;
}

export function wordToAssetWords(word: TranscriptResponseWord, speakerId: string): AssetTranscriptWord[] {
  const lastLetter = word.text[word.text.length - 1];

  if (!WORD_END_MARKS.includes(lastLetter)) {
    return [
      {
        type: 'word',
        text: word.text,
        start: word.start,
        end: word.end,
        confidence: word.confidence,
        speakerId,
      },
    ];
  }

  return [
    {
      type: 'word',
      text: word.text.slice(0, -1),
      start: word.start,
      end: word.end,
      confidence: word.confidence,
      speakerId,
    },
    {
      type: 'mark',
      text: lastLetter,
      start: word.end,
      end: word.end,
      confidence: word.confidence,
      speakerId,
    },
  ];
}

export function splitTextToWords(text: string) {
  const regex = new RegExp(
    `(?<word>[^${WORD_END_MARKS}\\s]+([${WORD_END_MARKS}]+[^${WORD_END_MARKS}\\s]+)+|[^${WORD_END_MARKS}\\s]+)|(?<mark>[${WORD_END_MARKS}])`,
    'g'
  );
  const words: { text: string; type: 'word' | 'mark' }[] = [];

  for (let match = regex.exec(text); match; match = regex.exec(text)) {
    const { word, mark } = match.groups;

    words.push({
      type: word ? 'word' : 'mark',
      text: word ?? mark,
    });
  }

  return words;
}

export function textToRawAssetWords(text: string, speakerId: string, confidence = 1) {
  const rawWords = splitTextToWords(text);

  return rawWords.map((word) => ({
    type: word.type,
    text: word.text,
    start: null,
    end: null,
    confidence,
    speakerId,
  }));
}

// eslint-disable-next-line max-lines-per-function
export function distributeRawAssetWordsInRanges(words: AssetTranscriptWord[], ranges: TimeAndIndexRange[]) {
  const weightedRanges = orderBy(
    ranges.map((range, rangeIndex) => ({
      rangeIndex,
      weight: range.toTime - range.fromTime + 1,
    })),
    (range) => range.weight,
    'desc'
  );

  const totalWeight = weightedRanges.reduce((sum, range) => sum + range.weight, 0);
  const totalWordsCount = words.reduce((count, words) => count + (words.type === 'word' ? 1 : 0), 0);

  const rangeDistributionInfos = weightedRanges.map((range) => ({
    rangeIndex: range.rangeIndex,
    wordCapacity: (totalWordsCount * range.weight) / totalWeight,
    words: [] as AssetTranscriptWord[],
    wordsCount: 0,
  }));

  for (const word of words) {
    let rangeInfo = rangeDistributionInfos[0];

    // If not a word, ignore distribution order
    if (word.type !== 'word') {
      rangeInfo.words.push(word);
      continue;
    }

    // Find the correct sorted position for the rangeInfo
    let index = 0;
    while (
      rangeDistributionInfos[index + 1] &&
      rangeInfo.wordsCount / rangeInfo.wordCapacity >
        rangeDistributionInfos[index + 1].wordsCount / rangeDistributionInfos[index + 1].wordCapacity
    ) {
      index++;
    }

    if (index !== 0) {
      rangeDistributionInfos.splice(0, 1);
      rangeDistributionInfos.splice(index, 0, rangeInfo);
    }

    // Get less filled range
    rangeInfo = rangeDistributionInfos[0];
    rangeInfo.words.push(word);
    rangeInfo.wordsCount++;
  }

  for (const info of rangeDistributionInfos) {
    const range = ranges[info.rangeIndex];
    const rangeDuration = range.toTime - range.fromTime + 1;
    const wordDuration = rangeDuration / info.wordsCount;

    let currentTime = range.fromTime;
    let wordIndex = 0; // index of words (excludes marks)

    for (const word of info.words) {
      if (word.type !== 'word') {
        word.start = currentTime;
        word.end = currentTime;
        continue;
      }

      word.start = Math.min(currentTime + (wordIndex ? 1 : 0), range.toTime);
      if (wordIndex === info.wordsCount - 1) {
        word.end = range.toTime;
      } else {
        word.end = Math.max(word.start, Math.floor(range.fromTime + wordDuration * (wordIndex + 1)));
      }

      wordIndex++;
      currentTime = word.end;
    }
  }

  return sortBy(rangeDistributionInfos, (info) => info.rangeIndex).map((info) => ({
    range: ranges[info.rangeIndex],
    words: info.words,
  }));
}

export function getTextCutToken(
  words: AssetTranscriptWord[],
  cutRange: NumberRange,
  options: { assetId: string; sectionId: string; uiStart: number }
): AssetTranscriptTextCutToken {
  const indices = searchWordIndicesInRange(words, cutRange);

  let speakerId = null;
  let hasText = false;

  if (indices.startIndex !== -1) {
    speakerId = indices.startIndex !== -1 ? words[indices.startIndex].speakerId : null;
    for (let i = indices.startIndex; i <= indices.endIndex; i++) {
      hasText = words[i].type === 'word' || words[i].type === 'mark';

      if (hasText) {
        break;
      }
    }
  } else {
    let adjacentIndex = getWordIndexAt(words, cutRange.from, 'before');

    if (adjacentIndex === -1) {
      adjacentIndex = getWordIndexAt(words, cutRange.from, 'after');
    }

    if (adjacentIndex !== -1) {
      speakerId = words[adjacentIndex].speakerId;
    }
  }

  return {
    type: 'text-cut',
    text: '',
    start: options.uiStart,
    end: options.uiStart,
    assetId: options.assetId ?? null,
    sectionId: options.sectionId ?? null,
    originalStart: cutRange.from,
    originalEnd: cutRange.to,
    originalIndex: -1,
    confidence: 1,
    speakerId,
    hasText,
  };
}

export function getWordIndexAt(
  words: (AssetTranscriptWord | AssetTranscriptTrimmerWord)[],
  time: number,
  find: 'exact' | 'before' | 'after' = 'exact'
) {
  let wordIndex = -1;
  let start = 0;
  let end = words.length - 1;

  if (time < 0) {
    return wordIndex;
  }

  for (let index = Math.floor((start + end) / 2); start <= end; index = Math.floor((start + end) / 2)) {
    if (time > words[index].end) {
      start = index + 1;
      continue;
    }

    if (time < words[index].start) {
      end = index - 1;
      continue;
    }

    wordIndex = index;
    break;
  }

  if (wordIndex === -1) {
    switch (find) {
      case 'after':
        return start > words.length - 1 ? -1 : start;
      case 'before':
        return end < 0 ? -1 : end;
      case 'exact':
      default:
        return -1;
    }
  }

  //Find the first term at 'time'
  for (let index = wordIndex; index >= 0; index--) {
    if (words[index].end < time) {
      break;
    }

    wordIndex = index;
  }

  return wordIndex;
}
