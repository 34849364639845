import { Injectable, OnDestroy } from '@angular/core';
import {
  commonenv,
  EmailTestEmailResultsDto,
  EmailTestEntity,
  ROUTE_OAUTH_CONNECTIONS,
} from '@openreel/frontend/common';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseOauthService } from './base-oauth.service';
import { ConnectionAuthEntity } from '@openreel/common/automations';

@Injectable({
  providedIn: 'root',
})
export class OutlookService implements OnDestroy {
  public urls = {
    redirectUrl: commonenv.automationsApiUrl + 'connections/outlook/auth',
    callbackUrl: commonenv.automationsApiUrl + 'connections/outlook/auth/callback',
    testUrl: commonenv.automationsApiUrl + 'connections/outlook/test',
  };

  constructor(
    private readonly baseOauthService: BaseOauthService,
    private readonly httpClient: HttpClient,
  ) { }

  sendTest(data: EmailTestEntity): Observable<EmailTestEmailResultsDto> {
    return this.httpClient.post<EmailTestEmailResultsDto>(this.urls.testUrl, data);
  }

  login(): Promise<ConnectionAuthEntity> {
    this.baseOauthService.openOauthWindow(`/${ROUTE_OAUTH_CONNECTIONS}/outlook`, 'Outlook Login');

    return new Promise<ConnectionAuthEntity>((resolve) => {
      window['outlookCallback'] = (connection: ConnectionAuthEntity) => {
        resolve(connection);
        delete window['outlookCallback'];
      };
    });
  }

  getRedirectUrl() {
    return this.httpClient.get<{ redirectUrl: string }>(this.urls.redirectUrl, {});
  }

  authCallback(code: string, state: string) {
    return this.httpClient.post<ConnectionAuthEntity>(this.urls.callbackUrl, { code, state });
  }

  ngOnDestroy() {
    delete window['outlookCallback'];
  }
}
