export enum VideoQuality {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export interface VideoQualityOption {
  label: string;
  value: VideoQuality;
}

export const videoQualityOptions: VideoQualityOption[] = [
  {
    label: 'Low',
    value: VideoQuality.Low,
  },
  {
    label: 'Medium',
    value: VideoQuality.Medium,
  },
  {
    label: 'High',
    value: VideoQuality.High,
  },
];