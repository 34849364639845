import { getLayerFromId } from '../helpers/timelines.helpers';
import { LayerIdEvent } from '../interfaces';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export interface ToggleLayerFitEvent extends LayerIdEvent {
  objectFit: 'contain' | 'cover';
}

export class UpdateLayerFitCommand extends WorkflowBaseBuilder<ToggleLayerFitEvent> {
  run(event: ToggleLayerFitEvent) {
    const layerInfo = getLayerFromId(event.layerId, this.source);
    if (layerInfo === null) {
      return { success: false };
    }

    const { layer } = layerInfo;

    layer.styles = {
      ...layer.styles,
      objectFit: event.objectFit,
    };

    if (event.objectFit === 'contain') {
      delete layer.styles.objectPosition;
    } else if (event.objectFit === 'cover' && !layer.styles.objectPosition) {
      layer.styles.objectPosition = [50, 50];
    }

    return this.ok();
  }
}
