import { AssetType, LayerType } from './../interfaces/workflow.interfaces';

export const assetTypeToLayerType = (assetType: AssetType): LayerType => {
  switch (assetType) {
    case 'clip':
      return 'video';
    case 'image':
      return 'image';
    case 'json':
      return 'lottie';
    default:
      throw new Error(`Can't find upload type for asset type: '${assetType}'`);
  }
};
