import { Component, Inject, ViewEncapsulation } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

export interface ShowTextPopupInput {
  full_text: string;
  title: string;
  highlightWords?: string[];
}

@Component({
  selector: 'openreel-show-text-popup',
  templateUrl: './show-text-popup.component.html',
  styleUrls: ['./show-text-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ShowTextPopupComponent {
  fullText: string;
  title: string;
  isLightThemed = true;
  isPopup = true;
  highlightWords: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ShowTextPopupInput,
    private dialogRef: MatDialogRef<ShowTextPopupComponent>
  ) {
    this.fullText = data.full_text;
    this.title = data.title;
    this.highlightWords = data.highlightWords || [];
  }

  closePopup() {
    this.dialogRef.close();
  }
}
